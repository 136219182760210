import React, { useContext, useMemo } from 'react'

import * as styled from '../CustomPicker.styled'

import { CustomPicker } from 'components'
import { DeviceSettingsContext, selectedDefaultState } from 'context'
import { StepStatus, Task, getTaskTitle } from 'hooks'
import { Warning } from 'utils'
import { ErrorsItem, WarningsItem } from './components'

interface Props {
  close: () => void
  warnings: Warning[]
  failedTasks: Task[]
}

export const IssuesPicker: React.FC<Props> = ({ close, warnings, failedTasks }) => {
  const {
    setSelected,
    selected: { screenId, componentId },
  } = useContext(DeviceSettingsContext)

  const onClick = (val: string) => setSelected({ ...selectedDefaultState, ...JSON.parse(val) })

  const values = useMemo(() => {
    const items: any = {}
    warnings.forEach(el => {
      if (!items[el.label]) {
        items[el.label] = []
      }
      items[el.label].push({ label: el.insideLabel, value: el.value })
    })
    return Object.keys(items).map(el => ({ label: el, values: items[el] }))
  }, [warnings])

  const value = JSON.stringify({ screenId, componentId })

  return (
    <CustomPicker
      title="Issues"
      close={close}
      optionsContainer={
        <styled.Items noSearchMode height="411px">
          {failedTasks.map(el => (
            <ErrorsItem
              key={el.id}
              error={{
                label: getTaskTitle(el),
                values:
                  el.steps
                    ?.filter(el => el.status === StepStatus.FAILED)
                    ?.map(el => ({ label: `${el.type}: ${el.error}` })) || [],
              }}
            />
          ))}
          {values.map(el => (
            <WarningsItem key={el.label} warning={el} onClick={onClick} value={value} />
          ))}
        </styled.Items>
      }
    />
  )
}
