import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useMemo, useState } from 'react'

import * as styled from './Common.styled'

import {
  Button,
  ButtonColor,
  ButtonSize,
  InputProperty,
  inputs,
  Loader,
  LoaderVariant,
  ModalActions,
  validate,
} from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { ProjectsService } from 'services/projects'
import { ValueType } from 'utils'
import { Terms } from './Terms'

interface Props {
  close: () => void
}

export const Login: React.FC<Props> = ({ close }) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)

  const [wait, setWait] = useState(false)

  const formik = useFormik({
    initialValues: { username: null, password: null },
    validate: data => validate(data, properties),
    onSubmit: data => {
      const { username, password } = data
      if (username && password) {
        setWait(true)
        ProjectsService.startAppstoreSession(id, { username, password })
          .catch(err => toast(err))
          .finally(() => setWait(false))
      }
    },
  })

  const properties = useMemo(
    (): InputProperty[] => [
      {
        name: 'username',
        type: ValueType.string,
        position: 0,
        isRequired: true,
        title: 'Apple ID',
      },
      {
        name: 'password',
        type: ValueType.password,
        position: 1,
        isRequired: true,
        title: 'Apple Password',
      },
    ],
    []
  )

  return wait ? (
    <Box height="388px">
      <Loader variant={LoaderVariant.DOTS} />
    </Box>
  ) : (
    <>
      <styled.SubTitle>Connect App Store Connect to enable automated publishing and integrations</styled.SubTitle>
      {inputs(properties, formik)}
      <Terms mt="30px" />
      <ModalActions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={close}>
          Cancel
        </Button>
        <Button size={ButtonSize.DEFAULT} onClick={formik.submitForm} loading={wait}>
          Continue
        </Button>
      </ModalActions>
    </>
  )
}
