import styled from 'styled-components'

export const Chat = styled.div<{ isMD: boolean; hidden?: boolean; initialMessage?: boolean }>`
  position: relative;
  width: ${props => (props.isMD ? '100%' : props.initialMessage ? '620px' : '510px')};
  height: 100%;
  min-width: ${props => (props.isMD ? '100%' : props.initialMessage ? '620px' : '510px')};
  max-width: 100%;
  padding-top: 60px;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
`

export const MessagesBox = styled.div`
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column-reverse;
  gap: 36px;
  overflow: auto;
`

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 30px;
`

export const EditMode = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.darkText};
  background-color: ${props => props.theme.colors.blue_50};
  padding: 3px 3px 3px 10px;
  & svg {
    cursor: pointer;
    & path {
      transition: ${props => props.theme.transition.primary};
    }
    &:hover {
      & path {
        stroke: ${props => props.theme.colors.iconHover};
      }
    }
  }
`

export const Form = styled.form<{ blockTyping?: boolean }>`
  opacity: ${props => props.blockTyping && '0.6'};
  pointer-events: ${props => props.blockTyping && 'none'};
`

export const Input = styled.div<{ initialMessage?: boolean }>`
  min-height: ${props => (props.initialMessage ? 80 : 40)}px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
  background: ${props => props.theme.colors.white};
  & > div {
    width: calc(100% - 32px);
    min-height: ${props => (props.initialMessage ? 78 : 38)}px;
    font-size: 14px;
    line-height: 150%;
    padding: 9.5px 10px;
    color: ${props => props.theme.colors.darkText};
    outline: 0;
    &:empty:before {
      content: attr(placeholder);
      color: ${props => props.theme.colors.grey1};
    }
  }
  transition: ${props => props.theme.transition.primary};
  &:focus-within,
  &:hover {
    border-color: ${props => props.theme.colors.primaryText};
  }
`

export const SendMessageButton = styled.button<{ disabled: boolean; generating?: boolean }>`
  pointer-events: ${props => props.disabled && 'none'};
  opacity: ${props => props.disabled && '0.3'};
  cursor: ${props => !props.disabled && 'pointer'};
  position: absolute;
  right: 8px;
  bottom: 8px;
  border: none;
  background-color: transparent;
  padding: 0;
  display: inline-flex;
  & > svg {
    & > path {
      display: ${props => props.generating && 'none'};
    }
    & > rect:last-child {
      display: ${props => !props.generating && 'none'};
    }
  }
  &:hover > svg > rect:first-child {
    fill: ${props => props.theme.colors.primaryTextHover};
  }
`

export const BottomText = styled.div`
  width: 100%;
  height: 50px;
  min-height: 50px;
  padding: 5px 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: center;
  color: ${props => props.theme.colors.grey};
`

export const InitialMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  flex: 1;
  padding: 0 30px;
`

export const InitialMessageTitle = styled.h1<{ isMD: boolean }>`
  margin-top: -80px;
  font-size: ${props => (props.isMD ? 24 : 32)}px;
  font-weight: 600;
  line-height: ${props => (props.isMD ? 28 : 41.6)}px;
  text-align: center;
  color: ${props => props.theme.colors.darkText};
`

export const DashedLogo = styled.img<{ initialMessage?: boolean; right?: boolean }>`
  position: absolute;
  left: ${props => !props.right && (props.initialMessage ? '-353px' : '-408px')};
  right: ${props => props.right && (props.initialMessage ? '-353px' : '-408px')};
  top: 50%;
  width: 353px;
  transform: translateY(-50%) ${props => props.right && 'rotate(180deg)'};
`
