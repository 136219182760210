import { useContext, useEffect, useState } from 'react'

import { ProgressContext } from 'context'
import { firestore } from 'services/firebase'
import { Collection, Config, DataObject, generateFirestoreId, getComponentPositionFromName, Screen } from 'utils'

interface PickerScreen {
  id: string
  position: number
  count: number
  name: string
  thumbnailUrls: string[]
}

export const useScreensToAdd = () => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [screens, setScreens] = useState<PickerScreen[]>([])

  useEffect(() => {
    const unsubscribe = firestore
      .collection('screens')
      .where('position', '==', 0)
      .onSnapshot({
        next: res => setScreens(res.docs.map(el => ({ id: el.id, ...el.data() })) as PickerScreen[]),
        error: err => toast(err),
      })
    return () => {
      unsubscribe()
    }
  }, [])

  const addScreens = (id: string, setConfig: (val: Config | ((prev: Config) => Config)) => void, close: () => void) => {
    startLoader()
    firestore
      .collection(`screens/${id}/configurations`)
      .doc('main')
      .get()
      .then(res => {
        const resScreens = res.data()?.screens
        const screens = (resScreens ? JSON.parse(resScreens) : null) as Screen[]
        const resCollections = res.data()?.collections
        const collections = (resCollections ? JSON.parse(resCollections) : null) as Collection[]
        const resDataObjects = res.data()?.dataObjects
        const dataObjects = (resDataObjects ? JSON.parse(resDataObjects) : null) as DataObject[]
        if (screens) {
          setConfig(config => {
            const configCopy = JSON.parse(JSON.stringify(config)) as Config
            if (collections) {
              configCopy.data.collections = [
                ...(configCopy.data.collections?.filter(a => !collections.find(b => a.name === b.name)) || []),
                ...collections,
              ]
            }
            if (dataObjects) {
              configCopy.data.dataObjects = [
                ...(configCopy.data.dataObjects?.filter(a => !dataObjects.find(b => a.name === b.name)) || []),
                ...dataObjects,
              ]
            }
            const firstScreen = screens[0]
            if (firstScreen.screenName === 'Screen 1') {
              const prevPos = getComponentPositionFromName(
                configCopy.screens
                  ?.filter(el => el.screenName.startsWith('Screen '))
                  .sort((a, b) =>
                    getComponentPositionFromName(a.screenName) > getComponentPositionFromName(b.screenName) ? 1 : -1
                  )
                  .slice(-1)[0]?.screenName
              )
              firstScreen.id = generateFirestoreId()
              firstScreen.screenName = `Screen ${prevPos + 1}`
            }
            configCopy.screens = [
              ...(configCopy.screens?.filter(a => !screens.find(b => a.screenName === b.screenName)) || []),
              ...screens,
            ]
            const observer = new MutationObserver(() => {
              const firstScreenElement = document.querySelector(`[id$='${firstScreen.id}']`)
              if (firstScreenElement) {
                observer.disconnect()
                firstScreenElement.scrollIntoView({ block: 'center', behavior: 'smooth' })
              }
            })
            observer.observe(document, {
              attributes: false,
              childList: true,
              characterData: false,
              subtree: true,
            })
            return configCopy
          })
          close()
        }
      })
      .catch(err => toast(err))
      .finally(() => stopLoader())
  }

  return { screens, addScreens }
}
