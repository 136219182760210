import styled from 'styled-components'

export const Wrapper = styled.div`
  width: calc(100% + 60px);
  height: calc(100% - 60px);
  margin-left: -60px;
  padding: 20px;
  display: flex;
  background-color: ${props => props.theme.colors.secondaryBackground};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.colors.darkText};
  & > span {
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    margin: 20px 0 15px;
  }
  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    max-width: 300px;
  }
`
