import React, { useEffect, useState } from 'react'

import * as styled from './GenerateScreens.styled'

import { useScreenshotFromVB } from 'hooks'
import { GenerateScreensKeyboardPayload } from 'services/conversations'
import { constants } from './constants'

interface Props {
  payload: GenerateScreensKeyboardPayload
}

export const GenerateScreens: React.FC<Props> = ({ payload }) => {
  const [screens, setScreens] = useState<string[]>([])
  const getScreenshotFromVB = useScreenshotFromVB()

  useEffect(() => {
    Promise.all((payload || constants).map(el => getScreenshotFromVB(el))).then(res => setScreens(res))
  }, [JSON.stringify(payload), getScreenshotFromVB])

  return (
    <styled.Screens>
      {screens.map((el, i) => (
        <styled.Screen key={el}>
          <img src={el} alt={`screen-${i}`} />
        </styled.Screen>
      ))}
    </styled.Screens>
  )
}
