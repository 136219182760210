import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import ReactTimeago from 'react-timeago'
import { ThemeContext } from 'styled-components'

import {
  ActionsIconTbodyWrapper,
  Avatar,
  Button,
  CustomSection,
  CustomSectionSubTitle,
  CustomSectionTitle,
  DropDown,
  Icon,
  Name,
  NoContent,
  TableWrapper,
  Text,
  Type,
} from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { ProjectsService } from 'services/projects'
import { firstLetterUpperCase } from 'utils'

interface UserRecord {
  uid: string
  email?: string
  emailVerified: boolean
  displayName?: string
  photoURL?: string
  phoneNumber?: string
  tenantId?: string | null
  creationTime: string
  lastSignInTime: string
  lastRefreshTime?: string | null
  status: UserRecordStatus
}

enum UserRecordStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  BLOCKED = 'BLOCKED',
}

export const Users: React.FC = () => {
  const themeContext = useContext(ThemeContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [users, setUsers] = useState<UserRecord[]>([])

  useEffect(() => {
    startLoader()
    ProjectsService.getUsers(id)
      .then(res => setUsers(res.data))
      .catch(err => toast(err))
      .finally(() => stopLoader())
  }, [])

  return (
    <CustomSection style={{ padding: '25px' }}>
      <CustomSectionTitle mb="10px">Users</CustomSectionTitle>
      <CustomSectionSubTitle>Manage your app’s user accounts and monitor activity.</CustomSectionSubTitle>
      <CustomSection style={{ padding: 0, margin: '20px 0 0 0' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" padding="20px 24px">
          <Box>
            <Text type={Type.H2}>Invite users</Text>
            <Text type={Type.BODY} as="p" color={themeContext.colors.grey1}>
              Send email or SMS invitations to add new users to your app.
            </Text>
          </Box>
          <Button>Invite</Button>
        </Box>
      </CustomSection>
      <TableWrapper empty={!users.length} big mt="30px">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {users.length} {users.length === 1 ? 'user' : 'users'}
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>LastSignIn</TableCell>
              <TableCell>Status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {!!users.length ? (
            <TableBody>
              {users.map(el => (
                <TableRow key={el.uid}>
                  <TableCell>
                    <Box display="flex" gap="10px" alignItems="center">
                      <Avatar value={el.photoURL} width={30} />
                      {el.displayName}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap="4px" alignItems="center">
                      {el.email}
                      {el.emailVerified && <Icon name={Name.ADDITIONAL_OK_FILLED} width={12} height={12} />}
                    </Box>
                  </TableCell>
                  <TableCell>{el.phoneNumber}</TableCell>
                  <TableCell>
                    <ReactTimeago date={el.creationTime} />
                  </TableCell>
                  <TableCell>
                    <ReactTimeago date={el.lastSignInTime} />
                  </TableCell>
                  <TableCell style={{ color: el.status === UserRecordStatus.PENDING && themeContext.colors.grey1 }}>
                    {firstLetterUpperCase(el.status.toLowerCase())}
                  </TableCell>
                  <TableCell onClick={e => e.stopPropagation()}>
                    <ActionsIconTbodyWrapper>
                      <div>
                        <DropDown
                          options={[
                            { label: 'Delete user', iconName: Name.PICKERS_DELETE, onClick: () => {}, red: true },
                          ]}
                          top="25px"
                          labelElement={
                            <Box display="flex" alignItems="center">
                              <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />
                            </Box>
                          }
                        />
                      </div>
                    </ActionsIconTbodyWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <NoContent isTable text="Empty" />
          )}
        </Table>
      </TableWrapper>
    </CustomSection>
  )
}
