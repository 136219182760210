import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import { DeviceSettings, Menu, SourceCode } from 'components'
import { DeviceSettingsContext, ProjectContext, TopPanelContext, selectedDefaultState } from 'context'
import { RightSidebar, TopPanelStudio } from 'partials'
import { MovableComponentsProvider } from 'providers'
import {
  Config,
  ScreenComponent,
  TabBar,
  deviceKey,
  findComponent,
  findComponentParentDataById,
  getDeviceFromStorage,
  getNewComponentPosition,
  getScaleFromStorage,
  isParentComponent,
  listItemName,
  profieTabs,
  removeComponentById,
  scaleKey,
  uniqueComponentId,
} from 'utils'
import { ComponentsToAdd, NavigationTree, Sections } from './components'

interface Props {
  ai?: boolean
  isXL?: boolean
}

export const Studio: React.FC<Props> = ({ ai, isXL }) => {
  const { page } = useContext(TopPanelContext)
  const { config, setConfig, undo, redo } = useContext(ProjectContext)
  const [selected, setSelected] = useState(selectedDefaultState)
  const selectedComponentId = selected.subComponentId || selected.componentId
  const [componentToCopy, setComponentToCopy] = useState<ScreenComponent | null>(null)
  const [device, setDevice] = useState(getDeviceFromStorage())
  const [scale, setScale] = useState(getScaleFromStorage())
  const [compression, setCompression] = useState(scale / 100)

  useEffect(() => {
    if (!ai) {
      document.title = 'Visual builder | CodePlatform'
      return () => {
        setSelected(selectedDefaultState)
      }
    }
  }, [])

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setCompression(scale / 100)
      localStorage.setItem(scaleKey, String(scale))
    }, 600)
    return () => clearTimeout(debounceFn)
  }, [scale])

  useEffect(() => {
    localStorage.setItem(deviceKey, device)
  }, [device])

  useEffect(() => {
    if (!ai && !page) {
      document.onkeydown = e => {
        const isBody = document.activeElement?.tagName === 'BODY'
        if (isBody) {
          const configCopy = JSON.parse(JSON.stringify(config)) as Config
          if (
            ((e.code === 'Delete' || e.code === 'Backspace') &&
              (selectedComponentId || selected.topBarId || selected.tabBarId)) ||
            ((e.ctrlKey || e.metaKey) && e.code === 'KeyX' && selectedComponentId)
          ) {
            const screen = configCopy.screens?.find(el => el.id === selected.screenId)
            if (screen) {
              if (selectedComponentId) {
                const selectedComponent = findComponent(screen, 'id', selectedComponentId)
                if (selectedComponent) {
                  if ((e.ctrlKey || e.metaKey) && e.code === 'KeyX') {
                    setComponentToCopy(JSON.parse(JSON.stringify(selectedComponent)) as ScreenComponent)
                  }
                  if (selectedComponent.name !== listItemName) {
                    removeComponentById(screen, selectedComponentId)
                  }
                }
              } else if (selected.tabBarId) {
                const tabBar = configCopy.tabBars?.find(el => el.id === selected.tabBarId) as TabBar
                const tabScreenName = selected.tabScreen || screen.screenName
                if (!profieTabs.includes(tabScreenName)) {
                  tabBar.tabs = tabBar.tabs?.filter(el => el.screen !== tabScreenName)
                }
              } else {
                delete screen.showTopBar
                delete screen.showBackButton
                delete screen.screenTitle
              }
              setConfig(configCopy)
            }
          } else if ((e.code === 'Delete' || e.code === 'Backspace') && selected.screenId) {
            const indexOfScreen = configCopy.screens?.findIndex(el => el.id === selected.screenId) || -1
            configCopy.screens?.splice(indexOfScreen, 1)
            setConfig(configCopy)
            setSelected(selectedDefaultState)
          } else if (e.ctrlKey || e.metaKey) {
            if (e.code === 'KeyC' && selectedComponentId) {
              const screen = configCopy.screens?.find(el => el.id === selected.screenId)
              if (screen) {
                const selectedComponent = findComponent(screen, 'id', selectedComponentId)
                if (selectedComponent) {
                  setComponentToCopy(JSON.parse(JSON.stringify(selectedComponent)) as ScreenComponent)
                }
              }
            } else if (e.code === 'KeyV' && selected.screenId && componentToCopy) {
              const screen = configCopy.screens?.find(el => el.id === selected.screenId)
              if (screen) {
                componentToCopy.name = `${componentToCopy.componentType} ${getNewComponentPosition(
                  screen,
                  componentToCopy.componentType
                )}`
                const selectedComponent = findComponent(screen, 'id', selectedComponentId)
                if (selectedComponent) {
                  if (
                    selectedComponentId === componentToCopy.id ||
                    !isParentComponent(selectedComponent.componentType)
                  ) {
                    const componentParentData = findComponentParentDataById(screen, selectedComponentId)
                    if (componentParentData) {
                      const { components } = componentParentData
                      components.push(componentToCopy)
                    }
                  } else {
                    if (selectedComponent.subComponents) {
                      selectedComponent.subComponents.push(componentToCopy)
                    } else {
                      selectedComponent.subComponents = [componentToCopy]
                    }
                  }
                } else {
                  if (screen.components) {
                    screen.components.push(componentToCopy)
                  } else {
                    screen.components = [componentToCopy]
                  }
                }
                uniqueComponentId(componentToCopy)
                setConfig(configCopy)
                setSelected(selected =>
                  selected.subComponentId
                    ? { ...selected, subComponentId: componentToCopy.id }
                    : { ...selected, componentId: componentToCopy.id }
                )
              }
            } else if (e.code === 'KeyZ') {
              undo()
            } else if (e.code === 'KeyY') {
              redo()
            }
          }
        }
      }
      return () => {
        document.onkeydown = () => {}
      }
    }
  }, [
    page,
    selectedComponentId,
    selected.topBarId,
    selected.tabBarId,
    selected.tabScreen,
    selected.screenId,
    componentToCopy,
    JSON.stringify(config),
  ])

  return (
    <DeviceSettingsContext.Provider value={{ compression, device, selected, setSelected }}>
      <MovableComponentsProvider>
        {!ai && <TopPanelStudio />}
        <Box height="calc(100% - 60px)" display="flex" justifyContent="space-between">
          {!ai && <Menu firstChild={<NavigationTree />} secondChild={<ComponentsToAdd />} />}
          <Box width="100%" height="100%" display="flex" flexDirection="column" position="relative">
            <Sections ai={ai} isXL={isXL} />
            <DeviceSettings ai={ai} device={device} setDevice={setDevice} scale={scale} setScale={setScale} />
            {process.env.REACT_APP_DEV_MODE && !ai && <SourceCode />}
          </Box>
          {!ai && <Menu right firstChild={<RightSidebar />} />}
        </Box>
      </MovableComponentsProvider>
    </DeviceSettingsContext.Provider>
  )
}
