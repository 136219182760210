import styled from 'styled-components'

export const Templates = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  height: 502px;
  box-sizing: border-box;
  overflow-x: auto;
  padding: 20px;
  border-radius: 24px;
  background: ${props => props.theme.colors.settingField};
`

export const Template = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
`

export const TemplateImage = styled.img`
  width: 185px;
  min-width: 185px;
  height: 400px;
  min-height: 400px;
  border: 2px solid ${props => props.theme.colors.blackBorder};
  border-radius: 22px;
`
