import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Button, CustomSection, Text, Type } from 'components'
import { useAddBillingAccount } from 'hooks'

export const AddNew: React.FC = () => {
  const themeContext = useContext(ThemeContext)
  const { onAddBillingAccount, wait } = useAddBillingAccount()

  return (
    <CustomSection
      style={{ margin: '40px 0 30px', padding: '15px 25px', backgroundColor: themeContext.colors.secondaryButtonBg }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Text type={Type.H3} as="h2" fontWeight={themeContext.weights.semibold}>
            Add billing account
          </Text>
          <Text type={Type.H3} as="p" fontWeight={themeContext.weights.normal} color={themeContext.colors.grey1}>
            Multiple billing accounts let you separate payments and invoices across projects or teams.
          </Text>
        </Box>
        <Button height={30} style={{ padding: '4.5px 15px' }} onClick={onAddBillingAccount} loading={wait}>
          Add
        </Button>
      </Box>
    </CustomSection>
  )
}
