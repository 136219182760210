import styled from 'styled-components'

export const Selects = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 17px;
  & > div {
    width: 50%;
    & h4 {
      font-weight: ${props => props.theme.weights.medium};
      font-size: 14px;
      line-height: 150%;
      color: ${props => props.theme.colors.darkText};
      margin-bottom: 10px;
    }
  }
`

export const Select = styled.div`
  position: relative;
  & * {
    color: ${props => props.theme.colors.darkText};
  }
`

export const SelectLabel = styled.div`
  cursor: pointer;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  height: 40px;
  background-color: ${props => props.theme.colors.settingField};
  & span {
    font-size: 14px;
    line-height: 150%;
  }
  & > svg {
    margin-right: 5px;
  }
  & input {
    font-size: 14px;
    line-height: 150%;
    width: 100%;
    border: none;
    outline: none;
    padding-right: 24px;
    height: 100%;
    z-index: 1;
    background: transparent;
  }
`

export const SelectIcon = styled.i`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SelectVariants = styled.div`
  position: absolute;
  margin-top: 5px;
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 8px;
  padding: 10px 0px;
  background: ${props => props.theme.colors.white};
  width: 100%;
  & * {
    font-size: 12px;
    line-height: 150%;
  }
  & p {
    height: 30px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.grey1};
  }
`

export const Variant = styled.div<{ active?: boolean }>`
  padding: 0 10px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background: ${props => props.theme.colors.settingField};
  }
  & svg {
    &:nth-child(1) {
      margin-right: 5px;
    }
    &:nth-child(2) {
      margin-left: 5px;
    }
  }
  & time {
    position: absolute;
    right: 15px;
    color: ${props => props.theme.colors.grey1};
  }
`

export const LockIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    margin: 0;
  }
`

export const Info = styled.div<{ connected?: boolean; connect?: boolean }>`
  margin-top: ${props => (props.connect ? '25px' : '30px')};
  background: ${props => props.theme.colors.settingField};
  border-radius: 8px;
  width: 590px;
  height: ${props => (props.connect || props.connected ? '135px' : '221px')};
  display: flex;
  justify-content: ${props => !props.connected && 'center'};
  align-items: center;
  font-size: 14px;
  line-height: 150%;
  ${props => {
    if (props.connected) {
      return `padding-left: 15px;`
    }
  }}
  & > svg {
    margin-right: 10px;
  }
  & p {
    padding: 0 20px;
    text-align: center;
    color: ${props => props.theme.colors.grey1};
    & b {
      font-weight: 600;
    }
  }
`
