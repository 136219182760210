import { Box } from '@mui/material'
import React, { useContext, useMemo, useRef, useState } from 'react'

import { Button, ButtonVariant, Icon, IssuesPicker, Name } from 'components'
import { ProjectContext } from 'context'
import { Task, useOnClickOutside } from 'hooks'
import { isValidConfig, Warning } from 'utils'

interface Props {
  failedTasks: Task[]
}

export const WarningsAndErrors: React.FC<Props> = ({ failedTasks }) => {
  const { config } = useContext(ProjectContext)
  const [open, setOpen] = useState(false)

  const warnings = useMemo(() => isValidConfig(config), [JSON.stringify(config)])

  return !!warnings.length || !!failedTasks.length ? (
    <>
      <Button
        variant={ButtonVariant.TOP_PANEL_ACTION}
        active={open}
        onClick={() => setOpen(open => !open)}
        colorNoChange
      >
        <Box display="flex" alignItems="center" gap="12px" pl="6px">
          {!!warnings.length && (
            <Box display="flex" alignItems="center" gap="5px">
              <Icon name={Name.PICKERS_WARNING} />
              {warnings.length}
            </Box>
          )}
          {!!failedTasks.length && (
            <Box display="flex" alignItems="center" gap="5px">
              <Icon name={Name.GLOBAL_ERROR} />
              {failedTasks.length}
            </Box>
          )}
        </Box>
      </Button>
      {open && <IssuesPickerWrapper close={() => setOpen(false)} warnings={warnings} failedTasks={failedTasks} />}
    </>
  ) : (
    <></>
  )
}

interface IssuesPickerWrapperProps {
  close: () => void
  warnings: Warning[]
  failedTasks: Task[]
}

const IssuesPickerWrapper: React.FC<IssuesPickerWrapperProps> = ({ close, warnings, failedTasks }) => {
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, close)

  return (
    <Box zIndex="10" width="350px" position="absolute" top="36px" right="-130px" ref={ref}>
      <IssuesPicker close={close} warnings={warnings} failedTasks={failedTasks} />
    </Box>
  )
}
