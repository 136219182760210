import React, { useEffect, useState } from 'react'

import { Transforms } from 'components'
import { LocalVariable, Screen, VariableSource, VariableSourceType, VariableTransform } from 'utils'

interface Props {
  transforms?: VariableTransform[]
  onChange: (val: VariableTransform[]) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const ActionTransforms: React.FC<Props> = ({ transforms, onChange, screenConfig, allLocalVariables }) => {
  const [currentVariable, setCurrentVariable] = useState<VariableSource | null>({
    type: VariableSourceType.collection,
    transforms,
  })

  useEffect(() => {
    setCurrentVariable({ type: VariableSourceType.collection, transforms })
  }, [JSON.stringify(transforms)])

  useEffect(() => {
    if (JSON.stringify(transforms) !== JSON.stringify(currentVariable?.transforms)) {
      onChange(currentVariable?.transforms || [])
    }
  }, [JSON.stringify(currentVariable?.transforms)])

  return (
    <Transforms
      transforms={currentVariable?.transforms}
      setCurrentVariable={setCurrentVariable}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  )
}
