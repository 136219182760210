import { Box } from '@mui/material'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as styled from './DeployBtn.styled'

import { Button, DropDown, Icon, Name } from 'components'
import { ProgressContext, ProjectContext, UserContext } from 'context'
import {
  Deploy,
  DeploymentType,
  ProjectApplication,
  Step,
  StepType,
  Task,
  TaskType,
  useOnClickOutside,
  useParamsFromUrl,
} from 'hooks'
import { ActivityItemSmall, ActivityItemType } from 'pages/Repository/components'
import { Deployment, DeploymentId } from 'pages/Repository/pages'
import { firestore } from 'services/firebase'
import { ProjectsService, TaskDto } from 'services/projects'

const getLinks = (deployments: Deployment[]) =>
  deployments
    .map(el =>
      el.id === DeploymentId.FUNCTIONS
        ? el.data.map(el => (el.eventTrigger ? '' : el.serviceConfig?.uri ? el.serviceConfig.uri : ''))
        : el.id === DeploymentId.SITES
        ? el.data.map(el => el.defaultUrl)
        : el.id === DeploymentId.APPETIZE
        ? el.data.map(el => el.url)
        : ''
    )
    .flat()
    .filter(el => !!el) as string[]

interface Props {
  ai?: boolean
  setOpenStoreManager: (val: boolean) => void
  tasks: Task[]
  setupSteps: Step[]
}

export const DeployBtn: React.FC<Props> = ({ ai, setOpenStoreManager, tasks, setupSteps }) => {
  const { user } = useContext(UserContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id, gcpProjectId },
  } = useContext(ProjectContext)
  const { setConnectGCP } = useParamsFromUrl()
  const navigate = useNavigate()
  const [iosLinks, setIosLinks] = useState<string[]>([])
  const [androidLinks, setAndroidLinks] = useState<string[]>([])
  const [webLinks, setWebLinks] = useState<string[]>([])
  const [backendLinks, setBackendLinks] = useState<string[]>([])
  const [open, setOpen] = useState(false)
  const [toUpdate, setToUpdate] = useState<TaskDto[]>([])
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  const sections: { name: string; deploys: Deploy[] }[] = useMemo(
    () => [
      {
        name: 'APP PREVIEW',
        deploys: [
          {
            name: 'iOS App',
            onSetupClick: () => {
              navigate(`/projects/${id}/settings/domains`)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.APPETIZE,
              applicationId: ProjectApplication.IOS,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.APPETIZE &&
                el.applicationId === ProjectApplication.IOS
            ),
            step:
              setupSteps.find(el => el.type === StepType.IOS_PREVIEW) ||
              setupSteps.find(el => el.type === StepType.IOS_GEN),
            links: [],
          },
          {
            name: 'Android App',
            onSetupClick: () => {
              navigate(`/projects/${id}/settings/domains`)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.APPETIZE,
              applicationId: ProjectApplication.ANDROID,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.APPETIZE &&
                el.applicationId === ProjectApplication.ANDROID
            ),
            step:
              setupSteps.find(el => el.type === StepType.ANDROID_PREVIEW) ||
              setupSteps.find(el => el.type === StepType.ANDROID_GEN),
            links: [],
          },
          {
            name: 'Web App',
            onSetupClick: () => {
              navigate(`/projects/${id}/settings/domains`)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.STAGING,
              applicationId: ProjectApplication.WEB,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.STAGING &&
                el.applicationId === ProjectApplication.WEB
            ),
            step:
              setupSteps.find(el => el.type === StepType.PWA_PREVIEW) ||
              setupSteps.find(el => el.type === StepType.PWA_GEN),
            links: [],
          },
        ],
      },
      {
        name: 'APP LIVE',
        deploys: [
          {
            name: 'iOS App',
            onSetupClick: () => {
              setOpenStoreManager(true)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.TESTFLIGHT,
              applicationId: ProjectApplication.IOS,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.TESTFLIGHT &&
                el.applicationId === ProjectApplication.IOS
            ),
            setupText: 'Publish to Appstore',
            step:
              setupSteps.find(el => el.type === StepType.IOS_RELEASE) ||
              setupSteps.find(el => el.type === StepType.IOS_GEN),
            links: iosLinks,
          },
          {
            name: 'Android App',
            onSetupClick: () => {
              setOpenStoreManager(true)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.GOOGLEPLAY,
              applicationId: ProjectApplication.ANDROID,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.GOOGLEPLAY &&
                el.applicationId === ProjectApplication.ANDROID
            ),
            setupText: 'Publish to Google Play',
            step:
              setupSteps.find(el => el.type === StepType.ANDROID_RELEASE) ||
              setupSteps.find(el => el.type === StepType.ANDROID_GEN),
            links: androidLinks,
          },
          {
            name: 'Web App',
            onSetupClick: () => {
              navigate(`/projects/${id}/settings/domains`)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.PRODUCTION,
              applicationId: ProjectApplication.WEB,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.PRODUCTION &&
                el.applicationId === ProjectApplication.WEB
            ),
            step:
              setupSteps.find(el => el.type === StepType.PWA_RELEASE) ||
              setupSteps.find(el => el.type === StepType.PWA_GEN),
            links: webLinks,
          },
        ],
      },
      {
        name: 'BACKEND',
        deploys: [
          {
            name: 'Backend App',
            onSetupClick: () => {
              navigate(`/projects/${id}/settings/domains`)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.PRODUCTION,
              applicationId: ProjectApplication.BACKEND,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.PRODUCTION &&
                el.applicationId === ProjectApplication.BACKEND
            ),
            step:
              setupSteps.find(el => el.type === StepType.BACKEND_RELEASE) ||
              setupSteps.find(el => el.type === StepType.BACKEND_GEN),
            links: backendLinks,
          },
        ],
      },
    ],
    [tasks, setupSteps, iosLinks, androidLinks, webLinks, backendLinks]
  )

  useEffect(() => {
    const iosUnsubscribe = firestore
      .collection(`projects/${id}/applications/${ProjectApplication.IOS}/deployments`)
      .onSnapshot({
        next: res => {
          const deployments = res.docs.map(el => ({ id: el.id, ...el.data() })) as Deployment[]
          setIosLinks(getLinks(deployments))
        },
        error: err => toast(err),
      })
    const androidUnsubscribe = firestore
      .collection(`projects/${id}/applications/${ProjectApplication.ANDROID}/deployments`)
      .onSnapshot({
        next: res => {
          const deployments = res.docs.map(el => ({ id: el.id, ...el.data() })) as Deployment[]
          setAndroidLinks(getLinks(deployments))
        },
        error: err => toast(err),
      })
    const webUnsubscribe = firestore
      .collection(`projects/${id}/applications/${ProjectApplication.WEB}/deployments`)
      .onSnapshot({
        next: res => {
          const deployments = res.docs.map(el => ({ id: el.id, ...el.data() })) as Deployment[]
          setWebLinks(getLinks(deployments))
        },
        error: err => toast(err),
      })
    const backendUnsubscribe = firestore
      .collection(`projects/${id}/applications/${ProjectApplication.BACKEND}/deployments`)
      .onSnapshot({
        next: res => {
          const deployments = res.docs.map(el => ({ id: el.id, ...el.data() })) as Deployment[]
          setBackendLinks(getLinks(deployments))
        },
        error: err => toast(err),
      })
    return () => {
      iosUnsubscribe()
      androidUnsubscribe()
      webUnsubscribe()
      backendUnsubscribe()
    }
  }, [])

  const onClick = () => {
    if (!gcpProjectId) {
      setConnectGCP()
      setOpen(false)
    } else {
      startLoader()
      Promise.all(toUpdate.map(el => ProjectsService.createTask(id, el)))
        .catch(err => toast(err))
        .finally(() => stopLoader())
    }
    setToUpdate([])
  }

  return (
    <div ref={ref}>
      <DropDown
        top="36px"
        width="400px"
        outerOpenState={open}
        onClick={() => setOpen(open => !open)}
        labelElement={
          <Button rightIcon={<Icon name={Name.GLOBAL_ARROW_BUTTON} />} onClick={() => setOpen(open => !open)}>
            Deploy
          </Button>
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="15px"
          p="0 15px"
          maxHeight={`calc(100vh - ${ai ? 90 : user.isLimitedAccess ? 130 : 90}px)`}
          overflow="auto"
        >
          {sections.map(el => (
            <styled.Section key={el.name}>
              <styled.SectionTitle>{el.name}</styled.SectionTitle>
              {el.deploys.map(el => (
                <ActivityItemSmall
                  key={el.name}
                  item={el}
                  type={ActivityItemType.DEPLOY}
                  checkBoxValue={
                    !!toUpdate.find(
                      ({ type, deploymentType, applicationId }) =>
                        type === el.dto.type &&
                        deploymentType === el.dto.deploymentType &&
                        applicationId === el.dto.applicationId
                    )
                  }
                  onCheckBoxChange={val =>
                    setToUpdate(toUpdate => {
                      const filtered = toUpdate.filter(
                        ({ type, deploymentType, applicationId }) =>
                          !(
                            type === el.dto.type &&
                            deploymentType === el.dto.deploymentType &&
                            applicationId === el.dto.applicationId
                          )
                      )
                      return val ? [...filtered, el.dto] : filtered
                    })
                  }
                />
              ))}
            </styled.Section>
          ))}
          <Box mt="10px" position="sticky" bottom="0">
            <Button disabled={!toUpdate.length} style={{ width: '100%' }} onClick={onClick}>
              Generate & Deploy selected apps
            </Button>
          </Box>
        </Box>
      </DropDown>
    </div>
  )
}
