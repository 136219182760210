import React from 'react'
import { FillType, ISvg, Svg } from '../Svg'

const Repository: React.FC<ISvg> = props => (
  <Svg fillType={FillType.STROKE} viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M6 7L1 12L6 17" stroke="#212133" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 17L23 12L18 7" stroke="#212133" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 4L10 20" stroke="#212133" strokeWidth="2" strokeLinecap="round" />
  </Svg>
)

export default Repository
