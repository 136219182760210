import React, { useContext } from 'react'

import { ProjectContext } from 'context'
import {
  ComponentAlignment,
  ComponentType,
  Config,
  ImageLayout,
  InnerLaunchScreenIcon,
  Resource,
  Screen,
  ScreenComponent,
  detectEmptyValue,
  generateFirestoreId,
  getAllLocalVariables,
} from 'utils'
import { ImageInput } from './ImageInput'

const brandIconComponent: ScreenComponent = {
  componentType: ComponentType.image,
  componentAlignment: ComponentAlignment.bottom,
  id: generateFirestoreId(),
  imageLayout: ImageLayout.center,
  height: { constant: '24' },
  name: 'image.brandIcon',
}

interface Props {
  big?: boolean
  label?: string
  subLabel?: string
  iconName: InnerLaunchScreenIcon
}

export const LaunchScreenIconInput: React.FC<Props> = ({ big, label, subLabel, iconName }) => {
  const {
    config: { screens = [] },
    setConfig,
  } = useContext(ProjectContext)
  const screenConfig = screens.find(el => el.screenName === 'Launch Screen') as Screen
  const subComponents = screenConfig.components?.[0]?.subComponents
  const component = (
    subComponents ? (iconName === InnerLaunchScreenIcon.splashIcon ? subComponents[0] : subComponents[1] || {}) : {}
  ) as ScreenComponent
  const { id, image } = component
  const allLocalVariables = getAllLocalVariables(screenConfig, component)

  const onComponentPropertiesChange = (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => {
    setConfig(config => {
      const configCopy = JSON.parse(JSON.stringify(config)) as Config
      const subComponents = configCopy.screens?.find(el => el.screenName === 'Launch Screen')?.components?.[0]
        ?.subComponents
      if (subComponents) {
        const component =
          iconName === InnerLaunchScreenIcon.splashIcon ? subComponents[0] : subComponents[1] || brandIconComponent
        props.forEach(({ propName, value, resources }) => {
          if (detectEmptyValue(value)) {
            delete component[propName]
          } else {
            component[propName] = value as never
          }
          if (resources) {
            configCopy.resources = resources
          }
          if (!id) {
            subComponents.push(component)
          } else if (!component[propName] && iconName === InnerLaunchScreenIcon.brandLogo) {
            subComponents.splice(1)
          }
        })
      }
      return configCopy
    })
  }

  return (
    <ImageInput
      big={big}
      label={label}
      subLabel={subLabel}
      value={image?.constant?.resourceId}
      onChange={(value, resources) =>
        onComponentPropertiesChange([{ propName: 'image', value: { constant: { resourceId: value } }, resources }])
      }
      source={image?.source}
      onSourceChange={value => onComponentPropertiesChange([{ propName: 'image', value: { source: value } }])}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
      withoutDelete={iconName === InnerLaunchScreenIcon.splashIcon}
    />
  )
}
