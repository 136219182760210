import { Box } from '@mui/material'
import React, { useState } from 'react'
import ReactTimeago from 'react-timeago'

import * as styled from './ActivityItem.styled'
import { BuildStatus } from './ActivityItem.styled'

import { Checkbox, Icon, Loader, Name, Rotation } from 'components'
import { Deploy, Step, StepStatus, Task, TaskStatus, getTaskTitle } from 'hooks'
import { getReadableTimeFromSeconds } from 'utils'
import { Activity, ActivityItemType, Build } from './ActivityItem'
import { CancellLoader, IconWrapper, Rerun } from './components'

export interface Pipeline {
  build_seconds_used: number
  completed_on: string
  duration_in_seconds: number
  image: { name: string }
  maxTime: number
  name: string
  pipeline: { type: string; uuid: string }
  run_number: number
  script_commands: []
  setup_commands: []
  started_on: string
  state: {
    name: string
    type: string
    result?: { name: BuildStatus; type: string }
  }
  teardown_commands: []
  trigger: { type: string }
  type: string
  uuid: string
}

interface NoData {
  title: string
}

interface Props {
  item: any
  type: ActivityItemType
  onClick?: () => void
  active?: boolean
  line?: boolean
  steps?: Step[]
  checkBoxValue?: boolean
  onCheckBoxChange?: (val: boolean) => void
}

export const ActivityItemSmall: React.FC<Props> = ({
  item,
  type,
  onClick,
  active,
  line,
  steps,
  checkBoxValue,
  onCheckBoxChange,
}) => {
  const [open, setOpen] = useState(false)

  const onArrowClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setOpen(open => !open)
  }

  switch (type) {
    case ActivityItemType.NO_DATA:
      const noData = item as NoData
      return (
        <styled.SmallItem>
          <styled.SmallItemTop noData>
            <i>{noData.title}</i>
          </styled.SmallItemTop>
        </styled.SmallItem>
      )
    case ActivityItemType.ACTIVITY:
      const activity = item as Activity
      return (
        <styled.SmallItem>
          <styled.SmallItemTop>
            <Icon name={Name.REPOSITORY_COMMIT} rotation={Rotation.DOWN} />
            <div>
              <div>
                <b>{activity.payload.actor.display_name}:</b> <i>{activity.type}</i>
              </div>
              <div>
                <i>
                  <ReactTimeago date={activity.time.toDate()} />
                </i>
              </div>
            </div>
          </styled.SmallItemTop>
        </styled.SmallItem>
      )
    case ActivityItemType.BUILD:
      const build = item as Build
      return (
        <styled.SmallItem>
          <styled.SmallItemTop onClick={onClick} hasHover>
            {build.state.result?.name === BuildStatus.SUCCESSFUL ? (
              <Icon name={Name.REPOSITORY_BUILD_SUCCESS_SMALL} />
            ) : build.state.result?.name === BuildStatus.FAILED ? (
              <Icon name={Name.REPOSITORY_BUILD_FAILED_SMALL} />
            ) : (
              <Loader small />
            )}
            <div>
              <div>
                <b>
                  #{build.build_number} {build.target.selector?.pattern || '—'}
                </b>
              </div>
              <div>
                <i>
                  <ReactTimeago date={new Date(build.created_on)} /> ·
                </i>{' '}
                <i>v{build.build_number}</i>
              </div>
            </div>
          </styled.SmallItemTop>
        </styled.SmallItem>
      )
    case ActivityItemType.DEPLOY:
      const deploy = item as Deploy
      const deployFailed = deploy.task
        ? deploy.task.status === TaskStatus.FAILED || deploy.task.status === TaskStatus.CANCELLED
        : deploy.step
        ? deploy.step.status === StepStatus.FAILED || deploy.step.status === StepStatus.CANCELLED
        : false
      const deployLoading = deploy.task
        ? deploy.task.status === TaskStatus.IN_PROGRESS || deploy.task.status === TaskStatus.PENDING
        : deploy.step
        ? deploy.step.status === StepStatus.IN_PROGRESS ||
          deploy.step.status === StepStatus.PENDING ||
          deploy.step.status === StepStatus.EXECUTING
        : false
      return (
        <styled.SmallItem deploy>
          <styled.SmallItemTop deploy>
            <Checkbox disabled={deployLoading} value={checkBoxValue} onChange={onCheckBoxChange} />
            <div>
              <div>
                <b>{deploy.name}</b>{' '}
                <i>
                  v2024.7.1
                  <styled.DotStatus
                    status={deploy.task ? deploy.task.status : deploy.step ? deploy.step.status : undefined}
                  />
                </i>
              </div>
              <div>
                {deploy.links.map(el => (
                  <a
                    key={el}
                    href={el}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none', display: 'flex' }}
                  >
                    {el}
                    <Icon name={Name.REPOSITORY_LINK} />
                  </a>
                ))}
                <i>
                  {deploy.task
                    ? deploy.task.status === TaskStatus.COMPLETED
                      ? 'Succeeded'
                      : deploy.task.status === TaskStatus.FAILED
                      ? 'Error'
                      : deploy.task.status === TaskStatus.CANCELLED
                      ? 'Cancelled'
                      : deploy.task.status === TaskStatus.IN_PROGRESS
                      ? 'In progress'
                      : deploy.task.status === TaskStatus.PENDING
                      ? 'Pending'
                      : 'Unknown'
                    : deploy.step
                    ? deploy.step.status === StepStatus.COMPLETED
                      ? 'Succeeded'
                      : deploy.step.status === StepStatus.FAILED
                      ? 'Error'
                      : deploy.step.status === StepStatus.CANCELLED
                      ? 'Cancelled'
                      : deploy.step.status === StepStatus.IN_PROGRESS
                      ? 'In progress'
                      : deploy.step.status === StepStatus.PENDING
                      ? 'Pending'
                      : deploy.step.status === StepStatus.EXECUTING
                      ? 'Executing'
                      : 'Unknown'
                    : 'Unknown'}
                </i>
              </div>
            </div>
            {deployLoading ? (
              <CancellLoader task={deploy.task} />
            ) : deployFailed ? (
              <Rerun task={deploy.task} />
            ) : (
              <styled.Setup onClick={deploy.onSetupClick}>{deploy.setupText || 'Setup'}</styled.Setup>
            )}
          </styled.SmallItemTop>
        </styled.SmallItem>
      )
    case ActivityItemType.STEP:
      const step = item as Step
      const stepLoading =
        step.status === StepStatus.IN_PROGRESS ||
        step.status === StepStatus.PENDING ||
        step.status === StepStatus.EXECUTING
      return (
        <styled.SmallItem step>
          <styled.SmallItemTop>
            <Icon name={Name.ADDITIONAL_AI_SMALL} />
            <div>
              <div>
                <b>
                  {step.type}
                  <styled.DotStatus status={step.status} />
                </b>
              </div>
              <div>
                <i>
                  {step.status === StepStatus.COMPLETED
                    ? 'Succeeded'
                    : step.status === StepStatus.FAILED
                    ? 'Error'
                    : step.status === StepStatus.CANCELLED
                    ? 'Cancelled'
                    : step.status === StepStatus.IN_PROGRESS
                    ? 'In progress'
                    : step.status === StepStatus.PENDING
                    ? 'Pending'
                    : step.status === StepStatus.EXECUTING
                    ? 'Executing'
                    : 'Unknown'}{' '}
                  |
                  <ReactTimeago date={new Date(step.updatedAt.seconds * 1000)} />
                </i>
              </div>
            </div>
            {stepLoading && <Loader small />}
          </styled.SmallItemTop>
        </styled.SmallItem>
      )
    case ActivityItemType.TASK:
      const task = item as Task
      const taskFailed = task.status === TaskStatus.FAILED || task.status === TaskStatus.CANCELLED
      const taskLoading = task.status === TaskStatus.IN_PROGRESS || task.status === TaskStatus.PENDING
      return (
        <styled.SmallItem task={!open}>
          <styled.SmallItemTop task={!open} onClick={onClick} hasHover={!open}>
            {!!steps?.length && (
              <IconWrapper>
                <Icon name={Name.PICKERS_ARROW} rotation={open ? Rotation.DOWN : undefined} onClick={onArrowClick} />
              </IconWrapper>
            )}
            <Icon name={Name.ADDITIONAL_AI_SMALL} />
            <div>
              <div>
                <b>
                  {getTaskTitle(task)}
                  <styled.DotStatus status={task.status} />
                </b>
              </div>
              <div>
                <i>
                  {task.status === TaskStatus.COMPLETED
                    ? 'Succeeded'
                    : task.status === TaskStatus.FAILED
                    ? 'Error'
                    : task.status === TaskStatus.CANCELLED
                    ? 'Cancelled'
                    : task.status === TaskStatus.IN_PROGRESS
                    ? 'In progress'
                    : task.status === TaskStatus.PENDING
                    ? 'Pending'
                    : 'Unknown'}{' '}
                  |
                  <ReactTimeago date={new Date(task.updatedAt.seconds * 1000)} />
                </i>
              </div>
            </div>
            {taskLoading ? <CancellLoader task={task} /> : taskFailed && <Rerun task={task} />}
          </styled.SmallItemTop>
          {open && (
            <Box pl="50px">
              {steps?.map(el => (
                <ActivityItemSmall key={el.id} type={ActivityItemType.STEP} item={el} />
              ))}
            </Box>
          )}
        </styled.SmallItem>
      )
    case ActivityItemType.PIPELINE:
      const pipeline = item as Pipeline
      return (
        <Box position="relative">
          {line && <styled.Line />}
          <styled.SmallItem pipeline>
            <styled.SmallItemTop active={active} onClick={onClick} hasHover>
              {pipeline.state.result?.name === BuildStatus.SUCCESSFUL ? (
                <Icon name={Name.REPOSITORY_BUILD_SUCCESS_SMALL_V2} />
              ) : pipeline.state.result?.name === BuildStatus.FAILED ? (
                <Icon name={Name.REPOSITORY_BUILD_FAILED_SMALL} />
              ) : (
                <Loader small />
              )}
              <div>
                <div>
                  <b>{pipeline.name}</b>
                </div>
                <div>
                  <i>{getReadableTimeFromSeconds(pipeline.duration_in_seconds || 0)}</i>
                </div>
              </div>
            </styled.SmallItemTop>
          </styled.SmallItem>
        </Box>
      )
    default:
      return <></>
  }
}
