import { useContext, useEffect, useState } from 'react'

import { ProgressContext } from 'context'
import { clearVariables } from 'hooks'
import { firestore } from 'services/firebase'

export interface Project {
  id: string
  name: string
  features: string[]
  logoUrl: string
  plan: ProjectPlan
  status: ProjectStatus
  subscriptionId?: string
  subscriptionStatus?: ProjectSubscriptionStatus
  memberIds: string[]
  createdAt: any
  updatedAt: any
  deletedAt?: any
  description?: string
  gcpProjectId?: string
  applications: ProjectApplication[]
  repositorySlug?: string
  billingAccountId?: string
}

export enum ProjectPlan {
  DRAFT = 'DRAFT',
  STARTER = 'STARTER',
  BASIC = 'BASIC',
  PRO = 'PRO',
  BUSINESS = 'BUSINESS',
  ENTERPRISE = 'ENTERPRISE',
  CUSTOM = 'CUSTOM',
}

export enum ProjectStatus {
  PENDING = 'PENDING',
  CREATING = 'CREATING',
  READY = 'READY',
  FAILED = 'FAILED',
  ARCHIVED = 'ARCHIVED',
}

export interface ProjectPermission {
  id: string
  role: ProjectRole
}

export enum ProjectRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  CONTENT_EDITOR = 'CONTENT_EDITOR',
  GUEST = 'GUEST',
}

export enum ProjectSubscriptionStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

export enum ProjectApplication {
  ANDROID = 'android-application',
  BACKEND = 'backend-application',
  IOS = 'ios-application',
  WEB = 'web-application',
}

export const useProject = (id: string) => {
  const { toast } = useContext(ProgressContext)
  const [project, setProject] = useState<Project>({} as Project)
  const [projectWait, setProjectWait] = useState(true)
  const projectReady = project.status === ProjectStatus.READY

  useEffect(() => {
    setProject({} as Project)
    setProjectWait(true)
    let ready = false
    const unsubscribe = firestore
      .collection('projects')
      .doc(id)
      .onSnapshot({
        next: res => {
          const resData = res.data()
          if (resData) {
            const project = { id: res.id, ...resData } as Project
            setProject(project)
            setProjectWait(false)
            if (!ready && project.status === ProjectStatus.READY) {
              ready = true
              clearVariables()
            }
          }
        },
        error: err => toast(err),
      })
    return () => {
      unsubscribe()
      clearVariables()
    }
  }, [id])

  return { project, projectWait, projectReady }
}
