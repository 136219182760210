import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ProgressContext, ProjectContext } from 'context'
import { Commit, FileContent, FileManager } from 'pages/Repository/components'
import { ProjectsService } from 'services/projects'

export interface FileOrDirectory {
  path: string
  type: string
  commit: {
    hash: string
  }
}

export interface File {
  content: string
  type: string
}

interface Props {
  ai?: boolean
  settings?: boolean
  isMD?: boolean
  isLG?: boolean
  isXXL?: boolean
}

export const Code: React.FC<Props> = ({ ai, settings, isMD, isLG, isXXL }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [path, setPath] = useState(pathname)
  const [lastUpdate, setLastUpdate] = useState(+new Date())
  const [data, setData] = useState<FileOrDirectory[] | File>()
  const [wait, setWait] = useState(true)
  const [commits, setCommits] = useState<Commit[]>([])
  const flag = ai ? '/ai' : settings ? `/projects/${id}/settings/repository/src` : `/projects/${id}/repository/src`

  useEffect(() => {
    setPath(pathname)
  }, [pathname])

  useEffect(() => {
    const abortController = new AbortController()
    setWait(true)
    startLoader()
    if (!Array.isArray(data)) {
      setData([])
    }
    ProjectsService.getRepositoryFiles(id, path.replace(flag, ''), abortController)
      .then(res => {
        if (res.data.values) {
          setData(res.data.values)
          if (!commits.length) {
            return ProjectsService.getRepositoryCommits(id).then(res => setCommits(res.data.values))
          }
        } else if (typeof res.data === 'string') {
          setData({ content: res.data, type: res.headers['content-type'] })
        } else {
          setData({ content: JSON.stringify(res.data, null, 2), type: res.headers['content-type'] })
        }
      })
      .catch(err => !abortController.signal.aborted && toast(err))
      .finally(() => {
        stopLoader()
        setWait(false)
      })
    return () => {
      abortController.abort()
    }
  }, [path, lastUpdate])

  const onClick = (path: string) => (ai ? setPath(path) : navigate(path))

  return (
    <>
      {data &&
        (Array.isArray(data) ? (
          <FileManager
            path={path}
            onClick={onClick}
            data={data}
            flag={flag}
            commits={commits}
            noContent={!wait && !data.length}
            refreshRepo={() => setLastUpdate(+new Date())}
            settings={settings}
            isMD={isMD}
            isLG={isLG}
            isXXL={isXXL}
          />
        ) : (
          <FileContent ai={ai} path={path} onClick={onClick} data={data} flag={flag} />
        ))}
    </>
  )
}
