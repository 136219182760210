import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ControllsWrapper = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  padding: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & nav {
    & hr {
      width: calc(100% - 14px);
      height: 1px;
      border: 0;
      background-color: ${props => props.theme.colors.darkBlue_100};
      align-self: center;
      margin: 6px 0;
    }
  }
`

export const LinkBox = styled.div<{ active?: boolean }>`
  width: 100%;
  cursor: pointer;
  & a,
  div {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 8px;
    border-radius: 8px;
    background-color: ${props => props.active && props.theme.colors.settingField};
    text-decoration: none;
    color: ${props => (props.active ? props.theme.colors.darkText : props.theme.colors.darkText1)};
    font-size: 14px;
    line-height: 150%;

    & > svg {
      margin-right: 8px;
      & * {
        fill: ${props => (props.active ? props.theme.colors.grey2 : props.theme.colors.grey1)};
      }
    }

    &:hover {
      background-color: ${props => props.theme.colors.settingField};
      color: ${props => props.theme.colors.darkText};
      & > svg {
        & * {
          fill: ${props => props.theme.colors.grey2};
        }
      }
    }
  }
`

export const Project = styled(Link)`
  text-decoration: none;
  width: calc(100% / 7);
  min-width: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  & > img {
    width: 80px;
    height: 80px;
    border-radius: 16px;
    object-fit: cover;
  }
  & > div {
    width: 80px;
    color: ${props => props.theme.colors.darkText};
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 11px;
    line-height: 150%;
  }
  @media (max-width: 900px) {
    width: calc(100% / 6);
  }
  @media (max-width: 750px) {
    width: calc(100% / 5);
  }
  @media (max-width: 600px) {
    width: calc(100% / 4);
  }
  @media (max-width: 500px) {
    width: calc(100% / 3);
  }
  @media (max-width: 350px) {
    width: calc(100% / 2);
  }
`
