import { Box } from '@mui/material'
import { memo, useContext } from 'react'
import { useLocation } from 'react-router-dom'

import * as styled from './Navigation.styled'

import { DropDown, Name } from 'components'
import { ProjectContext } from 'context'
import { Task } from 'hooks'
import { routes } from 'routes'
import { ProjectSetupModal, Tasks, WarningsAndErrors } from './components'

interface Props {
  tasks: Task[]
  runningTasks: Task[]
  failedTasks: Task[]
}

export const Navigation: React.FC<Props> = memo(({ tasks, runningTasks, failedTasks }) => {
  const {
    project: { id, name, logoUrl },
  } = useContext(ProjectContext)
  const { pathname } = useLocation()

  const replacedPath = (path: string) => path.replace(':id', id)

  const section = routes.find(
    route =>
      route.name &&
      (route.index ? pathname === replacedPath(route.path) : pathname.startsWith(replacedPath(route.path)))
  )

  return (
    <>
      <styled.Navigation>
        <Box display="flex" alignItems="center" gap="4px">
          <DropDown
            options={[
              { label: name, iconName: Name.PICKERS_APP_NAME, isProjectName: true },
              { label: 'Go to projects', iconName: Name.PICKERS_BACK_TO_PROJECTS, to: '/' },
            ]}
            top="-16px"
            left="-15px"
            width="240px"
            labelElement={
              <styled.NavItem clickable>
                <img width={22} height={22} src={logoUrl} alt="logo" />
                {name}
              </styled.NavItem>
            }
          />
          {section && <styled.NavItem>/ {section.name}</styled.NavItem>}
        </Box>
        <Box display="flex" alignItems="center" gap="6px">
          <Tasks tasks={tasks} runningTasks={runningTasks} />
          <ProjectSetupModal />
        </Box>
      </styled.Navigation>
      <styled.WarningsAndErrorsBox>
        <WarningsAndErrors failedTasks={failedTasks} />
      </styled.WarningsAndErrorsBox>
    </>
  )
})
