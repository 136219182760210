import { useFormik } from 'formik'
import React, { useMemo, useState } from 'react'

import * as styled from './AddNew.styled'

import {
  Button,
  ButtonColor,
  ButtonSize,
  Icon,
  InputProperty,
  Modal,
  ModalActions,
  Name,
  inputs,
  validate,
} from 'components'
import { Folder } from 'context/types'
import { rootFolderId } from 'services/cms'
import { ValueType } from 'utils'

interface Props {
  active: boolean
  close: () => void
  onAdd: (name: string, callback: () => void) => void
  subCollectionParentName?: string
  folder?: boolean
  currentFolder?: Folder
}

export const AddNew: React.FC<Props> = ({ active, close, onAdd, subCollectionParentName, folder, currentFolder }) => {
  const title = folder ? 'Folder' : 'Collection'

  return (
    <Modal active={active} title={`Create new ${title.toLowerCase()}`} onClose={close}>
      <Children
        close={close}
        title={title}
        onAdd={onAdd}
        subCollectionParentName={subCollectionParentName}
        folder={folder}
        currentFolder={currentFolder}
      />
    </Modal>
  )
}

interface ChildrenProps {
  close: () => void
  title: string
  onAdd: (name: string, callback: () => void) => void
  subCollectionParentName?: string
  folder?: boolean
  currentFolder?: Folder
}

const Children: React.FC<ChildrenProps> = ({ close, title, onAdd, subCollectionParentName, folder, currentFolder }) => {
  const [wait, setWait] = useState(false)

  const formik = useFormik({
    initialValues: { name: null },
    validate: data => validate(data, properties),
    onSubmit: data => {
      const { name } = data
      if (name) {
        setWait(true)
        onAdd(name, () => {
          close()
          setWait(false)
        })
      }
    },
  })

  const properties = useMemo(
    (): InputProperty[] => [
      {
        name: 'name',
        type: ValueType.string,
        position: 0,
        isRequired: true,
        title: `${title} name`,
      },
    ],
    [title]
  )

  return (
    <>
      {inputs(properties, formik)}
      {subCollectionParentName && (
        <styled.Path>
          <styled.IconWrapper>
            <Icon name={Name.ADDITIONAL_COLLECTION} />
          </styled.IconWrapper>
          <span>{subCollectionParentName.split('/').join(' / ')}</span>
          <b>/ {formik.values.name}</b>
        </styled.Path>
      )}
      {folder && (
        <styled.Path>
          <styled.IconWrapper>
            <Icon name={Name.ADDITIONAL_HOME_FOLDER} />
          </styled.IconWrapper>
          <span>{currentFolder?.name.split('/').join(' / ') || rootFolderId}</span>
          <b>/ {formik.values.name}</b>
        </styled.Path>
      )}
      <ModalActions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={close}>
          Cancel
        </Button>
        <Button size={ButtonSize.DEFAULT} onClick={formik.submitForm} loading={wait}>
          Create {title.toLowerCase()}
        </Button>
      </ModalActions>
    </>
  )
}
