import { rgbToHex } from '@mui/material'
import React, { useContext } from 'react'

import { ColorPicker, DevicePicker, Icon, Name, OneColorSvg, ScreenPicker, TwoColorsSvg } from 'components'
import { ProjectContext } from 'context'
import { RightSidebarItem, SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { getBrandingColorType } from 'utils'
import { Device, IDevice } from '../../utils'

interface Props {
  activeElement: IDevice
  updateActiveElement: (activeElement: IDevice) => void
  close: () => void
}

export const DeviceSettings: React.FC<Props> = ({ activeElement, close, updateActiveElement }) => {
  const { deviceType, screenshotName, image, color, colorName } = activeElement
  const {
    config: {
      branding: { colorStyles },
    },
    theme,
  } = useContext(ProjectContext)
  const currentColor = colorName ? colorStyles[getBrandingColorType(colorName)][theme] : color

  const onColorChange = (color: string) => updateActiveElement({ ...activeElement, color, colorName: '' })

  const onColorNameChange = (colorName: string) => updateActiveElement({ ...activeElement, colorName, color: '' })

  const changeDeviceImage = (image: string, screenshotName: string) =>
    updateActiveElement({ ...activeElement, image, screenshotName })

  const changeDevice = (deviceType: Device) => updateActiveElement({ ...activeElement, deviceType })

  const sectionName = 'Frame'

  return (
    <RightSidebarItem title={sectionName} hasCloseIcon onClose={close}>
      <SubWrapper childrenFor={sectionName}>
        <SettingField
          label="Style"
          value={deviceType}
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
          optionsContainer={<DevicePicker onDeviceClick={changeDevice} close={() => {}} />}
        />
        <SettingField
          subLabel="Screen"
          value={screenshotName || (image ? 'Image' : 'Empty')}
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
          optionsContainer={<ScreenPicker onChange={changeDeviceImage} value={screenshotName} close={() => {}} />}
        />
        {deviceType === Device.iPhone13Pro && (
          <SettingField
            subLabel="Color"
            value={colorName || (color.startsWith('rgb') ? rgbToHex(color) : color)}
            leftIcon={
              colorName ? (
                <TwoColorsSvg left={currentColor} right={currentColor} />
              ) : (
                <OneColorSvg color={currentColor} />
              )
            }
            optionsContainer={
              <ColorPicker
                close={() => {}}
                value={colorName}
                onChange={onColorNameChange}
                colorName={colorName}
                color={currentColor}
                onColorChange={onColorChange}
                onlySolid
              />
            }
          />
        )}
      </SubWrapper>
    </RightSidebarItem>
  )
}
