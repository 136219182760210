import { Box, Avatar as MuiAvatar } from '@mui/material'

import { Icon, Name } from 'components/Icon'

interface Props {
  value?: string | null
  width: number
  bR?: number
}

export const Avatar: React.FC<Props> = ({ value, width, bR }) => {
  const borderRadius = bR ? `${bR}px` : '50%'

  return value ? (
    <MuiAvatar
      src={value}
      alt="avatar"
      sx={{ width: `${width}px`, height: `${width}px`, minWidth: `${width}px`, minHeight: `${width}px`, borderRadius }}
    />
  ) : (
    <Box display="flex" sx={{ overflow: 'hidden', minWidth: `${width}px`, minHeight: `${width}px`, borderRadius }}>
      <Icon name={Name.ADDITIONAL_EMPTY_AVATAR} width={width} height={width} />
    </Box>
  )
}
