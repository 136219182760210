import { Box } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components'

export const OpenProjects: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Box m="0 15px 0 auto">
      <Button onClick={() => navigate('/')}>Open projects</Button>
    </Box>
  )
}
