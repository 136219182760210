import styled from 'styled-components'

export const About = styled.div`
  color: ${props => props.theme.colors.darkText};
  max-width: 234px;
  width: 100%;
  margin-left: 50px;
  padding-bottom: 10px;
  & h2 {
    font-size: 14px;
    line-height: 21px;
    font-weight: ${props => props.theme.weights.bold};
  }
  & > p {
    line-height: 21px;
    font-size: 14px;
    margin: 4px 0 15px;
  }
  & > span {
    border-bottom: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
    padding-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 21px;
    font-size: 14px;
    color: ${props => props.theme.colors.primaryText};
    & svg {
      margin-right: 9px;
      & path {
        stroke: ${props => props.theme.colors.primaryText};
      }
    }
    &:hover {
      color: ${props => props.theme.colors.primaryTextHover};
      & > svg path {
        stroke: ${props => props.theme.colors.primaryTextHover};
      }
    }
  }
`
