import styled from 'styled-components'

import { unreset } from 'theme'
import { BarComponentType, ComponentType } from 'utils'

const getScaleMargin = (px: number, compression: number) => `-${(px - px * compression) / 2}px`

export const ScreenContainer = styled.div<{ compression: number; width: number; height: number }>`
  width: ${props => props.width}px;
  transform: ${props => `scale(${props.compression})`};
  margin: ${props =>
    `${getScaleMargin(props.height, props.compression)} ${getScaleMargin(props.width, props.compression)}`};
  display: flex;
  flex-direction: column;
  gap: 15px;
  *::-webkit-scrollbar {
    display: none;
  }
  *[hidden] {
    display: none !important;
  }
`

export const ScreenWrapper = styled.div<{
  height: number
  isScreenActive: boolean
  isComponentOnScreenSelected: boolean
  disabled: boolean
}>`
  ${unreset}
  overflow: hidden;
  position: relative;
  width: 100%;
  height: ${props => props.height}px;
  outline: 1px solid
    ${props =>
      props.isComponentOnScreenSelected && props.isScreenActive
        ? props.theme.colors.grey1
        : props.isScreenActive
        ? props.theme.colors.primaryTextHover
        : props.theme.colors.darkBlue_100};
  & * {
    cursor: default;
    user-select: none;
    pointer-events: ${props => props.disabled && 'none'};
  }
  & #hoverHintLabel,
  & #selectedHintLabel {
    pointer-events: none;
    position: absolute;
    display: flex;
    gap: 1px;
    background: ${props => props.theme.colors.white};
    & > div {
      line-height: 26px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: unset;
      white-space: nowrap;
      padding: 0 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      & svg {
        width: 20px;
        height: 20px;
      }
      &:not(:first-child) {
        & > i {
          height: 70%;
          width: 1px;
          background: ${props => props.theme.colors.white}60;
        }
        & > svg {
          cursor: pointer;
          & * {
            cursor: pointer;
          }
        }
      }
    }
  }
  & #hoverHintLabel {
    z-index: 2;
    & > div {
      color: ${props => props.theme.colors.primaryText};
      & > svg {
        & *[fill] {
          fill: ${props => props.theme.colors.primaryText};
        }
        & *[stroke] {
          stroke: ${props => props.theme.colors.primaryText};
        }
      }
    }
  }
  & #selectedHintLabel {
    z-index: 1;
    & > div {
      background: ${props => props.theme.colors.primaryTextHover};
      color: ${props => props.theme.colors.white};
      & > svg {
        & *[fill] {
          fill: ${props => props.theme.colors.white};
        }
        & *[stroke] {
          stroke: ${props => props.theme.colors.white};
        }
      }
    }
  }
  .hoveredComponent:after,
  .selectedComponent:after {
    pointer-events: none;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .hoveredComponent:after {
    outline: 2px solid ${props => props.theme.colors.screenHover};
    outline-offset: -1px;
    z-index: 2;
  }
  .selectedComponent:after {
    outline: 2px solid ${props => props.theme.colors.primaryTextHover};
    outline-offset: -1px;
    z-index: 1;
  }
  .pointerLine {
    pointer-events: none;
    background: ${props => props.theme.colors.primaryTextHover};
  }
  & [id^='${ComponentType.carousel}'],
  & [id^='${ComponentType.list}'],
  & [id^='${BarComponentType.tabBar}'],
  & [id^='${BarComponentType.topBar}'] {
    & .screenComponent {
      pointer-events: none;
    }
  }
  .innerModeComponent {
    box-shadow: 0px 0px 0px 1000px #000000de;
    z-index: 1;
    & .screenComponent {
      pointer-events: auto !important;
      & #hoverHintLabel {
        z-index: 3;
      }
      & #selectedHintLabel {
        z-index: 2;
      }
    }
  }
`

export const ScreenName = styled.div<{ isScreenActive: boolean; isComponentOnScreenSelected: boolean }>`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  padding: 5px;
  background: ${props =>
    props.isScreenActive && !props.isComponentOnScreenSelected
      ? props.theme.colors.primaryTextHover
      : props.theme.colors.primaryBackground};
  outline: 1px solid
    ${props => (props.isComponentOnScreenSelected && props.isScreenActive ? props.theme.colors.grey1 : 'transparent')};
  color: ${props =>
    props.isScreenActive && !props.isComponentOnScreenSelected ? props.theme.colors.white : props.theme.colors.grey1};
  line-height: 150%;
  font-size: 16px;
  font-weight: 400;
  & > svg {
    & *[fill] {
      fill: ${props =>
        props.isScreenActive && !props.isComponentOnScreenSelected
          ? props.theme.colors.white
          : props.theme.colors.grey1};
    }
    & *[stroke] {
      stroke: ${props =>
        props.isScreenActive && !props.isComponentOnScreenSelected
          ? props.theme.colors.white
          : props.theme.colors.grey1};
    }
  }
`
