import React, { useContext, useEffect } from 'react'

import { SignInForm } from 'components'
import { UserContext } from 'context'
import { MessageBeforeSend } from 'services/conversations'

interface Props {
  sendMessage: (messageBeforeSend: MessageBeforeSend) => void
  isAdmin: boolean
}

export const Auth: React.FC<Props> = ({ sendMessage, isAdmin }) => {
  const { firebaseUser } = useContext(UserContext)
  const authorized = !firebaseUser.isAnonymous

  useEffect(() => {
    if (!isAdmin && authorized) {
      const { displayName, email, providerId, photoURL } = firebaseUser
      sendMessage({
        body: 'Logged in',
        format: 'text',
        authAttachment: { displayName, email, providerId, photoURL },
      })
    }
  }, [authorized])

  return authorized ? <></> : <SignInForm />
}
