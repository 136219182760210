import { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as styled from 'components/Menu/Menu.styled'

import { Icon, Name, RemoveContent } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { Folder } from 'context/types'
import { CMSService, assetsId, getParentFolderName, rootFolderId } from 'services/cms'
import { AddNew } from '../AddNew'
import { Branch, FoldersItem } from './FoldersItem'

interface Props {
  folders: Folder[]
  currentFolder?: Folder
  setIsSchemaPage: React.Dispatch<React.SetStateAction<boolean>>
}

export const FoldersItems: React.FC<Props> = ({ folders, currentFolder, setIsSchemaPage }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const navigate = useNavigate()
  const [addFolder, setAddFolder] = useState(false)
  const [toRemove, setToRemove] = useState<Folder | null>(null)

  const getFoldersTree = (lvl: number, root?: string): Branch[] =>
    folders
      .filter(el => el.name.split('/').length === lvl + 1 && (root ? el.name.startsWith(`${root}/`) : true))
      .map(el => ({ ...el, children: getFoldersTree(lvl + 1, el.name) } as Branch))

  const foldersTree = useMemo(() => getFoldersTree(0), [folders])

  const openRecords = (fId: string) => {
    setIsSchemaPage(false)
    navigate(`/projects/${id}/collections/${assetsId}?folder=${fId}`)
  }

  const createFolder = (name: string, callback: () => void) => {
    if (name) {
      const fullName = currentFolder ? `${currentFolder.name}/${name}` : `${rootFolderId}/${name}`
      if (folders.find(el => el.name === fullName)) {
        toast(`You cannot create folder named ${name}`)
        callback()
      } else {
        startLoader()
        CMSService.createFolder(id, fullName)
          .then(res => openRecords(res.data.id))
          .catch(err => toast(err))
          .finally(() => {
            callback()
            stopLoader()
          })
      }
    }
  }

  const remove = (callback: () => void) => {
    if (toRemove) {
      startLoader()
      CMSService.deleteFolder(id, toRemove.id)
        .then(() => {
          if (currentFolder?.id === toRemove.id) {
            const parentFolderId = folders.find(el => el.name === getParentFolderName(currentFolder.name))?.id
            openRecords(parentFolderId || rootFolderId)
          }
        })
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
        })
    }
  }

  return (
    <>
      <styled.NavHead>
        <styled.Title>Assets</styled.Title>
        <Icon name={Name.RIGHT_SIDEBAR_ADD} onClick={() => setAddFolder(true)} />
      </styled.NavHead>
      <styled.NavBody>
        {foldersTree.map(el => (
          <FoldersItem
            key={el.id}
            folder={el}
            currentFolder={currentFolder}
            openRecords={openRecords}
            setToRemove={setToRemove}
            lvl={0}
          />
        ))}
      </styled.NavBody>
      <AddNew
        active={addFolder}
        close={() => setAddFolder(false)}
        onAdd={createFolder}
        folder
        currentFolder={currentFolder}
      />
      <RemoveContent
        toRemove={toRemove}
        title="Delete folder"
        text="You are about to delete the folder <b>{name}</b>. To make sure, enter the folder name below"
        clean={() => setToRemove(null)}
        remove={remove}
        nameConfirmation
      />
    </>
  )
}
