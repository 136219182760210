import { Box } from '@mui/material'
import JSZip from 'jszip'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from './ScreenshotsStudio.styled'

import { DeviceSettings as BottomDeviceSettings, Menu } from 'components'
import { switchColorConfig } from 'components/CustomPicker/ColorPicker/components/SwitchColor/constants'
import { ProgressContext, ProjectContext } from 'context'
import { useHistoryState, useScreenshotFromVB } from 'hooks'
import { TopPanelScreenshotsStudio, downloadEvent, promoteEvent } from 'partials'
import { database } from 'services/firebase'
import { ProjectsService } from 'services/projects'
import {
  BrandingColorType,
  convertSvgStringToImage,
  generateFirestoreId,
  getBrandingColorType,
  urlToBase64,
} from 'utils'
import { BackgroundSettings, DeviceSettings, ScreenshotSettings, TextSettings } from './components'
import * as utils from './utils'

export const ScreenshotsStudio: React.FC = () => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { blue, buttonColor } = useContext(ThemeContext).colors
  const {
    project: { id },
    config: {
      branding: { colorStyles },
    },
    theme,
  } = useContext(ProjectContext)
  const ref = useRef<HTMLDivElement>(null)
  const getScreenshotFromVB = useScreenshotFromVB()

  const [c, setConfig, undo, redo] = useHistoryState({})
  const config: utils.ScreenshotsConfig = c
  const backgrounds = (config.elements?.filter(el => el.type === utils.Component.BACKGROUND) ||
    []) as utils.IBackground[]

  const [root, setRoot] = useState<SVGElement | null>(null)
  const [commonBackground, setCommonBackground] = useState<SVGElement | null>(null)
  const [backgroundsContainer, setBackgroundsContainer] = useState<SVGElement | null>(null)
  const [elementsContainer, setElementsContainer] = useState<SVGElement | null>(null)
  const [marginsContainer, setMarginsContainer] = useState<SVGElement | null>(null)
  const [linesContainer, setLinesContainer] = useState<SVGElement | null>(null)
  const [backgroundsBordersContainer, setBackgroundsBordersContainer] = useState<SVGElement | null>(null)

  const [activeElement, setActiveElement] = useState<
    utils.IBackground | utils.IImage | utils.IDevice | utils.IText | null
  >(null)
  const [toCopy, setToCopy] = useState<utils.IImage | utils.IDevice | utils.IText | null>(null)
  const [mode, setMode] = useState(utils.Mode.POINTER)
  const [mounted, setMounted] = useState(false)
  const [configWait, setConfigWait] = useState(true)
  const [isConfigSnapshot, setIsConfigSnapshot] = useState(false)
  const configRef = database.ref(`projects/${id}/configurations/screenshots`)

  useEffect(() => {
    fetch('/jsons/screenshotsStudioBase.json')
      .then(res => res.json())
      .then(res => {
        setBase(res.data)
        configRef.on('value', snapshot => {
          const config = snapshot.val()
          if (config) {
            console.log('GET SCREENSHOTS CONFIG')
            setIsConfigSnapshot(true)
            setConfig(config)
            setConfigWait(false)
            setTimeout(() => setIsConfigSnapshot(false), 0)
          }
        })
      })
      .catch(err => toast(err))
    return () => {
      configRef.off('value')
    }
  }, [])

  useEffect(() => {
    if (!isConfigSnapshot && !configWait) {
      const timer = setTimeout(() => {
        startLoader()
        console.log('SET SCREENSHOTS CONFIG')
        configRef
          .set(JSON.parse(JSON.stringify(config)) as utils.ScreenshotsConfig)
          .catch(err => toast(err))
          .finally(() => stopLoader())
      }, 0)
      return () => clearTimeout(timer)
    }
  }, [JSON.stringify(config)])

  const setBase = (data: string) => {
    if (ref.current) {
      ref.current.innerHTML = data
      const root = ref.current.querySelector('svg') as SVGElement
      setRoot(root)
      setBackgroundsContainer(root.querySelector('g[id="backgroundsContainer"]') as SVGElement)
      setCommonBackground(root.querySelector('foreignObject[id="commonBackground"]') as SVGElement)
      setElementsContainer(root.querySelector('g[id="elementsContainer"]') as SVGElement)
      setMarginsContainer(root.querySelector('g[id="marginsContainer"]') as SVGElement)
      setLinesContainer(root.querySelector('g[id="linesContainer"]') as SVGElement)
      setBackgroundsBordersContainer(root.querySelector('g[id="backgroundsBordersContainer"]') as SVGElement)
    }
  }

  const setScreens = async () => {
    if (
      !configWait &&
      root &&
      backgroundsContainer &&
      commonBackground &&
      elementsContainer &&
      marginsContainer &&
      linesContainer &&
      backgroundsBordersContainer
    ) {
      ;[...Array.from(backgroundsContainer.children), ...Array.from(elementsContainer.children)]
        .filter(el => el.id !== 'resize')
        .forEach(old => {
          if (!config.elements?.find(el => old.id === el.id)) {
            if (activeElement?.id === old.id) {
              setActiveElement(null)
            }
            const border = document.getElementById('border-' + old.id)
            if (border) {
              border.parentNode?.removeChild(border)
            }
            const margin = document.getElementById('margin-' + old.id)
            if (margin) {
              margin.parentNode?.removeChild(margin)
            }
            old.parentNode?.removeChild(old)
          }
        })
      let backgroundsCount = 0
      if (config.elements) {
        for (const el of config.elements) {
          switch (el.type) {
            case utils.Component.COMMON_BACKGROUND:
              const elCB = el as utils.ICommonBackground
              const commonBackgroundDiv = commonBackground.querySelector('div')
              if (elCB.background || elCB.colorName) {
                commonBackground.style.removeProperty('opacity')
                if (commonBackgroundDiv) {
                  if (elCB.background.startsWith('url(')) {
                    commonBackgroundDiv.style.removeProperty('background')
                    commonBackgroundDiv.style.gap = utils.marginWidth + 'px'
                    const divs: HTMLDivElement[] = []
                    for (let i = 0; i < backgrounds.length; i++) {
                      const div = document.createElement('div')
                      div.style.width = utils.backgroundWidth + 'px'
                      div.style.height = utils.containerHeight + 'px'
                      if (elCB.background === switchColorConfig.defaultImage) {
                        div.style.background = '#FFFFFF'
                      } else {
                        const url = elCB.background.slice(5, -24)
                        await urlToBase64(url, res => (div.style.background = elCB.background.replace(url, res)))
                      }
                      div.style.pointerEvents = 'none'
                      divs.push(div)
                    }
                    commonBackgroundDiv.innerHTML = ''
                    divs.forEach(el => commonBackgroundDiv.appendChild(el))
                  } else {
                    commonBackgroundDiv.style.background = elCB.colorName
                      ? colorStyles[getBrandingColorType(elCB.colorName)][theme]
                      : elCB.background
                    commonBackgroundDiv.innerHTML = ''
                  }
                }
              } else {
                commonBackground.style.opacity = '0'
                if (commonBackgroundDiv) {
                  commonBackgroundDiv.style.removeProperty('background')
                  commonBackgroundDiv.innerHTML = ''
                }
              }
              break
            case utils.Component.BACKGROUND:
              const elB = el as utils.IBackground
              const pos = backgroundsCount
              const backgroundContainer = await utils.addBackground(
                {
                  ...elB,
                  background: elB.colorName ? colorStyles[getBrandingColorType(elB.colorName)][theme] : elB.background,
                },
                backgroundsContainer
              )
              const x = pos * (utils.backgroundWidth + utils.marginWidth)
              backgroundContainer.setAttribute('x', String(x))
              utils.addLines(backgroundContainer, x, 0, utils.backgroundWidth, utils.containerHeight, 0, linesContainer)
              backgroundContainer.onmousedown = () => setActiveElement(elB)
              utils.addBackgroundBorder(pos, elB.id, buttonColor, backgroundsBordersContainer)
              utils.addMargin(pos, elB.id, marginsContainer)
              backgroundsCount++
              break
            case utils.Component.TEXT:
              const elT = el as utils.IText
              const textContainer = utils.addText(
                { ...elT, color: elT.colorName ? colorStyles[getBrandingColorType(elT.colorName)][theme] : elT.color },
                elementsContainer
              )
              utils.addElementsLines(textContainer, linesContainer)
              utils.elementMouseDown(
                textContainer,
                linesContainer,
                blue,
                () => setActiveElement(elT),
                updateActiveElement,
                elT,
                true
              )
              break
            case utils.Component.IMAGE:
              const elI = el as utils.IImage
              const imageContainer = await utils.addImage({ ...elI }, elementsContainer)
              utils.addElementsLines(imageContainer, linesContainer)
              utils.elementMouseDown(
                imageContainer,
                linesContainer,
                blue,
                () => setActiveElement(elI),
                updateActiveElement,
                elI
              )
              break
            case utils.Component.DEVICE:
              const elD = el as utils.IDevice
              let image = ''
              if (elD.screenshotName) {
                await getScreenshotFromVB(elD.screenshotName).then(res => (image = res))
              }
              const deviceContainer = await utils.addDevice(
                {
                  ...elD,
                  color: elD.colorName ? colorStyles[getBrandingColorType(elD.colorName)][theme] : elD.color,
                  image: image || elD.image,
                },
                elementsContainer
              )
              utils.addElementsLines(deviceContainer, linesContainer)
              utils.elementMouseDown(
                deviceContainer,
                linesContainer,
                blue,
                () => setActiveElement(elD),
                updateActiveElement,
                elD
              )
              break
          }
        }
      }
      const newWidth = backgroundsCount
        ? utils.backgroundWidth * backgroundsCount + utils.marginWidth * (backgroundsCount - 1)
        : 0
      root.setAttribute('width', String(newWidth))
      root.setAttribute('viewBox', `0 0 ${newWidth} ${utils.containerHeight}`)
      setMounted(true)
    }
  }

  useEffect(() => {
    setMounted(false)
    setTimeout(setScreens, 0)
    return () => {
      if (linesContainer) {
        linesContainer.innerHTML = ''
      }
    }
  }, [
    JSON.stringify(config),
    getScreenshotFromVB,
    root,
    backgroundsContainer,
    commonBackground,
    elementsContainer,
    linesContainer,
    backgroundsBordersContainer,
  ])

  useEffect(() => {
    document.onkeydown = e => {
      const isBody = document.activeElement?.tagName === 'BODY'
      if (isBody) {
        const isElement = activeElement && activeElement.type !== utils.Component.BACKGROUND
        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyC' && isElement) {
          setToCopy(activeElement as utils.IImage | utils.IDevice | utils.IText)
        } else if ((e.ctrlKey || e.metaKey) && e.code === 'KeyX' && isElement) {
          setToCopy(activeElement as utils.IImage | utils.IDevice | utils.IText)
          setConfig((oldConfig: utils.ScreenshotsConfig) => ({
            ...oldConfig,
            elements: oldConfig.elements?.filter(el => el.id !== activeElement.id),
          }))
          setActiveElement(null)
        } else if ((e.ctrlKey || e.metaKey) && e.code === 'KeyV' && toCopy) {
          const copy = { ...toCopy, id: generateFirestoreId(), x: +toCopy.x + 20, y: +toCopy.y + 20 }
          addToConfig([copy])
        } else if ((e.ctrlKey || e.metaKey) && e.code === 'KeyZ') {
          undo()
        } else if ((e.ctrlKey || e.metaKey) && e.code === 'KeyY') {
          redo()
        } else if ((e.code === 'Delete' || e.code === 'Backspace') && isElement) {
          setConfig((oldConfig: utils.ScreenshotsConfig) => ({
            ...oldConfig,
            elements: oldConfig.elements?.filter(el => el.id !== activeElement.id),
          }))
          setActiveElement(null)
        }
      }
    }
    return () => {
      document.onkeydown = () => {}
    }
  }, [activeElement, toCopy])

  useEffect(() => {
    if (mounted && activeElement && backgroundsBordersContainer && elementsContainer && linesContainer) {
      const element = document.getElementById(activeElement.id) as SVGElement | null
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' })
        if (activeElement.type === utils.Component.BACKGROUND) {
          utils.resizeContainer.parentNode?.removeChild(utils.resizeContainer)
          const div = backgroundsBordersContainer
            .querySelector(`foreignObject[id*=border-${activeElement.id}]`)
            ?.querySelector('div')
          if (div) {
            div.style.border = `2px solid ${blue}`
            return () => {
              div.style.border = `2px solid ${buttonColor}`
            }
          }
        } else {
          const elementsContainerChildren = Array.from(elementsContainer.children)
          if (
            elementsContainerChildren.at(-2) !== utils.resizeContainer ||
            elementsContainerChildren.at(-1) !== element
          ) {
            elementsContainer.appendChild(utils.resizeContainer)
            elementsContainer.appendChild(element)
          }
          utils.resizeHandler(
            element,
            linesContainer,
            updateActiveElement,
            activeElement as utils.IText | utils.IImage | utils.IDevice,
            activeElement.type === utils.Component.TEXT
          )
          if (mode === utils.Mode.ADD_TEXT && activeElement.type === utils.Component.TEXT) {
            utils.contenteditableHandler(element, true, value => updateActiveElement({ ...activeElement, value }))
            setMode(utils.Mode.POINTER)
          }
        }
      }
    }
    return () => {
      if (!activeElement) {
        utils.resizeContainer.parentNode?.removeChild(utils.resizeContainer)
      }
    }
  }, [mounted, activeElement])

  const addToConfig = (elements: (utils.IBackground | utils.IImage | utils.IDevice | utils.IText)[]) => {
    const background = elements.find(el => el.type === utils.Component.BACKGROUND)
    if (elements.length === 1) {
      setActiveElement(elements[0])
    } else if (background) {
      setActiveElement(background)
    }
    setConfig((oldConfig: utils.ScreenshotsConfig) => ({
      ...oldConfig,
      elements: [...(oldConfig.elements || []), ...elements],
    }))
  }

  const updateActiveElement = (activeElement: utils.IBackground | utils.IImage | utils.IDevice | utils.IText) =>
    setConfig((oldConfig: utils.ScreenshotsConfig) => {
      const configCopy = JSON.parse(JSON.stringify(oldConfig)) as utils.ScreenshotsConfig
      const active = configCopy.elements?.find(el => el.id === activeElement.id)
      if (active && JSON.stringify(active) !== JSON.stringify(activeElement)) {
        // @ts-ignore
        Object.keys(activeElement).forEach(key => (active[key] = activeElement[key]))
        setActiveElement(active as utils.IBackground | utils.IImage | utils.IDevice | utils.IText)
      }
      return configCopy
    })

  const applyToAllScreens = (background: string, colorName: string) =>
    setConfig((oldConfig: utils.ScreenshotsConfig) => {
      const configCopy = JSON.parse(JSON.stringify(oldConfig)) as utils.ScreenshotsConfig
      const commonBackground = configCopy.elements?.find(el => el.type === utils.Component.COMMON_BACKGROUND) as
        | utils.ICommonBackground
        | undefined
      if (commonBackground) {
        commonBackground.background = background
        commonBackground.colorName = colorName
      }
      return configCopy
    })

  const closeSettings = () => setActiveElement(null)

  const deleteScreen = () => {
    const { lastScreenConfig } = getLastScreen()
    if (lastScreenConfig.find(el => el.id === activeElement?.id)) {
      setActiveElement(null)
    }
    setConfig((oldConfig: utils.ScreenshotsConfig) => ({
      ...oldConfig,
      elements: oldConfig.elements?.filter(a => !lastScreenConfig.find(b => b.id === a.id)),
    }))
  }

  const getLastScreen = () => {
    const lastBackground: utils.IBackground = (config.elements
      ?.slice()
      .reverse()
      .find(el => el.type === utils.Component.BACKGROUND) as utils.IBackground | undefined) || {
      type: utils.Component.BACKGROUND,
      id: generateFirestoreId(),
      background: '',
      colorName: `@${BrandingColorType.primary}`,
    }
    const lastScreenX = +(root?.getAttribute('width') || 0) - utils.backgroundWidth
    const lastBackgroundElements = config.elements?.filter((el: any) => {
      if (el.hasOwnProperty('x')) {
        const { x, width } = el
        if (x + width >= lastScreenX) {
          return true
        }
      }
      return false
    })
    return { lastScreenX, lastScreenConfig: [lastBackground, ...(lastBackgroundElements || [])] }
  }

  const onContainerMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (root && elementsContainer) {
      const { x: rX, y: rY } = root.getBoundingClientRect()
      const x = e.clientX - rX
      const y = e.clientY - rY
      let width = 0
      let height = 0
      if (mode === utils.Mode.ADD_TEXT) {
        elementsContainer.appendChild(utils.resizeContainer)
        utils.resizeContainer.setAttribute('x', String(x))
        utils.resizeContainer.setAttribute('y', String(y))
        utils.resizeContainer.setAttribute('width', String(width))
        utils.resizeContainer.setAttribute('height', String(height))
        utils.resizeContainer.style.removeProperty('transform')
        document.onmousemove = (e: MouseEvent) => {
          width += e.movementX
          height += e.movementY
          utils.resizeContainer.setAttribute('width', String(width))
          utils.resizeContainer.setAttribute('height', String(height))
        }
        document.onmouseup = () => {
          document.onmousemove = () => {}
          document.onmouseup = () => {}
          const textContainer = {
            type: utils.Component.TEXT,
            id: generateFirestoreId(),
            x,
            y,
            width: width < 100 ? 100 : width,
            height: height < 34 ? 34 : height,
            angle: 0,
            value: '',
            color: '',
            colorName: `@${BrandingColorType.onPrimary}`,
            fontFamily: 'Roboto',
            fontWeight: '700',
            fontStyle: 'normal',
            fontSize: '30',
            textAlign: 'center',
            verticalAlign: 'center',
            lineHeight: '100',
            letterSpacing: '0',
          }
          addToConfig([textContainer])
        }
      }
    }
  }

  useEffect(() => {
    const getImages = (promote?: boolean) => {
      if (root) {
        setActiveElement(null)
        const serializer = new XMLSerializer()
        const svg = root.cloneNode(true) as SVGElement
        // @ts-ignore
        const { width, height } = root.attributes
        svg.setAttribute('width', String(+width.value * 3))
        svg.setAttribute('height', String(+height.value * 3))
        const source = serializer.serializeToString(svg as Node)
        const url = convertSvgStringToImage(source)
        const canvas = document.createElement('canvas')
        canvas.width = utils.backgroundWidth * 3
        canvas.height = utils.containerHeight * 3
        const ctx = canvas.getContext('2d')
        const img = new Image()
        img.onload = () => {
          if (ctx) {
            if (promote) {
              const res: string[] = []
              backgrounds.forEach((el, i) => {
                const x = i * (utils.backgroundWidth + utils.marginWidth) * 3
                ctx.drawImage(img, -x, 0)
                res.push(canvas.toDataURL('image/png'))
              })
              startLoader()
              Promise.all(res.map(el => fetch(el).then(response => response.blob())))
                .then(res =>
                  Promise.all(
                    res.map((el, i) => {
                      const formData = new FormData()
                      formData.append('resource', new File([el], `screenshot-${i + 1}.png`, { type: el.type }))
                      const resourceToUpdateId = config.resources?.[i]
                      return resourceToUpdateId
                        ? ProjectsService.updateResource(id, resourceToUpdateId, formData)
                        : ProjectsService.uploadResource(id, formData)
                    })
                  ).then(res => {
                    setConfig((oldConfig: utils.ScreenshotsConfig) => ({
                      ...oldConfig,
                      resources: res.map(el => el.data.id),
                    }))
                    ProjectsService.promote(
                      id,
                      res.map(el => el.data.url)
                    )
                  })
                )
                .catch(err => toast(err))
                .finally(() => stopLoader())
            } else {
              const zip = new JSZip()
              backgrounds.forEach((el, i) => {
                const x = i * (utils.backgroundWidth + utils.marginWidth) * 3
                ctx.drawImage(img, -x, 0)
                zip.file(`screenshot-${i + 1}.png`, canvas.toDataURL('image/png').split(';base64,')[1], {
                  base64: true,
                })
              })
              zip
                .generateAsync({ type: 'base64' })
                .then(content => {
                  const a = document.createElement('a')
                  a.href = 'data:application/zip;base64,' + content
                  a.setAttribute('download', `screenshots.zip`)
                  a.click()
                  a.remove()
                })
                .catch(err => toast(err))
            }
          }
        }
        img.src = url
      }
    }
    const download = () => getImages()
    const promote = () => getImages(true)
    document.addEventListener(downloadEvent, download)
    document.addEventListener(promoteEvent, promote)
    return () => {
      document.removeEventListener(downloadEvent, download)
      document.removeEventListener(promoteEvent, promote)
    }
  })

  return (
    <>
      <TopPanelScreenshotsStudio
        mode={mode}
        setMode={setMode}
        addToConfig={addToConfig}
        activeElement={activeElement}
        getLastScreen={getLastScreen}
      />
      <Box width="100%" height="100%" display="flex" justifyContent="space-between">
        <Box width="100%" height="100%" position="relative" overflow="auto">
          <styled.Container>
            <styled.Editor ref={ref} onMouseDown={onContainerMouseDown} isAddTextMode={mode === utils.Mode.ADD_TEXT} />
          </styled.Container>
          <BottomDeviceSettings />
        </Box>
        {!configWait && (
          <Menu
            right
            firstChild={
              <>
                {activeElement?.type === utils.Component.BACKGROUND && (
                  <BackgroundSettings
                    key={activeElement.id}
                    activeElement={activeElement as utils.IBackground}
                    updateActiveElement={updateActiveElement}
                    close={closeSettings}
                    appliedToAllScreens={commonBackground?.style.opacity !== '0'}
                    applyToAllScreens={applyToAllScreens}
                  />
                )}
                {activeElement?.type === utils.Component.TEXT && (
                  <TextSettings
                    key={activeElement.id}
                    activeElement={activeElement as utils.IText}
                    updateActiveElement={updateActiveElement}
                    close={closeSettings}
                    root={root}
                    config={config}
                  />
                )}
                {activeElement?.type === utils.Component.DEVICE && (
                  <DeviceSettings
                    key={activeElement.id}
                    activeElement={activeElement as utils.IDevice}
                    close={closeSettings}
                    updateActiveElement={updateActiveElement}
                  />
                )}
                <ScreenshotSettings
                  activeElement={activeElement}
                  addScreen={() => setMode(utils.Mode.ADD_MEDIA)}
                  deleteScreen={deleteScreen}
                  backgrounds={backgrounds}
                  setActiveElement={setActiveElement}
                />
              </>
            }
          />
        )}
      </Box>
    </>
  )
}
