import styled from 'styled-components'

export const ModalOverlay = styled.div<{ active: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.overlayBg};
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.active ? '1' : '0')};
  pointer-events: ${props => (props.active ? 'all' : 'none')};
  transition: background-color 0.4s ease, opacity 0.4s ease;
`

export const ModalWrapper = styled.div<{ active: boolean; width?: string }>`
  max-width: calc(100% - 60px);
  max-height: calc(100% - 60px);
  z-index: 3;
  width: ${props => props.width || '490px'};
  border-radius: 16px;
  background-color: ${props => props.theme.colors.modalBg};
  padding: 20px 25px 25px;
  transform: ${props => (props.active ? 'scale(1)' : 'scale(0)')};
  transition: 0.4s all;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 680px) {
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
  }
`

export const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  & > svg {
    & circle,
    & path {
      fill-opacity: 1;
      transition: 300ms ease-out;
    }
    & circle {
      fill: ${props => props.theme.colors.settingField};
    }
  }
  &:hover > svg {
    & circle {
      fill: ${props => props.theme.colors.settingFieldHover};
    }
    & path {
      stroke: ${props => props.theme.colors.darkText};
    }
  }
`

export const ModalInputs = styled.div`
  margin: 0 -25px;
  padding: 0 25px;
  overflow: auto;
`

export const ModalActions = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  & > button {
    min-width: 90px;
  }
`

export const PickerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.overlayBg};
`

export const PickerWrapper = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
