import React, { useEffect } from 'react'

import { auth } from 'services/firebase'
import { landingUrl } from 'utils'

export const Bridge: React.FC = () => {
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      firebaseUser =>
        window.location !== window.parent.location &&
        landingUrl &&
        window.parent.postMessage(
          {
            user: firebaseUser
              ? { uid: firebaseUser.uid, displayName: firebaseUser.displayName, photoUrl: firebaseUser.photoURL }
              : null,
          },
          landingUrl
        )
    )
    return () => {
      unsubscribe()
    }
  }, [])

  return <></>
}
