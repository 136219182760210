import { Route } from 'react-router-dom'

import { AI, CMS, Conversations, ConversationsHistory, Monetization, Repository, Settings, Studio } from 'pages'
import { Account, Billing, BillingAccount, BillingAccountDashboard, Projects, Templates } from 'pages/Projects/pages'
import { Activities, Builds, Code, Deployments, Logs, Settings as RepositorySettings } from 'pages/Repository/pages'
import {
  AppStore as SettingsAppStore,
  Appetize as SettingsAppetize,
  Authentication as SettingsAuthentication,
  Design as SettingsDesign,
  Domains as SettingsDomains,
  General as SettingsGeneral,
  Genkit as SettingsGenkit,
  Github as SettingsGithub,
  GooglePlay as SettingsGooglePlay,
  Languages as SettingsLanguages,
  Logic as SettingsLogic,
  Plans as SettingsPlans,
  Screenshots as SettingsScreenshots,
  Stripe as SettingsStripe,
  Teammates as SettingsTeammates,
  Usage as SettingsUsage,
} from 'pages/Settings/pages'
import { LogicItemRecord } from 'pages/Settings/pages/Logic/pages'
import { Icon, Name } from './components'

export const routes: IRoute[] = [
  {
    index: true,
    path: '/',
    component: <Projects />,
    name: 'Projects',
    projectsPageIcon: <Icon name={Name.SETTINGS_PROJECTS} />,
    childPath: '/new-project',
  },
  {
    path: '/new-project',
    component: <Templates />,
    name: 'Templates',
  },
  {
    path: '/billing',
    component: <Billing />,
    name: 'Billing',
    projectsPageIcon: <Icon name={Name.SETTINGS_PLANS} />,
  },
  {
    path: '/billing/:billingAccountId',
    component: <BillingAccountDashboard />,
    children: [
      {
        path: `/billing/:billingAccountId/edit`,
        component: <BillingAccount />,
      },
    ],
  },
  {
    index: true,
    path: '/projects/:id',
    component: <Studio />,
    name: 'Visual builder',
    icon: <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_STUDIO} />,
  },
  {
    path: `/projects/:id/collections`,
    component: <CMS />,
    name: 'Collections',
    icon: <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_CMS} />,
    children: [
      {
        path: '*',
        component: <CMS />,
      },
    ],
  },
  {
    path: '/projects/:id/repository',
    customPath: '/projects/:id/repository/src',
    component: <Repository />,
    name: 'Repository',
    icon: <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_REPOSITORY} />,
    children: [
      {
        path: '/projects/:id/repository/src',
        component: <Code />,
        name: 'Code',
        icon: <Icon name={Name.REPOSITORY_CODE} />,
        children: [
          {
            path: '*',
            component: <Code />,
          },
        ],
      },
      {
        path: '/projects/:id/repository/deployments',
        component: <Deployments />,
        name: 'Deployments',
        icon: <Icon name={Name.REPOSITORY_DEPLOYMENTS} />,
      },
      {
        path: '/projects/:id/repository/builds',
        component: <Builds />,
        name: 'Builds',
        icon: <Icon name={Name.REPOSITORY_BUILDS} />,
      },
      {
        path: '/projects/:id/repository/builds/:buildNumber/logs',
        component: <Logs />,
      },
      {
        path: '/projects/:id/repository/activities',
        component: <Activities />,
        name: 'Activities',
        icon: <Icon name={Name.REPOSITORY_ACTIVITIES} />,
      },
      {
        path: '/projects/:id/repository/settings',
        component: <RepositorySettings />,
        name: 'Settings',
        icon: <Icon name={Name.REPOSITORY_SETTINGS} />,
      },
    ],
  },
  {
    path: '/projects/:id/monetization',
    component: <Monetization />,
    name: 'Monetization',
    icon: <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_ANALYTICS} />,
    children: [
      {
        path: '*',
        component: <Monetization />,
      },
    ],
  },
  {
    path: '/projects/:id/conversations',
    component: <Conversations />,
    name: 'Conversations',
    icon: <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_CONVERSATIONS} />,
  },
  {
    path: '/projects/:id/settings',
    component: <Settings />,
    name: 'Settings',
    icon: <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_SETTINGS} />,
    children: [
      {
        index: true,
        path: '/projects/:id/settings',
        component: <SettingsGeneral />,
        name: 'General',
        icon: <Icon name={Name.SETTINGS_GENERAL} />,
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/design',
        component: <SettingsDesign />,
        name: 'Design',
        icon: <Icon name={Name.SETTINGS_DESIGN} />,
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/authentication',
        component: <SettingsAuthentication />,
        name: 'Users & Authentication',
        icon: <Icon name={Name.SETTINGS_AUTHENTICATION} />,
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/languages',
        component: <SettingsLanguages />,
        name: 'Languages',
        icon: <Icon name={Name.SETTINGS_LANGUAGES} />,
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/screenshots',
        component: <SettingsScreenshots />,
        name: 'Screenshots',
        icon: <Icon name={Name.SETTINGS_SCREENSHOTS} />,
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/domains',
        component: <SettingsDomains />,
        name: 'Domains',
        icon: <Icon name={Name.SETTINGS_DOMAINS} />,
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/repository',
        customPath: '/projects/:id/settings/repository/src',
        component: <Repository settings />,
        name: 'Repository',
        icon: <Icon name={Name.SETTINGS_REPOSITORY} />,
        parent: 'App',
        children: [
          {
            path: '/projects/:id/settings/repository/src',
            component: <Code settings />,
            name: 'Code',
            icon: <Icon name={Name.REPOSITORY_CODE} />,
            children: [
              {
                path: '*',
                component: <Code settings />,
              },
            ],
          },
          {
            path: '/projects/:id/settings/repository/deployments',
            component: <Deployments />,
            name: 'Deployments',
            icon: <Icon name={Name.REPOSITORY_DEPLOYMENTS} />,
          },
          {
            path: '/projects/:id/settings/repository/builds',
            component: <Builds />,
            name: 'Builds',
            icon: <Icon name={Name.REPOSITORY_BUILDS} />,
          },
          {
            path: '/projects/:id/settings/repository/builds/:buildNumber/logs',
            component: <Logs settings />,
          },
          {
            path: '/projects/:id/settings/repository/activities',
            component: <Activities />,
            name: 'Activities',
            icon: <Icon name={Name.REPOSITORY_ACTIVITIES} />,
          },
          {
            path: '/projects/:id/settings/repository/settings',
            component: <RepositorySettings />,
            name: 'Settings',
            icon: <Icon name={Name.REPOSITORY_SETTINGS} />,
          },
        ],
      },
      {
        path: '/projects/:id/settings/logic',
        component: <SettingsLogic />,
        name: 'Logic',
        icon: <Icon name={Name.SETTINGS_LOGIC} />,
        parent: 'App',
        children: [
          {
            path: `/projects/:id/settings/logic/flows/add`,
            component: <LogicItemRecord name="flows" />,
          },
          {
            path: `/projects/:id/settings/logic/flows/:logicItemRecordId`,
            component: <LogicItemRecord name="flows" />,
          },
          {
            path: `/projects/:id/settings/logic/prompts/add`,
            component: <LogicItemRecord name="prompts" />,
          },
          {
            path: `/projects/:id/settings/logic/prompts/:logicItemRecordId`,
            component: <LogicItemRecord name="prompts" />,
          },
          {
            path: `/projects/:id/settings/logic/indexers/add`,
            component: <LogicItemRecord name="indexers" />,
          },
          {
            path: `/projects/:id/settings/logic/indexers/:logicItemRecordId`,
            component: <LogicItemRecord name="indexers" />,
          },
        ],
      },
      {
        path: '/projects/:id/settings/appetize',
        component: <SettingsAppetize />,
        name: 'Appetize',
        icon: <Icon name={Name.SETTINGS_APPETIZE} />,
        parent: 'Integrations',
      },
      {
        path: '/projects/:id/settings/app-store',
        component: <SettingsAppStore />,
        name: 'App Store',
        icon: <Icon name={Name.SETTINGS_APP_STORE} />,
        parent: 'Integrations',
      },
      {
        path: '/projects/:id/settings/google-play',
        component: <SettingsGooglePlay />,
        name: 'Google Play',
        icon: <Icon name={Name.SETTINGS_GOOGLE_PLAY} />,
        parent: 'Integrations',
      },
      {
        path: '/projects/:id/settings/stripe',
        component: <SettingsStripe />,
        name: 'Stripe',
        icon: <Icon name={Name.SETTINGS_STRIPE} />,
        parent: 'Integrations',
      },
      {
        path: '/projects/:id/settings/github',
        component: <SettingsGithub />,
        name: 'Github',
        icon: <Icon name={Name.SETTINGS_GITHUB} />,
        parent: 'Integrations',
      },
      {
        path: '/projects/:id/settings/genkit',
        component: <SettingsGenkit />,
        name: 'Genkit',
        icon: <Icon name={Name.SETTINGS_GENKIT} />,
        parent: 'Integrations',
      },
      {
        path: '/projects/:id/settings/usage',
        component: <SettingsUsage />,
        name: 'Usage',
        icon: <Icon name={Name.SETTINGS_USAGE} />,
        parent: 'Project',
      },
      {
        path: '/projects/:id/settings/plans',
        component: <SettingsPlans />,
        name: 'Plans',
        icon: <Icon name={Name.SETTINGS_PLANS} />,
        parent: 'Project',
      },
      {
        path: '/projects/:id/settings/teammates',
        component: <SettingsTeammates />,
        name: 'Teammates',
        icon: <Icon name={Name.SETTINGS_TEAMMATES} />,
        parent: 'Project',
      },
    ],
  },
  {
    path: '/account',
    component: <Account />,
  },
  {
    path: '/ai',
    component: <AI />,
    ai: true,
  },
  // {
  //   path: '/ai/conversations',
  //   component: <ConversationsHistory />,
  //   ai: true,
  // },
  {
    path: '/admin/conversations',
    component: <ConversationsHistory adminMode />,
    ai: true,
  },
]

export interface IRoute {
  index?: boolean
  path: string
  customPath?: string
  component: JSX.Element
  name?: string
  icon?: JSX.Element
  projectsPageIcon?: JSX.Element
  children?: IRoute[]
  childPath?: string
  parent?: string
  ai?: boolean
}

const getRoutesWithClildren = (routes: IRoute[]) =>
  routes.map(route => (
    <Route key={route.path} index={route.index} path={route.path} element={route.component}>
      {route.children && getRoutesWithClildren(route.children)}
    </Route>
  ))

export const getRoutes = () => getRoutesWithClildren(routes)
