import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from '../Projects/Projects.styled'

import { Crumbs, Text, Type } from 'components'
import { ProgressContext } from 'context'
import { ITemplate } from 'context/types'
import { firestore } from 'services/firebase'
import { Template } from './components'

interface Props {
  isNewProject?: boolean
}

export const TemplateList: React.FC<Props> = ({ isNewProject }) => {
  const themeContext = useContext(ThemeContext)
  const { toast } = useContext(ProgressContext)
  const [templates, setTemplates] = useState<ITemplate[]>([])
  const [wait, setWait] = useState(true)

  useEffect(() => {
    const unsubscribe = firestore
      .collection('templates')
      .orderBy('position')
      .onSnapshot({
        next: res => {
          setTemplates(res.docs.map(el => ({ id: el.id, ...el.data() })) as ITemplate[])
          setWait(false)
        },
        error: err => {
          toast(err)
          setWait(false)
        },
      })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="flex-end">
          <Box>
            {isNewProject ? (
              <Crumbs firstCrumbTitle="Projects" secondCrumbTitle="New project" to="/" />
            ) : (
              <Text type={Type.TITLE}>Popular Templates</Text>
            )}
            <Text type={Type.SUB_TITLE} color={themeContext.colors.darkBlue_500}>
              Select a template to start with 🚀
            </Text>
          </Box>
        </Box>
      </Box>
      <Box width="100%">
        <styled.List isTemplate isNewProject={isNewProject}>
          {wait
            ? Array(4)
                .fill(0)
                .map((el, i) => <Template key={el + i} />)
            : templates.map(el => <Template key={el.id} template={el} />)}
        </styled.List>
      </Box>
    </>
  )
}
