import styled from 'styled-components'

export const NavWrapper = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
  background-color: ${props => props.theme.colors.secondaryBackground};
  & nav {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    display: flex;
  }
`

interface INavLink {
  active: boolean
}

export const NavLink = styled.div<INavLink>`
  & > a {
    position: relative;
    color: ${props => (props.active ? props.theme.colors.darkText : props.theme.colors.grey1)};
    font-weight: ${props => props.theme.weights.medium};
    font-size: 14px;
    line-height: 21px;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin: 10px 24px 10px 0;
    padding: 4px 6px;
    border-radius: 8px;
    transition: ${props => props.theme.transition.primary};
    &::after {
      background: ${props => (props.active ? props.theme.colors.darkText : 'transparent')};
      content: '';
      position: absolute;
      bottom: -11px;
      left: 0;
      width: 100%;
      height: 1px;
      border-radius: 10px;
    }
    & svg {
      margin-right: 7px;
      & path {
        transition: ${props => props.theme.transition.primary};
        stroke: ${props => (props.active ? props.theme.colors.iconHover : props.theme.colors.grey1)};
      }
      & circle {
        transition: ${props => props.theme.transition.primary};
        stroke: ${props => (props.active ? props.theme.colors.iconHover : props.theme.colors.grey1)};
      }
    }
    &:hover {
      background-color: ${props => props.theme.colors.settingField};
    }
  }
`
