import styled from 'styled-components'

export const SignInInForm = styled.div<{ modal?: boolean }>`
  width: 100%;
  margin-top: ${props => props.modal && '20px'};
  padding: ${props => (props.modal ? 15 : 20)}px;
  border-radius: ${props => (props.modal ? 8 : 24)}px;
  background: ${props => props.theme.colors.settingField};
  & > h2 {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 40px;
    font-weight: 600;
    color: ${props => props.theme.colors.darkText};
    & figure {
      position: absolute;
      left: 0;
      transform: scale(0.6);
      padding: 0 30px 0 0;
      width: 30px;
      height: 40px;
      cursor: pointer;
      transition: ${props => props.theme.transition.primary};
      &:hover {
        transform: translateX(-15px) scale(0.6);
        & > div {
          width: 40px;
        }
      }
      & * {
        position: absolute;
        height: 3px;
        background-color: ${props => props.theme.colors.black};
        border-radius: 0 3px 3px 0;
      }
      & > span {
        width: 15px;
        left: 0;
        &:first-child {
          transform: rotate(-45deg);
          top: 15.6px;
        }
        &:nth-child(2) {
          top: 24.4px;
          transform: rotate(45deg);
        }
      }
      & > div {
        width: 25px;
        left: 5px;
        top: 20px;
        transition: ${props => props.theme.transition.primary};
      }
    }
  }
  & > p {
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    max-width: 380px;
    margin: 0 auto;
    text-align: center;
    color: ${props => props.theme.colors.grey};
    margin-bottom: 20px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > form {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 250px;
      & > button {
        position: relative;
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${props => props.theme.colors.white};
        border: 1px solid ${props => props.theme.colors.borderColor};
        border-radius: 8px;
        font-size: 14px;
        line-height: 150%;
        font-weight: 500;
        color: ${props => props.theme.colors.darkText};
        cursor: pointer;
        &:hover {
          background: ${props => props.theme.colors.secondaryButtonHoverBg};
        }
        &:disabled {
          pointer-events: none;
          opacity: 0.5;
        }
        & svg {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 5px;
          left: 10px;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
      & > button[name='secondary'] {
        background: ${props => props.theme.colors.primaryText};
        border: 1px solid transparent;
        color: ${props => props.theme.colors.white};
        &:hover {
          background: ${props => props.theme.colors.primaryTextHover};
        }
      }
      & > input {
        height: 32px;
        width: 100%;
        border: none;
        border-radius: 8px;
        font-size: 14px;
        line-height: 150%;
        font-weight: 400;
        padding: 0px 15px;
        margin-bottom: 10px;
        background: ${props => props.theme.colors.settingField};
        box-sizing: border-box;
        display: block;
        font-family: ${props => props.theme.fonts.primary};
        color: ${props => props.theme.colors.darkText};
        &:disabled {
          pointer-events: none;
          opacity: 0.5;
        }
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: ${props => props.theme.colors.grey};
        }
      }
      & > p {
        text-align: center;
        color: ${props => props.theme.colors.grey};
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;
        line-height: 150%;
        font-weight: 400;
      }
      & > section {
        color: ${props => props.theme.colors.red};
        font-size: 14px;
        font-weight: 400;
        background-color: ${props => props.theme.colors.red}20;
        padding: 4px 15px;
        max-width: 400px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 15px;
        & > a {
          text-decoration: underline;
        }
      }
      & > article {
        margin: 5px 0 15px;
        color: ${props => props.theme.colors.grey};
        font-size: 10px;
        line-height: 13px;
        max-width: 100%;
        text-align: center;
        font-weight: 400;
        & a {
          color: ${props => props.theme.colors.primaryText};
          text-decoration: none;
          &:hover {
            color: ${props => props.theme.colors.primaryTextHover};
          }
        }
      }
    }
  }
`
