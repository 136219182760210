import React from 'react'

import { Text, Type } from 'components'
import { SettingsLayout } from '../components'
import { AddNew, TeammatesContent } from './components'

export const Teammates: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Teammates</Text>
    <Text type={Type.SUB_TITLE}>Manage and organize your project team.</Text>
    <AddNew />
    <TeammatesContent />
  </SettingsLayout>
)
