import styled from 'styled-components'

export const Suggestions = styled.div<{ scrollX?: boolean }>`
  min-height: 30px;
  display: flex;
  flex-wrap: ${props => !props.scrollX && 'wrap'};
  gap: 8px;
  color: ${props => props.theme.colors.darkText};
  overflow-x: ${props => props.scrollX && 'auto'};
  & span {
    flex-shrink: 0;
    font-size: 14px;
    line-height: 30px;
    padding: 0px 10px;
    border-radius: 100px;
    background-color: ${props => props.theme.colors.settingField};
    transition: ${props => props.theme.transition.secondary};
    &:hover {
      cursor: pointer;
      background-color: ${props => props.theme.colors.settingFieldHover};
    }
  }
`
