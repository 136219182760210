import React from 'react'
import { ISvg, Svg } from '../Svg'

export const Projects: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_15133_38971)">
      <rect width="7" height="7" rx="2" fill="#7F899E" />
      <rect x="9" width="7" height="7" rx="2" fill="#7F899E" />
      <rect y="9" width="7" height="7" rx="2" fill="#7F899E" />
      <rect x="9" y="9" width="7" height="7" rx="2" fill="#7F899E" />
    </g>
    <defs>
      <clipPath id="clip0_15133_38971">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)
