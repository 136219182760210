import React, { useContext } from 'react'

import { CommonPicker, Icon, Name } from 'components'
import { ProjectContext } from 'context'
import { SettingField } from 'partials/RightSidebar/components'
import { Platform } from 'utils'

const fontsList = [
  { value: 'San Francisco', label: 'San Francisco' },
  { value: 'Roboto', label: 'Roboto' },
]

interface Props {
  fontType: Platform
}

export const CoreFontInput: React.FC<Props> = ({ fontType }) => {
  const {
    config: {
      branding: {
        typography: { iosDefaultFontFamily, androidDefaultFontFamily },
      },
    },
    setConfig,
  } = useContext(ProjectContext)

  const onChange = (value: string) => {
    setConfig(config => ({
      ...config,
      branding: {
        ...config.branding,
        typography: {
          ...config.branding.typography,
          [fontType === Platform.iOS ? 'iosDefaultFontFamily' : 'androidDefaultFontFamily']: value,
        },
      },
    }))
  }

  return (
    <SettingField
      subLabel={fontType}
      leftIcon={<Icon name={Name.RIGHT_SIDEBAR_TEXT_STYLE} />}
      value={fontType === Platform.iOS ? iosDefaultFontFamily : androidDefaultFontFamily}
      optionsContainer={
        <CommonPicker
          title="Fonts"
          values={fontsList}
          value={fontType === Platform.iOS ? iosDefaultFontFamily : androidDefaultFontFamily}
          onChange={onChange}
          close={() => {}}
        />
      }
    />
  )
}
