import React from 'react'
import { ISvg, Svg } from '../Svg'

export const Domains: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M15.104 4.311L15.052 4.334C13.886 4.853 12.675 5.23 11.433 5.478C11.7838 7.14279 11.7832 8.86249 11.431 10.527C12.673 10.774 13.89 11.148 15.051 11.665L15.102 11.688C15.673 10.583 16 9.331 16 8C16 6.669 15.673 5.417 15.104 4.311ZM5.816 11.349C6.258 12.756 6.987 14.089 7.999 15.267C9.011 14.089 9.741 12.756 10.182 11.349C8.733 11.157 7.265 11.158 5.816 11.349ZM10.442 5.634C9.638 5.747 8.826 5.824 8 5.824C7.173 5.824 6.361 5.746 5.557 5.634C5.22 7.196 5.22 8.812 5.559 10.374C7.17822 10.149 8.82078 10.149 10.44 10.374C10.7792 8.81227 10.7798 7.19601 10.442 5.634ZM7.999 0.733C6.987 1.911 6.258 3.244 5.816 4.652C7.26507 4.843 8.73293 4.843 10.182 4.652C9.74 3.245 9.011 1.911 7.999 0.733ZM8.689 0C9.863 1.344 10.699 2.877 11.185 4.496C12.351 4.268 13.493 3.921 14.583 3.44C13.263 1.521 11.133 0.21 8.689 0ZM1.416 3.44C2.507 3.92 3.649 4.268 4.814 4.496C5.3 2.877 6.136 1.344 7.31 0C4.866 0.21 2.737 1.521 1.416 3.44ZM4.567 5.479C3.325 5.23 2.115 4.854 0.95 4.335L0.898 4.312C0.327 5.417 0 6.669 0 8C0 9.331 0.327 10.583 0.898 11.689L0.95 11.666C2.11 11.148 3.327 10.774 4.569 10.528C4.216 8.864 4.216 7.144 4.567 5.479ZM4.814 11.505C3.648 11.732 2.506 12.08 1.416 12.56C2.737 14.479 4.866 15.79 7.31 16C6.136 14.656 5.3 13.123 4.814 11.505ZM11.185 11.504C10.7 13.123 9.863 14.656 8.689 16C11.133 15.79 13.263 14.479 14.583 12.56C13.493 12.079 12.351 11.732 11.185 11.504Z"
      fill="#7F899E"
    />
  </Svg>
)
