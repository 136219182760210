import React from 'react'

import { Container, MaxWidthContainer } from 'pages/Projects/pages/index.styled'

import { rightSidebarId } from 'hooks'

interface Props {
  children: React.ReactNode
}

export const SettingsLayout: React.FC<Props> = ({ children }) => (
  <Container id={rightSidebarId}>
    <MaxWidthContainer maxWidth={980}>{children}</MaxWidthContainer>
  </Container>
)
