import React, { useEffect } from 'react'

import * as styled from './DraftProject.styled'

import { ProjectDraftAttachment } from 'services/conversations'

interface Props {
  projectDraftAttachment: ProjectDraftAttachment
  id: string | null
  setId: React.Dispatch<React.SetStateAction<string | null>>
  isXL: boolean
}

export const DraftProject: React.FC<Props> = ({ projectDraftAttachment, id, setId, isXL }) => {
  const { projectId, name, description, iconUrl, version } = projectDraftAttachment

  useEffect(() => {
    if (!isXL) {
      setId(projectId)
    }
  }, [])

  return (
    <styled.Wrapper active={id === projectId} onClick={() => setId(projectId)}>
      <img src={iconUrl} alt={iconUrl} />
      <div>
        <b>{name}</b>
        <span>{description}</span>
      </div>
      <i>{version}</i>
    </styled.Wrapper>
  )
}
