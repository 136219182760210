import { useEffect, useState } from 'react'

export const md = 767
export const lg = 918
export const xl = 1033
export const xxl = 1433

export const useIsBreakpoint = (breakpoint: number) => {
  const [isBreakpoint, setIsBreakpoint] = useState(window.innerWidth <= breakpoint)

  useEffect(() => {
    const resize = () => setIsBreakpoint(window.innerWidth <= breakpoint)
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [breakpoint])

  return isBreakpoint
}
