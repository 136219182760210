import React, { useContext, useEffect, useState } from 'react'

import { Modal, ProjectSetup } from 'components'
import { ProjectContext } from 'context'
import { useParamsFromUrl } from 'hooks'
import { ChoosePlan, Setup } from './components'

enum Step {
  setup = 'setup',
  choosePlan = 'choosePlan',
  projectSetup = 'projectSetup',
}

export const BillingConnectionModal: React.FC = () => {
  const {
    project: { billingAccountId },
  } = useContext(ProjectContext)
  const { connectGCP, connectBillingAccount, clearParams } = useParamsFromUrl()
  const choosePlan = connectBillingAccount && billingAccountId
  const [step, setStep] = useState<Step>(choosePlan ? Step.choosePlan : Step.setup)

  useEffect(() => {
    if (choosePlan) {
      setStep(Step.choosePlan)
    }
  }, [choosePlan])

  const close = () => {
    setStep(Step.setup)
    clearParams()
  }

  return (
    <Modal
      active={connectGCP || connectBillingAccount}
      title={
        step === Step.projectSetup
          ? 'Preparing your project'
          : step === Step.choosePlan
          ? 'Choose a subscription plan'
          : connectGCP
          ? 'Setup an isolated cloud project'
          : connectBillingAccount
          ? 'Billing account'
          : ''
      }
      subTitle={
        step === Step.projectSetup
          ? 'This process can take up to 10 minutes.'
          : step === Step.choosePlan
          ? 'Pick a plan to continue. Refunds are possible only if requested before app publishing or source code download.'
          : connectGCP
          ? 'Provision Google Cloud Platform (GCP) and Firebase resources'
          : connectBillingAccount
          ? 'Choose the billing account you want to assign this project'
          : ''
      }
      onClose={close}
      width={step === Step.projectSetup ? '500px' : '715px'}
    >
      {step === Step.projectSetup ? (
        <ProjectSetup />
      ) : step === Step.choosePlan ? (
        <ChoosePlan
          onContinue={choosePlan ? close : () => setStep(Step.projectSetup)}
          onBack={choosePlan ? close : () => setStep(Step.setup)}
          connectGCP={connectGCP}
        />
      ) : (
        <Setup onContinue={() => setStep(Step.choosePlan)} onBack={close} connectGCP={connectGCP} />
      )}
    </Modal>
  )
}
