import styled, { css } from 'styled-components'

export const Sidebar = styled.div<{ wide: boolean; isXL: boolean }>`
  width: ${props => (props.wide ? 266 : 60)}px;
  min-width: ${props => (props.wide ? 266 : 60)}px;
  height: 100%;
  background: ${props => (props.wide ? props.theme.colors.primaryBackground : props.theme.colors.secondaryBackground)};
  display: flex;
  flex-direction: column;
  ${props =>
    props.wide &&
    props.isXL &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: ${props => props.theme.colors.secondaryBackground};
    `}
`
