import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import { NoContent, Status, TableWrapper, getField } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { CMSCollectionRecord, CMSService } from 'services/cms'
import { ValueType } from 'utils'

export const TermsTable: React.FC = () => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
    language,
  } = useContext(ProjectContext)
  const [terms, setTerms] = useState<CMSCollectionRecord[]>([])

  useEffect(() => {
    CMSService.getRecords(id, 'terms', language)
      .then(res => setTerms(res.data))
      .catch(err => toast(err, true))
  }, [])

  return (
    <TableWrapper empty={!terms.length} mt="15px">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Body</TableCell>
            <TableCell>Record status</TableCell>
          </TableRow>
        </TableHead>
        {!!terms.length ? (
          <TableBody>
            {terms.map(el => (
              <TableRow key={el.id}>
                <TableCell>{getField(ValueType.string)?.forTable(el.title)}</TableCell>
                <TableCell>{getField(ValueType.richText)?.forTable(el.body)}</TableCell>
                <TableCell>
                  <Status status={el.recordStatus} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <NoContent isTable text="Empty" />
        )}
      </Table>
    </TableWrapper>
  )
}
