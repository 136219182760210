import React, { useContext } from 'react'

import { Block, Paragraph, Title } from '../../Settings.styled'
import * as styled from './Github.styled'

import { ConnectGithubButton, githubDescription, GithubLink, Icon, Name, Unlink } from 'components'
import { RepositoryContext } from 'context'
import { IntegrationGithub, IntegrationStatus } from 'hooks'

export const Github: React.FC = () => {
  const { githubIntegration } = useContext(RepositoryContext)

  return githubIntegration ? (
    <Block>
      <Title>GitHub Integration</Title>
      <Paragraph mb="25px">{githubDescription}</Paragraph>
      {githubIntegration.status === IntegrationStatus.READY ? (
        <>
          <Paragraph mb="10px" grey1>
            Connected to:
          </Paragraph>
          <styled.Connected>
            <GithubLink slug={(githubIntegration.data as IntegrationGithub | undefined)?.slug}>
              <Icon name={Name.SIZE_24_GITHUB} />
              {(githubIntegration.data as IntegrationGithub | undefined)?.slug}
            </GithubLink>
            <Unlink integration={githubIntegration} />
          </styled.Connected>
        </>
      ) : (
        <ConnectGithubButton githubIntegration={githubIntegration} />
      )}
    </Block>
  ) : (
    <></>
  )
}
