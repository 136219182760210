import { useContext, useEffect, useState } from 'react'

import { ProgressContext, ProjectsContext, UserContext } from 'context'
import { Project } from 'hooks'
import { firestore } from 'services/firebase'

interface Props {
  children: React.ReactNode
  id?: string
}

export const ProjectsProvider: React.FC<Props> = ({ children, id }) => {
  const { firebaseUser } = useContext(UserContext)
  const { toast } = useContext(ProgressContext)
  const [projects, setProjects] = useState<Project[]>([])
  const [waitProjects, setWaitProjects] = useState(true)

  useEffect(() => {
    if (!id) {
      setProjects([])
      setWaitProjects(true)
      const unsubscribe = firestore
        .collection('projects')
        .where('memberIds', 'array-contains', firebaseUser.uid)
        .onSnapshot({
          next: res => {
            setProjects(res.docs.map(el => ({ id: el.id, ...el.data() })) as Project[])
            setWaitProjects(false)
          },
          error: err => {
            toast(err)
            setWaitProjects(false)
          },
        })
      return () => {
        unsubscribe()
      }
    }
  }, [firebaseUser.uid, id])

  return (
    <ProjectsContext.Provider value={{ projects, waitProjects, setWaitProjects }}>{children}</ProjectsContext.Provider>
  )
}
