import { memo, useState } from 'react'

import { FieldProps } from '../../types'
import { ShowHidePassword } from '../RightIcons'

interface Props extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const PasswordInput = memo((props: Props) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <>
      <input
        type={showPassword ? 'text' : 'password'}
        value={props.value || ''}
        onChange={({ target: { value } }) => props.onChange(value || null)}
        placeholder={props.placeholder}
        disabled={props.disabled}
        data-1p-ignore
      />
      <ShowHidePassword {...props} showPassword={showPassword} setShowPassword={setShowPassword} />
    </>
  )
})
