import React from 'react'

import { Container, MaxWidthContainer } from '../index.styled'

import { TemplateList } from './TemplateList'

export const Templates: React.FC = () => (
  <Container>
    <MaxWidthContainer>
      <TemplateList isNewProject />
    </MaxWidthContainer>
  </Container>
)
