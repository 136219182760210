import React from 'react'
import { ISvg, Svg } from '../Svg'

const Screens: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M15 6V3.5C15 2.67157 14.3284 2 13.5 2H2.5C1.67157 2 1 2.67157 1 3.5V12.5C1 13.3284 1.67157 14 2.5 14H5"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8.8 9h5.4s0.8 0 0.8 0.8v3.4s0 0.8 -0.8 0.8h-5.4s-0.8 0 -0.8 -0.8v-3.4s0 -0.8 0.8 -0.8"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </Svg>
)

export default Screens
