import React, { useContext, useEffect, useState } from 'react'

import { CustomPicker, IPickerItem, OptionsContainer, Variable } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { CMSCollectionRecord, CMSService } from 'services/cms'
import { LocalVariable, Screen, StringVariable, VariableSource } from 'utils'

interface Props {
  selector?: StringVariable
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  close: () => void
  collectionName?: string
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const SelectorPicker: React.FC<Props> = ({
  selector,
  setCurrentVariable,
  close,
  collectionName,
  screenConfig,
  allLocalVariables,
}) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
    language,
  } = useContext(ProjectContext)
  const [recordsIdsValues, setRecordsIdsValues] = useState<IPickerItem[]>([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (collectionName) {
      const abortController = new AbortController()
      CMSService.getRecords(
        id,
        collectionName,
        language,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        abortController
      )
        .then(res => setRecordsIdsValues(res.data.map((el: CMSCollectionRecord) => ({ value: el.id, label: el.id }))))
        .catch(err => !abortController.signal.aborted && toast(err, true))
      return () => {
        abortController.abort()
      }
    } else {
      setRecordsIdsValues([])
    }
  }, [collectionName])

  return (
    <CustomPicker
      title="Constant"
      close={close}
      setSearch={setSearch}
      defaultTab={selector?.source ? 1 : 0}
      optionsContainer={
        <OptionsContainer
          search={search}
          value={selector?.constant}
          onChange={value =>
            setCurrentVariable(currentVariable =>
              currentVariable ? { ...currentVariable, selector: { constant: value } } : null
            )
          }
          values={recordsIdsValues}
          close={close}
        />
      }
      variableContainer={
        <Variable
          value={selector?.source}
          onChange={value =>
            setCurrentVariable(currentVariable =>
              currentVariable ? { ...currentVariable, selector: { source: value } } : null
            )
          }
          close={close}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      }
    />
  )
}
