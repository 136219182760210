import React, { useEffect, useState } from 'react'

import * as styled from './AppPlans.styled'

import { Interval, PlanCard } from 'components'
import { useProjectPlan } from 'hooks'
import { Pick } from '../components'

interface Props {
  setWait: React.Dispatch<React.SetStateAction<boolean>>
  setOnClickActionButton: React.Dispatch<React.SetStateAction<(() => void) | null>>
  planPriceId: string | null
  setPlanPriceId: (planPriceId: string | null) => void
  id: string
  billingAccountId: string
  initGcpProject?: () => void
}

export const AppPlans: React.FC<Props> = ({
  setWait,
  setOnClickActionButton,
  planPriceId,
  setPlanPriceId,
  id,
  billingAccountId,
  initGcpProject,
}) => {
  const [selected, setSelected] = useState(planPriceId || null)
  const { activeSubscription, plans, onPlanChange, ChangePlanAlert } = useProjectPlan(id, billingAccountId)

  useEffect(() => {
    if (planPriceId !== selected) {
      setPlanPriceId(selected)
    }
  }, [selected])

  useEffect(() => {
    const plan = plans.find(el => el.prices.find((el: any) => el.id === selected))
    const price = plan?.prices.find((el: any) => el.id === selected)
    setOnClickActionButton(() =>
      price
        ? onPlanChange
          ? () => {
              setWait(true)
              onPlanChange(price.id, () => setWait(false))
            }
          : null
        : null
    )
  }, [selected, JSON.stringify(plans), !!onPlanChange])

  useEffect(() => {
    if (activeSubscription) {
      initGcpProject?.()
    }
  }, [JSON.stringify(activeSubscription)])

  return (
    <>
      <styled.Plans>
        {plans.map(el => (
          <Plan
            key={el.id}
            plan={el}
            activeSubscription={activeSubscription}
            selected={selected}
            setSelected={setSelected}
            planPriceId={planPriceId}
          />
        ))}
      </styled.Plans>
      {ChangePlanAlert}
    </>
  )
}

interface PlanProps {
  plan: any
  activeSubscription?: any
  selected: string | null
  setSelected: React.Dispatch<React.SetStateAction<string | null>>
  planPriceId?: string | null
}

export const Plan: React.FC<PlanProps> = ({ plan, activeSubscription, selected, setSelected, planPriceId }) => {
  const intervalForPlanPriceId = plan.prices.find((el: any) => el.id === planPriceId)?.recurring.interval
  const [interval, setInterval] = useState(intervalForPlanPriceId || Interval.month)
  const [canSelect, setCanSelect] = useState(false)
  const [priceId, setPriceId] = useState('')

  return (
    <styled.Plan
      onClick={canSelect ? () => setSelected(selected => (priceId === selected ? null : priceId)) : undefined}
      canSelect={canSelect}
    >
      <styled.PlanCardWrapper selected={priceId === selected}>
        <PlanCard
          plan={plan}
          activeSubscription={activeSubscription}
          interval={interval}
          setInterval={setInterval}
          setSelected={setSelected}
          setCanSelect={setCanSelect}
          setPriceId={setPriceId}
        />
      </styled.PlanCardWrapper>
      <Pick selected={priceId === selected} transparent={!canSelect}>
        <i />
        {plan.metadata.title}
      </Pick>
    </styled.Plan>
  )
}
