import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'
import { Integration } from 'hooks'
import { connectGithubParamName } from './GithubConnectionModal'

interface Props {
  githubIntegration: Integration | null
}

export const ConnectGithubButton: React.FC<Props> = ({ githubIntegration }) => {
  const navigate = useNavigate()

  return (
    <Button
      variant={ButtonVariant.LINK}
      color={ButtonColor.SECONDARY}
      leftIcon={<Icon name={Name.SIZE_24_GITHUB} />}
      onClick={() => navigate(`?${connectGithubParamName}=true`)}
      disabled={!githubIntegration}
    >
      Link to GitHub
    </Button>
  )
}
