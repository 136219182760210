import React from 'react'

import * as styled from './LeftSidebar.styled'

import { ThemeToggle } from 'components'
import { ProjectMenu, ProjectsMenu } from './components'

interface Props {
  id?: string
  isXL: boolean
}

export const LeftSidebar: React.FC<Props> = ({ id, isXL }) => (
  <styled.Sidebar wide={!id} isXL={isXL}>
    <ThemeToggle wide={!id} />
    {id ? !isXL && <ProjectMenu id={id} /> : <ProjectsMenu isXL={isXL} />}
  </styled.Sidebar>
)
