import React from 'react'

import * as styled from './Icon.styled'

import * as Additional from './assets/Additional'
import * as FieldTypes from './assets/FieldTypes'
import * as Global from './assets/Global'
import * as Landing from './assets/Landing'
import * as LeftMenu from './assets/LeftMenu'
import * as LeftMenuSmall from './assets/LeftMenuSmall'
import * as LeftSideBar from './assets/LeftSideBar'
import * as Logo from './assets/Logo'
import * as Notification from './assets/Notification'
import * as Pickers from './assets/Pickers'
import * as Repository from './assets/Repository'
import * as RightSideBar from './assets/RightSideBar'
import * as SecondOrder from './assets/SecondOrder'
import * as Settings from './assets/Settings'
import * as Size24 from './assets/Size24'

export enum Name {
  LOGO_CODE_PLATFORM = 'logoCodePlatform',
  LOGO_CODE_PLATFORM_FULL = 'LOGO_CODE_PLATFORM_FULL',
  LOGO_CORNER_ARROW = 'logoCornerArrow',

  GLOBAL_ARROW_BUTTON = 'globalArrowButton',
  GLOBAL_PLAY = 'globalPlay',
  GLOBAL_FILE_TRANSPARENT = 'globalFileTransparent',
  GLOBAL_THEME_SWITCH = 'globalThemeSwitch',
  GLOBAL_TASKS = 'globalTasks',
  GLOBAL_ERROR = 'globalError',
  GLOBAL_SCREENS = 'globalScreens',
  GLOBAL_PREVIEW = 'globalPreview',
  GLOBAL_SOURCE_CODE = 'globalSourceCode',

  LEFT_SIDEBAR_ANALYTICS = 'leftSideBarAnalytics',
  LEFT_SIDEBAR_CMS = 'leftSideBarCms',
  LEFT_SIDEBAR_REPOSITORY = 'leftSideBarRepository',
  LEFT_SIDEBAR_CONVERSATIONS = 'LEFT_SIDEBAR_CONVERSATIONS',
  LEFT_SIDEBAR_HELP = 'leftSideBarHelp',
  LEFT_SIDEBAR_NOTIFICATION = 'leftSideBarNotification',
  LEFT_SIDEBAR_ACTIVE_NOTIFICATION = 'LEFT_SIDEBAR_ACTIVE_NOTIFICATION',
  LEFT_SIDEBAR_STUDIO = 'leftSideBarStudio',
  LEFT_SIDEBAR_SETTINGS = 'leftSideBarSettings',

  SETTINGS_PROJECTS = 'SETTINGS_PROJECTS',
  SETTINGS_TEAMMATES = 'SETTINGS_TEAMMATES',
  SETTINGS_USAGE = 'SETTINGS_USAGE',
  SETTINGS_PLANS = 'SETTINGS_PLANS',
  SETTINGS_GENERAL = 'SETTINGS_GENERAL',
  SETTINGS_DESIGN = 'SETTINGS_DESIGN',
  SETTINGS_AUTHENTICATION = 'SETTINGS_AUTHENTICATION',
  SETTINGS_LANGUAGES = 'SETTINGS_LANGUAGES',
  SETTINGS_SCREENSHOTS = 'SETTINGS_SCREENSHOTS',
  SETTINGS_DOMAINS = 'SETTINGS_DOMAINS',
  SETTINGS_REPOSITORY = 'SETTINGS_REPOSITORY',
  SETTINGS_LOGIC = 'SETTINGS_LOGIC',
  SETTINGS_APPETIZE = 'SETTINGS_APPETIZE',
  SETTINGS_APP_STORE = 'SETTINGS_APP_STORE',
  SETTINGS_GOOGLE_PLAY = 'SETTINGS_GOOGLE_PLAY',
  SETTINGS_STRIPE = 'SETTINGS_STRIPE',
  SETTINGS_GENKIT = 'SETTINGS_GENKIT',
  SETTINGS_GITHUB = 'SETTINGS_GITHUB',

  LEFT_MENU_TOPBAR = 'LEFT_MENU_TOPBAR',
  LEFT_MENU_COLUMN = 'LEFT_MENU_COLUMN',
  LEFT_MENU_ROW = 'LEFT_MENU_ROW',
  LEFT_MENU_OVERLAY = 'LEFT_MENU_OVERLAY',
  LEFT_MENU_BUTTON = 'LEFT_MENU_BUTTON',
  LEFT_MENU_TEXT = 'LEFT_MENU_TEXT',
  LEFT_MENU_FIELD = 'LEFT_MENU_FIELD',
  LEFT_MENU_IMAGE = 'LEFT_MENU_IMAGE',
  LEFT_MENU_IMAGE_CIRCLE = 'LEFT_MENU_IMAGE_CIRCLE',
  LEFT_MENU_TOGGLE = 'LEFT_MENU_TOGGLE',
  LEFT_MENU_SPACER = 'LEFT_MENU_SPACER',
  LEFT_MENU_DIVIDER = 'LEFT_MENU_DIVIDER',
  LEFT_MENU_SUBSCRIPTION = 'LEFT_MENU_SUBSCRIPTION',
  LEFT_MENU_FAB = 'LEFT_MENU_FAB',
  LEFT_MENU_COLLECTION = 'LEFT_MENU_COLLECTION',
  LEFT_MENU_CALENDAR = 'LEFT_MENU_CALENDAR',
  LEFT_MENU_VIDEO = 'LEFT_MENU_VIDEO',
  LEFT_MENU_CAROUSER = 'LEFT_MENU_CAROUSER',
  LEFT_MENU_CHECKBOX = 'LEFT_MENU_CHECKBOX',
  LEFT_MENU_TABBAR = 'LEFT_MENU_TABBAR',
  LEFT_MENU_TAB = 'LEFT_MENU_TAB',
  LEFT_MENU_PAYWALL = 'LEFT_MENU_PAYWALL',
  LEFT_MENU_PAGER = 'LEFT_MENU_PAGER',
  LEFT_MENU_SHADOW = 'LEFT_MENU_SHADOW',
  LEFT_MENU_WEBVIEW = 'LEFT_MENU_WEBVIEW',
  LEFT_MENU_CHAT = 'LEFT_MENU_CHAT',
  LEFT_MENU_CONVERSATIONS = 'LEFT_MENU_CONVERSATIONS',
  LEFT_MENU_MAP = 'LEFT_MENU_MAP',
  LEFT_MENU_COUNTER = 'LEFT_MENU_COUNTER',
  LEFT_MENU_SLIDER = 'LEFT_MENU_SLIDER',
  LEFT_MENU_SCROLL = 'LEFT_MENU_SCROLL',
  LEFT_MENU_PROGRESS = 'LEFT_MENU_PROGRESS',

  LEFT_MENU_TOPBAR_SMALL = 'LEFT_MENU_TOPBAR_SMALL',
  LEFT_MENU_COLUMN_SMALL = 'LEFT_MENU_COLUMN_SMALL',
  LEFT_MENU_ROW_SMALL = 'LEFT_MENU_ROW_SMALL',
  LEFT_MENU_OVERLAY_SMALL = 'LEFT_MENU_OVERLAY_SMALL',
  LEFT_MENU_BUTTON_SMALL = 'LEFT_MENU_BUTTON_SMALL',
  LEFT_MENU_TEXT_SMALL = 'LEFT_MENU_TEXT_SMALL',
  LEFT_MENU_FIELD_SMALL = 'LEFT_MENU_FIELD_SMALL',
  LEFT_MENU_IMAGE_SMALL = 'LEFT_MENU_IMAGE_SMALL',
  LEFT_MENU_IMAGE_CIRCLE_SMALL = 'LEFT_MENU_IMAGE_CIRCLE_SMALL',
  LEFT_MENU_TOGGLE_SMALL = 'LEFT_MENU_TOGGLE_SMALL',
  LEFT_MENU_SPACER_SMALL = 'LEFT_MENU_SPACER_SMALL',
  LEFT_MENU_DIVIDER_SMALL = 'LEFT_MENU_DIVIDER_SMALL',
  LEFT_MENU_SUBSCRIPTION_SMALL = 'LEFT_MENU_SUBSCRIPTION_SMALL',
  LEFT_MENU_FAB_SMALL = 'LEFT_MENU_FAB_SMALL',
  LEFT_MENU_COLLECTION_SMALL = 'LEFT_MENU_COLLECTION_SMALL',
  LEFT_MENU_CALENDAR_SMALL = 'LEFT_MENU_CALENDAR_SMALL',
  LEFT_MENU_VIDEO_SMALL = 'LEFT_MENU_VIDEO_SMALL',
  LEFT_MENU_CAROUSER_SMALL = 'LEFT_MENU_CAROUSER_SMALL',
  LEFT_MENU_CHECKBOX_SMALL = 'LEFT_MENU_CHECKBOX_SMALL',
  LEFT_MENU_TABBAR_SMALL = 'LEFT_MENU_TABBAR_SMALL',
  LEFT_MENU_TAB_SMALL = 'LEFT_MENU_TAB_SMALL',
  LEFT_MENU_PAYWALL_SMALL = 'LEFT_MENU_PAYWALL_SMALL',
  LEFT_MENU_PAGER_SMALL = 'LEFT_MENU_PAGER_SMALL',
  LEFT_MENU_SHADOW_SMALL = 'LEFT_MENU_SHADOW_SMALL',
  LEFT_MENU_WEBVIEW_SMALL = 'LEFT_MENU_WEBVIEW_SMALL',
  LEFT_MENU_CHAT_SMALL = 'LEFT_MENU_CHAT_SMALL',
  LEFT_MENU_CONVERSATIONS_SMALL = 'LEFT_MENU_CONVERSATIONS_SMALL',
  LEFT_MENU_MAP_SMALL = 'LEFT_MENU_MAP_SMALL',
  LEFT_MENU_COUNTER_SMALL = 'LEFT_MENU_COUNTER_SMALL',
  LEFT_MENU_SLIDER_SMALL = 'LEFT_MENU_SLIDER_SMALL',
  LEFT_MENU_SCROLL_SMALL = 'LEFT_MENU_SCROLL_SMALL',
  LEFT_MENU_CONTAINER_SMALL = 'LEFT_MENU_CONTAINER_SMALL',
  LEFT_MENU_PROGRESS_SMALL = 'LEFT_MENU_PROGRESS_SMALL',

  PICKERS_GRID = 'PICKERS_GRID',
  PICKERS_GALLERY = 'PICKERS_GALLERY',
  PICKERS_COLUMN = 'PICKERS_COLUMN',
  PICKERS_ROW = 'PICKERS_ROW',
  PICKERS_ACCOUNT_SETTINGS = 'PICKERS_ACCOUNT_SETTINGS',
  PICKERS_API_DOCUMENTATION = 'PICKERS_API_DOCUMENTATION',
  PICKERS_APP_NAME = 'PICKERS_APP_NAME',
  PICKERS_BACK_TO_PROJECTS = 'PICKERS_BACK_TO_PROJECTS',
  PICKERS_NEW_CONVERSATION = 'PICKERS_NEW_CONVERSATION',
  PICKERS_CONVERSATIONS_HISTORY = 'PICKERS_CONVERSATIONS_HISTORY',
  PICKERS_EDIT_RECORDS = 'PICKERS_EDIT_RECORDS',
  PICKERS_EDIT_SCHEMA = 'PICKERS_EDIT_SCHEMA',
  PICKERS_LOGOUT = 'PICKERS_LOGOUT',
  PICKERS_TERMS_AND_POLICES = 'PICKERS_TERMS_AND_POLICES',
  PICKERS_CLOSE = 'PICKERS_CLOSE',
  PICKERS_WARNING = 'PICKERS_WARNING',
  PICKERS_ARROW = 'PICKERS_ARROW',
  PICKERS_DELETE = 'PICKERS_DELETE',
  PICKERS_GENERATE_SCREENS = 'PICKERS_GENERATE_SCREENS',
  PICKERS_GENERATE_COMPONENTS = 'PICKERS_GENERATE_COMPONENTS',
  PICKERS_REFACTOR = 'PICKERS_REFACTOR',
  PICKERS_GENERATE_COLLECTION = 'PICKERS_GENERATE_COLLECTION',
  PICKERS_IMPORT_DATA = 'PICKERS_IMPORT_DATA',
  PICKERS_GENERATE_CONTENT = 'PICKERS_GENERATE_CONTENT',
  PICKERS_QA = 'PICKERS_QA',
  PICKERS_CANCELL_TASK = 'PICKERS_CANCELL_TASK',
  PICKERS_RERUN_TASK = 'PICKERS_RERUN_TASK',

  RIGHT_SIDEBAR_CLOSE = 'rightSideBarClose',
  RIGHT_SIDEBAR_ARROW_THIN_DOWN = 'rightSideBarArrowThinDown',
  RIGHT_SIDEBAR_DEFAULT_ICON = 'rightSideBarDefaultIcon',
  RIGHT_SIDEBAR_COPY = 'rightSideBarCopy',
  RIGHT_SIDEBAR_ID = 'rightSideBarId',
  RIGHT_SIDEBAR_TEXT_CENTER = 'rightSideBarTextCenter',
  RIGHT_SIDEBAR_TEXT_LEFT = 'rightSideBarTextLeft',
  RIGHT_SIDEBAR_TEXT_RIGHT = 'rightSideBarTextRight',
  RIGHT_SIDEBAR_TEXT_TOP = 'rightSideBarTextTop',
  RIGHT_SIDEBAR_TEXT_MIDDLE = 'rightSideBarTextMiddle',
  RIGHT_SIDEBAR_ADD = 'rightSideBarAdd',
  RIGHT_SIDEBAR_MOVE_UP = 'rightSideBarMoveUp',
  RIGHT_SIDEBAR_SHOW_MORE = 'rightSideBarShowMore',
  RIGHT_SIDEBAR_SMALL_ARROW_THIN_DOWN = 'rightSideBarSmallArrowThinDown',
  RIGHT_SIDEBAR_IMAGE_LEFT = 'rightSideBarImageLeft',
  RIGHT_SIDEBAR_IMAGE_T_LEFT = 'rightSideBarImageTLeft',
  RIGHT_SIDEBAR_IMAGE_T_RIGHT = 'rightSideBarImageTRight',
  RIGHT_SIDEBAR_EDIT = 'rightSideBarEdit',
  RIGHT_SIDEBAR_SEARCH = 'rightSideBarSearch',
  RIGHT_SIDEBAR_DELETE = 'rightSideBarDelete',
  RIGHT_SIDEBAR_FILTER = 'rightSideBarFilter',
  RIGHT_SIDEBAR_INFO = 'rightSideBarInfo',
  RIGHT_SIDEBAR_MORE = 'rightSideBarMore',
  RIGHT_SIDEBAR_LINE_HEIGHT = 'rightSideBarLineHeight',
  RIGHT_SIDEBAR_LETTER_SPACING = 'rightSideBarLetterSpacing',
  RIGHT_SIDEBAR_TEXT_STYLE = 'rightSideBarTextStyle',
  RIGHT_SIDEBAR_TEXT_STYLE_INVERTED = 'rightSideBarTextStyleInverted',
  RIGHT_SIDEBAR_PICKER_UNDO = 'rightSideBarPickerUndo',
  RIGHT_SIDEBAR_DATA = 'rightSideBarData',
  RIGHT_SIDEBAR_DEFAULT_COLOR = 'rightSideBarDefaultColor',
  RIGHT_SIDEBAR_ADD_ASSET = 'rightSideBarAddAsset',

  SECOND_ORDER_MOBILE = 'secondOrderMobile',
  SECOND_ORDER_TABLET = 'secondOrderTablet',
  SECOND_ORDER_DESKTOP = 'secondOrderDesktop',
  SECOND_ORDER_GOOGLE = 'secondOrderGoogle',
  SECOND_ORDER_EMAIL = 'secondOrderEmail',
  SECOND_ORDER_PROVIDERS_EMAIL = 'secondOrderProvidersEmail',
  SECOND_ORDER_PROVIDERS_GOOGLE = 'secondOrderProvidersGoogle',
  SECOND_ORDER_PROVIDERS_APPLE = 'secondOrderProvidersApple',
  SECOND_ORDER_PROVIDERS_PHONE = 'secondOrderProvidersPhone',
  SECOND_ORDER_PROVIDERS_VERTEX = 'secondOrderProvidersVertex',
  SECOND_ORDER_PROVIDERS_OPEN_AI = 'secondOrderProvidersOpenAI',
  SECOND_ORDER_PLUGINS_DROPROMPT = 'secondOrderPluginsDroprompt',
  SECOND_ORDER_PLUGINS_LANGCHAIN = 'secondOrderPluginsLangchain',

  ADDITIONAL_CLOSE_ROUND = 'additionalCloseRound',
  ADDITIONAL_OPEN_NAV_MENU = 'additionalOpenNavMenu',
  ADDITIONAL_TEXT = 'additionalText',
  ADDITIONAL_EDIT_FIELDS = 'ADDITIONAL_EDIT_FIELDS',
  ADDITIONAL_SCREEN = 'ADDITIONAL_SCREEN',
  ADDITIONAL_COLLECTION = 'ADDITIONAL_COLLECTION',
  ADDITIONAL_MOVABLE = 'ADDITIONAL_MOVABLE',
  ADDITIONAL_DOWNLOAD = 'ADDITIONAL_DOWNLOAD',
  ADDITIONAL_CROP = 'ADDITIONAL_CROP',
  ADDITIONAL_CLOSE = 'additionalClose',
  ADDITIONAL_DARK = 'additionalDark',
  ADDITIONAL_LIGHT = 'additionalLight',
  ADDITIONAL_OK = 'additionalOk',
  ADDITIONAL_OK_LIGHT = 'ADDITIONAL_OK_LIGHT',
  ADDITIONAL_OK_FILLED = 'ADDITIONAL_OK_FILLED',
  ADDITIONAL_POINTER = 'additionalPointer',
  ADDITIONAL_BACK = 'ADDITIONAL_BACK',
  ADDITIONAL_RELOAD = 'ADDITIONAL_RELOAD',
  ADDITIONAL_ADD = 'additionalAdd',
  ADDITIONAL_AI = 'additionalAI',
  ADDITIONAL_AI_SMALL = 'additionalAISmall',
  ADDITIONAL_ACTIONS = 'additionalActions',
  ADDITIONAL_UPLOAD_IMAGE = 'additionalUploadImage',
  ADDITIONAL_MINUS = 'ADDITIONAL_MINUS',
  ADDITIONAL_PLUS = 'ADDITIONAL_PLUS',
  ADDITIONAL_HOME_FOLDER = 'ADDITIONAL_HOME_FOLDER',
  ADDITIONAL_RESET = 'ADDITIONAL_RESET',
  ADDITIONAL_NOT_FOUND = 'ADDITIONAL_NOT_FOUND',
  ADDITIONAL_NAVIGATION_TREE_ARROW = 'AdditionalNavigationTreeArrow',
  ADDITIONAL_NAVIGATION_TREE_FOLDER = 'AdditionalNavigationTreeFolder',
  ADDITIONAL_NAVIGATION_TREE_FOLDER_OPEN = 'AdditionalNavigationTreeFolderOpen',
  ADDITIONAL_NO_CONTENT = 'ADDITIONAL_NO_CONTENT',
  ADDITIONAL_SEND_MESSAGE = 'ADDITIONAL_SEND_MESSAGE',
  ADDITIONAL_FIX = 'ADDITIONAL_FIX',
  ADDITIONAL_EMPTY_AVATAR = 'ADDITIONAL_EMPTY_AVATAR',
  ADDITIONAL_SHOW_PW = 'ADDITIONAL_SHOW_PW',
  ADDITIONAL_HIDE_PW = 'ADDITIONAL_HIDE_PW',
  ADDITIONAL_YOUTUBE = 'ADDITIONAL_YOUTUBE',
  ADDITIONAL_DOCS = 'ADDITIONAL_DOCS',
  ADDITIONAL_SCREENSHOTS_PREVIEW = 'ADDITIONAL_SCREENSHOTS_PREVIEW',
  ADDITIONAL_SWITCH_TO_A_LARGER_SCREEN = 'ADDITIONAL_SWITCH_TO_A_LARGER_SCREEN',
  ADDITIONAL_POPOVER_ICON = 'ADDITIONAL_POPOVER_ICON',
  ADDITIONAL_PRICING_TABLE_CHECK_MARK = 'ADDITIONAL_PRICING_TABLE_CHECK_MARK',
  ADDITIONAL_PRICING_TABLE_CROSS = 'ADDITIONAL_PRICING_TABLE_CROSS',
  ADDITIONAL_AI_REGENERATE = 'ADDITIONAL_AI_REGENERATE',
  ADDITIONAL_AI_USE = 'ADDITIONAL_AI_USE',

  REPOSITORY_FOLDER = 'repositoryFolder',
  REPOSITORY_SYSTEM_FOLDER = 'REPOSITORY_SYSTEM_FOLDER',
  REPOSITORY_FOLDER_COLORED = 'repositoryFolderColored',
  REPOSITORY_CODE = 'repositoryCode',
  REPOSITORY_DEPLOYMENTS = 'REPOSITORY_DEPLOYMENTS',
  REPOSITORY_BUILDS = 'REPOSITORY_BUILDS',
  REPOSITORY_ACTIVITIES = 'REPOSITORY_ACTIVITIES',
  REPOSITORY_SETTINGS = 'repositorySettings',
  REPOSITORY_TIME = 'repositoryTime',
  REPOSITORY_FILE = 'repositoryFile',
  REPOSITORY_FILE_COLORED = 'repositoryFileColored',
  REPOSITORY_README = 'repositoryReadme',
  REPOSITORY_BRANCH = 'repositoryBranch',
  REPOSITORY_BUILD_PENDING = 'repositoryBuildPending',
  REPOSITORY_BUILD_SUCCESS = 'repositoryBuildSuccess',
  REPOSITORY_BUILD_SUCCESS_SMALL = 'repositoryBuildSuccessSmall',
  REPOSITORY_BUILD_SUCCESS_SMALL_V2 = 'REPOSITORY_BUILD_SUCCESS_SMALL_V2',
  REPOSITORY_BUILD_FAILED = 'repositoryBuildFailed',
  REPOSITORY_BUILD_FAILED_SMALL = 'repositoryBuildFailedSmall',
  REPOSITORY_EDIT = 'repositoryEdit',
  REPOSITORY_DROPDOWN_ARROW = 'repositoryDropDownArrow',
  REPOSITORY_BACKEND = 'repositoryBackend',
  REPOSITORY_LINK = 'repositoryLink',
  REPOSITORY_COMMIT = 'repositoryCommit',

  SIZE_24_GITHUB = 'size24Github',
  SIZE_24_APP_STORE = 'size24AppStore',
  SIZE_24_PLAY_STORE = 'size24PlayStore',
  SIZE_24_CLOSE = 'size24Close',
  SIZE_24_ARROW = 'size24Arrow',
  SIZE_24_SEARCH = 'size24Search',
  SIZE_24_PLUS = 'size24Plus',
  SIZE_24_CHECK_MARK = 'size24CheckMark',
  SIZE_24_SWITCH_PROVIDER = 'size24SwitchProvider',
  SIZE_24_LOCK = 'size24Lock',

  NOTIFICATION_INFO = 'notificationInfo',
  NOTIFICATION_SUCCESS = 'notificationSuccess',
  NOTIFICATION_WARN = 'notificationWarn',
  NOTIFICATION_FAILED = 'notificationFailed',
  NOTIFICATION_REFRESH = 'notificationRefresh',
  NOTIFICATION_NEWS = 'notificationNews',

  FIELD_TYPES_ACCESS_LEVEL = 'FIELD_TYPES_ACCESS_LEVEL',
  FIELD_TYPES_ARRAY = 'FIELD_TYPES_ARRAY',
  FIELD_TYPES_BOOLEAN = 'FIELD_TYPES_BOOLEAN',
  FIELD_TYPES_IMAGE = 'FIELD_TYPES_IMAGE',
  FIELD_TYPES_NUMBER = 'FIELD_TYPES_NUMBER',
  FIELD_TYPES_REFERENCE = 'FIELD_TYPES_REFERENCE',
  FIELD_TYPES_STRING = 'FIELD_TYPES_STRING',
  FIELD_TYPES_URL = 'FIELD_TYPES_URL',
  FIELD_TYPES_VIDEO = 'FIELD_TYPES_VIDEO',
  FIELD_TYPES_AUDIO = 'FIELD_TYPES_AUDIO',
  FIELD_TYPES_FILE = 'FIELD_TYPES_FILE',
  FIELD_TYPES_DATE = 'FIELD_TYPES_DATE',
  FIELD_TYPES_DATE_TIME = 'FIELD_TYPES_DATE_TIME',
  FIELD_TYPES_RICH_TEXT = 'FIELD_TYPES_RICH_TEXT',
  FIELD_TYPES_JSON = 'FIELD_TYPES_JSON',
  FIELD_TYPES_KEY_VALUE_MAP = 'FIELD_TYPES_KEY_VALUE_MAP',
  FIELD_TYPES_COORDINATE = 'FIELD_TYPES_COORDINATE',

  LANDING_PRODUCTS_APP_TEMPLATES = 'LANDING_PRODUCTS_APP_TEMPLATSE',
  LANDING_PRODUCTS_VISUAL_APP_BUILDER = 'LANDING_PRODUCTS_VISUAL_APP_BUILDER',
  LANDING_PRODUCTS_IN_BROWSER_APP_PREVIEW = 'LANDING_PRODUCTS_IN_BROWSER_APP_PREVIEW',
  LANDING_PRODUCTS_CONTINIOUS_INTEGRATION = 'LANDING_PRODUCTS_CONTINIOUS_INTEGRATION',
  LANDING_PRODUCTS_BACKEND_AS_A_SERVICE = 'LANDING_PRODUCTS_BACKEND_AS_A_SERVICE',
  LANDING_PRODUCTS_APP_LANDING_PAGE = 'LANDING_PRODUCTS_APP_LANDING_PAGE',
  LANDING_PRODUCTS_STORE_MANAGER = 'LANDING_PRODUCTS_STORE_MANAGER',
  LANDING_PRODUCTS_SCREENSHOTS_GENERATOR = 'LANDING_PRODUCTS_SCREENSHOTS_GENERATOR',
  LANDING_PRODUCTS_TERMS_AND_POLICES_GENERATOR = 'LANDING_PRODUCTS_TERMS_AND_POLICES_GENERATOR',
  LANDING_PRODUCTS_CMS = 'LANDING_PRODUCTS_CMS',
  LANDING_PRODUCTS_CODEPLATFORM_AI = 'LANDING_PRODUCTS_CODEPLATFORM_AI',
}

type NameKeys = keyof typeof Name

export enum Rotation {
  DOWN = '90',
  LEFT = '180',
  UP = '270',
  RIGHT = '-90',
}

type RotationKeys = keyof typeof Rotation

export interface IIcon {
  name: (typeof Name)[NameKeys]
  width?: number
  height?: number
  color?: string
  onClick?: React.MouseEventHandler<HTMLElement>
  hasWrapper?: boolean
  active?: boolean
  rotation?: (typeof Rotation)[RotationKeys]
  onMouseDown?: React.MouseEventHandler<HTMLElement>
  onMouseUp?: React.MouseEventHandler<HTMLElement>
}

const IconsWrapper: React.FC<IIcon> = ({ name, ...props }) => {
  switch (name) {
    case Name.LOGO_CODE_PLATFORM:
      return <Logo.CodePlatform {...props} />
    case Name.LOGO_CODE_PLATFORM_FULL:
      return <Logo.CodePlatformFull {...props} />
    case Name.LOGO_CORNER_ARROW:
      return <Logo.CornerArrow {...props} />

    case Name.GLOBAL_ARROW_BUTTON:
      return <Global.ArrowButton {...props} stroke={props.color} />
    case Name.GLOBAL_PLAY:
      return <Global.Play {...props} />
    case Name.GLOBAL_FILE_TRANSPARENT:
      return <Global.FileTransparent {...props} />
    case Name.GLOBAL_THEME_SWITCH:
      return <Global.ThemeSwitch {...props} />
    case Name.GLOBAL_TASKS:
      return <Global.Tasks {...props} />
    case Name.GLOBAL_ERROR:
      return <Global.Error {...props} />
    case Name.GLOBAL_SCREENS:
      return <Global.Screens {...props} />
    case Name.GLOBAL_PREVIEW:
      return <Global.Preview {...props} />
    case Name.GLOBAL_SOURCE_CODE:
      return <Global.SourceCode {...props} />

    case Name.LEFT_SIDEBAR_ANALYTICS:
      return <LeftSideBar.Analytics {...props} />
    case Name.LEFT_SIDEBAR_CMS:
      return <LeftSideBar.Cms {...props} />
    case Name.LEFT_SIDEBAR_REPOSITORY:
      return <LeftSideBar.Repository {...props} />
    case Name.LEFT_SIDEBAR_CONVERSATIONS:
      return <LeftSideBar.Conversations {...props} />
    case Name.LEFT_SIDEBAR_HELP:
      return <LeftSideBar.Help {...props} />
    case Name.LEFT_SIDEBAR_NOTIFICATION:
      return <LeftSideBar.Notification {...props} />
    case Name.LEFT_SIDEBAR_ACTIVE_NOTIFICATION:
      return <LeftSideBar.ActiveNotification {...props} />
    case Name.LEFT_SIDEBAR_STUDIO:
      return <LeftSideBar.Studio {...props} />
    case Name.LEFT_SIDEBAR_SETTINGS:
      return <LeftSideBar.Settings {...props} />

    case Name.SETTINGS_PROJECTS:
      return <Settings.Projects {...props} />
    case Name.SETTINGS_TEAMMATES:
      return <Settings.Teammates {...props} />
    case Name.SETTINGS_USAGE:
      return <Settings.Usage {...props} />
    case Name.SETTINGS_PLANS:
      return <Settings.Plans {...props} />
    case Name.SETTINGS_GENERAL:
      return <Settings.General {...props} />
    case Name.SETTINGS_DESIGN:
      return <Settings.Design {...props} />
    case Name.SETTINGS_AUTHENTICATION:
      return <Settings.Authentication {...props} />
    case Name.SETTINGS_LANGUAGES:
      return <Settings.Languages {...props} />
    case Name.SETTINGS_SCREENSHOTS:
      return <Settings.Screenshots {...props} />
    case Name.SETTINGS_DOMAINS:
      return <Settings.Domains {...props} />
    case Name.SETTINGS_REPOSITORY:
      return <Settings.Repository {...props} />
    case Name.SETTINGS_LOGIC:
      return <Settings.Logic {...props} />
    case Name.SETTINGS_APPETIZE:
      return <Settings.Appetize {...props} />
    case Name.SETTINGS_APP_STORE:
      return <Settings.AppStore {...props} />
    case Name.SETTINGS_GOOGLE_PLAY:
      return <Settings.GooglePlay {...props} />
    case Name.SETTINGS_STRIPE:
      return <Settings.Stripe {...props} />
    case Name.SETTINGS_GENKIT:
      return <Settings.Genkit {...props} />
    case Name.SETTINGS_GITHUB:
      return <Settings.Github {...props} />

    case Name.LEFT_MENU_TOPBAR:
      return <LeftMenu.TopBar {...props} />
    case Name.LEFT_MENU_COLUMN:
      return <LeftMenu.Column {...props} />
    case Name.LEFT_MENU_ROW:
      return <LeftMenu.Column {...props} rotation={Rotation.DOWN} />
    case Name.LEFT_MENU_OVERLAY:
      return <LeftMenu.Overlay {...props} />
    case Name.LEFT_MENU_BUTTON:
      return <LeftMenu.Button {...props} />
    case Name.LEFT_MENU_TEXT:
      return <LeftMenu.Text {...props} />
    case Name.LEFT_MENU_FIELD:
      return <LeftMenu.Field {...props} />
    case Name.LEFT_MENU_IMAGE:
      return <LeftMenu.Image {...props} />
    case Name.LEFT_MENU_IMAGE_CIRCLE:
      return <LeftMenu.ImageCircle {...props} />
    case Name.LEFT_MENU_TOGGLE:
      return <LeftMenu.Toggle {...props} />
    case Name.LEFT_MENU_SPACER:
      return <LeftMenu.Spacer {...props} />
    case Name.LEFT_MENU_DIVIDER:
      return <LeftMenu.Divider {...props} />
    case Name.LEFT_MENU_SUBSCRIPTION:
      return <LeftMenu.Subscription {...props} />
    case Name.LEFT_MENU_FAB:
      return <LeftMenu.Fab {...props} />
    case Name.LEFT_MENU_COLLECTION:
      return <LeftMenu.Collection {...props} />
    case Name.LEFT_MENU_CALENDAR:
      return <LeftMenu.Calendar {...props} />
    case Name.LEFT_MENU_VIDEO:
      return <LeftMenu.Video {...props} />
    case Name.LEFT_MENU_CAROUSER:
      return <LeftMenu.Carousel {...props} />
    case Name.LEFT_MENU_CHECKBOX:
      return <LeftMenu.Checkbox {...props} />
    case Name.LEFT_MENU_TABBAR:
      return <LeftMenu.TabBar {...props} />
    case Name.LEFT_MENU_TAB:
      return <LeftMenu.Tab {...props} />
    case Name.LEFT_MENU_PAYWALL:
      return <LeftMenu.Paywall {...props} />
    case Name.LEFT_MENU_PAGER:
      return <LeftMenu.Pager {...props} />
    case Name.LEFT_MENU_SHADOW:
      return <LeftMenu.Shadow {...props} />
    case Name.LEFT_MENU_WEBVIEW:
      return <LeftMenu.WebView {...props} />
    case Name.LEFT_MENU_CHAT:
      return <LeftMenu.Chat {...props} />
    case Name.LEFT_MENU_CONVERSATIONS:
      return <LeftMenu.Conversations {...props} />
    case Name.LEFT_MENU_MAP:
      return <LeftMenu.Map {...props} />
    case Name.LEFT_MENU_COUNTER:
      return <LeftMenu.Counter {...props} />
    case Name.LEFT_MENU_SLIDER:
      return <LeftMenu.Slider {...props} />
    case Name.LEFT_MENU_SCROLL:
      return <LeftMenu.Scroll {...props} />
    case Name.LEFT_MENU_PROGRESS:
      return <LeftMenu.Progress {...props} />

    case Name.LEFT_MENU_TOPBAR_SMALL:
      return <LeftMenuSmall.TopBar {...props} />
    case Name.LEFT_MENU_COLUMN_SMALL:
      return <LeftMenuSmall.Column {...props} />
    case Name.LEFT_MENU_ROW_SMALL:
      return <LeftMenuSmall.Column {...props} rotation={Rotation.DOWN} />
    case Name.LEFT_MENU_OVERLAY_SMALL:
      return <LeftMenuSmall.Overlay {...props} />
    case Name.LEFT_MENU_BUTTON_SMALL:
      return <LeftMenuSmall.Button {...props} />
    case Name.LEFT_MENU_TEXT_SMALL:
      return <LeftMenuSmall.Text {...props} />
    case Name.LEFT_MENU_FIELD_SMALL:
      return <LeftMenuSmall.Field {...props} />
    case Name.LEFT_MENU_IMAGE_SMALL:
      return <LeftMenuSmall.Image {...props} />
    case Name.LEFT_MENU_IMAGE_CIRCLE_SMALL:
      return <LeftMenuSmall.ImageCircle {...props} />
    case Name.LEFT_MENU_TOGGLE_SMALL:
      return <LeftMenuSmall.Toggle {...props} />
    case Name.LEFT_MENU_SPACER_SMALL:
      return <LeftMenuSmall.Spacer {...props} />
    case Name.LEFT_MENU_DIVIDER_SMALL:
      return <LeftMenuSmall.Divider {...props} />
    case Name.LEFT_MENU_SUBSCRIPTION_SMALL:
      return <LeftMenuSmall.Subscription {...props} />
    case Name.LEFT_MENU_FAB_SMALL:
      return <LeftMenuSmall.Fab {...props} />
    case Name.LEFT_MENU_COLLECTION_SMALL:
      return <LeftMenuSmall.Collection {...props} />
    case Name.LEFT_MENU_CALENDAR_SMALL:
      return <LeftMenuSmall.Calendar {...props} />
    case Name.LEFT_MENU_VIDEO_SMALL:
      return <LeftMenuSmall.Video {...props} />
    case Name.LEFT_MENU_CAROUSER_SMALL:
      return <LeftMenuSmall.Carousel {...props} />
    case Name.LEFT_MENU_CHECKBOX_SMALL:
      return <LeftMenuSmall.Checkbox {...props} />
    case Name.LEFT_MENU_TABBAR_SMALL:
      return <LeftMenuSmall.TabBar {...props} />
    case Name.LEFT_MENU_TAB_SMALL:
      return <LeftMenuSmall.Tab {...props} />
    case Name.LEFT_MENU_PAYWALL_SMALL:
      return <LeftMenuSmall.Paywall {...props} />
    case Name.LEFT_MENU_PAGER_SMALL:
      return <LeftMenuSmall.Pager {...props} />
    case Name.LEFT_MENU_SHADOW_SMALL:
      return <LeftMenuSmall.Shadow {...props} />
    case Name.LEFT_MENU_WEBVIEW_SMALL:
      return <LeftMenuSmall.WebView {...props} />
    case Name.LEFT_MENU_CHAT_SMALL:
      return <LeftMenuSmall.Chat {...props} />
    case Name.LEFT_MENU_CONVERSATIONS_SMALL:
      return <LeftMenuSmall.Conversations {...props} />
    case Name.LEFT_MENU_MAP_SMALL:
      return <LeftMenuSmall.Map {...props} />
    case Name.LEFT_MENU_COUNTER_SMALL:
      return <LeftMenuSmall.Counter {...props} />
    case Name.LEFT_MENU_SLIDER_SMALL:
      return <LeftMenuSmall.Slider {...props} />
    case Name.LEFT_MENU_SCROLL_SMALL:
      return <LeftMenuSmall.Scroll {...props} />
    case Name.LEFT_MENU_PROGRESS_SMALL:
      return <LeftMenuSmall.Progress {...props} />
    case Name.LEFT_MENU_CONTAINER_SMALL:
      return <LeftMenuSmall.Container {...props} />

    case Name.PICKERS_COLUMN:
      return <Pickers.Column {...props} />
    case Name.PICKERS_ROW:
      return <Pickers.Column {...props} rotation={Rotation.DOWN} />
    case Name.PICKERS_GRID:
      return <Pickers.Grid {...props} />
    case Name.PICKERS_GALLERY:
      return <Pickers.Gallery {...props} />
    case Name.PICKERS_ACCOUNT_SETTINGS:
      return <Pickers.AccountSettings {...props} width={20} height={20} />
    case Name.PICKERS_API_DOCUMENTATION:
      return <Pickers.APIDocumentation {...props} width={20} height={20} />
    case Name.PICKERS_APP_NAME:
      return <Pickers.AppName {...props} width={20} height={20} />
    case Name.PICKERS_BACK_TO_PROJECTS:
      return <Pickers.BackToProjects {...props} width={20} height={20} />
    case Name.PICKERS_NEW_CONVERSATION:
      return <Pickers.NewConversation {...props} width={20} height={20} />
    case Name.PICKERS_CONVERSATIONS_HISTORY:
      return <Pickers.ConversationsHistory {...props} width={20} height={20} />
    case Name.PICKERS_EDIT_RECORDS:
      return <Pickers.EditRecords {...props} width={20} height={20} />
    case Name.PICKERS_EDIT_SCHEMA:
      return <Pickers.EditSchema {...props} width={20} height={20} />
    case Name.PICKERS_LOGOUT:
      return <Pickers.LogOut {...props} width={20} height={20} />
    case Name.PICKERS_TERMS_AND_POLICES:
      return <Pickers.TermsAndPolices {...props} width={20} height={20} />
    case Name.PICKERS_CLOSE:
      return <Pickers.Close {...props} width={24} height={24} />
    case Name.PICKERS_WARNING:
      return <Pickers.Warning {...props} />
    case Name.PICKERS_ARROW:
      return <Pickers.Arrow {...props} />
    case Name.PICKERS_DELETE:
      return <Pickers.Delete {...props} width={20} height={20} />
    case Name.PICKERS_GENERATE_SCREENS:
      return <Pickers.GenerateScreens {...props} />
    case Name.PICKERS_GENERATE_COMPONENTS:
      return <Pickers.GenerateComponents {...props} />
    case Name.PICKERS_REFACTOR:
      return <Pickers.Refactor {...props} />
    case Name.PICKERS_GENERATE_COLLECTION:
      return <Pickers.GenerateCollection {...props} />
    case Name.PICKERS_IMPORT_DATA:
      return <Pickers.ImportData {...props} />
    case Name.PICKERS_GENERATE_CONTENT:
      return <Pickers.GenerateContent {...props} />
    case Name.PICKERS_QA:
      return <Pickers.Qa {...props} />
    case Name.PICKERS_CANCELL_TASK:
      return <Pickers.CancellTask {...props} />
    case Name.PICKERS_RERUN_TASK:
      return <Pickers.RerunTask {...props} />

    case Name.RIGHT_SIDEBAR_CLOSE:
      return <RightSideBar.Close {...props} />
    case Name.RIGHT_SIDEBAR_ARROW_THIN_DOWN:
      return <RightSideBar.ArrowThinDown {...props} />
    case Name.RIGHT_SIDEBAR_DEFAULT_ICON:
      return <RightSideBar.DefaultIcon {...props} />
    case Name.RIGHT_SIDEBAR_COPY:
      return <RightSideBar.Copy {...props} />
    case Name.RIGHT_SIDEBAR_ID:
      return <RightSideBar.Id {...props} />
    case Name.RIGHT_SIDEBAR_TEXT_STYLE:
      return <RightSideBar.TextStyle {...props} />
    case Name.RIGHT_SIDEBAR_TEXT_STYLE_INVERTED:
      return <RightSideBar.TextStyleInverted {...props} />
    case Name.RIGHT_SIDEBAR_TEXT_CENTER:
      return <RightSideBar.TextCenter {...props} />
    case Name.RIGHT_SIDEBAR_TEXT_LEFT:
      return <RightSideBar.TextLeft {...props} />
    case Name.RIGHT_SIDEBAR_TEXT_RIGHT:
      return <RightSideBar.TextRight {...props} />
    case Name.RIGHT_SIDEBAR_TEXT_TOP:
      return <RightSideBar.TextTop {...props} />
    case Name.RIGHT_SIDEBAR_TEXT_MIDDLE:
      return <RightSideBar.TextMiddle {...props} />
    case Name.RIGHT_SIDEBAR_ADD:
      return <RightSideBar.Add {...props} />
    case Name.RIGHT_SIDEBAR_MOVE_UP:
      return <RightSideBar.MoveUp {...props} />
    case Name.RIGHT_SIDEBAR_SHOW_MORE:
      return <RightSideBar.ShowMore {...props} />
    case Name.RIGHT_SIDEBAR_SMALL_ARROW_THIN_DOWN:
      return <RightSideBar.SmallArrowThinDown {...props} />
    case Name.RIGHT_SIDEBAR_IMAGE_LEFT:
      return <RightSideBar.ImageLeft {...props} />
    case Name.RIGHT_SIDEBAR_IMAGE_T_LEFT:
      return <RightSideBar.ImageTLeft {...props} />
    case Name.RIGHT_SIDEBAR_IMAGE_T_RIGHT:
      return <RightSideBar.ImageTRight {...props} />
    case Name.RIGHT_SIDEBAR_EDIT:
      return <RightSideBar.Edit {...props} />
    case Name.RIGHT_SIDEBAR_SEARCH:
      return <RightSideBar.Search {...props} />
    case Name.RIGHT_SIDEBAR_DELETE:
      return <RightSideBar.Delete {...props} />
    case Name.RIGHT_SIDEBAR_FILTER:
      return <RightSideBar.Filter {...props} />
    case Name.RIGHT_SIDEBAR_INFO:
      return <RightSideBar.Info {...props} />
    case Name.RIGHT_SIDEBAR_MORE:
      return <RightSideBar.More {...props} />
    case Name.RIGHT_SIDEBAR_LINE_HEIGHT:
      return <RightSideBar.LineHeight {...props} />
    case Name.RIGHT_SIDEBAR_LETTER_SPACING:
      return <RightSideBar.LetterSpacing {...props} />
    case Name.RIGHT_SIDEBAR_PICKER_UNDO:
      return <RightSideBar.PickerUndo {...props} />
    case Name.RIGHT_SIDEBAR_DATA:
      return <RightSideBar.Data {...props} />
    case Name.RIGHT_SIDEBAR_DEFAULT_COLOR:
      return <RightSideBar.DefaultColor {...props} />
    case Name.RIGHT_SIDEBAR_ADD_ASSET:
      return <RightSideBar.AddAsset {...props} />

    case Name.SECOND_ORDER_MOBILE:
      return <SecondOrder.Mobile {...props} />
    case Name.SECOND_ORDER_TABLET:
      return <SecondOrder.Tablet {...props} />
    case Name.SECOND_ORDER_DESKTOP:
      return <SecondOrder.Desktop {...props} />
    case Name.SECOND_ORDER_GOOGLE:
      return <SecondOrder.Google {...props} width={24} height={24} />
    case Name.SECOND_ORDER_EMAIL:
      return <SecondOrder.Email {...props} width={24} height={24} />
    case Name.SECOND_ORDER_PROVIDERS_EMAIL:
      return <SecondOrder.ProvidersEmail {...props} width={40} height={40} />
    case Name.SECOND_ORDER_PROVIDERS_GOOGLE:
      return <SecondOrder.ProvidersGoogle {...props} width={40} height={40} />
    case Name.SECOND_ORDER_PROVIDERS_APPLE:
      return <SecondOrder.ProvidersApple {...props} width={40} height={40} />
    case Name.SECOND_ORDER_PROVIDERS_PHONE:
      return <SecondOrder.ProvidersPhone {...props} width={40} height={40} />
    case Name.SECOND_ORDER_PROVIDERS_VERTEX:
      return <SecondOrder.ProvidersVertex {...props} width={40} height={40} />
    case Name.SECOND_ORDER_PROVIDERS_OPEN_AI:
      return <SecondOrder.ProvidersOpenAI {...props} width={40} height={40} />
    case Name.SECOND_ORDER_PLUGINS_DROPROMPT:
      return <SecondOrder.PluginsDroprompt {...props} width={40} height={40} />
    case Name.SECOND_ORDER_PLUGINS_LANGCHAIN:
      return <SecondOrder.PluginsLangChain {...props} width={40} height={40} />

    case Name.ADDITIONAL_CLOSE_ROUND:
      return <Additional.CloseRound {...props} />
    case Name.ADDITIONAL_OPEN_NAV_MENU:
      return <Additional.OpenNavMenu {...props} />
    case Name.ADDITIONAL_TEXT:
      return <Additional.Text {...props} />
    case Name.ADDITIONAL_EDIT_FIELDS:
      return <Additional.EditFields {...props} />
    case Name.ADDITIONAL_SCREEN:
      return <Additional.Screen {...props} />
    case Name.ADDITIONAL_COLLECTION:
      return <Additional.Collection {...props} />
    case Name.ADDITIONAL_MOVABLE:
      return <Additional.Movable {...props} />
    case Name.ADDITIONAL_DOWNLOAD:
      return <Additional.Download {...props} />
    case Name.ADDITIONAL_CROP:
      return <Additional.Crop {...props} />
    case Name.ADDITIONAL_CLOSE:
      return <Additional.Close {...props} />
    case Name.ADDITIONAL_DARK:
      return <Additional.Dark width={24} height={24} {...props} />
    case Name.ADDITIONAL_LIGHT:
      return <Additional.Light width={24} height={24} {...props} />
    case Name.ADDITIONAL_OK:
      return <Additional.Ok {...props} />
    case Name.ADDITIONAL_OK_LIGHT:
      return <Additional.OkLight {...props} />
    case Name.ADDITIONAL_OK_FILLED:
      return <Additional.OkFilled {...props} />
    case Name.ADDITIONAL_POINTER:
      return <Additional.Pointer {...props} />
    case Name.ADDITIONAL_BACK:
      return <Additional.Back {...props} />
    case Name.ADDITIONAL_RELOAD:
      return <Additional.Reload {...props} />
    case Name.ADDITIONAL_ADD:
      return <Additional.Add {...props} />
    case Name.ADDITIONAL_AI:
      return <Additional.AI {...props} />
    case Name.ADDITIONAL_AI_SMALL:
      return <Additional.AISmall {...props} />
    case Name.ADDITIONAL_ACTIONS:
      return <Additional.Actions {...props} />
    case Name.ADDITIONAL_UPLOAD_IMAGE:
      return <Additional.UploadImage {...props} />
    case Name.ADDITIONAL_MINUS:
      return <Additional.Minus {...props} />
    case Name.ADDITIONAL_PLUS:
      return <Additional.Plus {...props} />
    case Name.ADDITIONAL_HOME_FOLDER:
      return <Additional.HomeFolder {...props} />
    case Name.ADDITIONAL_RESET:
      return <Additional.Reset {...props} />
    case Name.ADDITIONAL_NOT_FOUND:
      return <Additional.NotFound {...props} />
    case Name.ADDITIONAL_NAVIGATION_TREE_ARROW:
      return <Additional.NavigationTreeArrow {...props} />
    case Name.ADDITIONAL_NAVIGATION_TREE_FOLDER:
      return <Additional.NavigationTreeFolder {...props} />
    case Name.ADDITIONAL_NAVIGATION_TREE_FOLDER_OPEN:
      return <Additional.NavigationTreeFolderOpen {...props} />
    case Name.ADDITIONAL_NO_CONTENT:
      return <Additional.NoContent {...props} />
    case Name.ADDITIONAL_SEND_MESSAGE:
      return <Additional.SendMessage {...props} />
    case Name.ADDITIONAL_FIX:
      return <Additional.Fix {...props} />
    case Name.ADDITIONAL_EMPTY_AVATAR:
      return <Additional.EmptyAvatar {...props} />
    case Name.ADDITIONAL_SHOW_PW:
      return <Additional.ShowPW {...props} />
    case Name.ADDITIONAL_HIDE_PW:
      return <Additional.HidePW {...props} />
    case Name.ADDITIONAL_YOUTUBE:
      return <Additional.Youtube {...props} />
    case Name.ADDITIONAL_DOCS:
      return <Additional.Docs {...props} />
    case Name.ADDITIONAL_SCREENSHOTS_PREVIEW:
      return <Additional.ScreenshotsPreview {...props} width={270} height={150} />
    case Name.ADDITIONAL_SWITCH_TO_A_LARGER_SCREEN:
      return <Additional.SwitchToALargerScreen {...props} />
    case Name.ADDITIONAL_POPOVER_ICON:
      return <Additional.PopoverIcon {...props} width={20} height={20} />
    case Name.ADDITIONAL_PRICING_TABLE_CHECK_MARK:
      return <Additional.PricingTableCheckMark {...props} width={20} height={20} />
    case Name.ADDITIONAL_PRICING_TABLE_CROSS:
      return <Additional.PricingTableCross {...props} width={20} height={20} />
    case Name.ADDITIONAL_AI_REGENERATE:
      return <Additional.AIRegenerate {...props} />
    case Name.ADDITIONAL_AI_USE:
      return <Additional.AIUse {...props} />

    case Name.REPOSITORY_FOLDER:
      return <Repository.Folder {...props} />
    case Name.REPOSITORY_SYSTEM_FOLDER:
      return <Repository.SystemFolder {...props} />
    case Name.REPOSITORY_FOLDER_COLORED:
      return <Repository.FolderColored {...props} />
    case Name.REPOSITORY_BACKEND:
      return <Repository.Backend {...props} />
    case Name.REPOSITORY_CODE:
      return <Repository.Code {...props} />
    case Name.REPOSITORY_DEPLOYMENTS:
      return <Repository.Deployments {...props} />
    case Name.REPOSITORY_BUILDS:
      return <Repository.Builds {...props} />
    case Name.REPOSITORY_ACTIVITIES:
      return <Repository.Activities {...props} />
    case Name.REPOSITORY_SETTINGS:
      return <Repository.Settings {...props} />
    case Name.REPOSITORY_TIME:
      return <Repository.Time {...props} />
    case Name.REPOSITORY_FILE:
      return <Repository.File {...props} />
    case Name.REPOSITORY_FILE_COLORED:
      return <Repository.FileColored {...props} />
    case Name.REPOSITORY_README:
      return <Repository.Readme {...props} />
    case Name.REPOSITORY_BRANCH:
      return <Repository.Branch {...props} />
    case Name.REPOSITORY_BUILD_PENDING:
      return <Repository.BuildPending {...props} />
    case Name.REPOSITORY_BUILD_SUCCESS:
      return <Repository.BuildSuccess {...props} />
    case Name.REPOSITORY_BUILD_SUCCESS_SMALL:
      return <Repository.BuildSuccessSmall {...props} />
    case Name.REPOSITORY_BUILD_SUCCESS_SMALL_V2:
      return <Repository.BuildSuccessSmallV2 {...props} />
    case Name.REPOSITORY_BUILD_FAILED:
      return <Repository.BuildFailed {...props} />
    case Name.REPOSITORY_BUILD_FAILED_SMALL:
      return <Repository.BuildFailedSmall {...props} />
    case Name.REPOSITORY_EDIT:
      return <Repository.Edit {...props} />
    case Name.REPOSITORY_DROPDOWN_ARROW:
      return <Repository.DropDownArrow {...props} />
    case Name.REPOSITORY_LINK:
      return <Repository.LinkIcon {...props} />
    case Name.REPOSITORY_COMMIT:
      return <Repository.Commit {...props} />

    case Name.SIZE_24_GITHUB:
      return <Size24.Github {...props} width={24} height={24} />
    case Name.SIZE_24_APP_STORE:
      return <Size24.AppStore {...props} width={24} height={24} />
    case Name.SIZE_24_PLAY_STORE:
      return <Size24.PlayStore {...props} width={24} height={24} />
    case Name.SIZE_24_CLOSE:
      return <Size24.Close {...props} width={24} height={24} />
    case Name.SIZE_24_ARROW:
      return <Size24.Arrow {...props} width={24} height={24} />
    case Name.SIZE_24_SEARCH:
      return <Size24.Search {...props} width={24} height={24} />
    case Name.SIZE_24_PLUS:
      return <Size24.Plus {...props} width={24} height={24} />
    case Name.SIZE_24_CHECK_MARK:
      return <Size24.CheckMark {...props} width={24} height={24} />
    case Name.SIZE_24_SWITCH_PROVIDER:
      return <Size24.SwitchProvider {...props} width={24} height={24} />
    case Name.SIZE_24_LOCK:
      return <Size24.Lock {...props} width={24} height={24} />

    case Name.NOTIFICATION_INFO:
      return <Notification.Info {...props} width={24} height={24} />
    case Name.NOTIFICATION_SUCCESS:
      return <Notification.Success {...props} width={24} height={24} />
    case Name.NOTIFICATION_WARN:
      return <Notification.Warn {...props} width={24} height={24} />
    case Name.NOTIFICATION_FAILED:
      return <Notification.Failed {...props} width={24} height={24} />
    case Name.NOTIFICATION_REFRESH:
      return <Notification.Refresh {...props} width={24} height={24} />
    case Name.NOTIFICATION_NEWS:
      return <Notification.News {...props} width={24} height={24} />

    case Name.FIELD_TYPES_ACCESS_LEVEL:
      return <FieldTypes.AccessLevel {...props} />
    case Name.FIELD_TYPES_ARRAY:
      return <FieldTypes.Array {...props} />
    case Name.FIELD_TYPES_BOOLEAN:
      return <FieldTypes.Boolean {...props} />
    case Name.FIELD_TYPES_IMAGE:
      return <FieldTypes.Image {...props} />
    case Name.FIELD_TYPES_NUMBER:
      return <FieldTypes.Number {...props} />
    case Name.FIELD_TYPES_REFERENCE:
      return <FieldTypes.Reference {...props} />
    case Name.FIELD_TYPES_STRING:
      return <FieldTypes.String {...props} />
    case Name.FIELD_TYPES_URL:
      return <FieldTypes.Url {...props} />
    case Name.FIELD_TYPES_VIDEO:
      return <FieldTypes.Video {...props} />
    case Name.FIELD_TYPES_AUDIO:
      return <FieldTypes.Audio {...props} />
    case Name.FIELD_TYPES_FILE:
      return <FieldTypes.File {...props} />
    case Name.FIELD_TYPES_DATE:
      return <FieldTypes.Date {...props} />
    case Name.FIELD_TYPES_DATE_TIME:
      return <FieldTypes.DateTime {...props} />
    case Name.FIELD_TYPES_RICH_TEXT:
      return <FieldTypes.RichText {...props} />
    case Name.FIELD_TYPES_JSON:
      return <FieldTypes.Json {...props} />
    case Name.FIELD_TYPES_KEY_VALUE_MAP:
      return <FieldTypes.KeyValueMap {...props} />
    case Name.FIELD_TYPES_COORDINATE:
      return <FieldTypes.Coordinate {...props} />

    case Name.LANDING_PRODUCTS_APP_TEMPLATES:
      return <Landing.AppTemplates {...props} width={24} height={24} />
    case Name.LANDING_PRODUCTS_VISUAL_APP_BUILDER:
      return <Landing.VisualAppBuilder {...props} width={24} height={24} />
    case Name.LANDING_PRODUCTS_IN_BROWSER_APP_PREVIEW:
      return <Landing.InBrowserAppsPreview {...props} width={24} height={24} />
    case Name.LANDING_PRODUCTS_CONTINIOUS_INTEGRATION:
      return <Landing.ContinuousIntegration {...props} width={24} height={24} />
    case Name.LANDING_PRODUCTS_BACKEND_AS_A_SERVICE:
      return <Landing.BackendAsAService {...props} width={24} height={24} />
    case Name.LANDING_PRODUCTS_APP_LANDING_PAGE:
      return <Landing.AppLandingPage {...props} width={24} height={24} />
    case Name.LANDING_PRODUCTS_STORE_MANAGER:
      return <Landing.StoreManager {...props} width={24} height={24} />
    case Name.LANDING_PRODUCTS_SCREENSHOTS_GENERATOR:
      return <Landing.ScreenshotsGenerator {...props} width={24} height={24} />
    case Name.LANDING_PRODUCTS_TERMS_AND_POLICES_GENERATOR:
      return <Landing.TermsAndPoliciesGenerator {...props} width={24} height={24} />
    case Name.LANDING_PRODUCTS_CMS:
      return <Landing.CMS {...props} width={24} height={24} />
    case Name.LANDING_PRODUCTS_CODEPLATFORM_AI:
      return <Landing.CodeplatformAI {...props} width={24} height={24} />

    default:
      return null
  }
}

export const Icon: React.FC<IIcon> = props => {
  if (props.hasWrapper) {
    const { onClick, ...otherProps } = props
    return (
      <styled.IconWrapper onClick={onClick} active={props.active}>
        <IconsWrapper {...otherProps} />
      </styled.IconWrapper>
    )
  }

  return <IconsWrapper onClick={props.onClick} active={props.active} {...props} />
}

Icon.defaultProps = {
  color: '#212133',
}
