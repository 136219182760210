import styled from 'styled-components'

import { ThemeType } from 'theme'
import { ButtonColor, ButtonVariant } from './types'

export const Text = styled.span`
  white-space: nowrap;
`

export const Box = styled.button<{
  hasChildren: boolean
  variant?: ButtonVariant
  color?: ButtonColor
  height: number
  rightIcon: boolean
  leftIcon: boolean
  colorNoChange?: boolean
  custom?: boolean
  disabled?: boolean
  active?: boolean
}>`
  height: ${props => props.height}px;
  outline: 0;
  border: 0;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  display: flex;
  justify-content: center;
  column-gap: ${props => (props.rightIcon ? 5 : 8)}px;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  ${props => {
    switch (props.variant) {
      case ButtonVariant.LINK:
        return `
        padding: ${!props.hasChildren ? '3px 6px' : '3px 12px 3px 6px'};
        `
      case ButtonVariant.TOP_PANEL_ACTION:
        return `
        padding: ${!props.hasChildren ? '4px' : props.rightIcon ? '4px 5px 4px 4px' : '4px 10px 4px 4px'};    
        `
      default:
        return `
        padding: ${props.rightIcon ? '8px 10px 8px 15px' : props.leftIcon ? '8px 10px' : '9.5px 10px'};
        `
    }
  }}
  border-radius: 8px;
  transition: ${props => props.theme.transition.primary};

  ${props => {
    switch (props.color) {
      case ButtonColor.PRIMARY:
        return `
        background-color: ${props.disabled ? props.theme.colors.disabledBgColor : props.theme.colors.primaryButtonBg};
        color: ${props.disabled ? props.theme.colors.disabledTextColor : props.theme.colors.white};
        `
      case ButtonColor.SECONDARY:
        return `
        background-color: ${
          props.disabled ? props.theme.colors.primaryBackground : props.theme.colors.secondaryButtonBg
        };
        color: ${props.disabled ? props.theme.colors.disabledTextColor : props.theme.colors.darkText};
        border: 1px solid ${props.theme.colors.borderColor}; 
        `
      case ButtonColor.SECONDARY1:
        return `
        background-color: ${
          props.disabled ? props.theme.colors.primaryBackground : props.theme.colors.secondaryButtonBg1
        };
        color: ${props.disabled ? props.theme.colors.disabledTextColor : props.theme.colors.darkText};
        border: 1px solid ${props.theme.colors.borderColor}; 
        & svg path {
          fill: ${props.theme.colors.darkText};
        }
        `
      case ButtonColor.TERTIARY:
        return `
        background-color: transparent;
        color: ${props.disabled ? props.theme.colors.disabledTextColor : props.theme.colors.darkText};
        & svg path {
          stroke: ${props.disabled ? props.theme.colors.disabledTextColor : props.theme.colors.darkText};
        } 
        `
      case ButtonColor.PICKER:
        return `
        background-color: transparent;
        color: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.white};
        opacity: ${props.disabled ? 0.5 : 1};
        `
      case ButtonColor.TAG:
        return `
        background-color: ${props.theme.colors.settingField};
        color: ${props.theme.colors.darkText1};
        `
      case ButtonColor.DELETE:
        return `
        background-color: ${props.theme.colors.delete};
        color: ${props.theme.colors.white};
        opacity: ${props.disabled ? 0.5 : 1};
        `
      case ButtonColor.CUSTOM_DELETE:
        return `
        background-color: transparent;
        border: 1px solid ${props.theme.colors.delete}; 
        color: ${props.theme.colors.delete};
        opacity: ${props.disabled ? 0.5 : 1};
        `
      case ButtonColor.ACTION:
        return `
        background-color: transparent;
        color: ${props.theme.colors.grey1};
        opacity: ${props.disabled ? 0.5 : 1};
        & > svg {
          &:nth-child(1) {
            width: 22px;
            height: 22px;
            ${
              !props.colorNoChange &&
              !props.custom &&
              `
            & rect {
              &[fill] {
                fill: ${!props.active && props.theme.colors.topPanelButtonBg};
              }
              &[stroke] {
                stroke: ${!props.active && props.theme.colors.topPanelButtonBg};
              }
            }
            & path {
              &[fill] {
                fill: ${!props.active && props.theme.colors.topPanelButtonInside};
              }
              &[stroke] {
                stroke: ${!props.active && props.theme.colors.topPanelButtonInside};
              }
            }
            `
            }
          }
          &:nth-child(2) {
            width: 14px;
            height: 14px;
            & path {
              &[stroke] {
                stroke: ${props.theme.colors.grey1};
              }
            }
          }
        }
        `
      case ButtonColor.OUTLINED:
        return `
        background-color: ${props.theme.colors.white};
        color: ${props.disabled ? props.theme.colors.disabledTextColor : props.theme.colors.primaryText};
        border: 1px solid ${props.disabled ? props.theme.colors.borderColor : props.theme.colors.primaryText}; 
        `
      case ButtonColor.BLACK:
        return `
        background-color: ${props.theme.colors.darkText};
        color: ${props.theme.colors.buttonColor};
        & svg * {
          fill: ${props.theme.colors.buttonColor};
        }
        `
      default:
        return null
    }
  }}
  ${props => {
    if (props.active && props.variant === ButtonVariant.TOP_PANEL_ACTION) {
      return `
      background-color: ${props.theme.colors.settingField};
      color: ${props.theme.colors.darkText};
      ${
        !props.colorNoChange &&
        `
        ${
          props.theme.themeType === ThemeType.LIGHT
            ? `
        & svg {
          & rect {
            fill: ${props.theme.colors.darkText}; 
          }
        } 
        `
            : `
        & svg {
          & rect {
            fill: ${props.theme.colors.darkText};
          } 
          & path {
            &[fill] {
              fill: ${props.active && props.theme.colors.primaryBackground};
            }
            &[stroke] {
              stroke: ${props.active && props.theme.colors.primaryBackground};
            }
          }
        }    
        `
        }
      `
      }
      ${
        props.custom &&
        `
        & svg {
          & path {
            &[fill] {
              fill: ${props.theme.colors.darkText};
            }
            &[stroke] {
              stroke: ${props.theme.colors.darkText};
            }
          }
        }
        `
      }
      `
    }
  }}
  & input {
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &:hover {
    ${props => {
      if (!props.disabled) {
        switch (props.color) {
          case ButtonColor.PRIMARY:
            return `
            background-color: ${props.theme.colors.primaryButtonHoverBg};
            `
          case ButtonColor.SECONDARY:
            return `
            background-color: ${props.theme.colors.secondaryButtonHoverBg};
            `
          case ButtonColor.SECONDARY1:
            return `
            background-color: ${props.theme.colors.secondaryButtonHoverBg1}; 
            `
          case ButtonColor.TERTIARY:
            return `
            background-color: ${props.theme.colors.settingField};
            `
          case ButtonColor.TAG:
            return `
            background-color: ${props.theme.colors.settingFieldHover};
            `
          case ButtonColor.PICKER:
            return `
            background-color: ${props.theme.colors.overlayBg};
            `
          case ButtonColor.DELETE:
            return `
            background-color: ${props.theme.colors.deleteHover};
            `
          case ButtonColor.CUSTOM_DELETE:
            return `
            background-color: ${props.theme.colors.primaryBackground};
            `
          case ButtonColor.ACTION:
            return `
            ${
              !props.active &&
              `
            color: ${props.theme.colors.darkText};
            ${
              !props.colorNoChange &&
              `
              ${
                props.theme.themeType === ThemeType.LIGHT
                  ? `
              & svg rect {
                fill: ${props.theme.colors.darkText};
              } 
              `
                  : `
              & svg {
                & path {
                  &[fill] {
                    fill: ${!props.active && props.theme.colors.darkText};
                  }
                  &[stroke] {
                    stroke: ${!props.active && props.theme.colors.darkText};
                  }
                }
              }    
            `
              }
            `
            }
            `
            }

            `
          case ButtonColor.OUTLINED:
            return `
            color: ${props.theme.colors.primaryTextHover};
            border: 1px solid ${props.theme.colors.primaryTextHover};  
            `
          case ButtonColor.BLACK:
            return `
            background-color: ${props.theme.colors.darkText}d9;
            `
          default:
            return null
        }
      }
    }}
  }
  &:active {
    ${props => {
      if (props.color === ButtonColor.PICKER) {
        return `background-color: #0000007f;`
      }
    }}
  }
  & ${Text} {
    font-size: 14px;
    line-height: 150%;
    font-weight: ${props =>
      props.color === ButtonColor.TAG ? props.theme.weights.normal : props.theme.weights.medium};
  }
`

export const Divider = styled.hr`
  border: 0;
  height: 16px;
  width: 1.5px;
  border-radius: 40px;
  background-color: ${props => props.theme.colors.white}33;
`
