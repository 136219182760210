import React from 'react'

import { ValueType } from 'utils'
import { SwitchedEdit } from '../../components'

export const Limits: React.FC = () => (
  <SwitchedEdit
    title="Access limits"
    subTitle="Set restrictions on who can sign in to your app."
    items={[
      {
        id: '0',
        title: 'Allow sign-in from specific domains',
        subTitle: 'Restrict sign-ins to email addresses from particular domains (e.g., @example.com).',
        checked: true,
      },
      {
        id: '1',
        title: 'Allow sign-in if invited',
        subTitle: 'Only allow users with a direct invitation to create accounts.',
        checked: false,
      },
    ]}
    properties={[
      {
        name: 'domains',
        type: ValueType.array,
        accept: ValueType.string,
        position: 0,
        hideLabel: true,
      },
    ]}
  />
)
