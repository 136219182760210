import { useSearchParams } from 'react-router-dom'

export const useParamsFromUrl = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const adminModeParamName = 'adminMode'
  const conversationIdParamName = 'conversationId'
  const intentParamName = 'intent'
  const templateNameParamName = 'templateName'
  const templateIdParamName = 'templateId'
  const pageParamName = 'page'
  const codeplatform_ga_session_idParamName = 'codeplatform_ga_session_id'
  const userAgentParamName = 'userAgent'
  const initialMessageParamName = 'initialMessage'
  const planPriceIdName = 'planPriceId'

  const successParamName = 'success'
  const canceledParamName = 'canceled'
  const checkoutSessionIdParamName = 'sessionId'

  const nameParamName = 'name'
  const gcpProjectIdParamName = 'gcpProjectId'
  const regionIdParamName = 'regionId'

  const connectGCPParamName = 'connect-gcp'
  const connectBillingAccountParamName = 'connect-billing-account'

  const adminMode = searchParams.get(adminModeParamName)
  const isAdmin = adminMode === 'true'
  const conversationId = searchParams.get(conversationIdParamName)
  const intent = searchParams.get(intentParamName)
  const templateName = searchParams.get(templateNameParamName)
  const templateId = searchParams.get(templateIdParamName)
  const page = searchParams.get(pageParamName)
  const codeplatform_ga_session_id = searchParams.get(codeplatform_ga_session_idParamName)
  const userAgent = searchParams.get(userAgentParamName)
  const initialMessage = searchParams.get(initialMessageParamName)
  const planPriceId = searchParams.get(planPriceIdName)

  const success = searchParams.get(successParamName)
  const canceled = searchParams.get(canceledParamName)
  const checkoutSessionId = searchParams.get(checkoutSessionIdParamName)

  const name = searchParams.get(nameParamName)
  const gcpProjectId = searchParams.get(gcpProjectIdParamName)
  const regionId = searchParams.get(regionIdParamName)

  const connectGCP = !!searchParams.get(connectGCPParamName)
  const connectBillingAccount = !!searchParams.get(connectBillingAccountParamName)

  const setConversationIdToUrl = (conversationId: string) => {
    searchParams.delete(adminModeParamName)
    searchParams.delete(conversationIdParamName)
    searchParams.delete(intentParamName)
    searchParams.delete(templateNameParamName)
    searchParams.delete(templateIdParamName)
    searchParams.delete(pageParamName)
    searchParams.delete(codeplatform_ga_session_idParamName)
    searchParams.delete(userAgentParamName)
    searchParams.delete(initialMessageParamName)
    searchParams.delete(planPriceIdName)

    searchParams.delete(successParamName)
    searchParams.delete(canceledParamName)
    searchParams.delete(checkoutSessionIdParamName)

    searchParams.set(conversationIdParamName, conversationId)
    setSearchParams(searchParams, { replace: true })
  }

  const setIntentToUrl = (intent: string) => {
    searchParams.set(intentParamName, intent)
    setSearchParams(searchParams, { replace: true })
  }

  const setInitialMessageToUrl = (initialMessage: string) => {
    searchParams.set(intentParamName, 'create-project')
    searchParams.set(initialMessageParamName, initialMessage)
    setSearchParams(searchParams, { replace: true })
  }

  const setPlanPriceId = (planPriceId: string | null) => {
    if (planPriceId) {
      searchParams.set(planPriceIdName, planPriceId)
    } else {
      searchParams.delete(planPriceIdName)
    }
    setSearchParams(searchParams, { replace: true })
  }

  const setName = (name?: string | null) => {
    if (name) {
      searchParams.set(nameParamName, name)
    } else {
      searchParams.delete(nameParamName)
    }
    searchParams.delete(gcpProjectIdParamName)
    setSearchParams(searchParams, { replace: true })
  }

  const setGcpProjectId = (gcpProjectId?: string | null) => {
    if (gcpProjectId) {
      searchParams.set(gcpProjectIdParamName, gcpProjectId)
    } else {
      searchParams.delete(gcpProjectIdParamName)
    }
    setSearchParams(searchParams, { replace: true })
  }

  const setRegionId = (regionId?: string | null) => {
    if (regionId) {
      searchParams.set(regionIdParamName, regionId)
    } else {
      searchParams.delete(regionIdParamName)
    }
    setSearchParams(searchParams, { replace: true })
  }

  const setConnectGCP = () => {
    searchParams.set(connectGCPParamName, 'true')
    setSearchParams(searchParams, { replace: true })
  }

  const setConnectBillingAccount = (planPriceId: string) => {
    searchParams.set(connectBillingAccountParamName, 'true')
    searchParams.set(planPriceIdName, planPriceId)
    setSearchParams(searchParams, { replace: true })
  }

  const clearCheckoutParams = () => {
    searchParams.delete(successParamName)
    searchParams.delete(canceledParamName)
    searchParams.delete(checkoutSessionIdParamName)
    setSearchParams(searchParams, { replace: true })
  }

  const clearParams = () => {
    searchParams.delete(planPriceIdName)

    searchParams.delete(successParamName)
    searchParams.delete(canceledParamName)
    searchParams.delete(checkoutSessionIdParamName)

    searchParams.delete(nameParamName)
    searchParams.delete(gcpProjectIdParamName)
    searchParams.delete(regionIdParamName)

    searchParams.delete(connectGCPParamName)
    searchParams.delete(connectBillingAccountParamName)

    setSearchParams(searchParams, { replace: true })
  }

  return {
    isAdmin,
    conversationId,
    intent,
    templateName,
    templateId,
    page,
    codeplatform_ga_session_id,
    userAgent,
    initialMessage,
    planPriceId,
    success,
    canceled,
    checkoutSessionId,
    name,
    gcpProjectId,
    regionId,
    connectGCP,
    connectBillingAccount,
    setConversationIdToUrl,
    setIntentToUrl,
    setInitialMessageToUrl,
    setPlanPriceId,
    setName,
    setGcpProjectId,
    setRegionId,
    setConnectGCP,
    setConnectBillingAccount,
    clearCheckoutParams,
    clearParams,
  }
}
