import { memo } from 'react'

import * as styled from './RightIcons.styled'

import { Icon, Name } from 'components'
import { FieldProps } from '../../types'

interface Props extends FieldProps {
  showPassword: boolean
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>
}

export const ShowHidePassword = memo((props: Props) => (
  <styled.RightIcon
    smallWidth={props.smallWidth}
    onClick={e => {
      e.stopPropagation()
      e.preventDefault()
    }}
  >
    <Icon
      onClick={() => props.setShowPassword(show => !show)}
      name={props.showPassword ? Name.ADDITIONAL_HIDE_PW : Name.ADDITIONAL_SHOW_PW}
    />
  </styled.RightIcon>
))
