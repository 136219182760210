import styled from 'styled-components'

import { unreset } from 'theme'

export const ChatMessageText = styled.div`
  ${unreset}
  margin-top: 1.5px;
  color: ${props => props.theme.colors.darkText};
  font-size: 14px;
  line-height: 150%;
  word-break: break-word;
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`

export const ChatMessage = styled.div<{ isThinking: boolean }>`
  position: relative;
  display: flex;
  gap: 10px;
  & > img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    animation: ${props => props.isThinking && 'is-thinking-animation 1s infinite'};
    @keyframes is-thinking-animation {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.2);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  & ${ChatMessageText} {
    color: ${props => (props.isThinking ? props.theme.colors.grey : props.theme.colors.darkText)};
  }
`

export const UserMessage = styled.div`
  position: relative;
  display: flex;
  column-gap: 10px;
  align-self: flex-end;
  & > svg {
    min-width: 16px;
    min-height: 16px;
    margin-top: 13px;
    cursor: pointer;
    &:hover {
      & * {
        &[fill] {
          fill: ${props => props.theme.colors.iconHover};
        }
        &[stroke] {
          stroke: ${props => props.theme.colors.iconHover};
        }
      }
    }
  }
`

export const UserMessageText = styled(ChatMessageText)<{ pending: boolean; error: boolean }>`
  margin-top: unset;
  opacity: ${props => props.pending && '0.7'};
  background-color: ${props => (props.error ? `${props.theme.colors.red}20` : props.theme.colors.settingField)};
  border-radius: 8px;
  padding: 10px;
`
