import styled from 'styled-components'

export const Title = styled.h3`
  font-size: 14px;
  font-weight: ${props => props.theme.weights.bold};
  line-height: normal;
  margin-bottom: 10px;
`

export const Price = styled.div`
  font-size: 18px;
  font-weight: ${props => props.theme.weights.bold};
  line-height: 22px;
  margin-bottom: 5px;
`

export const Description = styled.div`
  font-size: 12px;
  line-height: normal;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.grey1};
`

export const CardButton = styled.div`
  margin-bottom: 15px;
  & > * {
    width: 100%;
  }
`

export const SetIntervalButtons = styled.div`
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  & > button {
    width: 50%;
    height: 26px;
    & > span {
      font-size: 12px !important;
    }
  }
`

export const FeaturesHeading = styled.p`
  font-size: 12px;
  font-weight: ${props => props.theme.weights.medium};
  line-height: normal;
  margin-bottom: 10px;
  padding-top: 15px;
  border-top: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
`

export const FeaturesList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  & li {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    & svg {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
    }
  }
`

export const BorderBox = styled.div<{ active: boolean; custom: boolean }>`
  background: ${props =>
    props.active
      ? `${props.theme.colors.primaryText} !important`
      : props.custom
      ? props.theme.colors.secondaryBackground
      : props.theme.colors.plansCardBg};
  border: 1px solid ${props => (props.custom ? props.theme.colors.borderColorToLightBorder : 'transparent')};
  width: 290px;
  border-radius: 12px;
  padding: 10px 10px 20px;
  ${Title} {
    color: ${props => (props.active ? props.theme.colors.white : props.theme.colors.darkText)};
  }
  ${Price} {
    color: ${props => (props.active ? props.theme.colors.white : props.theme.colors.darkText)};
  }
  ${Description} {
    color: ${props =>
      props.active ? '#DAE2FB' : props.custom ? props.theme.colors.darkText1 : props.theme.colors.grey1};
  }
  ${CardButton} {
    & > button,
    & > a {
      background: ${props =>
        props.active
          ? props.theme.colors.white
          : props.custom
          ? props.theme.colors.darkText
          : props.theme.colors.primaryText};
      color: ${props => props.active && props.theme.colors.darkText_const};
    }
  }
  ${SetIntervalButtons} {
    background: ${props => (props.active ? props.theme.colors.white : props.theme.colors.settingField)};
  }
  ${FeaturesHeading} {
    color: ${props => (props.active ? props.theme.colors.white : props.theme.colors.darkText)};
  }
  ${FeaturesList} {
    & li {
      color: ${props => (props.active ? props.theme.colors.white : props.theme.colors.darkText1)};
      & svg path {
        stroke: ${props => (props.active ? props.theme.colors.white : props.theme.colors.primaryText)};
      }
    }
  }
`
