import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ProjectPlan } from 'hooks'

export const MoreIconBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.4s opacity ease;
  opacity: 0;
  pointer-events: none;

  & circle {
    transition: 0.3s fill ease-out;
  }

  &:hover {
    & circle {
      fill: ${props => props.theme.colors.iconHover};
    }
  }
`
export const RotateIconBox = styled.div`
  padding: 15px;
  transform: rotate(90deg);
`

export const Container = styled.div<{ load: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
  aspect-ratio: 1.2/1;
  transition: 0.4s transform ease;
  transform: translateY(0px);

  ${props =>
    props.load &&
    css`
      pointer-events: none;
    `}

  &:hover {
    transform: translateY(-10px);
    ${MoreIconBox} {
      opacity: 1;
      pointer-events: all;
    }
  }
`

export const AppLink = styled.div`
  height: 100%;
  width: 100%;
  background: ${props => props.theme.colors.primaryBackground};
  cursor: pointer;
`

export const CardBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const Plan = styled(Link)<{ plan: ProjectPlan }>`
  position: absolute;
  left: 10px;
  top: 12px;
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  color: ${props => (props.plan === ProjectPlan.BASIC ? '#0099ff' : props.theme.colors.darkBlue_800_const)};
  font-size: 14px;
  font-weight: ${props => props.theme.weights.medium};
  text-transform: uppercase;
  text-decoration: none;
`
