import { TableCell } from '@mui/material'
import React, { memo, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ActionsIconTbodyWrapper, DropDown, Icon, Name } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { CMSCollectionRecord, CMSService, RecordStatus, getCollectionName, getParentCollectionName } from 'services/cms'
import { Collection } from 'utils'

interface Props {
  page: string
  currentCollection: Collection
  collectionId: string
  record: CMSCollectionRecord
  setSelectedRecords: React.Dispatch<React.SetStateAction<CMSCollectionRecord[]>>
  refreshRecords: () => void
  setToRemove: React.Dispatch<React.SetStateAction<CMSCollectionRecord | null>>
  openRecord: (recordId: string) => void
  small?: boolean
}

export const Actions: React.FC<Props> = memo(
  ({
    page,
    currentCollection,
    collectionId,
    record,
    setSelectedRecords,
    refreshRecords,
    setToRemove,
    openRecord,
    small,
  }) => {
    const { startLoader, stopLoader, toast } = useContext(ProgressContext)
    const {
      project: { id },
      config: {
        data: { collections = [] },
      },
    } = useContext(ProjectContext)
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const subCollections = useMemo(
      () => (small ? [] : collections.filter(el => getParentCollectionName(el.name) === currentCollection.name)),
      [currentCollection, collections]
    )

    const publishRecord = (publish: boolean, recordId: string) => {
      if (publish) {
        openRecord(recordId)
      } else {
        startLoader()
        CMSService.publishRecord(id, collectionId, recordId, publish)
          .then(() => {
            setSelectedRecords(selectedRecords => selectedRecords.filter(el => el.id !== recordId))
            refreshRecords()
          })
          .catch(err => toast(err))
          .finally(() => stopLoader())
      }
    }

    const dublicateRecord = (record: CMSCollectionRecord) => {
      startLoader()
      CMSService.createRecord(id, collectionId, record)
        .then(() => {
          setSelectedRecords(selectedRecords => selectedRecords.filter(el => el.id !== record.id))
          refreshRecords()
        })
        .catch(err => toast(err))
        .finally(() => stopLoader())
    }

    const openSubCollection = (subCollectionId: string) =>
      navigate(`/projects/${id}/${page}/${collectionId}/${record.id}/${subCollectionId}`)

    return (
      <TableCell onClick={e => e.stopPropagation()} sx={{ zIndex: open ? '2 !important' : '1' }}>
        <ActionsIconTbodyWrapper>
          <DropDown
            options={[
              {
                label: 'View/edit',
                iconName: Name.PICKERS_EDIT_RECORDS,
                onClick: () => openRecord(record.id),
              },
              {
                label:
                  record.recordStatus === RecordStatus.PUBLISHED
                    ? 'Unpublish'
                    : record.recordStatus === RecordStatus.CHANGED
                    ? 'Publish changes'
                    : 'Publish',
                iconName: Name.GLOBAL_FILE_TRANSPARENT,
                onClick: () => publishRecord(record.recordStatus !== RecordStatus.PUBLISHED, record.id),
              },
              {
                label: 'Dublicate',
                iconName: Name.RIGHT_SIDEBAR_COPY,
                onClick: () => dublicateRecord(record),
              },
              ...subCollections.map(el => {
                const subCollectionId = getCollectionName(el.name)
                return {
                  label: subCollectionId,
                  iconName: Name.ADDITIONAL_COLLECTION,
                  onClick: () => openSubCollection(subCollectionId),
                }
              }),
              {
                label: record.recordStatus === RecordStatus.CHANGED ? 'Discard' : 'Delete',
                iconName: Name.PICKERS_DELETE,
                onClick: () => setToRemove(record),
                red: true,
              },
            ]}
            top="25px"
            labelElement={<Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />}
            checkIfOpen={setOpen}
          />
        </ActionsIconTbodyWrapper>
      </TableCell>
    )
  }
)
