import React, { useContext } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import * as styled from './Navigation.styled'

import { ProjectContext } from 'context'
import { IRoute, routes } from 'routes'

interface Props {
  settings?: boolean
}

export const Navigation: React.FC<Props> = ({ settings }) => {
  const {
    project: { id },
  } = useContext(ProjectContext)

  const navRoutes = settings
    ? routes
        .find(el => el.path === '/projects/:id/settings')
        ?.children?.find(el => el.path === '/projects/:id/settings/repository')?.children || []
    : routes.find(el => el.path === '/projects/:id/repository')?.children || []

  const { pathname } = useLocation()
  const { buildNumber } = useParams()

  const replacedPath = (path: string) => {
    path = path.replace(':id', id)
    if (buildNumber) {
      path = path.replace(':buildNumber', buildNumber)
    }
    return path
  }

  const checkActive = (route: IRoute) => {
    const path = replacedPath(route.path)
    return route.index ? pathname === path : pathname.startsWith(path)
  }

  return (
    <styled.NavWrapper>
      <nav>
        {navRoutes.map(
          el =>
            el.icon && (
              <styled.NavLink key={el.path} active={checkActive(el)}>
                <Link to={replacedPath(el.customPath || el.path)}>
                  {el.icon}
                  {el.name}
                </Link>
              </styled.NavLink>
            )
        )}
      </nav>
    </styled.NavWrapper>
  )
}
