import { Box } from '@mui/material'
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react'

import {
  ActionTransforms,
  AnyVariableField,
  Button,
  ButtonColor,
  CustomPicker,
  isParametersNotValid,
  isRecordModificationsNotValid,
  isTransformsNotValid,
  Parameters,
  RecordModifications,
  SelectStringValueField,
  StringField,
  VariableField,
} from 'components'
import { ProjectContext } from 'context'
import { Actions } from 'partials/RightSidebar/settings/common'
import {
  Action,
  ActionType,
  ComponentType,
  findAllScreenComponentsByType,
  LocalVariable,
  Screen,
  ValueType,
  VariableSourceType,
} from 'utils'

const buttonPadding = { padding: '9.5px' }

interface Props {
  close: () => void
  value: Action
  onChange: (val: Action) => void
  onRemove?: () => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const ActionPicker: React.FC<Props> = ({
  close,
  value,
  onChange,
  onRemove,
  screenConfig,
  allLocalVariables,
}) => {
  const {
    config: {
      screens = [],
      logic: { flows = [] },
    },
  } = useContext(ProjectContext)
  const [subPicker, setSubPicker] = useState<ReactElement | null>(null)
  const [action, setAction] = useState(JSON.parse(JSON.stringify(value)) as Action)
  const {
    options,
    // enabled,
    actionType,
    parameters,
    collection,
    record,
    recordModifications,
    componentName,
    variable,
    valueTarget,
    transforms,
    variableContextKey,
    // description,
    subActions,
    fallbackActions,
  } = action
  const screenNames = useMemo(() => screens.map(el => el.screenName), [screens])
  const flowNames = useMemo(() => flows.map(el => el.name), [flows])
  const scrollComponentsValues = useMemo(() => {
    if (actionType === ActionType.scrollToStart) {
      const components = [
        ...findAllScreenComponentsByType(screenConfig, ComponentType.list),
        ...findAllScreenComponentsByType(screenConfig, ComponentType.scroll),
      ]
      return components.map(el => el.name).filter(el => !!el)
    }
    return []
  }, [screenConfig, actionType])

  useEffect(() => {
    setAction(JSON.parse(JSON.stringify(value)) as Action)
  }, [value])

  const onConfirm = () => {
    onChange(action)
    close()
  }

  const onDelete =
    value && onRemove
      ? () => {
          onRemove()
          close()
        }
      : undefined

  const localVariable = allLocalVariables.find(el => el.name === valueTarget?.variableName)

  const confirmDisabled =
    !actionType ||
    (actionType === ActionType.check && !variable?.source) ||
    (actionType === ActionType.checkAccessLevels && (!parameters || !options?.screen)) ||
    (actionType === ActionType.subscribe && (!variable?.source || !subActions)) ||
    (actionType === ActionType.checkoutSelected && !variable?.source) ||
    ((actionType === ActionType.navigate || actionType === ActionType.show) && !options?.screen) ||
    ((actionType === ActionType.openLink || actionType === ActionType.openWeb) && !options?.link) ||
    (actionType === ActionType.openEmail && !options?.email) ||
    ((actionType === ActionType.sendSignInLinkToEmail ||
      actionType === ActionType.reSendSignInLinkToEmail ||
      actionType === ActionType.sendPasswordResetEmail) &&
      !variable?.source) ||
    ((actionType === ActionType.createUserWithEmailAndPassword ||
      actionType === ActionType.signInWithEmailAndPassword) &&
      !parameters) ||
    (actionType === ActionType.createRecord && (!collection || !recordModifications)) ||
    (actionType === ActionType.updateRecord && (!record || !recordModifications)) ||
    (actionType === ActionType.deleteRecord && !record) ||
    (actionType === ActionType.setValue && (!valueTarget || !variable)) ||
    (actionType === ActionType.clearValue && !valueTarget) ||
    (actionType === ActionType.scrollToStart && !componentName) ||
    (actionType === ActionType.runFlow && !options?.name) ||
    isParametersNotValid(parameters) ||
    isRecordModificationsNotValid(recordModifications) ||
    isTransformsNotValid(transforms)

  return (
    <CustomPicker
      title="Actions"
      close={close}
      optionsContainer={
        <Box maxHeight="500px" overflow="auto">
          {subPicker || (
            <>
              <SelectStringValueField
                label="Action"
                value={actionType}
                onChange={value => setAction({ actionType: value as ActionType })}
                setSubPicker={setSubPicker}
                options={Object.values(ActionType)}
              />
              {actionType === ActionType.check && (
                <>
                  <VariableField
                    label="Source"
                    source={variable?.source}
                    onSourceChange={value => setAction(action => ({ ...action, variable: { source: value } }))}
                    setSubPicker={setSubPicker}
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                  <Actions
                    title="Fallback actions"
                    subActions={fallbackActions || []}
                    onChange={value => setAction(action => ({ ...action, fallbackActions: value }))}
                    small
                    noBorderBottom
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                </>
              )}
              {actionType === ActionType.checkAccessLevels && (
                <>
                  <SelectStringValueField
                    label="Screen"
                    value={options?.screen}
                    onChange={value =>
                      setAction(action => ({ ...action, options: { ...action.options, screen: value } }))
                    }
                    setSubPicker={setSubPicker}
                    options={screenNames}
                  />
                  <Parameters
                    parameters={parameters}
                    setAction={setAction}
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                  <Actions
                    title="Fallback actions"
                    subActions={fallbackActions || []}
                    onChange={value => setAction(action => ({ ...action, fallbackActions: value }))}
                    small
                    noBorderBottom
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                </>
              )}
              {(actionType === ActionType.checkNotificationsEnabled ||
                actionType === ActionType.checkIdfaAndCookieTrackingEnabled ||
                actionType === ActionType.checkLocationTrackingEnabled ||
                actionType === ActionType.handleDeeplink) && (
                <Actions
                  title="Fallback actions"
                  subActions={fallbackActions || []}
                  onChange={value => setAction(action => ({ ...action, fallbackActions: value }))}
                  small
                  noBorderBottom
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {actionType === ActionType.subscribe && (
                <>
                  <VariableField
                    label="Source"
                    source={variable?.source}
                    onSourceChange={value => setAction(action => ({ ...action, variable: { source: value } }))}
                    setSubPicker={setSubPicker}
                    dataMode
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                  <StringField
                    label="Context key"
                    value={variableContextKey}
                    onChange={value => setAction(action => ({ ...action, variableContextKey: value }))}
                    setSubPicker={setSubPicker}
                  />
                  <Actions
                    title="Sub actions"
                    subActions={subActions || []}
                    onChange={value => setAction(action => ({ ...action, subActions: value }))}
                    small
                    noBorderBottom
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                </>
              )}
              {actionType === ActionType.checkoutSelected && (
                <VariableField
                  label="Source"
                  source={variable?.source}
                  onSourceChange={value => setAction(action => ({ ...action, variable: { source: value } }))}
                  setSubPicker={setSubPicker}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {(actionType === ActionType.navigate || actionType === ActionType.show) && (
                <>
                  <SelectStringValueField
                    label="Screen"
                    value={options?.screen}
                    onChange={value =>
                      setAction(action => ({
                        ...action,
                        options: { ...action.options, screen: value },
                        parameters: [],
                      }))
                    }
                    setSubPicker={setSubPicker}
                    options={screenNames}
                  />
                  {actionType === ActionType.navigate && options?.screen && (
                    <Parameters
                      screenName={options.screen}
                      parameters={parameters}
                      setAction={setAction}
                      screenConfig={screenConfig}
                      allLocalVariables={allLocalVariables}
                    />
                  )}
                </>
              )}
              {(actionType === ActionType.openLink || actionType === ActionType.openWeb) && (
                <AnyVariableField
                  valueType={ValueType.url}
                  label="Link"
                  value={options?.link}
                  onChange={value => setAction(action => ({ ...action, options: { link: value } }))}
                  setSubPicker={setSubPicker}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {actionType === ActionType.openEmail && (
                <StringField
                  label="Target email"
                  value={options?.email}
                  onChange={value => setAction(action => ({ ...action, options: { ...action.options, email: value } }))}
                  setSubPicker={setSubPicker}
                />
              )}
              {(actionType === ActionType.sendSignInLinkToEmail ||
                actionType === ActionType.reSendSignInLinkToEmail ||
                actionType === ActionType.sendPasswordResetEmail) && (
                <VariableField
                  label="Email"
                  source={variable?.source}
                  onSourceChange={value => setAction(action => ({ ...action, variable: { source: value } }))}
                  setSubPicker={setSubPicker}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {(actionType === ActionType.createUserWithEmailAndPassword ||
                actionType === ActionType.signInWithEmailAndPassword) && (
                <Parameters
                  parameters={parameters}
                  setAction={setAction}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {actionType === ActionType.createRecord && (
                <>
                  <VariableField
                    label="Collection"
                    source={{ type: VariableSourceType.collection, collection }}
                    onSourceChange={value =>
                      setAction(action => ({ ...action, collection: value?.collection, recordModifications: [] }))
                    }
                    setSubPicker={setSubPicker}
                    collectionMode
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                  <VariableField
                    label="Value target"
                    source={valueTarget}
                    onSourceChange={value => setAction(action => ({ ...action, valueTarget: value }))}
                    setSubPicker={setSubPicker}
                    withoutTransforms
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                  {collection && (
                    <RecordModifications
                      collection={collection}
                      recordModifications={recordModifications}
                      setAction={setAction}
                      screenConfig={screenConfig}
                      allLocalVariables={allLocalVariables}
                    />
                  )}
                </>
              )}
              {actionType === ActionType.updateRecord && (
                <>
                  <VariableField
                    label="Record"
                    source={record}
                    onSourceChange={value =>
                      setAction(action => ({ ...action, record: value, recordModifications: [] }))
                    }
                    setSubPicker={setSubPicker}
                    recordMode
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                  {record && (
                    <RecordModifications
                      record={record}
                      recordModifications={recordModifications}
                      setAction={setAction}
                      screenConfig={screenConfig}
                      allLocalVariables={allLocalVariables}
                    />
                  )}
                </>
              )}
              {actionType === ActionType.deleteRecord && (
                <VariableField
                  label="Record"
                  source={record}
                  onSourceChange={value => setAction(action => ({ ...action, record: value }))}
                  setSubPicker={setSubPicker}
                  recordMode
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {(actionType === ActionType.setValue || actionType === ActionType.clearValue) && (
                <>
                  <VariableField
                    label="Value target"
                    source={valueTarget}
                    onSourceChange={value => setAction(action => ({ ...action, valueTarget: value }))}
                    setSubPicker={setSubPicker}
                    withoutTransforms
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                  {actionType === ActionType.setValue && valueTarget && (
                    <AnyVariableField
                      valueType={
                        localVariable?.type ||
                        (localVariable?.variable?.arrayConstant
                          ? ValueType.array
                          : localVariable?.variable?.booleanConstant !== undefined
                          ? ValueType.boolean
                          : ValueType.string)
                      }
                      label="Value"
                      value={variable}
                      onChange={value => setAction(action => ({ ...action, variable: value }))}
                      setSubPicker={setSubPicker}
                      screenConfig={screenConfig}
                      allLocalVariables={allLocalVariables}
                    />
                  )}
                </>
              )}
              {actionType === ActionType.scrollToStart && (
                <SelectStringValueField
                  label="Component name"
                  value={componentName}
                  onChange={value => setAction(action => ({ ...action, componentName: value }))}
                  setSubPicker={setSubPicker}
                  options={scrollComponentsValues}
                />
              )}
              {(actionType === ActionType.getPhoto ||
                actionType === ActionType.getVideo ||
                actionType === ActionType.getFile) && (
                <>
                  <VariableField
                    label="Value target"
                    source={valueTarget}
                    onSourceChange={value => setAction(action => ({ ...action, valueTarget: value }))}
                    setSubPicker={setSubPicker}
                    withoutTransforms
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                  <ActionTransforms
                    transforms={transforms}
                    onChange={value => setAction(action => ({ ...action, transforms: value }))}
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                </>
              )}
              {actionType === ActionType.runFlow && (
                <>
                  <SelectStringValueField
                    label="Flow name"
                    value={options?.name}
                    onChange={value =>
                      setAction(action => ({ ...action, options: { ...action.options, name: value }, parameters: [] }))
                    }
                    setSubPicker={setSubPicker}
                    options={flowNames}
                  />
                  {options?.name && (
                    <Parameters
                      flowName={options.name}
                      parameters={parameters}
                      setAction={setAction}
                      screenConfig={screenConfig}
                      allLocalVariables={allLocalVariables}
                    />
                  )}
                </>
              )}
              <Box
                padding="15px 10px"
                display="flex"
                justifyContent="space-between"
                gap="8px"
                flexWrap="wrap-reverse"
                width="100%"
              >
                {onDelete ? (
                  <Button style={buttonPadding} color={ButtonColor.DELETE} onClick={onDelete}>
                    Remove
                  </Button>
                ) : (
                  <span />
                )}
                <Box display="flex" gap="8px">
                  <Button style={buttonPadding} color={ButtonColor.SECONDARY} onClick={close}>
                    Cancel
                  </Button>
                  <Button style={buttonPadding} onClick={onConfirm} disabled={confirmDisabled}>
                    Confirm
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      }
    />
  )
}
