import { Box } from '@mui/material'
import { useRef, useState } from 'react'

import * as styled from './Tasks.styled'

import { Icon, Loader, Name, TasksPicker } from 'components'
import { Task, getTaskTitle, useOnClickOutside } from 'hooks'

interface Props {
  tasks: Task[]
  runningTasks: Task[]
}

export const Tasks: React.FC<Props> = ({ tasks, runningTasks }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {!!runningTasks.length ? (
        <styled.Task onClick={() => setOpen(open => !open)}>
          {runningTasks.length === 1 ? getTaskTitle(runningTasks[0]) : `Running ${runningTasks.length} tasks`}
          <Loader small />
        </styled.Task>
      ) : (
        !!tasks.length && (
          <styled.Task onClick={() => setOpen(open => !open)}>
            <Icon name={Name.GLOBAL_TASKS} width={22} height={22} />
          </styled.Task>
        )
      )}
      {open && <TasksPickerWrapper close={() => setOpen(false)} tasks={tasks} />}
    </>
  )
}

interface TasksPickerWrapperProps {
  close: () => void
  tasks: Task[]
}

const TasksPickerWrapper: React.FC<TasksPickerWrapperProps> = ({ close, tasks }) => {
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, close)

  return (
    <Box zIndex="10" width="400px" position="absolute" top="36px" right="-195px" ref={ref}>
      <TasksPicker close={close} tasks={tasks} />
    </Box>
  )
}
