import { LayoutContext } from 'context'
import React, { useContext } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

export interface ColorTheme {
  aiBackground: string
  primaryBackground: string
  modalBg: string
  borderColor: string
  borderColorToLightBorder: string
  borderColorToTransparent: string
  blackBorder: string
  darkText: string
  darkText_const: string
  darkText1: string
  darkText2: string
  darkText3: string
  darkText4: string
  darkText5: string
  darkText6: string
  grey: string
  grey1: string
  grey2: string
  grey3: string
  grey4: string
  primaryBlue: string
  selectBackground: string
  secondaryButtonBg: string
  secondaryButtonHoverBg: string
  secondaryButtonBg1: string
  secondaryButtonHoverBg1: string
  primaryButtonBg: string
  primaryButtonHoverBg: string
  topPanelButtonBg: string
  topPanelButtonInside: string
  folderIcon: string
  plansCardBg: string
  tableRoleBg: string

  disabledBgColor: string
  disabledTextColor: string

  overlayBg: string
  screenHover: string

  blue_500: string
  blue_50: string
  blue_100: string
  blue_200: string
  blue_300: string
  blue_400: string
  blue_600: string
  blue_700: string
  blue_800: string
  blue_900: string
  blue_950: string

  darkBlue_500: string
  darkBlue_500_1: string
  darkBlue_50: string
  darkBlue_100: string
  darkBlue_100_lightBorder: string
  darkBlue_100_transparent: string
  darkBlue_100_grey1: string
  darkBlue_100_noChange: string
  darkBlue_200: string
  darkBlue_300: string
  darkBlue_400: string
  darkBlue_600: string
  darkBlue_700: string
  darkBlue_800: string
  darkBlue_900: string
  darkBlue_950: string

  black_100: string
  black_200: string
  black_300: string
  black_400: string
  black_600: string
  black_700: string
  black_800: string
  darkBlue_800_const: string
  black_900: string
  black_950: string

  iconHover: string
  settingField: string
  settingFieldHover: string

  white: string
  black: string
  blue: string

  secondaryBackground: string

  buttonColor: string

  primaryText: string
  primaryText1: string
  primaryText2: string
  primaryTextHover: string

  scrollBarBackground: string
  scrollBarThumb: string

  green: string
  red: string
  delete: string
  deleteHover: string
  tagsBackground: string

  notificationBlueBg: string
  notificationBlueText: string
  notificationBlueBorder: string
  notificationGreenBg: string
  notificationGreenText: string
  notificationGreenBorder: string
  notificationYellowBg: string
  notificationYellowText: string
  notificationYellowBorder: string
  notificationRedBg: string
  notificationRedText: string
  notificationRedBorder: string
}

export interface FontsTheme {
  primary: string
}

export interface TransitionTheme {
  primary: string
  primaryOut: string
  secondary: string
}

export interface WeightsTheme {
  normal: number
  medium: number
  semibold: number
  bold: number
}

export interface Spacing {
  s1: number
  s2: number
  s3: number
  s4: number
}

export enum ThemeType {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface CodePlatformTheme {
  themeType: ThemeType
  colors: ColorTheme
  fonts: FontsTheme
  transition: TransitionTheme
  weights: WeightsTheme
  spacing: Spacing
}

export const themeTypes: { light: ThemeType; dark: ThemeType } = {
  light: ThemeType.LIGHT,
  dark: ThemeType.DARK,
}

export const themeColors: { light: ColorTheme; dark: ColorTheme } = {
  light: {
    aiBackground: '#FAFAFA',
    primaryBackground: '#F2F2F3',
    secondaryBackground: '#FFF',
    selectBackground: '#FFF',
    modalBg: '#FFF',
    darkText: '#212133',
    darkText_const: '#212133',
    darkText1: '#212133',
    darkText2: '#212133',
    darkText3: '#212133',
    darkText4: '#212133',
    darkText5: '#212133',
    darkText6: '#212133',
    grey: '#7F899E',
    grey1: '#7F899E',
    grey2: '#7F899E',
    grey3: '#7F899E',
    grey4: '#7F899E',
    borderColor: '#DFDFEA',
    borderColorToTransparent: '#DFDFEA',
    borderColorToLightBorder: '#DFDFEA',
    blackBorder: '#212133',
    secondaryButtonBg: '#FFF',
    secondaryButtonHoverBg: '#F2F2F3',
    secondaryButtonBg1: '#FFF',
    secondaryButtonHoverBg1: '#F2F2F3',
    primaryButtonBg: '#737BF8',
    primaryButtonHoverBg: '#444FF5',
    topPanelButtonBg: '#B8BDC8',
    topPanelButtonInside: '#F2F2F3',
    folderIcon: '#B8BDC8',
    plansCardBg: '#E9ECFC',
    tableRoleBg: '#F8FAFD',

    primaryText: '#737BF8',
    primaryText1: '#737BF8',
    primaryText2: '#737BF8',
    primaryTextHover: '#444FF5',
    primaryBlue: '#476CEC',

    disabledBgColor: '#E8E8EE',
    disabledTextColor: '#BEBED5',

    overlayBg: '#0d111a8c',
    screenHover: '#D0D3FD',

    //new-palette
    blue_500: '#476BEC',
    blue_50: '#E8ECFD',
    blue_100: '#D1DAFA',
    blue_200: '#A3B5F5',
    blue_300: '#748FF1',
    blue_400: '#466AEC',
    blue_600: '#1337B9',
    blue_700: '#0E298B',
    blue_800: '#0A1C5C',
    blue_900: '#050E2E',
    blue_950: '#020717',

    darkBlue_500: '#27334D',
    darkBlue_500_1: '#27334D',
    darkBlue_50: '#EEF1F6',
    darkBlue_100: '#DDE2EE',
    darkBlue_100_lightBorder: '#DDE2EE',
    darkBlue_100_transparent: '#DDE2EE',
    darkBlue_100_grey1: '#DDE2EE',
    darkBlue_100_noChange: '#DDE2EE',
    darkBlue_200: '#BBC6DD',
    darkBlue_300: '#99A9CC',
    darkBlue_400: '#788DBA',
    darkBlue_600: '#455A87',
    darkBlue_700: '#334366',
    darkBlue_800: '#222D44',
    darkBlue_800_const: '#222D44',
    darkBlue_900: '#111622',
    darkBlue_950: '#090B11',

    black_100: '#E4E6E7',
    black_200: '#C9CCCF',
    black_300: '#AEB3B7',
    black_400: '#93999F',
    black_600: '#60666C',
    black_700: '#484D51',
    black_800: '#303336',
    black_900: '#181A1B',
    black_950: '#0C0D0D',

    iconHover: '#212133',
    settingField: '#9A9A9A26',
    settingFieldHover: '#9A9A9A4D',

    white: '#FFFFFF',
    black: '#000000',
    blue: '#007AFF',
    buttonColor: '#EBEBEB',
    scrollBarBackground: '#a1a1a1',
    scrollBarThumb: '#8f8f8f',
    green: '#038700',
    red: '#E96565',
    delete: '#DF5636',
    deleteHover: '#B20122',
    tagsBackground: '#EFECEC',

    notificationBlueBg: '#F6F4FF',
    notificationBlueText: '#737BF8',
    notificationBlueBorder: '#8A91F9',
    notificationGreenBg: '#F1FAF7',
    notificationGreenText: '#05543F',
    notificationGreenBorder: '#32C48D',
    notificationYellowBg: '#FEFDEB',
    notificationYellowText: '#8D4B11',
    notificationYellowBorder: '#E9B63F',
    notificationRedBg: '#FDF1F1',
    notificationRedText: '#9A1C1D',
    notificationRedBorder: '#E43F3E',
  },
  dark: {
    aiBackground: '#333236',
    //
    primaryBackground: '#333236',
    //
    secondaryBackground: '#1E1E1F',
    //
    selectBackground: '#2D2C32',
    //
    modalBg: '#3F3E42',
    //
    darkText: '#FFF',
    //
    darkText_const: '#212133',
    //
    darkText1: '#ABAAAE',
    //
    darkText2: '#7D7D7E',
    //
    darkText3: '#C8C9CB',
    //
    darkText4: '#727FF0',
    //
    darkText5: '#4256EC',
    //
    darkText6: '#83828626',
    //
    grey: '#7D7D7E',
    //
    grey1: '#ABAAAE',
    //
    grey2: '#FFF',
    //
    grey3: '#6F6E72',
    //
    grey4: '#717074',
    //
    borderColor: '#1F1E22',
    //
    borderColorToTransparent: 'transparent',
    //
    borderColorToLightBorder: '#51515D',
    //
    blackBorder: '#1F2024',
    //
    secondaryButtonBg: '#333236',
    //
    secondaryButtonHoverBg: '#83828626',
    //
    secondaryButtonBg1: '#83828626',
    //
    secondaryButtonHoverBg1: '#333236',
    //
    primaryButtonBg: '#476CEC',
    //
    primaryButtonHoverBg: '#444FF5',
    //
    primaryText: '#737BF8',
    //
    primaryText1: '#ABAAAE',
    //
    primaryText2: '#FFF',
    //
    topPanelButtonBg: '#83828626',
    //
    topPanelButtonInside: '#ABAAAE',
    //
    folderIcon: '#ABAAAE',
    //
    plansCardBg: '#333236',
    //
    tableRoleBg: '#333236',

    // not-from-figma
    disabledBgColor: '#8382864D',
    // not-from-figma
    disabledTextColor: '#7D7D7E',

    //
    overlayBg: '#0A0A0BB3',
    //
    darkBlue_500: '#7D7D7E',
    //
    darkBlue_500_1: '#ABAAAE',
    //
    darkBlue_50: '#302F33',
    //
    darkBlue_100: '#47464A',
    //
    darkBlue_100_lightBorder: '#51515D',
    //
    darkBlue_100_transparent: 'transparent',
    //
    darkBlue_100_grey1: '#ABAAAE',
    //
    darkBlue_100_noChange: '#DDE2EE',
    //
    darkBlue_800: '#FFF',
    //
    darkBlue_800_const: '#222D44',
    //
    black_400: '#7D7D7E',

    //not-used-start
    darkBlue_900: '#111622',
    darkBlue_950: '#090B11',
    black_200: '#C9CCCF',
    black_600: '#60666C',
    black_700: '#484D51',
    black_800: '#303336',
    black_900: '#181A1B',
    black_950: '#0C0D0D',
    darkBlue_400: '#788DBA',
    darkBlue_600: '#455A87',
    darkBlue_700: '#334366',
    darkBlue_200: '#BBC6DD',
    blue_500: '#476BEC',
    blue_50: '#E8ECFD',
    blue_100: '#D1DAFA',
    blue_200: '#A3B5F5',
    blue_300: '#748FF1',
    blue_400: '#466AEC',
    blue_600: '#1337B9',
    blue_700: '#0E298B',
    blue_800: '#0A1C5C',
    blue_900: '#050E2E',
    blue_950: '#020717',
    primaryBlue: '#476CEC',
    //not-used-end

    //
    iconHover: '#FFF',
    //
    settingField: '#83828626',
    //
    settingFieldHover: '#8382864D',
    //
    white: '#FFFFFF',
    //
    black: '#000000',
    //
    blue: '#4151E2',

    //used-but-not-updated-start
    screenHover: '#D0D3FD',
    darkBlue_300: '#99A9CC',
    black_300: '#AEB3B7',
    black_100: '#E4E6E7',
    primaryTextHover: '#444FF5',
    buttonColor: '#505050',
    scrollBarBackground: '#a1a1a1',
    scrollBarThumb: '#8f8f8f',
    green: '#038700',
    red: '#E96565',
    delete: '#E44040',
    deleteHover: '#B20122',
    tagsBackground: '#EFECEC',
    //used-but-not-updated-end

    //cannot-be-used-on-notification-level-because-theme-is-undefined-there
    notificationBlueBg: '#F6F4FF',
    notificationBlueText: '#737BF8',
    notificationBlueBorder: '#8A91F9',
    notificationGreenBg: '#F1FAF7',
    notificationGreenText: '#05543F',
    notificationGreenBorder: '#32C48D',
    notificationYellowBg: '#FEFDEB',
    notificationYellowText: '#8D4B11',
    notificationYellowBorder: '#E9B63F',
    notificationRedBg: '#FDF1F1',
    notificationRedText: '#9A1C1D',
    notificationRedBorder: '#E43F3E',
  },
}

export const codePlatformTheme: CodePlatformTheme = {
  themeType: themeTypes.light,
  colors: themeColors.light,
  fonts: {
    primary: "'Roboto', Arial, sans-serif",
  },
  transition: {
    primary: 'all 0.2s ease',
    primaryOut: 'all 150ms ease-in-out 0s',
    secondary: '0.3s ease',
  },
  weights: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  spacing: {
    s1: 16,
    s2: 20,
    s3: 22,
    s4: 24,
  },
}

interface Props {
  theme?: ThemeType
  children: React.ReactNode
}

export const ThemeProvider: React.FC<Props> = ({ children }) => {
  const { theme } = useContext(LayoutContext)

  return (
    <StyledThemeProvider theme={{ ...codePlatformTheme, colors: themeColors[theme], themeType: themeTypes[theme] }}>
      {children}
    </StyledThemeProvider>
  )
}
