import axios from 'axios'

import { auth } from 'services/firebase'

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

httpClient.interceptors.request.use(
  async config => {
    const accessToken = await auth.currentUser?.getIdToken()
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  err => Promise.reject(err)
)

httpClient.interceptors.response.use(
  res => res,
  async err => {
    if (err.response) {
      const originalConfig = err.config
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        const accessToken = await auth.currentUser?.getIdToken(true)
        if (accessToken) {
          httpClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
        }
        return httpClient(originalConfig)
      }
      if (err.response.data) {
        return Promise.reject(err.response.data)
      }
    }
    return Promise.reject(err)
  }
)
