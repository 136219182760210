import { Box } from '@mui/material'
import React from 'react'

import {
  CustomSection,
  CustomSectionSubTitle,
  CustomSectionTitle,
  CustomSectionTitleAction,
  CustomSectionTitleWithActionWrapper,
  Icon,
  Name,
} from 'components'

export const Transactions: React.FC = () => (
  <CustomSection>
    <CustomSectionTitleWithActionWrapper>
      <CustomSectionTitle>Recent Transactions</CustomSectionTitle>
      <CustomSectionTitleAction to="">
        <Icon name={Name.REPOSITORY_LINK} width={24} height={24} />
        View all customers
      </CustomSectionTitleAction>
    </CustomSectionTitleWithActionWrapper>
    <Box display="flex" justifyContent="center">
      <CustomSectionSubTitle secondary>No live transactions to show</CustomSectionSubTitle>
    </Box>
  </CustomSection>
)
