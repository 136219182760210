import styled from 'styled-components'

export const Dots = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.grey};
  text-align: center;
  & > div {
    transform: translateX(-38px);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px, 19px 0 0 5px, 38px 0 0 5px, 57px 0 0 5px,
      19px 19px 0 0px, 38px 19px 0 0px, 57px 19px 0 0px;
    animation: l26 2s infinite linear;
    @keyframes l26 {
      12.5% {
        box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 5px, 19px 0 0 5px, 38px 0 0 0px, 57px 0 0 5px,
          19px 19px 0 0px, 38px 19px 0 0px, 57px 19px 0 0px;
      }
      25% {
        box-shadow: 19px -19px 0 5px, 38px -19px 0 0px, 57px -19px 0 5px, 19px 0 0 0px, 38px 0 0 0px, 57px 0 0 0px,
          19px 19px 0 0px, 38px 19px 0 5px, 57px 19px 0 0px;
      }
      50% {
        box-shadow: 19px -19px 0 5px, 38px -19px 0 5px, 57px -19px 0 0px, 19px 0 0 0px, 38px 0 0 0px, 57px 0 0 0px,
          19px 19px 0 0px, 38px 19px 0 0px, 57px 19px 0 5px;
      }
      62.5% {
        box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px, 19px 0 0 5px, 38px 0 0 0px, 57px 0 0 0px,
          19px 19px 0 0px, 38px 19px 0 5px, 57px 19px 0 5px;
      }
      75% {
        box-shadow: 19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px, 19px 0 0 0px, 38px 0 0 0px, 57px 0 0 5px,
          19px 19px 0 0px, 38px 19px 0 0px, 57px 19px 0 5px;
      }
      87.5% {
        box-shadow: 19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px, 19px 0 0 0px, 38px 0 0 5px, 57px 0 0 0px,
          19px 19px 0 5px, 38px 19px 0 0px, 57px 19px 0 0px;
      }
    }
  }
`
