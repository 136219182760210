import styled from 'styled-components'

export const ProjectsBox = styled.div`
  height: auto;
  width: 100%;
`

export const SelectBox = styled.div`
  margin-bottom: -20px;
  & > div > div:first-child {
    background-color: transparent;
    padding: 0px;
    & > span {
      color: ${props => props.theme.colors.darkText2};
    }

    &:hover {
      background-color: transparent;
    }
  }
`

export const List = styled.div<{
  isTemplate?: boolean
  isModal?: boolean
  isNewProject?: boolean
}>`
  margin: ${props => (props.isNewProject ? '40px' : '30px')} 0 60px;
  width: 100%;
  display: grid;
  grid-template-columns: ${props =>
    props.isModal ? 'repeat(auto-fill, 255px)' : 'repeat(auto-fill, minmax(255px, 1fr))'};
  justify-content: center;
  grid-gap: 30px;
  ${props => {
    if (props.isTemplate) {
      return `row-gap: 80px;`
    }
  }}

  @media (max-height: 899px) {
    margin: ${props => (props.isModal ? '10px 0 10px' : props.isNewProject ? '40px 0 60px' : '30px 0 60px')};
  }
`

export const SortBox = styled.div`
  margin-bottom: -20px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
  cursor: pointer;
`
