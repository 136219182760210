import { Box } from '@mui/material'
import { useFormik } from 'formik'
import ISO6391 from 'iso-639-1'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import {
  CustomSection,
  CustomSectionButton,
  CustomSectionTitle,
  InputProperty,
  Translations,
  inputs,
  validate,
} from 'components'
import { ProjectContext } from 'context'
import { ValueType } from 'utils'

export const AppLanguages: React.FC = () => {
  const {
    config: {
      localization: { languages, primaryLanguage },
    },
    setConfig,
    language,
    setLanguage,
  } = useContext(ProjectContext)
  const [showManageTranslations, setShowManageTranslations] = useState(false)
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')

  const formik = useFormik({
    initialValues: { languages, primaryLanguage, language },
    validate: data => validate(data, properties),
    onSubmit: data => {
      const { languages, primaryLanguage, language } = data
      setConfig(config => ({ ...config, localization: { languages, primaryLanguage } }))
      setLanguage(language)
    },
  })

  useEffect(() => {
    const record = { languages, primaryLanguage, language }
    formik.setValues(record)
    setValuesBeforeEdit(JSON.stringify(record))
  }, [JSON.stringify(languages), primaryLanguage, language])

  const formikLanguages = (formik.values.languages || []) as string[]

  const properties = useMemo(
    (): InputProperty[] => [
      {
        name: 'languages',
        type: ValueType.array,
        accept: ValueType.string,
        position: 0,
        isRequired: true,
        optionsToSelect: ISO6391.getAllCodes().map(el => ({ value: el, label: ISO6391.getName(el) })),
        smallWidth: true,
        hideLabel: true,
      },
      {
        name: 'primaryLanguage',
        type: ValueType.string,
        position: 1,
        isRequired: true,
        title: 'Primary Language',
        helpText: 'Select the base language of your application.',
        optionsToSelect: formikLanguages.map(el => ({ value: el, label: ISO6391.getName(el) })),
        smallWidth: true,
      },
      {
        name: 'language',
        type: ValueType.string,
        position: 2,
        isRequired: true,
        title: 'Display Language',
        helpText: 'Select which language to view your app in the Visual Builder and App Preview.',
        optionsToSelect: formikLanguages.map(el => ({ value: el, label: ISO6391.getName(el) })),
        smallWidth: true,
      },
    ],
    [JSON.stringify(formikLanguages)]
  )

  const showActions = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)

  useEffect(() => {
    if (showActions) {
      formik.submitForm()
    }
  }, [showActions])

  return (
    <CustomSection>
      <CustomSectionTitle mb="-5px">App Languages</CustomSectionTitle>
      {inputs(properties, formik)}
      <Box mt="30px">
        <CustomSectionButton
          title="Translations"
          subTitle="Manage app translations."
          onClick={() => setShowManageTranslations(true)}
          buttonText="Manage translations"
        />
      </Box>
      {showManageTranslations && <Translations close={() => setShowManageTranslations(false)} />}
    </CustomSection>
  )
}
