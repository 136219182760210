import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, ButtonColor, ButtonVariant, connectAppStoreParamName, Icon, Name } from 'components'
import { Integration, IntegrationAppstore, IntegrationStatus } from 'hooks'

interface Props {
  appstoreIntegration: Integration | null
}

export const AppStoreButton: React.FC<Props> = ({ appstoreIntegration }) => {
  const navigate = useNavigate()

  return appstoreIntegration?.status === IntegrationStatus.READY ? (
    <a href="https://appstoreconnect.apple.com" target="_blank" rel="noreferrer">
      <Button
        variant={ButtonVariant.LINK}
        color={ButtonColor.SECONDARY}
        leftIcon={<Icon name={Name.SIZE_24_APP_STORE} />}
      >
        {(appstoreIntegration?.data as IntegrationAppstore | undefined)?.teamName}
      </Button>
    </a>
  ) : (
    <Button
      variant={ButtonVariant.LINK}
      color={ButtonColor.SECONDARY}
      leftIcon={<Icon name={Name.SIZE_24_APP_STORE} />}
      onClick={() => navigate(`?${connectAppStoreParamName}=true`)}
      disabled={!appstoreIntegration}
    >
      Link to App Store Connect
    </Button>
  )
}
