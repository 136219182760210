import ISO6391 from 'iso-639-1'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import * as styled from './DeviceSettings.styled'

import { CommonPicker, Icon, Name, Rotation } from 'components'
import { ProjectContext } from 'context'
import { useOnClickOutside } from 'hooks'
import { BrandingThemeType, devicesForPicker } from 'utils'

export enum DeviceMode {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}

export const mobileModeEvent = 'mobileModeEvent'
export const tabletModeEvent = 'tabletModeEvent'
export const desktopModeEvent = 'desktopModeEvent'

const scaleValues = ['100', '90', '80', '70', '50'].map(el => ({ value: el, label: `${el}%` }))

interface Props {
  ai?: boolean
  device?: string
  setDevice?: React.Dispatch<React.SetStateAction<string>>
  scale?: number
  setScale?: React.Dispatch<React.SetStateAction<number>>
  topPanel?: boolean
  changeDeviceMode?: boolean
}

export const DeviceSettings: React.FC<Props> = ({
  ai,
  device,
  setDevice,
  scale,
  setScale,
  topPanel,
  changeDeviceMode,
}) => {
  const {
    config: {
      localization: { languages = [] },
    },
    language,
    setLanguage,
    theme,
    setTheme,
  } = useContext(ProjectContext)
  const languagesValues = useMemo(
    () => languages.map(el => ({ value: el, label: ISO6391.getName(el) })),
    [JSON.stringify(languages)]
  )
  const [deviceMode, setDeviceMode] = useState(window.innerWidth < 768 ? DeviceMode.DESKTOP : DeviceMode.MOBILE)
  const [openLanguagePicker, setOpenLanguagePicker] = useState(false)
  const [openDevicePicker, setOpenDevicePicker] = useState(false)
  const [openScalePicker, setOpenScalePicker] = useState(false)
  const languagePickerRef = useRef<HTMLDivElement>(null)
  const devicePickerRef = useRef<HTMLDivElement>(null)
  const scalePickerRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(languagePickerRef, () => setOpenLanguagePicker(false))
  useOnClickOutside(devicePickerRef, () => setOpenDevicePicker(false))
  useOnClickOutside(scalePickerRef, () => setOpenScalePicker(false))

  useEffect(() => {
    if (changeDeviceMode) {
      const onResize = () => setDeviceMode(window.innerWidth < 768 ? DeviceMode.DESKTOP : DeviceMode.MOBILE)
      window.addEventListener('resize', onResize)
      return () => {
        window.removeEventListener('resize', onResize)
      }
    }
  }, [changeDeviceMode])

  useEffect(() => {
    if (changeDeviceMode) {
      document.dispatchEvent(
        new Event(
          deviceMode === DeviceMode.MOBILE
            ? mobileModeEvent
            : deviceMode === DeviceMode.TABLET
            ? tabletModeEvent
            : desktopModeEvent
        )
      )
    }
  }, [deviceMode, changeDeviceMode])

  const setScaleHandler = (increase: boolean) =>
    setScale?.(scale => (increase ? (scale !== 100 ? scale + 1 : scale) : scale - 1))

  const changeScale = (increase: boolean) => {
    setScaleHandler(increase)
    const timer = setInterval(() => setScaleHandler(increase), 300)
    document.onmouseup = () => {
      clearInterval(timer)
      document.onmouseup = () => {}
    }
    setOpenScalePicker(false)
  }

  const handleChangeScale = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value
    if (value >= 0 && value <= 100) {
      setScale?.(value)
    } else if (value > 100) {
      setScale?.(100)
    }
  }

  const changeTheme = () =>
    setTheme(theme => (theme !== BrandingThemeType.light ? BrandingThemeType.light : BrandingThemeType.dark))

  const changeDevice = (val: string) => {
    setDevice?.(val)
    setOpenDevicePicker(false)
  }

  const changeLanguage = (val: string) => {
    setLanguage(val)
    setOpenLanguagePicker(false)
  }

  const changeScaleFromPicker = (val: string) => {
    setScale?.(+val)
    setOpenScalePicker(false)
  }

  return (
    <styled.DeviceSettings onClick={e => e.stopPropagation()} ai={ai} topPanel={topPanel}>
      {changeDeviceMode && (
        <>
          <styled.Box active={deviceMode === DeviceMode.MOBILE} onClick={() => setDeviceMode(DeviceMode.MOBILE)}>
            <Icon name={Name.SECOND_ORDER_MOBILE} />
          </styled.Box>
          <styled.Box active={deviceMode === DeviceMode.TABLET} onClick={() => setDeviceMode(DeviceMode.TABLET)}>
            <Icon name={Name.SECOND_ORDER_TABLET} width={20} height={20} />
          </styled.Box>
          <styled.Box active={deviceMode === DeviceMode.DESKTOP} onClick={() => setDeviceMode(DeviceMode.DESKTOP)}>
            <Icon name={Name.SECOND_ORDER_DESKTOP} width={20} height={20} />
          </styled.Box>
        </>
      )}
      <styled.Box onClick={changeTheme} isCircle>
        <Icon rotation={theme === BrandingThemeType.dark ? Rotation.LEFT : undefined} name={Name.GLOBAL_THEME_SWITCH} />
      </styled.Box>
      <styled.Box ref={languagePickerRef} onClick={() => setOpenLanguagePicker(open => !open)}>
        <Icon name={Name.SETTINGS_LANGUAGES} />
        {openLanguagePicker && (
          <styled.Picker onClick={e => e.stopPropagation()} topPanel={topPanel}>
            <CommonPicker
              value={language}
              onChange={changeLanguage}
              values={languagesValues}
              noSearchMode
              close={() => {}}
            />
          </styled.Picker>
        )}
      </styled.Box>
      {setDevice && (
        <styled.Box ref={devicePickerRef} onClick={() => setOpenDevicePicker(open => !open)}>
          <Icon name={Name.SECOND_ORDER_MOBILE} />
          {openDevicePicker && (
            <styled.Picker onClick={e => e.stopPropagation()} topPanel={topPanel}>
              <CommonPicker
                value={device}
                onChange={changeDevice}
                values={devicesForPicker}
                noSearchMode
                close={() => {}}
              />
            </styled.Picker>
          )}
        </styled.Box>
      )}
      {setScale && (
        <styled.Scale ref={scalePickerRef}>
          <Icon name={Name.ADDITIONAL_MINUS} onMouseDown={() => changeScale(false)} />
          <input value={scale} onChange={handleChangeScale} onClick={() => setOpenScalePicker(open => !open)} />%
          <Icon name={Name.ADDITIONAL_PLUS} onMouseDown={() => changeScale(true)} />
          {openScalePicker && (
            <styled.ScalePicker onClick={e => e.stopPropagation()} topPanel={topPanel}>
              <CommonPicker
                value={String(scale)}
                onChange={changeScaleFromPicker}
                values={scaleValues}
                noSearchMode
                close={() => {}}
              />
            </styled.ScalePicker>
          )}
        </styled.Scale>
      )}
    </styled.DeviceSettings>
  )
}
