import { Dispatch, SetStateAction } from 'react'

import { GetVariableValue } from 'hooks'
import {
  BrandingColorStyles,
  BrandingColorType,
  BrandingThemeType,
  Resource,
  TabBar,
  TabBarItem,
  screenComponentClassName,
  setColors,
  setInnerModeEvent,
  urlToBase64,
} from 'utils'
import { BarComponentType, getMediaResourceUrl, getTextValue } from '../utils'

export const createTabBar = async (
  id: string,
  tabBarId: string,
  tabBar: TabBar,
  height: number,
  screen: HTMLElement,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  resources: Resource[],
  getVariableValue: GetVariableValue,
  onClickComponent?: () => void,
  setHoveredId?: Dispatch<SetStateAction<string>>
) => {
  const fromVB = !!(onClickComponent && setHoveredId)
  const { backgroundColor } = tabBar

  const screenTabBar = document.createElement('div')
  screen.appendChild(screenTabBar)

  screenTabBar.id = id
  screenTabBar.className = screenComponentClassName
  screenTabBar.style.position = 'absolute'
  screenTabBar.style.left = '0'
  screenTabBar.style.bottom = '0'
  screenTabBar.style.zIndex = '2'
  screenTabBar.style.width = '100%'
  screenTabBar.style.height = `${height}px`
  screenTabBar.style.padding = '5px 0'
  screenTabBar.style.boxSizing = 'border-box'
  screenTabBar.style.display = 'flex'
  screenTabBar.style.justifyContent = 'space-around'
  screenTabBar.style.alignItems = 'flex-start'
  screenTabBar.style.backgroundBlendMode = 'hard-light'
  screenTabBar.style.borderWidth = '0'
  screenTabBar.style.borderStyle = 'solid'
  screenTabBar.style.borderColor = colorStyles.surfaceVariant[theme]
  screenTabBar.style.borderTopWidth = '1px'

  await setColors(
    screenTabBar,
    colorStyles,
    theme,
    resources,
    getVariableValue,
    backgroundColor || { constant: `@${BrandingColorType.background}` }
  )

  if (fromVB) {
    screenTabBar.ondblclick = e => {
      e.stopPropagation()
      document.dispatchEvent(new Event(setInnerModeEvent))
    }
    screenTabBar.onmousedown = e => {
      e.stopPropagation()
      onClickComponent()
    }
    screenTabBar.onmouseover = e => {
      e.stopPropagation()
      setHoveredId(tabBarId)
    }
    screenTabBar.onmouseleave = e => {
      e.stopPropagation()
      setHoveredId('')
    }
  }

  return screenTabBar
}

export const profieTabs = ['No Authorization Profile', 'Unauthorized Profile', 'LoggedIn Profile']

export const setTabs = async (
  screenName: string,
  tabBar: TabBar,
  screenTabBar: HTMLElement,
  fontFamily: string,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  resources: Resource[],
  language: string,
  getVariableValue: GetVariableValue,
  onClickComponent?: (tabScreen: string) => void,
  setHoveredId?: Dispatch<SetStateAction<string>>
  // setMovableTabComponent?: (config: TabBarItem) => void
) => {
  const fromVB = !!(
    onClickComponent &&
    // && setMovableTabComponent
    setHoveredId
  )
  const { tabs = [] } = tabBar
  const isSignedOut = screenName === profieTabs[0]
  const isAnonymous = screenName === profieTabs[1]
  const hasUserAccount = !isSignedOut && !isAnonymous
  const visibleTabs: TabBarItem[] = []
  for (let i = 0; i < tabs.length; i++) {
    const tab = tabs[i]
    const { visible } = tab
    if (
      (visible.source?.variableName === 'isSignedOut' && isSignedOut) ||
      (visible.source?.variableName === 'isAnonymous' && isAnonymous) ||
      (visible.source?.variableName === 'hasUserAccount' && hasUserAccount) ||
      visible.constant
    ) {
      visibleTabs.push(tab)
    }
  }
  for (const el of visibleTabs) {
    const { image, title, screen } = el
    const isActive = screen === screenName

    const tab = document.createElement('div')
    screenTabBar.appendChild(tab)

    tab.id = `${BarComponentType.tab}.${screen}.${screen}`
    tab.className = screenComponentClassName
    tab.style.position = 'relative'
    tab.style.display = 'flex'
    tab.style.flexDirection = 'column'
    tab.style.alignItems = 'center'
    tab.style.gap = '4px'
    tab.style.width = `calc(100% / ${tabs.length})`

    if (fromVB) {
      tab.ondblclick = e => e.stopPropagation()
      // let timer: NodeJS.Timeout | null = null
      tab.onmousedown = e => {
        e.stopPropagation()
        onClickComponent(screen)
        // timer = setTimeout(() => setMovableTabComponent(el), 600)
      }
      // tab.onmouseup = () => timer && clearTimeout(timer)
      tab.onmouseover = e => {
        e.stopPropagation()
        setHoveredId(screen)
      }
      tab.onmouseleave = e => {
        e.stopPropagation()
        setHoveredId('')
      }
    }

    const width = 24
    const imageElement = document.createElement('img')
    imageElement.style.pointerEvents = 'none'
    imageElement.style.width = `${width}px`
    imageElement.style.height = `${width}px`
    imageElement.style.maxHeight = '100%'
    imageElement.style.objectFit = 'contain'
    const url = await getMediaResourceUrl(
      resources,
      width * 3,
      getVariableValue,
      image,
      { constant: isActive ? `@${BrandingColorType.primary}` : `@${BrandingColorType.neutral}` },
      theme,
      colorStyles
    )
    if (fromVB) {
      imageElement.setAttribute('src', url)
    } else {
      await urlToBase64(url, res => imageElement.setAttribute('src', res))
    }
    tab.appendChild(imageElement)

    const textElement = document.createElement('div')
    textElement.style.pointerEvents = 'none'
    textElement.style.fontFamily = fontFamily
    textElement.style.lineHeight = '12px'
    textElement.style.fontSize = '10px'
    textElement.style.textAlign = 'center'
    textElement.style.color = isActive ? colorStyles.primary[theme] : colorStyles.neutral[theme]
    textElement.innerHTML = await getTextValue(language, getVariableValue, title)
    tab.appendChild(textElement)
  }
}
