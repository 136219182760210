import styled from 'styled-components'

export const SubTitle = styled.p`
  color: ${props => props.theme.colors.darkBlue_500};
  margin-top: 10px;
  font-size: 14px;
  line-height: 21px;
  & b {
    font-weight: 600;
  }
`
