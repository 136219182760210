import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 30px;
`

export const Name = styled.h1`
  margin-bottom: 10px;
  color: ${props => props.theme.colors.darkText};
  font-weight: ${props => props.theme.weights.bold};
  font-size: 18px;
  line-height: 27px;
`

export const DropDowns = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 33px;
  & > span {
    margin-right: 10px;
  }
`

export const File = styled.div`
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 4px;
  overflow: hidden;
`

export const FileHead = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  padding: 12px 14px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  background-color: ${props => props.theme.colors.primaryBackground};
  & svg {
    min-width: 16px;
  }
`

export const FileCrumbs = styled.div`
  & i {
    color: ${props => props.theme.colors.grey1};
    font-size: 14px;
    margin-right: 4px;
    transition: ${props => props.theme.transition.primary};
    &:last-child {
      pointer-events: none;
      color: ${props => props.theme.colors.darkText};
      font-weight: ${props => props.theme.weights.bold};
    }
    &:hover {
      cursor: pointer;
      color: ${props => props.theme.colors.darkText};
    }
  }
`

export const ImageWrapper = styled.div<{ ai?: boolean; isLimitedAccess: boolean }>`
  width: 100%;
  height: calc(100vh - ${props => (props.ai ? 256 : props.isLimitedAccess ? 477 : 427)}px);
  display: flex;
  justify-content: center;
  padding: 40px 20px;
`

export const CodeWrapper = styled.div<{ ai?: boolean; isLimitedAccess: boolean }>`
  width: 100%;
  height: calc(100vh - ${props => (props.ai ? 256 : props.isLimitedAccess ? 477 : 427)}px);
`
