import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import * as styled from './Repository.styled'

import { GithubButton, GithubConnectionModal, Text, Type } from 'components'
import { RepositoryContext } from 'context'
import { IntegrationId, useProjectIntegration } from 'hooks'
import { Navigation } from './components'

interface Props {
  settings?: boolean
}

export const Repository: React.FC<Props> = ({ settings }) => {
  const githubIntegration = useProjectIntegration(IntegrationId.github)

  useEffect(() => {
    if (!settings) {
      document.title = 'Repository | CodePlatform'
    }
  }, [settings])

  return (
    <>
      <styled.Container settings={settings}>
        <styled.Content settings={settings}>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
            <Text type={Type.TITLE}>Repository</Text>
            <GithubButton githubIntegration={githubIntegration} />
          </Box>
          <Text type={Type.SUB_TITLE}>Manage your app’s source code and build pipelines.</Text>
          <Navigation settings={settings} />
          <RepositoryContext.Provider value={{ githubIntegration }}>
            <Outlet />
          </RepositoryContext.Provider>
        </styled.Content>
      </styled.Container>
      {githubIntegration ? <GithubConnectionModal githubIntegration={githubIntegration} /> : <></>}
    </>
  )
}
