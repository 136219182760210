import styled from 'styled-components'

export const Container = styled.div`
  padding: 30px 90px;
  width: 100%;
  height: 100%;
  overflow: hidden scroll;
  min-width: 768px;
  background-color: ${props => props.theme.colors.secondaryBackground};
`

export const MaxWidthContainer = styled.div<{ maxWidth?: number }>`
  max-width: ${props => props.maxWidth || 1280}px;
  margin: 0 auto;
  & > p {
    max-width: 740px;
  }
`
