import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'

import { httpClient } from 'utils'

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID || '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  measurementId: process.env.REACT_APP_FIREBASE_MEASHUREMENT_ID || '',
}

const app = firebase.initializeApp(firebaseConfig)

export const firestore = firebase.firestore(app)

export const database = firebase.database(app)

export const auth = firebase.auth()

const googleProvider = new firebase.auth.GoogleAuthProvider()
googleProvider.setCustomParameters({ prompt: 'select_account' })

export const signInWithGoogle = () => auth.signInWithPopup(googleProvider)

export default firebase

export const readableError = (err: string) => err.split(' (')[0]?.replace('Firebase: ', '')

export const migrate = (userId: string, anonymousUserIdToken: string) => {
  const url = `/users/${userId}/migrate`
  return httpClient.post(url, { anonymousUserIdToken })
}
