export const sectionsSubTitles: { [key: string]: string } = {
  'Launch screen & Icons':
    "This is the first screen users see when the app is started. Choose Icon and Brand template to indicate a parent company if needed. App icons will also be used while you're uploading the application via Store Manager.",
  Walkthrough:
    'This is the place for users to get the first look of your app. Briefly explain the benefits and functionality of your app here. Try not to overload these slides with unnecessary data, but rather showcase the killer features of your app that might interest the user.',
  'Before you join':
    'Inform your users about important terms & conditions, 3rd party subprocessors and other regulations or policies they have to know and consent to before signing into the app. You can deactivate this screen if necessary.',
  'Authentication providers': `Select the most relevant authentication providers and list them in order of priority. You may also allow users an option to skip authentication if your app is partially or fully used without signing in. Anonymous session will be created by default for seamless account upgrade in the future if you decide to enable sign-up functionality in the next versions.`,
  'Email authentication':
    'The email authentication flow includes sign-in/sign-up, email verification, resetting the password and account pre-fill.',
  Paywall:
    "All the paywall screen templates are designed in accordance with app stores guidelines and market-proven practices to achieve higher conversion. Use CodePlatform's Monetization to set up and manage subscriptions with ease. Inject Paywall into Onboarding flow or restrict the access to specific screens in their settings.",
  'Push notifications': `Soft push notifications requests minimize the rate of rejections when users click 'Don't Allow' as an afterthought. Explain why and what kind of notifications your app sends before showing the system alert.<br />Push notification request is integrated into the onboarding flow triggered by the log-in.<br />Notification control is integrated into user profile settings. In case of denial, user profile settings provide a deep link and an instruction on how to re-enable push notifications.`,
  'Camera & Gallery access':
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  'IDFA tracking':
    "Soft IDFA tracking requests minimize the rate of rejections when clicking 'Ask App Not to Track' as an afterthought. Explain why tracking is vital for your app and what benefits users get.",
  'Home screen': `This is your application home. Tailor its content to your needs visually or by changing the source code.<br />We recommend using Tab Bar for navigation. This is one of the most commonly used and habitual patterns for large screens.`,
  'Profile screens':
    'This is a universal user profile which is designed to collect features that are not frequently used, but related to users (e.g. sign out, settings, subscriptions, support, terms & conditions, notification management, etc.). App name replaces user avatar in case authentication is disabled. Tailor the content of the screens to your needs visually or by changing the source code.',
  Settings:
    "Light and dark mode behavior, notifications management and other settings are gathered on this screen. It also contains app versions and build numbers, which are relevant for testing. You may remove the 'Made with CodePlatform' sign if you wish.",
  'Custom screens': "Here are the screens that didn't make it to the modules or were built with source code.",
}
