import { Portal } from '@mui/material'
import { memo, useContext, useState } from 'react'

import * as styled from './RefField.styled'

import { FieldWrapper, Icon, Name, RemoveContent } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { LogicItemRecord } from 'pages/Settings/pages/Logic/pages'
import { CMSCollectionRecord } from 'services/cms'
import { LogicService } from 'services/logic'
import { ConfigLogic } from 'utils'
import { FieldProps } from '../../types'
import { ReferenceItems } from './ReferenceItems'

export const LogicItemField = memo((props: FieldProps) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id },
    config: { logic },
  } = useContext(ProjectContext)
  const arrayValue = logic[props.name as keyof ConfigLogic]?.map(el => ({ id: el.name, ...el })) || []
  const [toRemove, setToRemove] = useState<CMSCollectionRecord | null>(null)
  const [openAddNew, setOpenAddNew] = useState(false)
  const [recordId, setRecordId] = useState('')
  const singularName = props.name.slice(0, -1)

  const remove = (callback: () => void) => {
    if (toRemove) {
      startLoader()
      LogicService.deleteLogicItemRecord(id, props.name, toRemove.id)
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
        })
    }
  }

  return (
    <>
      <FieldWrapper
        {...props}
        children={props => (
          <>
            <styled.RefFieldInput
              onClick={e => {
                e.preventDefault()
                setOpenAddNew(open => !open)
              }}
            >
              <span>
                <Icon name={Name.RIGHT_SIDEBAR_ADD} />
                Add {props.name}
              </span>
            </styled.RefFieldInput>
            <ReferenceItems
              values={arrayValue}
              onChange={props.onChange}
              remove={val => setToRemove(val as CMSCollectionRecord)}
              open={setRecordId}
              name={props.name}
              logicItem
            />
          </>
        )}
      />
      {(openAddNew || recordId) && (
        <Portal>
          <LogicItemRecord
            name={props.name as keyof ConfigLogic}
            recordId={recordId}
            closeAddRef={() => {
              setOpenAddNew(false)
              setRecordId('')
            }}
          />
        </Portal>
      )}
      <RemoveContent
        toRemove={toRemove}
        title={`Delete ${singularName}`}
        text={`You are about to delete the ${singularName} <b>{name}</b>. To make sure, enter the ${singularName} name below`}
        clean={() => setToRemove(null)}
        remove={remove}
        nameConfirmation
      />
    </>
  )
})
