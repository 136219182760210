import { Box } from '@mui/material'
import React from 'react'

import { BooleanInput, CommonPicker, CoordinateInput, Icon, Name, SourceInput } from 'components'
import { removeOldLocalVariable } from 'hooks'
import {
  RightSidebarItem,
  SettingField,
  SettingNumberField,
  SettingTextField,
  SubWrapper,
} from 'partials/RightSidebar/components'
import { getAllLocalVariables, LocalVariable, MapStyle, Resource, Screen, ScreenComponent, VariableSource } from 'utils'
import { Common, Id } from '../common'

const mapStyleValues = Object.values(MapStyle).map(el => ({ value: el, label: el }))

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const MapSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const {
    id,
    componentType,
    name,
    mapStyle,
    zoom,
    showUserLocation,
    coordinate,
    listItems,
    listItemContextKey,
    listItemCoordinate,
  } = component

  const onDataChange = (value?: VariableSource) => {
    if (listItemContextKey) {
      removeOldLocalVariable(screenConfig, id, listItemContextKey)
    }
    onComponentPropertiesChange([{ propName: 'listItems', value: { source: value } }])
  }

  const onListItemContextKeyChange = (value: string) => {
    if (listItemContextKey) {
      removeOldLocalVariable(screenConfig, id, listItemContextKey)
    }
    onComponentPropertiesChange([{ propName: 'listItemContextKey', value }])
  }

  const itemsSectionName = 'Item'

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        <CoordinateInput
          label="Coordinate"
          value={coordinate?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'coordinate', value: { constant: value } }])}
          source={coordinate?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'coordinate', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SourceInput
          label="Data"
          source={listItems?.source}
          onSourceChange={onDataChange}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
          dataMode
        />
        <SettingTextField label="Item context key" value={listItemContextKey} onChange={onListItemContextKeyChange} />
        <SettingField
          label="Style"
          value={mapStyle}
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
          optionsContainer={
            <CommonPicker
              title="Styles"
              value={mapStyle}
              onChange={value => onComponentPropertiesChange([{ propName: 'mapStyle', value }])}
              values={mapStyleValues}
              close={() => {}}
            />
          }
        />
      </SubWrapper>
      <SubWrapper title="Components" canHide childrenFor={componentType}>
        <Box display="flex" flexDirection="column" gap="12px">
          <RightSidebarItem small title={itemsSectionName}>
            <SubWrapper small childrenFor={itemsSectionName}>
              <CoordinateInput
                label="Data"
                valueDescription={`<b>coordinate</b> - `}
                value={listItemCoordinate?.constant}
                onChange={value =>
                  onComponentPropertiesChange([{ propName: 'listItemCoordinate', value: { constant: value } }])
                }
                source={listItemCoordinate?.source}
                onSourceChange={value =>
                  onComponentPropertiesChange([{ propName: 'listItemCoordinate', value: { source: value } }])
                }
                screenConfig={screenConfig}
                allLocalVariables={getAllLocalVariables(screenConfig, component, true)}
              />
            </SubWrapper>
          </RightSidebarItem>
        </Box>
      </SubWrapper>
      <SubWrapper title="Options" childrenFor={componentType} canHide>
        <SettingNumberField
          subLabel="Zoom"
          value={zoom?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'zoom', value: { constant: value } }])}
          source={zoom?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'zoom', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <BooleanInput
          subLabel="Show user location"
          value={showUserLocation?.constant}
          onChange={value =>
            onComponentPropertiesChange([{ propName: 'showUserLocation', value: { constant: value } }])
          }
          source={showUserLocation?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'showUserLocation', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
        hideLocalVariables
        hideActions
      />
    </RightSidebarItem>
  )
}
