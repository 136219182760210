import { useEffect, useState } from 'react'

import { Modal, ProjectSetup, openProjectSetupModalEvent } from 'components'

export const ProjectSetupModal: React.FC = () => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    const openModal = () => setActive(true)
    document.addEventListener(openProjectSetupModalEvent, openModal)
    return () => document.removeEventListener(openProjectSetupModalEvent, openModal)
  }, [])

  return (
    <Modal
      active={active}
      title="Preparing you project"
      subTitle="This process can take up to 10 minutes."
      onClose={() => setActive(false)}
      width="580px"
    >
      <ProjectSetup />
    </Modal>
  )
}
