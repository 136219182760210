import { Box } from '@mui/material'
import { marked } from 'marked'
import React, { ReactElement } from 'react'

import * as styled from './ChatMessage.styled'

import { Message } from 'services/conversations'
import { DraftProject } from './components'

const getText = (message: Message) => {
  const attachmentStyle = 'width: 32px; height: 32px; border-radius: 6px; margin: -5px 10px -5px -5px; float: left;'
  let text = message.body || ''
  let isThinking = false
  if (message.format === 'markdown') {
    text = marked(text, { async: false }) as string
  }
  if (message.iconAttachment) {
    text = `<img src="${message.iconAttachment.url}" alt="icon" style="${attachmentStyle}" />` + text
  }
  if (message.colorAttachment) {
    text = `<div style="background: ${message.colorAttachment.color}; ${attachmentStyle}"></div>` + text
  }
  if (message.status === 'generating') {
    isThinking = !text
    text = isThinking ? 'is thinking...' : `${text}⚫`
  }
  return { text, isThinking }
}

interface ChatMessageProps {
  message: Message
  widgetElement?: ReactElement
  buttons?: ReactElement
  id: string | null
  setId: React.Dispatch<React.SetStateAction<string | null>>
  isXL: boolean
}

export const ChatMessage: React.FC<ChatMessageProps> = ({ message, widgetElement, buttons, id, setId, isXL }) => {
  const { text, isThinking } = getText(message)

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <styled.ChatMessage isThinking={isThinking}>
        {message.authorId === 'ai-agent' ? (
          <img src="/assets/chat-codeplatform.svg" alt="AI App Builder" />
        ) : (
          <img src="/assets/emoji/1.png" alt="User" />
        )}
        <styled.ChatMessageText dangerouslySetInnerHTML={{ __html: text }} />
      </styled.ChatMessage>
      {message.projectDraftAttachment && (
        <DraftProject projectDraftAttachment={message.projectDraftAttachment} id={id} setId={setId} isXL={isXL} />
      )}
      {widgetElement}
      {buttons}
    </Box>
  )
}

interface UserMessageProps {
  message: Message
  editMode: boolean
  setEditMode: () => void
}

export const UserMessage: React.FC<UserMessageProps> = ({
  message,
  // editMode, setEditMode
}) => {
  const { text } = getText(message)

  return (
    <styled.UserMessage>
      {/* {!editMode && <Icon name={Name.REPOSITORY_EDIT} onClick={setEditMode} />} */}
      <styled.UserMessageText
        dangerouslySetInnerHTML={{ __html: text }}
        pending={message.status === 'pending'}
        error={message.status === 'error' || message.status === 'failed'}
      />
    </styled.UserMessage>
  )
}
