import { Box } from '@mui/material'
import React, { useContext, useMemo } from 'react'

import { AnyVariableInput, CommonPicker, getFieldIcon, Icon, Name } from 'components'
import { ProjectContext } from 'context'
import { RightSidebarItem, SettingField, SettingTextField, SubWrapper } from 'partials/RightSidebar/components'
import { Action, InputParameter, LocalVariable, Screen, ValueType } from 'utils'

export const isParametersNotValid = (parameters?: InputParameter[]) => !!parameters?.find(el => !el.name || !el.value)

interface Props {
  screenName?: string
  flowName?: string
  parameters?: InputParameter[]
  setAction: React.Dispatch<React.SetStateAction<Action>>
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const Parameters: React.FC<Props> = ({
  screenName,
  flowName,
  parameters,
  setAction,
  screenConfig,
  allLocalVariables,
}) => {
  const {
    config: {
      screens = [],
      logic: { flows = [] },
    },
  } = useContext(ProjectContext)

  const parametersNamesValues = useMemo(
    () =>
      screenName
        ? screens
            .find(el => el.screenName === screenName)
            ?.inputParameters?.map(el => ({
              value: el.parameter,
              label: el.parameter,
              iconName: getFieldIcon(el.type),
              type: el.type,
              accept: el.accept,
            })) || []
        : flowName
        ? flows
            .find(el => el.name === flowName)
            ?.input.properties?.map(el => ({
              value: el.name,
              label: el.name,
              iconName: getFieldIcon(el.type),
              type: el.type,
              accept: el.accept,
            })) || []
        : [],
    [screenName, flowName, JSON.stringify(screens), JSON.stringify(flows)]
  )

  const onAdd = () =>
    setAction(action => ({
      ...action,
      parameters: [...(action.parameters || []), {}],
    }))

  const onRemove = (index: number) =>
    setAction(action => {
      const copy = JSON.parse(JSON.stringify(action)) as Action
      copy.parameters?.splice(index, 1)
      if (copy.parameters && !copy.parameters.length) {
        delete copy.parameters
      }
      return copy
    })

  const onChange = (index: number, parameter: InputParameter) =>
    setAction(action => {
      const copy = JSON.parse(JSON.stringify(action)) as Action
      copy.parameters?.splice(index, 1, parameter)
      return copy
    })

  return (
    <SubWrapper small noBorderBottom title="Parameters" onAdd={onAdd} canHide={!!parameters?.length} childrenFor="">
      {parameters && (
        <Box display="flex" flexDirection="column" gap="12px">
          {parameters.map((el, i) => (
            <RightSidebarItem key={`${el.name}${i}`} small title="">
              <SubWrapper
                small
                title="PARAMETER"
                childrenFor=""
                customIcon={<Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onRemove(i)} />}
              >
                {screenName || flowName ? (
                  <>
                    <SettingField
                      insidePicker
                      subLabel="Name"
                      value={el.name}
                      small
                      optionsContainer={
                        <CommonPicker
                          title="Names"
                          value={el.name}
                          onChange={value => onChange(i, { name: value })}
                          values={parametersNamesValues}
                          close={() => {}}
                        />
                      }
                    />
                    <AnyVariableInput
                      subLabel="Value"
                      valueType={parametersNamesValues.find(a => a.value === el.name)?.type}
                      value={el.value}
                      onChange={value => onChange(i, { ...el, value })}
                      screenConfig={screenConfig}
                      allLocalVariables={allLocalVariables}
                    />
                  </>
                ) : (
                  <>
                    <SettingTextField
                      insidePicker
                      subLabel="Name"
                      value={el.name}
                      onChange={value => onChange(i, { ...el, name: value })}
                      small
                    />
                    <AnyVariableInput
                      subLabel="Value"
                      valueType={ValueType.any}
                      value={el.value}
                      onChange={value => onChange(i, { ...el, value })}
                      screenConfig={screenConfig}
                      allLocalVariables={allLocalVariables}
                    />
                  </>
                )}
              </SubWrapper>
            </RightSidebarItem>
          ))}
        </Box>
      )}
    </SubWrapper>
  )
}
