import React, { useEffect, useState } from 'react'

import { AIModal } from 'components'
import { useParamsFromUrl } from 'hooks'

export const AI: React.FC = () => {
  const { conversationId, intent } = useParamsFromUrl()
  const openAIByDefault = !!(conversationId || intent)
  const [openAI, setOpenAI] = useState(openAIByDefault)

  useEffect(() => {
    setOpenAI(openAIByDefault)
  }, [openAIByDefault])

  return <AIModal initialMessage={!openAI} />
}
