import { GetVariableValue } from 'hooks'
import { BooleanVariable, ListStyle, StringVariable, VariableSourceType } from 'utils'
import {
  listEmptyName,
  listItemName,
  listItemNameCopy,
  listSectionHeaderName,
  listSectionHeaderNameCopy,
} from './index'

const currentEmptyMode: { [key: string]: boolean } = {}

const getListItems = (list: HTMLElement, reversed?: boolean) => {
  const listItems = Array.from(
    list.querySelectorAll(
      `:scope > [id*="${listItemName}"], :scope > [id*="${listItemNameCopy}"], :scope > [id*="${listSectionHeaderName}"], :scope > [id*="${listSectionHeaderNameCopy}"]`
    )
  ) as HTMLElement[]
  return reversed ? listItems.reverse() : listItems
}

export const listHandler = (list: HTMLElement, listStyle?: ListStyle) =>
  setEmptyMode(list, listStyle, currentEmptyMode[list.id])

export const setEmptyMode = (list: HTMLElement, listStyle?: ListStyle, set?: boolean) => {
  const listEmpty = list.querySelector(`:scope > [id*="${listEmptyName}"]`) as HTMLElement | null
  const listItems = getListItems(list)
  if (listEmpty) {
    if (set) {
      listItems.forEach(el => (el.hidden = true))
      listEmpty.removeAttribute('hidden')
      currentEmptyMode[list.id] = true
    } else {
      listItems.forEach(el => el.removeAttribute('hidden'))
      listEmpty.hidden = true
      delete currentEmptyMode[list.id]
    }
  }
  if (listStyle === ListStyle.gallery) {
    list.style.scrollSnapType = 'x mandatory'
    listItems.forEach(el => (el.style.scrollSnapAlign = 'center'))
  }
}

export const listGalleryHandler = async (
  list: HTMLElement,
  getVariableValue: GetVariableValue,
  value?: StringVariable,
  reversed?: BooleanVariable,
  name?: string
) => {
  const valueValue = value?.source ? await getVariableValue(value.source) : value?.constant
  const reversedValue = reversed?.source ? (await getVariableValue(reversed.source)) === 'true' : !!reversed?.constant
  const listItems = getListItems(list, reversedValue)
  const currentValue = valueValue ? +valueValue : reversedValue ? listItems.length - 1 : 0
  const listItem = listItems[currentValue]
  if (listItem) {
    const { offsetLeft, offsetTop } = listItem
    list.scrollTo(offsetLeft, offsetTop)
  }
  const { x, y } = list.getBoundingClientRect()
  let valueToSet = '0'
  for (let i = 0; i < listItems.length; i++) {
    const listItem = listItems[i]
    const { x: iX, y: iY } = listItem.getBoundingClientRect()
    if (x <= iX && y <= iY) {
      valueToSet = String(i)
      break
    }
  }
  await getVariableValue({ type: VariableSourceType.component, componentName: name, fieldName: 'value' }, valueToSet)
}
