import { TableCell, TableRow } from '@mui/material'
import React, { memo, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { Checkbox, getField, Status } from 'components'
import { Preview } from 'components/FieldTypes/components/RefField/ReferenceItems'
import { ProjectContext } from 'context'
import { Folder } from 'context/types'
import { CMSCollectionProperty, CMSCollectionRecord } from 'services/cms'
import { Collection } from 'utils'
import { Actions } from './Actions'

interface Props {
  page: string
  record: CMSCollectionRecord
  collectionId: string
  currentCollection: Collection
  currentFolder?: Folder
  selectedRecords: CMSCollectionRecord[]
  setSelectedRecords: React.Dispatch<React.SetStateAction<CMSCollectionRecord[]>>
  refreshRecords: () => void
  activeColumns: CMSCollectionProperty[]
  setToRemove: React.Dispatch<React.SetStateAction<CMSCollectionRecord | null>>
  small?: boolean
}

export const Row: React.FC<Props> = memo(
  ({
    page,
    record,
    currentCollection,
    collectionId,
    currentFolder,
    selectedRecords,
    setSelectedRecords,
    refreshRecords,
    activeColumns,
    setToRemove,
    small,
  }) => {
    const themeContext = useContext(ThemeContext)
    const {
      project: { id },
    } = useContext(ProjectContext)
    const navigate = useNavigate()

    const openRecord = (recordId: string) =>
      navigate(
        `/projects/${id}/${page}/${collectionId}/${recordId}${currentFolder ? `?folder=${currentFolder.id}` : ''}`
      )

    const onSelectRecordClick = (val: boolean, record: CMSCollectionRecord) =>
      val
        ? setSelectedRecords(selectedRecords => [...selectedRecords, record])
        : setSelectedRecords(selectedRecords => selectedRecords.filter(selectedRecord => selectedRecord !== record))

    return (
      <TableRow
        onClick={() => openRecord(record.id)}
        sx={{
          background: selectedRecords.includes(record) ? themeContext.colors.darkBlue_50 : 'transparent',
        }}
      >
        <TableCell onClick={e => e.stopPropagation()}>
          <Checkbox value={selectedRecords.includes(record)} onChange={value => onSelectRecordClick(value, record)} />
        </TableCell>
        {currentFolder && (
          <TableCell>
            <Preview asset={record} small />
          </TableCell>
        )}
        {activeColumns.map(property => (
          <TableCell key={property.id}>{getField(property.type)?.forTable(record[property.name])}</TableCell>
        ))}
        <TableCell>
          <Status status={record.recordStatus} />
        </TableCell>
        <Actions
          page={page}
          currentCollection={currentCollection}
          collectionId={collectionId}
          record={record}
          setSelectedRecords={setSelectedRecords}
          refreshRecords={refreshRecords}
          setToRemove={setToRemove}
          openRecord={openRecord}
          small={small}
        />
      </TableRow>
    )
  }
)
