import { withErrorBoundary } from '@sentry/react'
import React, { memo } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { VersionController } from 'components'
import { Bridge, GithubConnection } from 'pages'
import { Layout } from 'partials'
import { NotFound, SomethingWentWrong } from 'partials/LandingLayout/pages'
import { getRoutes } from 'routes'

const Root: React.FC = () => (
  <BrowserRouter>
    <VersionController />
    <Routes>
      <Route path="/" element={<Layout />}>
        {getRoutes()}
      </Route>
      <Route path="/bridge" element={<Bridge />} />
      <Route path="/connected" element={<GithubConnection />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
)

export default withErrorBoundary(memo(Root), { fallback: <SomethingWentWrong /> })
