import { Box } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import * as styled from './Logs.styled'

import { Button, Icon, Name } from 'components'
import { ProgressContext, ProjectContext, UserContext } from 'context'
import {
  ActivityItem,
  ActivityItemSmall,
  ActivityItemType,
  Build,
  BuildStatus,
  Pipeline,
} from 'pages/Repository/components'
import { firestore } from 'services/firebase'
import { ProjectsService } from 'services/projects'

interface Props {
  settings?: boolean
}

export const Logs: React.FC<Props> = ({ settings }) => {
  const { user } = useContext(UserContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const { buildNumber } = useParams()
  const ref = useRef<HTMLPreElement>(null)
  const [build, setBuild] = useState<Build | null>(null)
  const [pipelines, setPipelines] = useState<Pipeline[]>([])
  const [activePipelineUuid, setActivePipelineUuid] = useState('')
  const [log, setLog] = useState('')
  const [wait, setWait] = useState(true)

  useEffect(() => {
    startLoader()
    const unsubscribe = firestore
      .collection(`projects/${id}/builds`)
      .where('build_number', '==', +(buildNumber as string))
      .onSnapshot({
        next: res => {
          const builds = res.docs.map(el => ({ id: el.id, ...el.data() })) as Build[]
          setBuild(builds[0])
          stopLoader()
        },
        error: err => {
          toast(err)
          stopLoader()
        },
      })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (build) {
      let timer: NodeJS.Timeout | null = null
      startLoader()
      const getSteps = () =>
        ProjectsService.getPipelineSteps(id, build.uuid)
          .then(res => {
            const pipelines = res.data.values as Pipeline[]
            setPipelines(pipelines)
            const activePipelineUuid =
              pipelines.find(el => !el.state.result)?.uuid || pipelines.slice(-1)[0]?.uuid || ''
            setActivePipelineUuid(activePipelineUuid)
            if (!!pipelines.length && pipelines.filter(el => !!el.state.result).length === pipelines.length) {
              if (timer) {
                clearInterval(timer)
              }
              setWait(false)
            } else if (!timer) {
              timer = setInterval(getSteps, 5000)
            }
            return getLogs(activePipelineUuid)
          })
          .catch(err => toast(err))
          .finally(() => stopLoader())
      getSteps()
      return () => {
        if (timer) {
          clearInterval(timer)
        }
      }
    }
  }, [build])

  useEffect(() => {
    if (!wait) {
      startLoader()
      getLogs(activePipelineUuid)
        .catch(err => toast(err))
        .finally(() => stopLoader())
    }
  }, [activePipelineUuid])

  const getLogs = (activePipelineUuid: string) =>
    ProjectsService.getStepsLogs(id, build?.uuid as string, activePipelineUuid).then(res => {
      setLog(res.data)
      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollTop = ref.current.scrollHeight
        }
      }, 0)
    })

  const download = () => {
    const a = document.createElement('a')
    a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(log))
    a.setAttribute('download', 'log.txt')
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const to = settings ? `/projects/${id}/settings/repository/builds` : `/projects/${id}/repository/builds`

  return (
    <styled.Container>
      <styled.Crumbs>
        <Link to={to}>Builds</Link> / <Link to={to}>#{buildNumber}</Link> / <Link to="#">Build Log</Link>
      </styled.Crumbs>
      {build && <ActivityItem item={build} type={ActivityItemType.BUILD} noView />}
      <Box mt="30px" display="flex" gap="30px" alignItems="flex-start">
        <styled.Pipeline width="265px">
          <styled.PipelineHead>
            <div>Pipeline</div>
          </styled.PipelineHead>
          <styled.PipelineContent isLimitedAccess={user.isLimitedAccess}>
            {pipelines.map((el, i) => (
              <ActivityItemSmall
                key={el.uuid}
                item={el}
                type={ActivityItemType.PIPELINE}
                active={el.uuid === activePipelineUuid}
                onClick={() => !wait && setActivePipelineUuid(el.uuid)}
                line={pipelines[i - 1]?.state.result?.name === BuildStatus.SUCCESSFUL}
              />
            ))}
          </styled.PipelineContent>
        </styled.Pipeline>
        <styled.File>
          <styled.FileHead>
            <div>
              <Icon name={Name.REPOSITORY_FILE} />
              Build Log
            </div>
            <Button onClick={download}>Download Raw</Button>
          </styled.FileHead>
          <styled.FileContent ref={ref} isLimitedAccess={user.isLimitedAccess}>
            {log}
          </styled.FileContent>
        </styled.File>
      </Box>
    </styled.Container>
  )
}
