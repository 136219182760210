import React from 'react'

import * as styled from './Selects.styled'

import { Select } from 'components'

export const kindOfImagesOptions = [
  { value: 'SF Symbols' },
  { value: 'Unsplash' },
  { value: 'Resources' },
  { value: 'AI Generator' },
]
export const categoriesOptions = [
  { value: 'Black' },
  { value: 'Heavy' },
  { value: 'Bold' },
  { value: 'Semibold' },
  { value: 'Medium' },
  { value: 'Regular' },
  { value: 'Light' },
  { value: 'Ultralight' },
  { value: 'Thin' },
]

interface Props {
  currentKindOfImages: string
  setCurrentKindOfImages: React.Dispatch<React.SetStateAction<string>>
  currentCategory: string
  setCurrentCategory: React.Dispatch<React.SetStateAction<string>>
}

export const Selects: React.FC<Props> = ({
  currentKindOfImages,
  setCurrentKindOfImages,
  currentCategory,
  setCurrentCategory,
}) => (
  <styled.Selects>
    <Select
      options={kindOfImagesOptions}
      onClick={setCurrentKindOfImages}
      value={currentKindOfImages}
      wrapperStyle={{ width: '96%' }}
      labelStyle={{ justifyContent: 'space-between' }}
      width="100%"
    />
    <Select
      options={categoriesOptions}
      onClick={setCurrentCategory}
      value={currentCategory}
      wrapperStyle={{ width: '96%' }}
      labelStyle={{ justifyContent: 'space-between' }}
      width="100%"
    />
  </styled.Selects>
)
