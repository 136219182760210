import styled from 'styled-components'

export const ReadOnlyMode = styled.div`
  height: 50px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #dd3e00;
  & > div {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #ffffff;
    &:nth-child(1) {
      width: calc(20%);
      font-weight: 600;
    }
    &:nth-child(2) {
      width: calc(60%);
      justify-content: center;
      text-align: center;
    }
    &:nth-child(3) {
      width: calc(20%);
      justify-content: flex-end;
    }
  }
  @media (max-width: 680px) {
    & > div {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        width: auto;
        justify-content: flex-start;
        text-align: left;
      }
      &:nth-child(3) {
        width: auto;
      }
    }
  }
`
