import { GetVariableValue } from 'hooks'
import { Action, ActionType, getTextValue } from 'utils'

export const runActions = async (language: string, getVariableValue: GetVariableValue, actions: Action[]) => {
  for (let i = 0; i < actions.length; i++) {
    const action = actions[i]
    const { actionType, variable, valueTarget } = action
    if (actionType === ActionType.setValue && valueTarget && variable) {
      const valueToSet = variable.source
        ? await getVariableValue(variable.source)
        : variable.textConstant
        ? await getTextValue(language, getVariableValue, variable.textConstant)
        : variable.booleanConstant !== undefined
        ? String(variable.booleanConstant)
        : variable.numberConstant
      await getVariableValue(valueTarget, valueToSet)
    }
  }
}
