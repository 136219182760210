import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Button, ButtonColor, Image, Text, Type } from 'components'
import { landingUrl } from 'utils'

export const Banner: React.FC = () => {
  const themeContext = useContext(ThemeContext)

  return (
    <Box
      width="100%"
      borderRadius="16px"
      padding="10px"
      marginBottom="16px"
      sx={{
        background: `linear-gradient(135deg, ${themeContext.colors.secondaryBackground} 1.61%, ${themeContext.colors.primaryBackground} 45.88%, ${themeContext.colors.secondaryBackground} 100%);`,
      }}
    >
      <Box display="flex" alignItems="center" columnGap="10px">
        <Image src="/assets/needHand.svg" width="45px" height="45px" />
        <Text type={Type.H3} fontWeight={themeContext.weights.bold} lineHeight="100%">
          Need a hand with development?
        </Text>
      </Box>
      <p style={{ fontSize: '12px', color: themeContext.colors.grey1, marginTop: '10px', lineHeight: '150%' }}>
        We would be happy to help with your next project. Learn more about CodePlatform Experts.
      </p>
      <Button
        color={ButtonColor.SECONDARY}
        style={{ width: '100%', margin: '15px 0 5px' }}
        to={`${landingUrl}/experts?source=banner`}
      >
        Explore experts
      </Button>
    </Box>
  )
}
