import { useFormik } from 'formik'
import { memo, useEffect, useMemo, useState } from 'react'

import * as styled from './RightIcons.styled'

import {
  Button,
  ButtonColor,
  ButtonSize,
  Icon,
  InputProperty,
  inputs,
  Modal,
  ModalActions,
  Name,
  validate,
} from 'components'
import { FieldProps } from '../../types'

interface Props extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const EditByModal = memo((props: Props) => {
  const [active, setActive] = useState(false)

  const close = () => setActive(false)

  return (
    <>
      <styled.RightIcon
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          setActive(true)
        }}
      >
        <Icon name={Name.REPOSITORY_EDIT} />
      </styled.RightIcon>
      <Modal active={active} title="Edit field" onClose={close}>
        <Children {...props} close={close} />
      </Modal>
    </>
  )
})

interface ChildrenProps extends FieldProps {
  close: () => void
}

const Children: React.FC<ChildrenProps> = props => {
  const formik = useFormik({
    initialValues: { [props.property.name]: props.value },
    validate: data => validate(data, properties),
    onSubmit: data => {
      const value = data[props.property.name]
      props.onChange(value)
      props.close()
    },
  })

  useEffect(() => {
    formik.setValues({ [props.property.name]: props.value })
  }, [props.value])

  const properties = useMemo(
    (): InputProperty[] => [
      {
        ...props.property,
        editByModal: false,
      },
    ],
    [props.property]
  )

  return (
    <>
      {inputs(properties, formik)}
      <ModalActions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={props.close}>
          Cancel
        </Button>
        <Button size={ButtonSize.DEFAULT} onClick={formik.submitForm}>
          Save
        </Button>
      </ModalActions>
    </>
  )
}
