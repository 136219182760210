import { createGlobalStyle } from 'styled-components'

import './fonts.css'

import { CodeTheme } from './theme'

export const GlobalStyle = createGlobalStyle`
html, body, #root {
    height:  100%;
}
body {
    font-family: 'Averta', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    color: ${CodeTheme.colors.black};
}
html,body{padding: 0; margin: 0; border: 0}
input,button,textarea,a,a:visited,h1,h2,h3,h4,h5,h6{font-family:inherit;font-size:inherit;font-weight:inherit;line-height:inherit;letter-spacing:inherit;color:inherit;}
h1,h2,h3,h4,h5,h6{margin: 0;}
a,a:visited{text-decoration:none;cursor:pointer;}
a:hover{text-decoration:none;}
ul li{list-style:none;}
`
