import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'

import {
  Button,
  ButtonColor,
  ButtonSize,
  CustomSection,
  InputProperty,
  inputs,
  Modal,
  ModalActions,
  Text,
  Type,
  validate,
} from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { ProjectRole } from 'hooks'
import { ProjectsService } from 'services/projects'
import { ValueType } from 'utils'

export const AddNew: React.FC = () => {
  const themeContext = useContext(ThemeContext)
  const [active, setActive] = useState(false)

  return (
    <CustomSection
      style={{ margin: '40px 0 30px', padding: '15px 25px', backgroundColor: themeContext.colors.secondaryButtonBg }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Text type={Type.H3} as="h2" fontWeight={themeContext.weights.semibold}>
            Add Teammate
          </Text>
          <Text type={Type.H3} as="p" fontWeight={themeContext.weights.normal} color={themeContext.colors.grey1}>
            Invite teammates to collaborate on your project. Ensure they are registered on CodePlatform.
          </Text>
        </Box>
        <>
          <Button height={30} style={{ padding: '4.5px 15px' }} onClick={() => setActive(true)}>
            Add
          </Button>
          <Modal active={active} title="Add new project member" onClose={() => setActive(false)}>
            <Children close={() => setActive(false)} />
          </Modal>
        </>
      </Box>
    </CustomSection>
  )
}

interface ChildrenProps {
  close: () => void
}

const Children: React.FC<ChildrenProps> = ({ close }) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [wait, setWait] = useState(false)

  const formik = useFormik({
    initialValues: { email: null, role: null },
    validate: data => validate(data, properties),
    onSubmit: data => {
      const { email, role } = data
      if (email && role) {
        setWait(true)
        ProjectsService.addProjectMember(id, email, role)
          .then(() => close())
          .catch(err => toast(err))
          .finally(() => setWait(false))
      }
    },
  })

  const properties = useMemo(
    (): InputProperty[] => [
      {
        name: 'email',
        type: ValueType.string,
        position: 0,
        isRequired: true,
      },
      {
        name: 'role',
        type: ValueType.string,
        position: 1,
        isRequired: true,
        optionsToSelect: Object.values(ProjectRole).map(el => ({ value: el, label: el })),
      },
    ],
    []
  )

  return (
    <>
      {inputs(properties, formik)}
      <ModalActions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={close}>
          Cancel
        </Button>
        <Button size={ButtonSize.DEFAULT} onClick={formik.submitForm} loading={wait}>
          Save
        </Button>
      </ModalActions>
    </>
  )
}
