import { useEffect } from 'react'

export const refreshNavigationTreeEvent = 'refreshNavigationTreeEvent'

export const refreshNavigationTree = () => document.dispatchEvent(new Event(refreshNavigationTreeEvent))

export const refreshUserEvent = 'refreshUserEvent'

export const refreshUser = () => document.dispatchEvent(new Event(refreshUserEvent))

export const useLastRefresh = (event: string, callback: () => void) => {
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    const clear = () => timeout && clearTimeout(timeout)
    const refresh = () => {
      clear()
      timeout = setTimeout(callback, 0)
    }
    document.addEventListener(event, refresh)
    return () => {
      clear()
      document.removeEventListener(event, refresh)
    }
  }, [])
}
