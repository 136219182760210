import { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Loader, LoaderVariant, Modal, SignInForm } from 'components'
import { ProgressContext, UserContext } from 'context'
import { refreshUserEvent, useLastRefresh, User } from 'hooks'
import firebase, { auth, firestore, migrate, readableError } from 'services/firebase'

interface Props {
  children: React.ReactNode
}

export const UserProvider: React.FC<Props> = ({ children }) => {
  const { toast } = useContext(ProgressContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const authParamName = 'auth'
  const active = !!searchParams.get(authParamName)
  const [lastUpdate, setLastUpdate] = useState(+new Date())
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null)
  const [user, setUser] = useState<User | null>(null)
  const [anonymousUser, setAnonymousUser] = useState<firebase.User | null>(null)

  useLastRefresh(refreshUserEvent, () => setLastUpdate(+new Date()))

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
      if (!!(firebaseUser && anonymousUser && firebaseUser.uid !== anonymousUser.uid)) {
        setFirebaseUser(null)
        try {
          const anonymousUserIdToken = await anonymousUser.getIdToken()
          await migrate(firebaseUser.uid, anonymousUserIdToken)
          setAnonymousUser(null)
        } catch (err) {
          toast(err)
        }
      }
      setFirebaseUser(firebaseUser)
      if (firebaseUser?.isAnonymous) {
        setAnonymousUser(firebaseUser)
      }
      if (!firebaseUser) {
        auth.signInAnonymously().catch(err => toast(readableError(err.message)))
      } else if (!firebaseUser.isAnonymous) {
        closeSignIn()
      }
    })
    return () => {
      unsubscribe()
    }
  }, [lastUpdate, anonymousUser])

  useEffect(() => {
    if (firebaseUser?.uid) {
      const unsubscribe = firestore
        .collection('users')
        .doc(firebaseUser.uid)
        .onSnapshot({
          next: res => {
            const resData = res.data()
            if (resData) {
              setUser({ id: res.id, ...resData } as User)
            }
          },
          error: err => toast(err),
        })
      return () => {
        unsubscribe()
      }
    }
  }, [firebaseUser?.uid])

  const signIn = () => {
    searchParams.set(authParamName, 'true')
    setSearchParams(searchParams)
  }

  const closeSignIn = () => {
    searchParams.delete(authParamName)
    setSearchParams(searchParams)
  }

  return !firebaseUser || !user ? (
    <Loader variant={LoaderVariant.DOTS} />
  ) : (
    <UserContext.Provider value={{ firebaseUser, user, signIn }}>
      {children}
      {firebaseUser.isAnonymous && (
        <Modal active={active} onClose={closeSignIn}>
          <SignInForm modal />
        </Modal>
      )}
    </UserContext.Provider>
  )
}
