import { useContext, useEffect, useState } from 'react'

import { RemoveContent } from 'components'
import { ProgressContext } from 'context'
import { ProjectsService } from 'services/projects'
import { useParamsFromUrl } from './useParamsFromUrl'

const refreshProjectPlansEvent = 'refreshProjectPlansEvent'
const refreshProjectPlans = () => document.dispatchEvent(new Event(refreshProjectPlansEvent))

export const useProjectPlan = (id: string, billingAccountId: string) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { clearParams } = useParamsFromUrl()
  const [wait, setWait] = useState(true)
  const [activeSubscription, setActiveSubscription] = useState<any>()
  const [plans, setPlans] = useState<any[]>([])
  const [priceIdToChange, setPriceIdToChange] = useState('')
  const [lastRefresh, setLastRefresh] = useState(+new Date())

  useEffect(() => {
    const refreshProjectPlans = () => setLastRefresh(+new Date())
    document.addEventListener(refreshProjectPlansEvent, refreshProjectPlans)
    return () => {
      document.removeEventListener(refreshProjectPlansEvent, refreshProjectPlans)
    }
  }, [])

  useEffect(() => {
    setWait(true)
    setActiveSubscription(null)
    setPlans([])
    setPriceIdToChange('')
    const abortController = new AbortController()
    startLoader()
    Promise.all([
      ProjectsService.getProjectPlans(id, abortController),
      ProjectsService.getProjectSubscriptions(id, abortController)
        .then(res => setActiveSubscription(res.data))
        .catch(err => err.statusCode !== 404 && !abortController.signal.aborted && toast(err)),
    ])
      .then(res => {
        const plans = res[0].data.sort((a: any, b: any) => +a.metadata.weight - +b.metadata.weight)
        return Promise.all(
          plans.map((el: any) => ProjectsService.getProductPrices(billingAccountId, el.id, abortController))
        ).then(res => {
          res.forEach(
            (el, i) =>
              (plans[i].prices = el.data.map((a: any) => {
                const currency = el.headers.get('X-Currency')
                a.currency = currency
                a.unit_amount = a.currency_options[currency]?.unit_amount
                return a
              }))
          )
          setPlans(plans)
          setWait(false)
        })
      })
      .catch(err => !abortController.signal.aborted && toast(err))
      .finally(() => stopLoader())
    return () => {
      abortController.abort()
    }
  }, [id, billingAccountId, lastRefresh])

  const onPlanChange = wait
    ? undefined
    : (priceId: string, callback?: () => void) => {
        if (activeSubscription && !priceIdToChange) {
          setPriceIdToChange(priceId)
          return
        }
        startLoader()
        ;(activeSubscription
          ? ProjectsService.updateProjectSubscription(id, activeSubscription.id, priceId)
          : ProjectsService.createProjectSubscription(id, priceId)
        )
          .then(() => {
            clearParams()
            refreshProjectPlans()
          })
          .catch(err => toast(err))
          .finally(() => {
            callback?.()
            stopLoader()
          })
      }

  const ChangePlanAlert = activeSubscription ? (
    <RemoveContent
      toRemove={priceIdToChange}
      title="Change plan"
      text={
        priceIdToChange &&
        `You are about to change the plan from ${
          plans.find((el: any) => el.prices.find((a: any) => a.id === activeSubscription.plan.id))?.metadata.title
        } to ${plans.find((el: any) => el.prices.find((a: any) => a.id === priceIdToChange))?.metadata.title}`
      }
      clean={() => setPriceIdToChange('')}
      remove={callback => onPlanChange?.(priceIdToChange, callback)}
      blue
    />
  ) : null

  return { activeSubscription, plans, onPlanChange, ChangePlanAlert }
}
