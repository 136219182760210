import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { AICommon, Loader, LoaderVariant } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { useHistoryState, useProject } from 'hooks'
import { EmptyTopPanel, TopPanel } from 'partials'
import { database } from 'services/firebase'
import {
  Config,
  getLanguageFromStorage,
  getLanguagesController,
  getThemeFromStorage,
  setLanguagesController,
  themeKey,
} from 'utils'
import { MobileMode } from './components'

interface Props {
  ai?: boolean
  close?: () => void
  id: string
  isMD?: boolean
  isLG?: boolean
  isXL?: boolean
  isXXL?: boolean
  children: React.ReactNode
}

export const FetchProjectWrapper: React.FC<Props> = ({ ai, close, id, isMD, isLG, isXL, isXXL, children }) => {
  const themeContext = useContext(ThemeContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { project, projectWait, projectReady } = useProject(id)
  const [c, setConfig, undo, redo] = useHistoryState({})
  const config: Config = c
  const [configWait, setConfigWait] = useState(true)
  const [isConfigSnapshot, setIsConfigSnapshot] = useState(false)
  const configRef = database.ref(`projects/${id}/configurations/main`)
  const [language, setLanguage] = useState(getLanguageFromStorage(id))
  const [theme, setTheme] = useState(getThemeFromStorage())

  useEffect(() => {
    if (config.localization) {
      const { languages = [], primaryLanguage } = config.localization
      if (!languages.includes(language)) {
        setLanguage(primaryLanguage)
      }
    }
  }, [config.localization])

  useEffect(() => {
    const controller = getLanguagesController()
    controller[id] = language
    setLanguagesController(controller)
  }, [id, language])

  useEffect(() => {
    localStorage.setItem(themeKey, theme)
  }, [theme])

  useEffect(() => {
    setConfig({})
    setConfigWait(true)
    configRef.on('value', snapshot => {
      const config = snapshot.val()
      if (config) {
        console.log('GET MAIN CONFIG')
        setIsConfigSnapshot(true)
        setConfig(config)
        setConfigWait(false)
        setTimeout(() => setIsConfigSnapshot(false), 0)
      }
    })
    return () => {
      configRef.off('value')
    }
  }, [id])

  useEffect(() => {
    if (!ai && !isConfigSnapshot && !configWait) {
      const timer = setTimeout(() => {
        startLoader()
        console.log('SET MAIN CONFIG')
        configRef
          .set(JSON.parse(JSON.stringify(config)) as Config)
          .catch(err => toast(err))
          .finally(() => stopLoader())
      }, 0)
      return () => clearTimeout(timer)
    }
  }, [JSON.stringify(config)])

  return projectWait || configWait ? (
    <>
      <EmptyTopPanel />
      <Box height="calc(100% - 60px)" bgcolor={themeContext.colors.secondaryBackground}>
        <Loader variant={LoaderVariant.DOTS} />
      </Box>
    </>
  ) : (
    <ProjectContext.Provider
      value={{ project, projectReady, config, setConfig, undo, redo, language, setLanguage, theme, setTheme }}
    >
      <TopPanel ai={ai} close={close} isMD={isMD} isLG={isLG} isXL={isXL} isXXL={isXXL} />
      {isXL ? <MobileMode /> : children}
      {!ai && <AICommon />}
    </ProjectContext.Provider>
  )
}
