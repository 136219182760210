import styled from 'styled-components'

export const BuildVariables = styled.div`
  width: 78%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  & > div {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
`

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Actions = styled.div`
  width: 48px;
  flex-shrink: 0;
  display: flex;
  column-gap: 15px;
  justify-content: space-between;
  align-items: center;
  & svg {
    cursor: pointer;
    user-select: none;
    & path,
    & circle {
      transition: 300ms ease-out;
    }
    &:hover {
      & path,
      & circle {
        stroke: ${props => props.theme.colors.darkText};
      }
    }
  }
`
