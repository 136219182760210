import { ReactElement, createContext } from 'react'

import { Integration, Project, User } from 'hooks'
import firebase from 'services/firebase'
import { ThemeType } from 'theme'
import { BrandingThemeType, Collection, Config, ConfigLogic, ScreenComponent, devicesForPicker } from 'utils'
import { Folder, ISelected } from './types'

export const ProgressContext = createContext({
  startLoader: () => {},
  stopLoader: () => {},
  toast: (err: any, detect403StatusCode?: boolean) => {},
  getErrorMessage: (err: any) => '',
})

export const LayoutContext = createContext({
  theme: ThemeType.LIGHT,
  setTheme: (theme: ThemeType) => {},
})

export const UserContext = createContext({
  firebaseUser: {} as firebase.User,
  user: {} as User,
  signIn: () => {},
})

export const TopPanelContext = createContext({
  backButton: null as ReactElement | null,
  setBackButton: (val: ReactElement | null) => {},
  leftButtons: null as ReactElement | null,
  setLeftButtons: (val: ReactElement | null) => {},
  pageLeftButtons: null as ReactElement | null,
  setPageLeftButtons: (val: ReactElement | null) => {},
  pageCenterButtons: null as ReactElement | null,
  setPageCenterButtons: (val: ReactElement | null) => {},
  rightButtons: null as ReactElement | null,
  setRightButtons: (val: ReactElement | null) => {},
  page: null as ReactElement | null,
  setPage: (val: ReactElement | null) => {},
  openStoreManager: false,
  setOpenStoreManager: (val: boolean) => {},
  openLogicItem: null as keyof ConfigLogic | null,
  setOpenLogicItem: (val: keyof ConfigLogic | null) => {},
})

export const ProjectsContext = createContext({
  projects: [] as Project[],
  setWaitProjects: (val: boolean) => {},
  waitProjects: true,
})

export const RepositoryContext = createContext({
  githubIntegration: null as Integration | null,
})

export const CMSContext = createContext({
  rootCollections: [] as Collection[],
  assets: null as Collection | null,
  folders: [] as Folder[],
})

export const MovableComponentsContext = createContext({
  movableComponent: null as ScreenComponent | null,
  setMovableComponent: (config: ScreenComponent | null) => {},
  movableTabBarComponent: false,
  setMovableTabBarComponent: (config: boolean) => {},
  movableTopBarComponent: false,
  setMovableTopBarComponent: (config: boolean) => {},
  // movableTabComponent: null as TabBarItem | null,
  // setMovableTabComponent: (config: TabBarItem | null) => {},
})

export const selectedDefaultState: ISelected = {
  screenId: '',
  innerModeComponentId: '',
  componentId: '',
  subComponentId: '',
  appIcon: false,
  topBarId: '',
  tabBarId: '',
  tabScreen: '',
}
export const DeviceSettingsContext = createContext({
  compression: 0.7,
  device: devicesForPicker[0].value,
  selected: selectedDefaultState,
  setSelected: (val: ISelected | ((prev: ISelected) => ISelected)) => {},
})

export const ProjectContext = createContext({
  project: {} as Project,
  projectReady: false,
  config: {} as Config,
  setConfig: (val: Config | ((prev: Config) => Config)) => {},
  undo: () => {},
  redo: () => {},
  language: '',
  setLanguage: (val: string | ((prev: string) => string)) => {},
  theme: BrandingThemeType.light,
  setTheme: (val: BrandingThemeType | ((prev: BrandingThemeType) => BrandingThemeType)) => {},
})
