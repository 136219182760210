import { marked } from 'marked'
import { useContext } from 'react'
import { format } from 'timeago.js'

import { ProgressContext, ProjectContext } from 'context'
import { CMSCollectionRecord, CMSService, assetsId, profilesId } from 'services/cms'
import {
  Collection,
  CollectionProperty,
  CollectionVariable,
  LocalVariable,
  LocalizedContent,
  Screen,
  ScreenComponent,
  ScreenParameter,
  StringVariable,
  TableQuery,
  ValueType,
  VariableSource,
  VariableSourceType,
  VariableTransform,
  VariableTransformTransform,
  findComponent,
  getTextValue,
} from 'utils'

export type GetVariable = (screenConfig: Screen, listId?: string, indexInList?: number) => GetVariableValue

export type GetVariableValue = (
  source: VariableSource,
  valueToSet?: CollectionWithRecords | string,
  initialValueToSet?: boolean,
  listParams?: { listId: string; listItemContextKey: string; limit?: number }
) => Promise<any>

export interface CollectionWithRecords extends Collection {
  collectionId: string
  records: CMSCollectionRecord[]
  limit?: number
}

let variables: {
  [key: string]: CollectionWithRecords | string
} = {}
let firebaseDatasources: { [key: string]: CMSCollectionRecord } = {}

export const clearVariables = () => {
  variables = {}
  firebaseDatasources = {}
}

export const removeOldLocalVariable = (screenConfig: Screen, listId: string, listItemContextKey: string) => {
  const screenId = screenConfig.id
  delete variables[screenId + listId + listItemContextKey]
}

export const useVariable = () => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
    config: {
      data: { collections = [], globalVariables = [] },
      branding: {
        appName,
        icons: { iosIcon },
      },
      resources = [],
    },
    language,
  } = useContext(ProjectContext)
  const applicationName = appName.locales[language] || ''
  const applicationIcon = resources.find(el => el.id === iosIcon)?.url || ''

  const getVariable: GetVariable = (screenConfig, listId, indexInList) => {
    const getVariableValue: GetVariableValue = async (source, valueToSet, initialValueToSet, listParams) => {
      const { type, collection, fieldName, selector, query, transforms, variableName, componentName } = source
      const getVariableValue = getVariable(screenConfig, listId, indexInList)
      const screenId = screenConfig.id
      switch (type) {
        case VariableSourceType.globalVariable:
          if (variableName) {
            let value = ''
            switch (variableName) {
              case 'currentUserId':
                value = 'CAgla5YRTxbsi71r5VUaArj1d0C3'
                break
              case 'applicationVersion':
                value = 'v.1.0.1 (1)'
                break
              case 'applicationName':
                value = applicationName
                break
              case 'applicationIcon':
                value = applicationIcon
                break
              case 'isSignedOut':
                value = 'false'
                break
              case 'isAnonymous':
                value = 'false'
                break
              case 'hasUserAccount':
                value = 'false'
                break
              case 'isEmailVerified':
                value = 'false'
                break
              case 'accessLevels':
                const globalVariable = globalVariables.find(el => el.variableName === variableName)
                if (globalVariable?.variable.source) {
                  value = await getVariableValue(globalVariable.variable.source)
                }
                break
            }
            return convertFieldToString(
              value,
              { name: '', type: ValueType.string, position: 0 },
              getVariableValue,
              transforms
            )
          }
          return ''
        case VariableSourceType.collection:
          if (collection) {
            const collectionId = await getCurrentCollectionId(collection, getVariableValue)
            const collectionData = collections.find(el => el.name === collection.name)
            if (collectionData) {
              const firstTransform = transforms?.find(el => el.transform === VariableTransformTransform.first)
              const property = collectionData.properties?.find(el => el.name === fieldName)
              const recordId = selector?.source ? await getVariableValue(selector.source) : selector?.constant
              if (listParams) {
                const { listId, listItemContextKey, limit } = listParams
                const name = screenId + listId + listItemContextKey
                const variable = variables[name] as CollectionWithRecords | undefined
                if (
                  !variable ||
                  hasMutableFilter(query) ||
                  variable.collectionId !== collectionId ||
                  variable.limit !== limit
                ) {
                  if (recordId) {
                    const firebaseDatasourceName = `${collectionId}/${recordId}`
                    const set = async (record: CMSCollectionRecord) => {
                      firebaseDatasources[firebaseDatasourceName] = record
                      if (fieldName && record[fieldName] && property) {
                        const subCollection =
                          property.type === ValueType.array &&
                          property.accept === ValueType.record &&
                          collections.find(el => property.collection && el.name === property.collection.name)
                        if (subCollection) {
                          const recordsIds = record[fieldName].map((el: string) => extractRecordRef(el).recordId)
                          if (recordsIds.length) {
                            const set = (records: CMSCollectionRecord[]) => {
                              variables[name] = {
                                ...subCollection,
                                collectionId,
                                records,
                                limit,
                              }
                            }
                            await getRecords(
                              set,
                              subCollection.name,
                              getVariableValue,
                              query,
                              recordsIds,
                              transforms,
                              limit
                            )
                          }
                        }
                      }
                    }
                    if (!firebaseDatasources[firebaseDatasourceName]) {
                      await getRecord(set, collectionId, recordId)
                    } else {
                      await set(firebaseDatasources[firebaseDatasourceName])
                    }
                  } else {
                    const set = (records: CMSCollectionRecord[]) => {
                      variables[name] = {
                        ...collectionData,
                        collectionId,
                        records,
                        limit,
                      }
                    }
                    await getRecords(set, collectionId, getVariableValue, query, undefined, transforms, limit)
                  }
                }
                return variables[name]
              } else if (recordId && fieldName && property) {
                const record = await getFirebaseDatasourceRecord(collectionId, recordId)
                return convertFieldToString(record[fieldName], property, getVariableValue, transforms)
              } else if (recordId && transforms) {
                const record = await getFirebaseDatasourceRecord(collectionId, recordId)
                return convertRecordToString(record, transforms, getVariableValue)
              } else if (recordId && initialValueToSet) {
                const record = await getFirebaseDatasourceRecord(collectionId, recordId)
                return {
                  ...collectionData,
                  collectionId,
                  records: [record],
                }
              } else if (recordId) {
                if (collectionId === assetsId) {
                  const record = await getFirebaseDatasourceRecord(collectionId, recordId)
                  return JSON.stringify(record)
                } else {
                  return `${collectionPrefix}${collectionId}/${recordId}`
                }
              } else if (firstTransform) {
                let variable: CollectionWithRecords | undefined
                const set = (records: CMSCollectionRecord[]) => {
                  variable = {
                    ...collectionData,
                    collectionId,
                    records,
                  }
                }
                await getRecords(set, collectionId, getVariableValue, query, undefined, transforms)
                if (initialValueToSet) {
                  return variable
                } else {
                  const recordId = variable?.records[0]?.id
                  return `${collectionPrefix}${collectionId}/${recordId}`
                }
              } else if (transforms) {
                return convertRecordToString({}, transforms, getVariableValue)
              }
            }
          }
          return ''
        case VariableSourceType.localVariable:
          if (variableName) {
            const innerListItemValue = variables[screenId + listId + variableName + indexInList]
            const listItemsValue = variables[screenId + listId + variableName]
            if (valueToSet !== undefined) {
              let name
              if (initialValueToSet) {
                name =
                  listId && indexInList !== undefined
                    ? screenId + listId + variableName + indexInList
                    : listId
                    ? screenId + listId + variableName
                    : screenId + variableName
              } else {
                name =
                  innerListItemValue !== undefined
                    ? screenId + listId + variableName + indexInList
                    : listItemsValue !== undefined
                    ? screenId + listId + variableName
                    : screenId + variableName
              }
              if (JSON.stringify(variables[name]) !== JSON.stringify(valueToSet)) {
                variables[name] = valueToSet
              }
              return ''
            }
            const name =
              innerListItemValue !== undefined
                ? screenId + listId + variableName + indexInList
                : listItemsValue !== undefined
                ? screenId + listId + variableName
                : screenId + variableName
            const variable = variables[name]
            const value = typeof variable === 'string' && variable
            const collectionData = typeof variable !== 'string' && variable
            if (value || value === '') {
              return convertFieldToString(
                value,
                { name: '', type: ValueType.string, position: 0 },
                getVariableValue,
                transforms
              )
            } else if (collectionData) {
              const { name, collectionId: cId, records } = collectionData
              const collectionId = cId || name
              const record = innerListItemValue !== undefined ? records[0] || {} : records[indexInList || 0] || {}
              const recordId = record.id as string | undefined
              if (recordId) {
                const firebaseDatasourceName = `${collectionId}/${recordId}`
                firebaseDatasources[firebaseDatasourceName] = record
              }
              return getVariableValue(
                {
                  type: VariableSourceType.collection,
                  collection: { name, collectionId },
                  selector: { constant: recordId },
                  fieldName,
                  query,
                  transforms,
                },
                valueToSet,
                initialValueToSet,
                listParams
              )
            }
          }
          return ''
        case VariableSourceType.component:
          if (componentName && fieldName) {
            const name = screenId + componentName + fieldName
            if (valueToSet !== undefined) {
              if (JSON.stringify(variables[name]) !== JSON.stringify(valueToSet)) {
                variables[name] = valueToSet
              }
              return ''
            }
            const variable = variables[name]
            let value = typeof variable === 'string' && variable
            if (!value && value !== '') {
              const fieldValue = findComponent(screenConfig, 'name', componentName)?.[
                fieldName as keyof ScreenComponent
              ]
              if (fieldValue) {
                if (fieldName === 'text') {
                  const text = fieldValue as LocalizedContent
                  value = await getTextValue(language, getVariableValue, text)
                } else if (fieldName === 'value' || fieldName === 'date' || fieldName === 'displayDate') {
                  const number = fieldValue as StringVariable
                  value = number.source ? await getVariableValue(number.source) : number.constant || ''
                }
              } else {
                value = ''
              }
            }
            return convertFieldToString(
              value,
              { name: '', type: ValueType.string, position: 0 },
              getVariableValue,
              transforms
            )
          }
          return ''
        default:
          return ''
      }
    }
    return getVariableValue
  }

  const getFirebaseDatasourceRecord = async (collectionId: string, recordId: string) => {
    const firebaseDatasourceName = `${collectionId}/${recordId}`
    if (!firebaseDatasources[firebaseDatasourceName]) {
      const set = (record: CMSCollectionRecord) => {
        firebaseDatasources[firebaseDatasourceName] = record
      }
      await getRecord(set, collectionId, recordId)
    }
    return firebaseDatasources[firebaseDatasourceName]
  }

  const getRecord = (set: (record: CMSCollectionRecord) => void, collectionId: string, recordId: string) =>
    new Promise(resolve => {
      const setRecord = async (record: CMSCollectionRecord) => {
        await set(record)
        resolve(true)
      }
      CMSService.getRecord(id, collectionId, recordId, true)
        .then(res => setRecord(res.data || {}))
        .catch(err => {
          toast(err)
          setRecord({})
        })
    })

  const getRecords = (
    set: (records: CMSCollectionRecord[]) => void,
    collectionId: string,
    getVariableValue: GetVariableValue,
    query?: TableQuery,
    ids?: string[],
    transforms?: VariableTransform[],
    limit?: number
  ) =>
    new Promise(async resolve => {
      const setRecords = async (records: CMSCollectionRecord[]) => {
        await set(records)
        resolve(true)
      }
      const firstTransform = transforms?.find(el => el.transform === VariableTransformTransform.first)
      CMSService.getRecords(id, collectionId, language, query, ids, firstTransform ? 1 : limit, getVariableValue, true)
        .then(res => setRecords(res.data || []))
        .catch(err => {
          toast(err, true)
          setRecords([])
        })
    })

  const convertFieldToString = async (
    value: any,
    property: CollectionProperty,
    getVariableValue: GetVariableValue,
    transforms?: VariableTransform[]
  ) => {
    let valueToReturn = ''
    const { type, collection } = property
    const fieldTransform = transforms?.find(el => el.transform === VariableTransformTransform.field)
    const formatTimeIntervalTransform = transforms?.find(
      el => el.transform === VariableTransformTransform.formatTimeInterval
    )
    const formatTimeAgoTransform = transforms?.find(el => el.transform === VariableTransformTransform.formatTimeAgo)
    const trimWhitespacesAndNewlinesTransform = transforms?.find(
      el => el.transform === VariableTransformTransform.trimWhitespacesAndNewlines
    )
    const existsTransform = transforms?.find(el => el.transform === VariableTransformTransform.exists)
    const isNotEmptyTransform = transforms?.find(el => el.transform === VariableTransformTransform.isNotEmpty)
    const isEmptyTransform = transforms?.find(el => el.transform === VariableTransformTransform.isEmpty)
    if (value !== undefined && value !== null) {
      if (
        type === ValueType.accessLevel ||
        type === ValueType.color ||
        type === ValueType.string ||
        type === ValueType.url ||
        type === ValueType.json
      ) {
        valueToReturn = value
      } else if (type === ValueType.number) {
        valueToReturn = String(value)
      } else if (
        type === ValueType.boolean ||
        type === ValueType.array ||
        type === ValueType.coordinate ||
        type === ValueType.keyValueMap
      ) {
        valueToReturn = JSON.stringify(value)
      } else if (type === ValueType.richText) {
        valueToReturn = marked(value, { async: false }) as string
      } else if (type === ValueType.date || type === ValueType.dateTime) {
        if (formatTimeAgoTransform) {
          valueToReturn = format(value)
        } else {
          valueToReturn = value
        }
      } else if (
        type === ValueType.image ||
        type === ValueType.video ||
        type === ValueType.audio ||
        type === ValueType.file
      ) {
        if (fieldTransform && fieldTransform.fieldName) {
          valueToReturn = value[fieldTransform.fieldName]
        } else {
          valueToReturn = JSON.stringify(value)
        }
      } else if (type === ValueType.record && collection) {
        valueToReturn = value
        if (fieldTransform && fieldTransform.fieldName) {
          const { collectionId, recordId } = extractRecordRef(valueToReturn)
          valueToReturn =
            fieldTransform.fieldName === 'id'
              ? recordId
              : await getVariableValue({
                  type: VariableSourceType.collection,
                  collection: { ...collection, collectionId },
                  selector: { constant: recordId },
                  fieldName: fieldTransform.fieldName,
                })
        }
      }
    }
    if (formatTimeIntervalTransform && !isNaN(+valueToReturn)) {
      let time = ''
      const fullTime = new Date(+valueToReturn * 1000).toISOString().slice(11, 19)
      const hours = fullTime.slice(0, 2)
      time = hours === '00' ? fullTime.slice(3, 8) : fullTime
      valueToReturn = time.startsWith('0') ? time.slice(1) : time
    }
    if (trimWhitespacesAndNewlinesTransform) {
      valueToReturn = valueToReturn.trim()
    }
    if (existsTransform || isNotEmptyTransform) {
      valueToReturn = !!valueToReturn ? 'true' : 'false'
    }
    if (isEmptyTransform) {
      valueToReturn = !!valueToReturn ? 'false' : 'true'
    }
    valueToReturn = await commonTransforms(valueToReturn, getVariableValue, transforms)
    return valueToReturn
  }

  const convertRecordToString = async (
    record: CMSCollectionRecord,
    transforms: VariableTransform[],
    getVariableValue: GetVariableValue
  ) => {
    const getParticipantName = async (participantId: string, participantNameKeys: string[]) => {
      const fields: string[] = []
      for (const fieldName of participantNameKeys) {
        fields.push(
          await getVariableValue({
            type: VariableSourceType.collection,
            collection: { name: profilesId },
            selector: { constant: participantId },
            fieldName,
          })
        )
      }
      return fields
    }
    let valueToReturn = ''
    const conversationTitleTransform = transforms.find(
      el => el.transform === VariableTransformTransform.conversationTitle
    )
    const conversationImageTransform = transforms.find(
      el => el.transform === VariableTransformTransform.conversationImage
    )
    const identifierTransform = transforms.find(el => el.transform === VariableTransformTransform.identifier)
    const existsTransform = transforms?.find(el => el.transform === VariableTransformTransform.exists)
    const isNotEmptyTransform = transforms?.find(el => el.transform === VariableTransformTransform.isNotEmpty)
    const isEmptyTransform = transforms?.find(el => el.transform === VariableTransformTransform.isEmpty)
    if (conversationTitleTransform) {
      if (conversationTitleTransform.fieldName && record[conversationTitleTransform.fieldName]) {
        valueToReturn = record[conversationTitleTransform.fieldName]
      } else if (
        conversationTitleTransform.participantsKey &&
        record[conversationTitleTransform.participantsKey]?.length === 2
      ) {
        const currentUserId = await getVariableValue({
          type: VariableSourceType.globalVariable,
          variableName: 'currentUserId',
        })
        const participantId = record[conversationTitleTransform.participantsKey].find(
          (el: string) => el !== currentUserId
        )
        if (conversationTitleTransform.participantNameKeys) {
          valueToReturn = (
            await getParticipantName(participantId, conversationTitleTransform.participantNameKeys)
          ).join(' ')
        }
      }
    }
    if (conversationImageTransform) {
      if (conversationImageTransform.fieldName && record[conversationImageTransform.fieldName]?.url) {
        valueToReturn = record[conversationImageTransform.fieldName].url
      } else if (
        conversationImageTransform.participantsKey &&
        record[conversationImageTransform.participantsKey]?.length === 2
      ) {
        const currentUserId = await getVariableValue({
          type: VariableSourceType.globalVariable,
          variableName: 'currentUserId',
        })
        const participantId = record[conversationImageTransform.participantsKey].find(
          (el: string) => el !== currentUserId
        )
        if (conversationImageTransform.participantImageKey) {
          valueToReturn = await getVariableValue({
            type: VariableSourceType.collection,
            collection: { name: profilesId },
            selector: { constant: participantId },
            fieldName: conversationImageTransform.participantImageKey,
          })
          if (!valueToReturn && conversationImageTransform.participantNameKeys) {
            valueToReturn = createAvatar(
              (await getParticipantName(participantId, conversationImageTransform.participantNameKeys))
                .map(el => el[0] || '')
                .join('')
            )
          }
        }
      }
    }
    if (identifierTransform && record.id) {
      valueToReturn = record.id
    }
    if (existsTransform || isNotEmptyTransform) {
      valueToReturn = record.id ? 'true' : 'false'
    }
    if (isEmptyTransform) {
      valueToReturn = record.id ? 'false' : 'true'
    }
    valueToReturn = await commonTransforms(valueToReturn, getVariableValue, transforms)
    return valueToReturn
  }

  const commonTransforms = async (
    valueToReturn: string,
    getVariableValue: GetVariableValue,
    transforms?: VariableTransform[]
  ) => {
    const anyTrueTransform = transforms?.find(el => el.transform === VariableTransformTransform.anyTrue)
    const compareEqualTransform = transforms?.find(el => el.transform === VariableTransformTransform.compareEqual)
    const compareNotEqualTransform = transforms?.find(el => el.transform === VariableTransformTransform.compareNotEqual)
    const boolNotTransform = transforms?.find(el => el.transform === VariableTransformTransform.boolNot)
    const conditionalValueTransform = transforms?.find(
      el => el.transform === VariableTransformTransform.conditionalValue
    )
    if (anyTrueTransform) {
      try {
        const values = JSON.parse(valueToReturn) as any[]
        valueToReturn = !!values.find(el => el === 'true') ? 'true' : 'false'
      } catch {
        valueToReturn = 'false'
      }
    }
    if (compareEqualTransform) {
      if (compareEqualTransform.value?.source) {
        valueToReturn =
          valueToReturn === (await getVariableValue(compareEqualTransform.value.source)) ? 'true' : 'false'
      } else if (compareEqualTransform.value?.textConstant) {
        valueToReturn =
          valueToReturn === (await getTextValue(language, getVariableValue, compareEqualTransform.value.textConstant))
            ? 'true'
            : 'false'
      } else if (compareEqualTransform.value?.calendarStyleConstant) {
        valueToReturn = valueToReturn === compareEqualTransform.value.calendarStyleConstant ? 'true' : 'false'
      }
    }
    if (compareNotEqualTransform) {
      if (compareNotEqualTransform.value?.source) {
        valueToReturn =
          valueToReturn !== (await getVariableValue(compareNotEqualTransform.value.source)) ? 'true' : 'false'
      }
    }
    if (boolNotTransform) {
      valueToReturn = valueToReturn === 'false' ? 'true' : 'false'
    }
    if (conditionalValueTransform) {
      if (conditionalValueTransform.value?.colorConstant && conditionalValueTransform.value2?.colorConstant) {
        const {
          value: { colorConstant: cC1 },
          value2: { colorConstant: cC2 },
        } = conditionalValueTransform
        valueToReturn = valueToReturn === 'true' ? cC1 : cC2
      } else if (conditionalValueTransform.value?.imageConstant && conditionalValueTransform.value2?.imageConstant) {
        const {
          value: {
            imageConstant: { resourceId: rId1 },
          },
          value2: {
            imageConstant: { resourceId: rId2 },
          },
        } = conditionalValueTransform
        valueToReturn =
          valueToReturn === 'true'
            ? resources.find(el => el.id === rId1)?.url || ''
            : resources.find(el => el.id === rId2)?.url || ''
      } else if (conditionalValueTransform.value?.source && conditionalValueTransform.value2?.imageConstant) {
        const {
          value: { source },
          value2: {
            imageConstant: { resourceId },
          },
        } = conditionalValueTransform
        valueToReturn =
          valueToReturn === 'true'
            ? await getVariableValue(source)
            : resources.find(el => el.id === resourceId)?.url || ''
      } else if (conditionalValueTransform.value?.textConstant && conditionalValueTransform.value2?.source) {
        const {
          value: { textConstant },
          value2: { source },
        } = conditionalValueTransform
        valueToReturn =
          valueToReturn === 'true'
            ? await getTextValue(language, getVariableValue, textConstant)
            : await getVariableValue(source)
      } else if (conditionalValueTransform.value?.source && conditionalValueTransform.value2?.source) {
        const {
          value: { source: s1 },
          value2: { source: s2 },
        } = conditionalValueTransform
        valueToReturn = valueToReturn === 'true' ? await getVariableValue(s1) : await getVariableValue(s2)
      }
    }
    return valueToReturn
  }

  return getVariable
}

export const collectionPrefix = 'collection://'

export const extractRecordRef = (recordRef: string) => {
  const segments = recordRef.replace(collectionPrefix, '').split('/')
  const collectionId = segments.slice(0, -1).join('/')
  const recordId = segments.slice(-1)[0]
  return { collectionId, recordId }
}

const getCurrentCollectionId = async (collection: CollectionVariable, getVariableValue: GetVariableValue) => {
  const { collectionId, name, params } = collection
  if (collectionId) {
    return collectionId
  } else {
    let collectionId = name
    if (params) {
      for (const el of Object.keys(params)) {
        const param = params[el]
        const paramValue = param.source ? await getVariableValue(param.source) : param.constant
        if (paramValue) {
          collectionId = collectionId.replace(`{${el}}`, paramValue)
        }
      }
    }
    return collectionId
  }
}

const hasMutableFilter = (query?: TableQuery) =>
  !!query?.filters?.find(el => {
    const type = el.value.source?.type
    return type
      ? [VariableSourceType.collection, VariableSourceType.localVariable, VariableSourceType.component].includes(type)
      : false
  })

const createAvatar = (initials: string) => {
  const randomColor = () => '#' + (0x1000000 | (Math.random() * 0xffffff)).toString(16).substr(1, 6)
  const canvas = document.createElement('canvas')
  canvas.width = 100
  canvas.height = 100
  const context = canvas.getContext('2d')
  if (context) {
    context.fillStyle = randomColor()
    context.beginPath()
    context.ellipse(canvas.width / 2, canvas.height / 2, canvas.width / 2, canvas.height / 2, 0, 0, Math.PI * 2)
    context.fill()
    context.font = '60px serif'
    context.fillStyle = randomColor()
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillText(initials, canvas.width / 2, canvas.height / 2)
  }
  return canvas.toDataURL()
}

export const setInputParameters = async (getVariableValue: GetVariableValue, inputParameters: ScreenParameter[]) => {
  for (const el of inputParameters) {
    const { parameter, type, collection } = el
    if (type === ValueType.record && collection) {
      const value = await getVariableValue(
        {
          type: VariableSourceType.collection,
          collection,
          transforms: [{ transform: VariableTransformTransform.first }],
        },
        undefined,
        true
      )
      await getVariableValue({ type: VariableSourceType.localVariable, variableName: parameter }, value, true)
    } else if (type === ValueType.string || type === ValueType.number) {
      await getVariableValue({ type: VariableSourceType.localVariable, variableName: parameter }, '', true)
    }
  }
}

export const setLocalVariables = async (
  language: string,
  getVariableValue: GetVariableValue,
  localVariables: LocalVariable[]
) => {
  for (const el of localVariables) {
    const { name, variable, type, collection } = el
    if (variable?.source) {
      const value = await getVariableValue(variable.source, undefined, true)
      await getVariableValue({ type: VariableSourceType.localVariable, variableName: name }, value, true)
    } else if (variable?.arrayConstant) {
      const values = []
      for (const el of variable.arrayConstant) {
        if (el.source) {
          values.push(await getVariableValue(el.source, undefined, true))
        }
      }
      await getVariableValue(
        { type: VariableSourceType.localVariable, variableName: name },
        JSON.stringify(values),
        true
      )
    } else if (variable?.textConstant) {
      const value = await getTextValue(language, getVariableValue, variable.textConstant)
      await getVariableValue({ type: VariableSourceType.localVariable, variableName: name }, value, true)
    } else if (variable?.numberConstant) {
      await getVariableValue(
        { type: VariableSourceType.localVariable, variableName: name },
        variable.numberConstant,
        true
      )
    } else if (variable?.booleanConstant !== undefined) {
      await getVariableValue(
        { type: VariableSourceType.localVariable, variableName: name },
        String(variable.booleanConstant),
        true
      )
    } else if (variable?.calendarStyleConstant) {
      await getVariableValue(
        { type: VariableSourceType.localVariable, variableName: name },
        variable.calendarStyleConstant,
        true
      )
    } else if (type === ValueType.record && collection) {
      const value = await getVariableValue({ type: VariableSourceType.collection, collection }, undefined, true)
      await getVariableValue({ type: VariableSourceType.localVariable, variableName: name }, value, true)
    } else if (
      type === ValueType.image ||
      type === ValueType.video ||
      type === ValueType.audio ||
      type === ValueType.file
    ) {
      const value = await getVariableValue(
        { type: VariableSourceType.collection, collection: { name: assetsId } },
        undefined,
        true
      )
      await getVariableValue({ type: VariableSourceType.localVariable, variableName: name }, value, true)
    }
  }
}
