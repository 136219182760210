import React, { useContext } from 'react'

import { ColorPicker, Icon, Name, TwoColorsSvg } from 'components'
import { ProjectContext } from 'context'
import { SettingField } from 'partials/RightSidebar/components'
import { getBrandingColorType, getVariableName, LocalVariable, Screen, VariableSource } from 'utils'

interface Props {
  big?: boolean
  small?: boolean
  label?: string
  subLabel?: string
  value?: string
  onChange: (val?: string) => void
  source?: VariableSource
  onSourceChange?: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  withoutDelete?: boolean
  disabled?: boolean
  insidePicker?: boolean
}

export const BrandingColorInput: React.FC<Props> = ({
  big,
  small,
  label,
  subLabel,
  value,
  onChange,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
  withoutDelete,
  disabled,
  insidePicker,
}) => {
  const {
    config: {
      branding: { colorStyles },
    },
  } = useContext(ProjectContext)

  const color = value ? colorStyles[getBrandingColorType(value)] : undefined

  return (
    <SettingField
      big={big}
      small={small}
      label={label}
      subLabel={subLabel}
      insidePicker={insidePicker}
      value={source ? getVariableName(source) : value}
      disabled={disabled}
      leftIcon={
        insidePicker ? undefined : color ? (
          <TwoColorsSvg left={color.dark} right={color.light} />
        ) : (
          <Icon name={Name.RIGHT_SIDEBAR_DEFAULT_COLOR} />
        )
      }
      optionsContainer={
        <ColorPicker
          close={() => {}}
          value={value}
          onChange={onChange}
          source={source}
          onSourceChange={onSourceChange}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
          onlySolid
          withoutDelete={withoutDelete}
        />
      }
      actions={
        !withoutDelete && (value || source) ? (
          <Icon
            name={Name.RIGHT_SIDEBAR_DELETE}
            onClick={() => {
              onChange()
              onSourceChange?.()
            }}
          />
        ) : undefined
      }
    />
  )
}
