import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'
import { Integration, IntegrationStatus, IntegrationStripe } from 'hooks'

export const connectStripeParamName = 'connect-stripe'

interface Props {
  stripeIntegration: Integration | null
}

export const StripeButton: React.FC<Props> = ({ stripeIntegration }) => {
  const navigate = useNavigate()

  return stripeIntegration?.status === IntegrationStatus.READY ? (
    <a href="https://stripe.com" target="_blank" rel="noreferrer">
      <Button
        variant={ButtonVariant.LINK}
        color={ButtonColor.SECONDARY}
        leftIcon={<Icon name={Name.SETTINGS_STRIPE} />}
      >
        {(stripeIntegration?.data as IntegrationStripe | undefined)?.teamName}
      </Button>
    </a>
  ) : (
    <Button
      variant={ButtonVariant.LINK}
      color={ButtonColor.SECONDARY}
      leftIcon={<Icon name={Name.SETTINGS_STRIPE} />}
      onClick={() => navigate(`?${connectStripeParamName}=true`)}
      disabled={!stripeIntegration}
    >
      Link to Stripe
    </Button>
  )
}
