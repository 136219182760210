import React, { useState } from 'react'

import * as styled from './AIModal.styled'

import { ThemeToggle } from 'components'
import { md, useIsBreakpoint, xl } from 'hooks'
import { Chat, InitialMessage, OpenProjects, Project, Version } from './components'

interface Props {
  initialMessage?: boolean
}

export const AIModal: React.FC<Props> = ({ initialMessage }) => {
  const [id, setId] = useState<string | null>(null)
  const isMD = useIsBreakpoint(md)
  const isXL = useIsBreakpoint(xl)

  return (
    <styled.AIModal>
      <styled.Top initialMessage={initialMessage}>
        <ThemeToggle ai />
        <Version />
        {!isMD && initialMessage && <OpenProjects />}
      </styled.Top>
      {initialMessage ? (
        <InitialMessage isMD={isMD} />
      ) : (
        <>
          <Chat id={id} setId={setId} isMD={isMD} isXL={isXL} />
          {id && <Project id={id} close={() => setId(null)} isMD={isMD} isXL={isXL} />}
        </>
      )}
    </styled.AIModal>
  )
}
