import React, { ReactElement, useContext, useRef, useState } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from '../DropDown/DropDown.styled'

import { Icon, Name, Rotation } from 'components'
import { useOnClickOutside } from 'hooks'

export interface ISelectOption {
  value: string
  label?: ReactElement
  icon?: ReactElement
  onClick?: () => void
  color?: string
  style?: React.CSSProperties
}

interface Props {
  children?: React.ReactNode
  options: ISelectOption[]
  value?: string
  onClick?: any
  width: string
  eachOptionStyle?: React.CSSProperties
  labelStyle?: React.CSSProperties
  wrapperStyle?: React.CSSProperties
}

export const Select: React.FC<Props> = ({
  children,
  options,
  value,
  onClick,
  width,
  eachOptionStyle,
  labelStyle,
  wrapperStyle,
}) => {
  const themeContext = useContext(ThemeContext)
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  const handleOptionClick = (val: string, onOptionClick?: any) => {
    onClick?.(val)
    onOptionClick?.()
    setOpen(false)
  }

  const currentValue = options.find(option => option.value === value)

  return (
    <styled.Wrapper ref={ref} style={wrapperStyle}>
      <styled.Label style={labelStyle} onClick={() => setOpen(open => !open)}>
        {currentValue?.label || <span>{currentValue?.value}</span>}
        <Icon rotation={open ? Rotation.LEFT : undefined} name={Name.SIZE_24_ARROW} />
        {open && (
          <styled.OptionsWrapper width={width} top="30px" right="0px" onClick={e => e.stopPropagation()}>
            {options.map((el, i) => (
              <React.Fragment key={`${el.value}-${i}`}>
                <styled.Option
                  onClick={() => handleOptionClick(el.value, el.onClick)}
                  hasOwnDropDown={false}
                  color={el.value.includes('Delete') ? themeContext.colors.delete : el.color}
                  style={eachOptionStyle || el.style}
                  active={el.value === value}
                >
                  <div>
                    {el.icon}
                    {el.label || <span>{el.value}</span>}
                  </div>
                </styled.Option>
              </React.Fragment>
            ))}
            {children}
          </styled.OptionsWrapper>
        )}
      </styled.Label>
    </styled.Wrapper>
  )
}
