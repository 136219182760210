import styled from 'styled-components'

export const Container = styled.div`
  padding: 30px 30px 60px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${props => props.theme.colors.secondaryBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Properties = styled.ul`
  padding-top: 30px;
  max-width: 800px;
  width: 100%;
`

export const Property = styled.li<{ active?: boolean }>`
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => (props.active ? props.theme.colors.primaryTextHover : props.theme.colors.borderColor)};
  border-radius: 8px;
  height: 50px;
  box-sizing: border-box;
  padding: 0 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  transition: border 300ms ease-out;
  & > div:first-of-type {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.theme.colors.grey1};
    & svg {
      margin-right: 15px;
    }
  }
  &:hover {
    border-color: ${props => props.theme.colors.primaryText};
  }
`

export const PropertyMove = styled.div`
  cursor: grab;
  display: flex;
  align-items: center;
  & svg {
    pointer-events: none;
    & path {
      transition: 300ms ease-out;
    }
  }
  &:hover svg path {
    fill: ${props => props.theme.colors.iconHover};
  }
`

export const PropertyName = styled.div<{ toRemove?: boolean }>`
  text-decoration: ${props => props.toRemove && 'line-through'};
  font-weight: 600;
  margin-right: 10px;
  color: ${props => props.theme.colors.darkText};
`

export const DopInfo = styled.div<{ red?: boolean }>`
  background: ${props => (props.red ? props.theme.colors.red : props.theme.colors.settingField)};
  border-radius: 100px;
  padding: 2px 10px;
  color: ${props => (props.red ? props.theme.colors.white : props.theme.colors.grey1)};
  font-size: 12px;
  line-height: 150%;
  white-space: nowrap;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  & svg {
    cursor: pointer;
    & path {
      transition: 300ms ease-out;
    }
    &:hover path {
      &[stroke] {
        stroke: ${props => props.theme.colors.iconHover};
      }
      &[fill] {
        fill: ${props => props.theme.colors.iconHover};
      }
    }
  }
`

export const AddField = styled.div`
  width: 100%;
  max-width: 800px;
  & > li {
    cursor: pointer;
    margin-top: 20px;
    justify-content: center;
    border-style: dashed;
    font-weight: ${props => props.theme.weights.medium};
    font-size: 12px;
    line-height: 150%;
    color: ${props => props.theme.colors.grey1};
    & svg {
      margin-right: 5px !important;
    }
    &:hover {
      color: ${props => props.theme.colors.iconHover};
      & svg path {
        stroke: ${props => props.theme.colors.iconHover};
      }
    }
  }
`
