import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
`

export const TableWrapper = styled.div`
  width: 100%;
`

export const TableDropDowns = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`

export const Table = styled.table`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
  border-collapse: initial;
  border-radius: 8px;
  overflow: hidden;
  * {
    font-size: 14px;
    line-height: 21px;
    color: ${props => props.theme.colors.grey1};
    font-weight: ${props => props.theme.weights.normal};
  }
  & thead {
    background-color: ${props => props.theme.colors.primaryBackground};
    height: 41px;
    & th {
      vertical-align: middle;
      padding: 10px 15px;
      &:nth-child(2) {
        width: 60%;
      }
      & span {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & svg {
          margin-right: 6px;
          fill: ${props => props.theme.colors.darkText1};
        }
      }
      & b {
        color: ${props => props.theme.colors.darkText};
        font-weight: ${props => props.theme.weights.medium};
        margin-right: 4px;
      }
    }
  }
  & td {
    border-top: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
    vertical-align: middle;
    & > i {
      padding: 10px 15px;
      color: ${props => props.theme.colors.darkText};
      text-decoration: none;
      display: flex;
      align-items: center;
      & > svg {
        margin-right: 10px;
        min-width: 16px;
      }
      & > time {
        margin-left: auto;
        white-space: nowrap;
      }
    }
  }
  & tr td:nth-child(2) {
    position: relative;
    & > i {
      width: calc(100% - 40px);
      left: 0;
      top: 2px;
      position: absolute;
      & div {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  & tbody > tr {
    transition: ${props => props.theme.transition.primary};
    &:hover {
      cursor: pointer;
      background-color: ${props => props.theme.colors.primaryBackground};
    }
  }
`
