export enum ButtonVariant {
  DEFAULT = 'default',
  LINK = 'link',
  TOP_PANEL_ACTION = 'top_panel_action',
}

export enum ButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
}

export enum ButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARY1 = 'secondary1',
  TERTIARY = 'tertiary',
  PICKER = 'picker',
  TAG = 'tag',
  DELETE = 'delete',
  CUSTOM_DELETE = 'custom_delete',
  ACTION = 'action',
  OUTLINED = 'outlined',
  BLACK = 'black',
}
