import React from 'react'

import * as styled from './ReadOnlyMode.styled'

import { Button, ButtonColor } from 'components'
import { landingUrl } from 'utils'

export const ReadOnlyMode: React.FC = () => (
  <styled.ReadOnlyMode>
    <div>Read-only mode</div>
    <div>Join the CodePlatform Private Preview Program to gain access</div>
    <div>
      <Button color={ButtonColor.SECONDARY} to={`${landingUrl}/request-a-demo`}>
        Contact Us
      </Button>
    </div>
  </styled.ReadOnlyMode>
)
