import React, { useContext, useEffect, useRef, useState } from 'react'
import AceEditor from 'react-ace'

import { Button, Menu } from 'components'
import { DeviceSettingsContext, ProgressContext, ProjectContext } from 'context'

export const SourceCode: React.FC = () => {
  const aceRef: React.LegacyRef<AceEditor> = useRef(null)
  const [open, setOpen] = useState(false)
  const { config, setConfig } = useContext(ProjectContext)
  const [configForAceEditor, setConfigForAceEditor] = useState(JSON.stringify(config, null, '\t'))
  const {
    selected: { tabScreen, tabBarId, subComponentId, componentId, screenId },
  } = useContext(DeviceSettingsContext)
  const { toast } = useContext(ProgressContext)
  const selectedId = tabScreen || tabBarId || subComponentId || componentId || screenId

  useEffect(() => {
    setConfigForAceEditor(JSON.stringify(config, null, '\t'))
  }, [JSON.stringify(config), open])

  useEffect(() => {
    if (selectedId && open) {
      aceRef.current?.editor.find(selectedId)
    }
  }, [selectedId, open])

  useEffect(() => {
    aceRef.current?.editor.commands.addCommand({
      name: 'save',
      bindKey: { win: 'Ctrl-S', mac: 'Cmd-S' },
      exec: onSave,
    })
  }, [open, configForAceEditor])

  const onSave = () => {
    try {
      setConfig(JSON.parse(configForAceEditor))
    } catch (err) {
      toast(err)
    }
  }

  const configChanged = JSON.stringify(config, null, '\t') !== configForAceEditor

  return (
    <Menu
      bottom
      isOpen={setOpen}
      firstChild={
        <>
          <AceEditor
            ref={aceRef}
            value={configForAceEditor}
            onChange={setConfigForAceEditor}
            mode="json"
            width="100%"
            height="100%"
            style={{ position: 'absolute' }}
          />
          <Button
            style={{ position: 'absolute', top: '10px', right: '10px' }}
            onClick={onSave}
            disabled={!configChanged}
          >
            Save
          </Button>
        </>
      }
    />
  )
}
