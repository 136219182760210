import { httpClient } from 'utils'

const createLogicItemRecord = (projectId: string, name: string, data: { sourceCode: string }) => {
  const url = `/projects/${projectId}/logic/${name}`
  return httpClient.post(url, data)
}

const updateLogicItemRecord = (projectId: string, name: string, id: string, data: { sourceCode: string }) => {
  const url = `/projects/${projectId}/logic/${name}/${id}`
  return httpClient.put(url, data)
}

const deleteLogicItemRecord = (projectId: string, name: string, id: string) => {
  const url = `/projects/${projectId}/logic/${name}/${id}`
  return httpClient.delete(url)
}

export const LogicService = {
  createLogicItemRecord,
  updateLogicItemRecord,
  deleteLogicItemRecord,
}
