import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { desktopModeEvent, DeviceMode, mobileModeEvent, tabletModeEvent } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { firebaseConfig } from 'services/firebase'
import { ProjectsService } from 'services/projects'

export const reloadPWAPreviewIframeEvent = 'reloadPWAPreviewIframeEvent'

interface Props {
  adaptive?: boolean
  page?: string
  email?: string
  token?: string
}

export const PWAPreviewIframe: React.FC<Props> = ({ adaptive, page, email, token }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id, gcpProjectId },
    config,
    language,
    theme,
  } = useContext(ProjectContext)
  const firestorePrefix = gcpProjectId ? '' : `/projects/${id}/datasources/${config.preview}/`
  const previewIframeUrl = `https://${id}.preview.codeplatform.com`
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [webFirebaseConfig, setWebFirebaseConfig] = useState<{ [key: string]: string }>()
  const [lastRefresh, setLastRefresh] = useState(+new Date())
  const [deviceMode, setDeviceMode] = useState(
    adaptive && window.innerWidth < 768 ? DeviceMode.DESKTOP : DeviceMode.MOBILE
  )
  const inDevice = !!(adaptive && deviceMode === DeviceMode.MOBILE)
  const message = useMemo(
    () => ({ inDevice, firestorePrefix, webFirebaseConfig, config, language, theme, email, token }),
    [inDevice, firestorePrefix, webFirebaseConfig, config, language, theme, email, token]
  )
  const key = JSON.stringify(message) + previewIframeUrl + lastRefresh

  useEffect(() => {
    const onMessage = (e: any) => {
      if (typeof e.data === 'string') {
        const READY = 'READY'
        const NEED_INDEX = 'NEED_INDEX'
        if (e.data === READY) {
          iframeRef.current?.contentWindow?.postMessage(message, previewIframeUrl)
        } else if (e.data.startsWith(NEED_INDEX)) {
          const data = JSON.parse(e.data.replace(NEED_INDEX, ''))
          ProjectsService.createIndex(id, data).catch(err => toast(err))
        }
      }
    }
    window.addEventListener('message', onMessage)
    return () => window.removeEventListener('message', onMessage)
  }, [key])

  useEffect(() => {
    const refresh = () => setLastRefresh(+new Date())
    document.addEventListener(reloadPWAPreviewIframeEvent, refresh)
    return () => {
      document.removeEventListener(reloadPWAPreviewIframeEvent, refresh)
    }
  }, [])

  useEffect(() => {
    if (firestorePrefix) {
      setWebFirebaseConfig(firebaseConfig)
    } else {
      startLoader()
      ProjectsService.getWebFirebaseConfig(id)
        .then(res => setWebFirebaseConfig(res.data))
        .catch(err => toast(err))
        .finally(() => stopLoader())
    }
  }, [firestorePrefix])

  useEffect(() => {
    if (adaptive) {
      const setMobileModeFromListener = () => setDeviceMode(DeviceMode.MOBILE)
      const setTabletModeFromListener = () => setDeviceMode(DeviceMode.TABLET)
      const setDesktopModeFromListener = () => setDeviceMode(DeviceMode.DESKTOP)
      const onResize = () => setDeviceMode(window.innerWidth < 768 ? DeviceMode.DESKTOP : DeviceMode.MOBILE)
      document.addEventListener(mobileModeEvent, setMobileModeFromListener)
      document.addEventListener(tabletModeEvent, setTabletModeFromListener)
      document.addEventListener(desktopModeEvent, setDesktopModeFromListener)
      window.addEventListener('resize', onResize)
      return () => {
        document.removeEventListener(mobileModeEvent, setMobileModeFromListener)
        document.removeEventListener(tabletModeEvent, setTabletModeFromListener)
        document.removeEventListener(desktopModeEvent, setDesktopModeFromListener)
        window.removeEventListener('resize', onResize)
      }
    }
  }, [adaptive])

  return webFirebaseConfig ? (
    <iframe
      ref={iframeRef}
      key={key}
      title="Preview"
      src={page ? `${previewIframeUrl}/${page}` : previewIframeUrl}
      width="100%"
      height="100%"
    />
  ) : (
    <></>
  )
}
