import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'

import {
  CustomSection,
  CustomSectionActions,
  CustomSectionSubTitle,
  CustomSectionTitle,
  Icon,
  InputProperty,
  Name,
  Switch,
  SwitchVariant,
  Text,
  Type,
  inputs,
  validate,
} from 'components'

export interface SwitchedEditItem {
  id: string
  title: string
  subTitle: string
  checked: boolean
  iconName?: Name
}

interface Props {
  title: string
  subTitle: string
  items: SwitchedEditItem[]
  properties: InputProperty[]
}

export const SwitchedEdit: React.FC<Props> = ({ title, subTitle, items, properties }) => {
  const themeContext = useContext(ThemeContext)
  const [valuesBeforeEdit] = useState('')

  const formik = useFormik({
    initialValues: {},
    validate: data => validate(data, properties),
    onSubmit: data => {},
  })

  const cancel = () => {}

  const showActions = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)

  return (
    <CustomSection showActions={showActions} style={{ padding: '25px' }}>
      <CustomSectionTitle mb="10px">{title}</CustomSectionTitle>
      <CustomSectionSubTitle>{subTitle}</CustomSectionSubTitle>
      <CustomSection style={{ padding: 0, margin: '20px 0 0 0' }}>
        {items.map((el, i) => (
          <Box
            key={el.id}
            padding="20px 24px"
            borderTop={i !== 0 ? `1px solid ${themeContext.colors.darkBlue_100}` : ''}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
              <Box display="flex" alignItems="center" gap="8px">
                {el.iconName && <Icon name={el.iconName} />}
                <Box>
                  <Text type={Type.H3}>{el.title}</Text>
                  <Text type={Type.BODY} as="p" color={themeContext.colors.grey1}>
                    {el.subTitle}
                  </Text>
                </Box>
              </Box>
              <Switch checked={el.checked} onChange={() => {}} variant={SwitchVariant.BIG} />
            </Box>
            {el.checked && !!properties.length && <Box mt="-15px">{inputs(properties, formik)}</Box>}
          </Box>
        ))}
      </CustomSection>
      {showActions && <CustomSectionActions cancel={cancel} onClick={formik.submitForm} />}
    </CustomSection>
  )
}
