import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import { ProgressContext, ProjectContext } from 'context'
import { firestore } from 'services/firebase'
import { Card } from '../components'

interface Metric {
  id: string
  name: string
  description: string
  value: number
  unit: string
}

export const Cards: React.FC = () => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [metrics, setMetrics] = useState<Metric[]>([])

  useEffect(() => {
    const unsubscribe = firestore.collection(`projects/${id}/metrics`).onSnapshot({
      next: res => setMetrics(res.docs.map(el => ({ id: el.id, ...el.data() })) as Metric[]),
      error: err => toast(err),
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Box display="flex" flexWrap="wrap" gap="16px" mb="-24px" mt="40px">
      {metrics.map(el => (
        <Card key={el.id} name={el.name} value={`${el.value || 0} ${el.unit}`} description={el.description} />
      ))}
    </Box>
  )
}
