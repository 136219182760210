import React, { useContext } from 'react'

import { Container, MaxWidthContainer } from '../index.styled'

import { CustomSectionsWrapper, Notification, NotificationColor, Text, Type } from 'components'
import { UserContext } from 'context'
import { LoginMethods, MultiFactor, Profile } from './sections'

export const Account: React.FC = () => {
  const { firebaseUser, signIn } = useContext(UserContext)

  return (
    <Container>
      <MaxWidthContainer>
        <Text type={Type.TITLE}>Account settings</Text>
        <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
          Workspace members can create, edit, and delete projects. They can also access project data and invite other
          members.
        </Text>
        {firebaseUser.isAnonymous ? (
          <Notification
            color={NotificationColor.YELLOW}
            open
            inside
            title="Sign in required"
            description="You need to be signed in to interact with this page. Please sign in or create an account to continue."
            signIn={signIn}
          />
        ) : (
          <CustomSectionsWrapper>
            <Profile />
            <LoginMethods />
            <MultiFactor />
          </CustomSectionsWrapper>
        )}
      </MaxWidthContainer>
    </Container>
  )
}
