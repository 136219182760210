import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { Container, MaxWidthContainer } from '../index.styled'
import * as styled from './Projects.styled'

import { Crumbs, RemoveContent, Select, Text, Type } from 'components'
import { ProgressContext, ProjectsContext } from 'context'
import { Project } from 'hooks'
import { ProjectsService } from 'services/projects'
import { TemplateList } from '../Templates'
import { AppCard, CreateProject } from './components'

enum SortOption {
  LAST_MODIFED = 'Last modified',
  FIRST_MODIFED = 'First modified',
  ALPHABETICAL = 'Alphabetical',
}

const sortOptionKey = 'sortOptionKey'
const getSortOptionFromStorage = () => {
  const sortOptionFromStorage = localStorage.getItem(sortOptionKey) as SortOption | null
  return sortOptionFromStorage || SortOption.LAST_MODIFED
}

const alphabeticalSort = (a: Project, b: Project) =>
  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0

export const updatedAtSort = (a: Project, b: Project) => a.updatedAt.seconds - b.updatedAt.seconds

const sortProjects = (sortOption: SortOption, projects: Project[]) => {
  localStorage.setItem(sortOptionKey, sortOption)
  const projectsCopy = projects.slice()
  switch (sortOption) {
    case SortOption.ALPHABETICAL:
      projectsCopy.sort(alphabeticalSort)
      break
    case SortOption.FIRST_MODIFED:
      projectsCopy.sort(updatedAtSort)
      break
    case SortOption.LAST_MODIFED:
      projectsCopy.sort((a, b) => -updatedAtSort(a, b))
  }
  return projectsCopy
}

export const Projects: React.FC = () => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const themeContext = useContext(ThemeContext)
  const { projects, waitProjects, setWaitProjects } = useContext(ProjectsContext)
  const [sortedProjects, setSortedProjects] = useState<Project[]>([])
  const [toRemove, setToRemove] = useState<Project | null>(null)

  useEffect(() => {
    document.title = 'CodePlatform'
  }, [])

  useEffect(() => {
    setSortedProjects(sortProjects(getSortOptionFromStorage(), projects))
  }, [JSON.stringify(projects)])

  const removeApp = (callback: () => void) => {
    if (toRemove && !waitProjects) {
      startLoader()
      setWaitProjects(true)
      ProjectsService.deleteProject(toRemove.id)
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
          setWaitProjects(false)
        })
    }
  }

  return (
    <>
      <Container>
        <MaxWidthContainer>
          <styled.ProjectsBox style={{ pointerEvents: 'auto' }}>
            <Box>
              <Crumbs firstCrumbTitle="Projects" icon={null} />
              <Text type={Type.SUB_TITLE} color={themeContext.colors.darkBlue_500}>
                Manage your projects
              </Text>
              <Box display="flex" justifyContent="flex-end">
                {!!projects.length && (
                  <styled.SelectBox>
                    <Select
                      onClick={(value: SortOption) => setSortedProjects(sortProjects(value, projects))}
                      eachOptionStyle={{ fontWeight: themeContext.weights.normal }}
                      width="145px"
                      value={getSortOptionFromStorage()}
                      options={[
                        { value: SortOption.LAST_MODIFED },
                        { value: SortOption.FIRST_MODIFED },
                        { value: SortOption.ALPHABETICAL },
                      ]}
                    />
                  </styled.SelectBox>
                )}
              </Box>
            </Box>
            <styled.List>
              <CreateProject />
              {waitProjects
                ? Array(3)
                    .fill(0)
                    .map((el, i) => <AppCard key={el + i} />)
                : sortedProjects.map(el => <AppCard key={el.id} project={el} setToRemove={setToRemove} />)}
            </styled.List>
          </styled.ProjectsBox>
          <TemplateList />
          <Box
            display="flex"
            columnGap="30px"
            marginBottom="50px"
            flexWrap="wrap"
            justifyContent="center"
            rowGap="30px"
            width="100%"
          />
        </MaxWidthContainer>
      </Container>
      <RemoveContent
        toRemove={toRemove}
        title="Delete project"
        text="You are about to delete the project <b>{name}</b>. To make sure, enter the project name below"
        clean={() => setToRemove(null)}
        remove={removeApp}
        nameConfirmation
      />
    </>
  )
}
