import React from 'react'

import { Name } from 'components'
import { SwitchedEdit } from '../../components'

export const Providers: React.FC = () => (
  <SwitchedEdit
    title="Authentication providers"
    subTitle="Configure how users can sign in to your app."
    items={[
      {
        id: '0',
        title: 'Email',
        subTitle: 'Allow users to sign in using an email link or a classic password login.',
        iconName: Name.SECOND_ORDER_PROVIDERS_EMAIL,
        checked: false,
      },
      {
        id: '1',
        title: 'Allow sign-in with Google',
        subTitle: 'Enable users to sign in with their Google accounts for a seamless experience.',
        iconName: Name.SECOND_ORDER_PROVIDERS_GOOGLE,
        checked: true,
      },
      {
        id: '2',
        title: 'Allow sign-in with Apple',
        subTitle: 'Allow sign-in using Apple ID for users with iOS devices.',
        iconName: Name.SECOND_ORDER_PROVIDERS_APPLE,
        checked: true,
      },
      {
        id: '3',
        title: 'Allow sign-in with Phone',
        subTitle: 'Let users sign in using their phone numbers with SMS-based verification.',
        iconName: Name.SECOND_ORDER_PROVIDERS_PHONE,
        checked: true,
      },
    ]}
    properties={[]}
  />
)
