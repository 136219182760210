import styled from 'styled-components'

export const Project = styled.div`
  z-index: 1;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.secondaryBackground};
  box-shadow: -1px 0px 10px 0px #00000026;
`
