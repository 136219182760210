import styled from 'styled-components'

export const Wrapper = styled.div<{ active: boolean }>`
  width: 100%;
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 13px;
  border: 1px solid
    ${props => (props.active ? props.theme.colors.primaryText : props.theme.colors.borderColorToLightBorder)};
  background: ${props => props.theme.colors.white};
  font-size: 14px;
  line-height: 21px;
  transition: ${props => props.theme.transition.primary};
  cursor: pointer;
  & > img {
    width: 42px;
    height: 42px;
    border-radius: 10px;
    object-fit: cover;
  }
  & > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    & > b {
      font-weight: 600;
      color: ${props => props.theme.colors.darkText};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > span {
      font-weight: 400;
      color: ${props => props.theme.colors.grey};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  & > i {
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin: 0 6px 0 auto;
    border-radius: 6px;
    background: ${props => props.theme.colors.settingField};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: ${props => props.theme.colors.grey};
  }
  &:hover {
    border-color: ${props => props.theme.colors.primaryText};
  }
`
