import React, { useContext } from 'react'

import { BooleanInput, BrandingColorInput, Icon, Name, Rotation } from 'components'
import { DeviceSettingsContext, ProjectContext } from 'context'
import { RightSidebarItem, SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { LocalVariable, Resource, Screen, TabBar, TabBarItem, getTextareaValue, profieTabs } from 'utils'
import { Id } from '../common'

interface Props {
  tabBar: TabBar
  onTabBarPropertiesChange: (props: { propName: keyof TabBar; value: any; resources?: Resource[] }[]) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const TabBarSetting: React.FC<Props> = ({
  tabBar,
  onTabBarPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const { language } = useContext(ProjectContext)
  const { setSelected } = useContext(DeviceSettingsContext)
  const { name, backgroundColor, tabs = [] } = tabBar

  const moveTab = (start: boolean, index: number) => {
    const tabsCopy = JSON.parse(JSON.stringify(tabs)) as TabBarItem[]
    let tabCopy: TabBarItem | null = null
    if (start && index !== 0) {
      tabCopy = tabsCopy[index - 1]
      tabsCopy[index - 1] = tabsCopy[index]
      tabsCopy[index] = tabCopy
    } else if (!start && index !== tabsCopy.length - 1) {
      tabCopy = tabsCopy[index + 1]
      tabsCopy[index + 1] = tabsCopy[index]
      tabsCopy[index] = tabCopy
    }
    if (tabCopy) {
      onTabBarPropertiesChange([{ propName: 'tabs', value: tabsCopy }])
    }
  }

  const onTabClick = (screen: string) =>
    setSelected(selected => ({
      ...selected,
      tabScreen: screen,
      innerModeComponentId: tabBar.id,
    }))

  const sectionName = 'TabBar'

  return (
    <RightSidebarItem title={sectionName} hasCloseIcon>
      <SubWrapper childrenFor={sectionName}>
        <Id value={name} />
        <BrandingColorInput
          label="Background"
          subLabel="Fill"
          value={backgroundColor?.constant}
          onChange={value => onTabBarPropertiesChange([{ propName: 'backgroundColor', value: { constant: value } }])}
          source={backgroundColor?.source}
          onSourceChange={value =>
            onTabBarPropertiesChange([{ propName: 'backgroundColor', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <SubWrapper title="Tabs" canHide onAdd={() => {}} childrenFor={sectionName}>
        {tabs.map((el, i) => (
          <SettingField
            key={el.screen}
            value={getTextareaValue(language, el.title).value}
            onClick={() => onTabClick(el.screen)}
            actions={
              !profieTabs.includes(el.screen) ? (
                <>
                  {!profieTabs.includes(tabs[i + 1]?.screen) && (
                    <Icon
                      name={Name.RIGHT_SIDEBAR_MOVE_UP}
                      rotation={Rotation.LEFT}
                      onClick={() => moveTab(false, i)}
                    />
                  )}
                  <Icon name={Name.RIGHT_SIDEBAR_MOVE_UP} onClick={() => moveTab(true, i)} />
                  <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />
                </>
              ) : undefined
            }
          />
        ))}
      </SubWrapper>
      <SubWrapper title="Options" canHide childrenFor={sectionName}>
        <BooleanInput subLabel="Show tab names" value={true} onChange={() => {}} />
      </SubWrapper>
    </RightSidebarItem>
  )
}
