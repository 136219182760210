import React, { useContext } from 'react'

import { Block, Title } from '../../Settings.styled'
import * as styled from './AppInfo.styled'

import { Icon, Name } from 'components'
import { ProjectContext } from 'context'

export const AppInfo: React.FC = () => {
  const {
    project: { id },
  } = useContext(ProjectContext)

  return (
    <Block>
      <Title>App Information</Title>
      <styled.AppInfo>
        <div>
          <b>Firebase Project</b>
          <a href={`https://console.firebase.google.com/project/${id}`} target="_blank" rel="noopener noreferrer">
            {id}
            <Icon name={Name.REPOSITORY_LINK} />
          </a>
        </div>
      </styled.AppInfo>
    </Block>
  )
}
