import { useContext, useEffect, useState } from 'react'

import { ProgressContext } from 'context'
import { ProjectsService } from 'services/projects'

export const useSuggestedGcpProjectId = (id: string, name: string | null, subscribe: boolean) => {
  const { toast } = useContext(ProgressContext)
  const [suggestedGcpProjectId, setSuggestedGcpProjectId] = useState('')
  const [wait, setWait] = useState(true)

  useEffect(() => {
    if (subscribe) {
      if (name) {
        const abortController = new AbortController()
        ProjectsService.getSuggestedGcpProjectId(id, name, abortController)
          .then(res => setSuggestedGcpProjectId(res.data))
          .catch(err => !abortController.signal.aborted && toast(err))
          .finally(() => setWait(false))
        return () => {
          abortController.abort()
        }
      } else {
        setSuggestedGcpProjectId('')
      }
    }
  }, [id, name, subscribe])

  return { suggestedGcpProjectId, wait }
}
