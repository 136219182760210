import styled from 'styled-components'

export const AIModal = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: ${props => props.theme.colors.aiBackground};
`

export const Top = styled.div<{ initialMessage?: boolean }>`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: ${props => props.initialMessage && '100%'};
  height: 60px;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const AIIcon = styled.div<{ open: boolean; whiteIcon?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > svg {
    min-width: 16px;
    min-height: 16px;
    & path {
      fill: ${props => (props.whiteIcon ? props.theme.colors.white : props.open && props.theme.colors.iconHover)};
    }
  }
  &:hover > svg path {
    fill: ${props => !props.whiteIcon && props.theme.colors.iconHover};
  }
`
