import { rgbToHex } from '@mui/material'
import React, { useContext } from 'react'

import * as styled from './CoreColorInput.styled'

import { CustomPicker, OneColorSvg, SwitchColor, TwoColorsSvg } from 'components'
import { ProjectContext } from 'context'
import { SettingField, SubWrapper } from 'partials/RightSidebar/components'
import {
  BrandingColorType,
  BrandingThemeType,
  ColorVariant,
  firstLetterUpperCase,
  getColorVariantName,
  getCustomColors,
} from 'utils'

interface Props {
  big?: boolean
  colorType: BrandingColorType
}

export const CoreColorInput: React.FC<Props> = ({ big, colorType }) => {
  const {
    config: {
      branding: { colorStyles },
    },
    setConfig,
  } = useContext(ProjectContext)
  const pickerColor = colorStyles[colorType].light

  const onColorChange = (pickerColor: string) => {
    if (pickerColor !== colorStyles[colorType].light) {
      const customColors = getCustomColors(pickerColor, colorType)
      setConfig(config => ({
        ...config,
        branding: { ...config.branding, colorStyles: { ...config.branding.colorStyles, ...customColors } },
      }))
    }
  }

  return (
    <SettingField
      big={big}
      subLabel={big ? undefined : firstLetterUpperCase(colorType)}
      value={pickerColor}
      leftIcon={<OneColorSvg color={pickerColor} />}
      optionsContainer={
        <CustomPicker
          title="Color"
          close={() => {}}
          optionsContainer={
            <SwitchColor
              onlySolid
              withoutOpacity
              value={pickerColor}
              onChange={(color: string) => onColorChange(rgbToHex(color).slice(0, -2))}
              preview={
                <styled.Preview>
                  <h3>Preview</h3>
                  <div>
                    {['light', 'dark'].map(el => (
                      <SubWrapper key={el} title={`${el === 'light' ? 'Light' : 'Dark'} theme`} childrenFor="">
                        {colorType === BrandingColorType.neutral
                          ? [
                              BrandingColorType.background,
                              BrandingColorType.onBackground,
                              BrandingColorType.surface,
                              BrandingColorType.onSurface,
                              BrandingColorType.surfaceVariant,
                              BrandingColorType.onSurfaceVariant,
                              BrandingColorType.outline,
                              BrandingColorType.shadow,
                              BrandingColorType.inverseSurface,
                              BrandingColorType.inverseOnSurface,
                            ].map(variant => {
                              const color = colorStyles[variant][el as BrandingThemeType]
                              return (
                                <SettingField
                                  key={variant}
                                  transparent
                                  leftIcon={<TwoColorsSvg left={color} right={color} />}
                                  value={variant}
                                />
                              )
                            })
                          : ['color', 'onColor', 'colorContainer', 'onColorContainer'].map(variant => {
                              const color =
                                colorStyles[getColorVariantName(colorType, variant as ColorVariant)][
                                  el as BrandingThemeType
                                ]
                              return (
                                <SettingField
                                  key={variant}
                                  transparent
                                  leftIcon={<TwoColorsSvg left={color} right={color} />}
                                  value={getColorVariantName(colorType, variant as ColorVariant)}
                                />
                              )
                            })}
                      </SubWrapper>
                    ))}
                  </div>
                </styled.Preview>
              }
            />
          }
        />
      }
    />
  )
}
