import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Crumbs, Icon, InputProperty, Name, RightForm, inputs, validate } from 'components'
import { ProgressContext } from 'context'
import { CMSCollectionRecord } from 'services/cms'
import { ProjectsService } from 'services/projects'
import { ValueType } from 'utils'

const properties: InputProperty[] = [
  'name',
  'customerName',
  'email',
  'phone',
  'taxId',
  'taxCountry',
  'addressLine1',
  'addressLine2',
  'city',
  'state',
  'postalCode',
  'country',
].map((el, i) => ({
  name: el,
  type: ValueType.string,
  position: i,
  isRequired: true,
}))

export const BillingAccount: React.FC = () => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const navigate = useNavigate()
  const { billingAccountId } = useParams()
  const [wait, setWait] = useState(true)
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)

  const formik = useFormik({
    initialValues: {},
    validate: data => validate(data, properties),
    onSubmit: data => {
      setSaveLoading(true)
      startLoader()
      ProjectsService.updateBillingAccount(billingAccountId as string, data)
        .then(() => setValuesBeforeEdit(JSON.stringify(data)))
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setSaveLoading(false)
        })
    },
  })

  useEffect(() => {
    setForm()
  }, [billingAccountId])

  const setForm = async () => {
    const record: CMSCollectionRecord = {}
    setWait(true)
    try {
      startLoader()
      const res = await ProjectsService.getBillingAccount(billingAccountId as string)
      properties.forEach(property => {
        record[property.name] = res.data[property.name] || null
      })
    } catch (err) {
      toast(err)
    } finally {
      stopLoader()
    }
    setValuesBeforeEdit(JSON.stringify(record))
    formik.setValues(record)
    setWait(false)
  }

  const showConfirmWhen = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)
  const closePath = `/billing/${billingAccountId}`

  return (
    <RightForm
      showConfirmWhen={showConfirmWhen}
      onClose={() => navigate(closePath)}
      title={
        <>
          <Crumbs
            firstCrumbTitle="Billing"
            secondCrumbTitle="Edit billing account"
            icon={<Icon name={Name.RIGHT_SIDEBAR_CLOSE} />}
            to={closePath}
            small
          />
          <Button onClick={formik.submitForm} disabled={!showConfirmWhen} loading={saveLoading}>
            Save
          </Button>
        </>
      }
      form={wait ? <></> : <>{inputs(properties, formik)}</>}
    />
  )
}
