import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'

import {
  Button,
  Crumbs,
  CustomSectionSubTitle,
  CustomSectionTitle,
  CustomSectionTitleAction,
  CustomSectionTitleWithActionWrapper,
  Icon,
  InputProperty,
  Name,
  RightForm,
  inputs,
  validate,
} from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { CMSCollectionRecord } from 'services/cms'
import { database } from 'services/firebase'
import { ValueType } from 'utils'
import { TermsTable } from './components'

export interface StoremanagerConfig {
  name: string
  language: string
  primaryCategory: string
  secondaryCategory: string
  screenshots?: string[]
  text: string
  description: string
  keywords: string
  copyright: string
  support_url: string
  privacy_policy_url: string
  marketing_url: string
  terms_policies: string
}

const properties: InputProperty[] = [
  {
    name: 'name',
    type: ValueType.string,
    position: 0,
    isRequired: true,
    helpText: 'The name of your app as it will appear in the app stores. It must not exceed 30 characters.',
    // ai: true,
  },
  {
    name: 'language',
    type: ValueType.string,
    position: 1,
    isRequired: true,
    title: 'Primary Language',
    helpText:
      'The default language for your app’s information. If localized app information is unavailable in certain countries or regions, this primary language will be used.',
    optionsToSelect: [{ value: 'English (US)', label: 'English (US)' }],
  },
  {
    name: 'primaryCategory',
    type: ValueType.string,
    position: 2,
    isRequired: true,
    title: 'Primary Category',
    helpText: 'The main category that best describes your app. This helps users find it in the app stores.',
    optionsToSelect: [{ value: 'Photo & Video', label: 'Photo & Video' }],
  },
  {
    name: 'secondaryCategory',
    type: ValueType.string,
    position: 3,
    title: 'Secondary Category',
    helpText: 'An additional category that supports your primary category and improves discoverability.',
    optionsToSelect: [{ value: 'Entertainment', label: 'Entertainment' }],
  },
  {
    name: 'screenshots',
    type: ValueType.screenshots,
    position: 4,
    title: 'App Screenshots',
    helpText:
      'Upload up to 10 high-quality screenshots that showcase your app’s features and design. These will appear on your app store pages. If localized screenshots are unavailable, the ones in the primary language will be displayed.',
  },
  {
    name: 'text',
    type: ValueType.string,
    position: 5,
    isRequired: true,
    title: 'Promotional Text',
    helpText:
      'A short and engaging description that highlights your app’s value proposition. This will appear in promotional sections of the app stores. Example: “Welcome to YC Library, your go-to resource hub for aspiring and seasoned startup founders! Whether you’re looking to kickstart your entrepreneurial journey, gain insights from successful founders, or stay updated on the latest trends, we’ve got you covered.”',
    // ai: true,
  },
  {
    name: 'description',
    type: ValueType.string,
    position: 6,
    isRequired: true,
    helpText:
      'Provide a detailed overview of your app’s features, functionality, and benefits. This will help users understand what makes your app unique.',
    // ai: true,
  },
  {
    name: 'keywords',
    type: ValueType.string,
    position: 7,
    helpText:
      'Provide relevant keywords to help users find your app in the App Store and Google Play. Use terms that reflect your app’s features and purpose (e.g., productivity, gaming, learning).',
    // ai: true,
  },
  {
    name: 'copyright',
    type: ValueType.string,
    position: 8,
    isRequired: true,
    helpText: 'Enter the legal entity or individual that holds the copyright for the app. Example: App Company LLC.',
  },
  {
    name: 'support_url',
    type: ValueType.url,
    position: 9,
    isRequired: true,
    title: 'Support URL',
    helpText: 'Provide a URL where users can get support for your app (e.g., contact page).',
    editByModal: true,
  },
  {
    name: 'privacy_policy_url',
    type: ValueType.url,
    position: 10,
    isRequired: true,
    title: 'Privacy Policy URL',
    helpText: 'Provide a link to your app’s published privacy policy.',
    editByModal: true,
  },
  {
    name: 'marketing_url',
    type: ValueType.url,
    position: 11,
    isRequired: true,
    title: 'Marketing URL',
    helpText: 'Provide a link to a website or page that markets your app and gives users additional information.',
    editByModal: true,
  },
]

interface Props {
  close: () => void
}

export const StoreManager: React.FC<Props> = ({ close }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [configWait, setConfigWait] = useState(true)
  const [isConfigSnapshot, setIsConfigSnapshot] = useState(false)
  const configRef = database.ref(`projects/${id}/configurations/storemanager`)

  const formik = useFormik({
    initialValues: {},
    validate: data => validate(data, properties),
    onSubmit: data => {
      startLoader()
      console.log('SET STOREMANAGER CONFIG')
      configRef
        .set(JSON.parse(JSON.stringify(data)) as StoremanagerConfig)
        .catch(err => toast(err))
        .finally(() => stopLoader())
    },
  })

  useEffect(() => {
    configRef.on('value', snapshot => {
      const config = snapshot.val()
      if (config) {
        console.log('GET STOREMANAGER CONFIG')
        const record: CMSCollectionRecord = {}
        properties.forEach(property => {
          record[property.name] = config[property.name] || null
        })
        setIsConfigSnapshot(true)
        formik.setValues(record)
        setConfigWait(false)
        setTimeout(() => setIsConfigSnapshot(false), 0)
      }
    })
    return () => {
      configRef.off('value')
    }
  }, [])

  useEffect(() => {
    if (!isConfigSnapshot && !configWait) {
      const timer = setTimeout(() => formik.submitForm(), 0)
      return () => clearTimeout(timer)
    }
  }, [JSON.stringify(formik.values)])

  return !configWait ? (
    <RightForm
      showConfirmWhen={false}
      onClose={close}
      title={
        <>
          <Crumbs
            firstCrumbTitle="Publish to App Store & Google Play"
            onFirstCrumbClick={close}
            icon={<Icon name={Name.RIGHT_SIDEBAR_CLOSE} />}
            small
          />
          <Button onClick={() => {}}>Send to review</Button>
        </>
      }
      form={
        <>
          <CustomSectionTitle mt="20px" mb="5px">
            Binaries
          </CustomSectionTitle>
          <CustomSectionSubTitle pb="0px">
            Select your app’s binary files for distribution on the App Store and Google Play.
          </CustomSectionSubTitle>
          <CustomSectionTitle mt="40px" mb="5px">
            App Information
          </CustomSectionTitle>
          <CustomSectionSubTitle pb="0px">
            The product page for this app version will be published on the App Store and Google Play with the assets and
            metadata listed below.
          </CustomSectionSubTitle>
          {inputs(properties.slice(0, 9), formik)}
          <CustomSectionTitle mt="40px" mb="5px">
            App Privacy & Support
          </CustomSectionTitle>
          <CustomSectionSubTitle pb="0px">
            The product page for this app version will be published on the App Store and Google Play with the assets and
            metadata listed below.
          </CustomSectionSubTitle>
          <CustomSectionSubTitle spaceBetween mt="30px">
            <b>Terms & Policies</b>
            <CustomSectionTitleAction to={`/projects/${id}/collections/terms`} onClick={close}>
              Edit
            </CustomSectionTitleAction>
          </CustomSectionSubTitle>
          <Box mt="-5px">
            <CustomSectionSubTitle secondary pb="0px">
              Provide links to your app’s privacy policy and terms of service to comply with app store requirements.
            </CustomSectionSubTitle>
            <TermsTable />
          </Box>
          {inputs(properties.slice(9), formik)}
          <CustomSectionTitleWithActionWrapper mt="40px" mb="5px">
            <CustomSectionTitle>Products</CustomSectionTitle>
            <CustomSectionTitleAction to={`/projects/${id}/monetization`} onClick={close}>
              Edit
            </CustomSectionTitleAction>
          </CustomSectionTitleWithActionWrapper>
          <CustomSectionSubTitle pb="0px">
            Provide details about the in-app products and subscriptions available for your app. This information will be
            published on the App Store and Google Play. Use this section to manage your app’s offerings, pricing, and
            descriptions.
          </CustomSectionSubTitle>
          {/* <ProductsTable fromStoreManager /> */}
        </>
      }
    />
  ) : (
    <></>
  )
}
