import { memo } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import * as styled from './PhoneNumberInput.styled'

import { FieldProps } from '../../types'

interface Props extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const PhoneNumberInput = memo((props: Props) => (
  <styled.PhoneNumberInput>
    <PhoneInput
      country="us"
      value={props.value}
      onChange={value => props.onChange(value || null)}
      placeholder={props.placeholder}
      disabled={props.disabled}
      data-1p-ignore
    />
  </styled.PhoneNumberInput>
))
