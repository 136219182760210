import React, { useRef, useState } from 'react'

import { Button, ButtonVariant, DropDown, Icon, Name } from 'components'
import { useOnClickOutside } from 'hooks'

export const Version: React.FC = () => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  return (
    <div ref={ref}>
      <DropDown
        top="36px"
        width="200px"
        options={[{ label: 'CodePlatform-1.0-preview' }]}
        outerOpenState={open}
        onClick={() => setOpen(open => !open)}
        labelElement={
          <Button
            active={open}
            variant={ButtonVariant.TOP_PANEL_ACTION}
            rightIcon={<Icon name={Name.GLOBAL_ARROW_BUTTON} />}
            onClick={() => setOpen(open => !open)}
          >
            CodePlatform-1.0-preview
          </Button>
        }
      />
    </div>
  )
}
