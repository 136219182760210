import React, { useMemo } from 'react'

import * as styled from './Loader.styled'

export enum LoaderVariant {
  DEFAULT = 'default',
  DOTS = 'dots',
}

interface ILoader {
  variant?: LoaderVariant
  styles?: React.CSSProperties
  small?: boolean
}

export const Loader: React.FC<ILoader> = ({ variant = LoaderVariant.DEFAULT, styles, small }) => {
  const dotsText = useMemo(() => {
    const dotsTexts = ['Flipping Bits...', 'Shifting Bytes...', 'Counting Bits and Bytes...']
    return dotsTexts[Math.floor(Math.random() * dotsTexts.length)]
  }, [])

  switch (variant) {
    case LoaderVariant.DEFAULT:
      return (
        <svg
          style={styles}
          xmlns="http://www.w3.org/2000/svg"
          width={small ? '16' : '38'}
          height={small ? '16' : '38'}
          viewBox="0 0 38 38"
          stroke="#7F899E"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="4">
              <circle strokeOpacity=".5" cx="18" cy="18" r="17" />
              <path d="M35 18c0-9.94-8.06-17-17-17" stroke="#747BF0">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      )
    case LoaderVariant.DOTS:
      return (
        <styled.Dots>
          <div />
          {dotsText}
        </styled.Dots>
      )
    default:
      return null
  }
}
