import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import { CodePlatformTheme } from './Theme'
import './fonts.css'

interface IGlobalStyle {
  theme: CodePlatformTheme
}

export const GlobalStyle = createGlobalStyle`
  ${reset} 

  html, body, #root {
    height:  100%;
  }

  body {
    font-family: ${(props: IGlobalStyle) => props.theme.fonts.primary};
    font-size: 16px;
    -webkit-tap-highlight-color: transparent; 
  }

  button {
    font: inherit;
  }

  h1, h2, h3, h4, h5, hr {
    margin: 0;
  }

  div {
    box-sizing: border-box;
  }

  img, svg {
    flex-shrink: 0;
  }

  * {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${props => props.theme.colors.scrollBarBackground};
      border-radius: 10px;

      &:hover {
        background-color: ${props => props.theme.colors.scrollBarThumb};
      }
    }
  }
`
