import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import {
  CustomSection,
  CustomSectionSubTitle,
  CustomSectionTitle,
  CustomSectionTitleHashLink,
  CustomSectionTitleWithActionWrapper,
  PWAPreview,
} from 'components'
import { ProjectContext } from 'context'
import { useScreenshotFromVB } from 'hooks'

export const Paywalls: React.FC = () => {
  const {
    project: { id },
  } = useContext(ProjectContext)
  const getScreenshotFromVB = useScreenshotFromVB()
  const [url, setUrl] = useState('')

  useEffect(() => {
    getScreenshotFromVB('Purchases').then(res => setUrl(res))
  }, [getScreenshotFromVB])

  return (
    <CustomSection>
      <CustomSectionTitleWithActionWrapper>
        <CustomSectionTitle>Paywalls</CustomSectionTitle>
        <CustomSectionTitleHashLink to={`/projects/${id}#paywall`}>Edit in Visual Builder</CustomSectionTitleHashLink>
      </CustomSectionTitleWithActionWrapper>
      <CustomSectionSubTitle>Default Variant</CustomSectionSubTitle>
      {url && (
        <Box p="10px 0">
          <PWAPreview noTransform>
            <img src={url} alt="paywall" />
          </PWAPreview>
        </Box>
      )}
    </CustomSection>
  )
}
