import { Box } from '@mui/material'
import { AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as styled from './CollectionRecord.styled'

import {
  Button,
  Crumbs,
  DropDown,
  Icon,
  InputProperty,
  Name,
  RemoveContent,
  RightForm,
  Status,
  Text,
  Type,
  getField,
  inputs,
  validate,
} from 'components'
import { CMSContext, ProgressContext, ProjectContext } from 'context'
import { Folder } from 'context/types'
import { collectionPrefix } from 'hooks'
import {
  CMSCollectionRecord,
  CMSService,
  RecordStatus,
  ResourceTypes,
  createAssetProperties,
  getCollectionName,
  oldFileName,
  rootFolderId,
} from 'services/cms'
import { Collection, ValueType } from 'utils'

export const refreshRecordsEvent = 'refreshRecordsEvent'
export const refreshRecords = () => document.dispatchEvent(new Event(refreshRecordsEvent))

interface Props {
  recordId: string
  collectionId: string
  currentCollection: Collection
  currentFolder?: Folder
  resourceType?: ResourceTypes
  addRef?: (val: any) => void
  refreshRef?: () => void
  close?: () => void
  page?: string
}

export const CollectionRecord: React.FC<Props> = ({
  recordId,
  collectionId,
  currentCollection,
  currentFolder,
  resourceType,
  addRef,
  refreshRef,
  close,
  page,
}) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id },
    config: {
      data: { dataObjects = [], collections = [] },
    },
  } = useContext(ProjectContext)
  const { assets } = useContext(CMSContext)
  const navigate = useNavigate()
  const [wait, setWait] = useState(true)
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')
  const [status, setStatus] = useState<RecordStatus>()
  const [lastUpdate, setLastUpdate] = useState(+new Date())
  const [localLoading, setLocalLoading] = useState(false)
  const [publishLoading, setPublishLoading] = useState(false)
  const wasChanged = useRef(false)
  const [toRemove, setToRemove] = useState(false)
  const assetRealProperties = assets?.properties || []
  const properties = currentFolder || resourceType ? createAssetProperties : currentCollection.properties || []
  const subCollectionsProperties: InputProperty[] = useMemo(
    () =>
      collections
        .filter(el => el.name !== currentCollection.name && el.name.startsWith(currentCollection.name))
        .map((el, i) => ({
          name: getCollectionName(el.name),
          type: ValueType.subCollection,
          collection: { name: `${collectionId}/${recordId}/${getCollectionName(el.name)}` },
          position: i,
        })),
    [JSON.stringify(collections)]
  )

  const formik = useFormik({
    initialValues: {} as any,
    validate: data => validate(data, properties, dataObjects),
    onSubmit: () => publishRecord(true),
  })

  const subCollectionsFormik = useFormik({
    initialValues: {},
    validate: data => validate(data, subCollectionsProperties),
    onSubmit: data => {},
  })

  useEffect(() => {
    if (!close) {
      return () => {
        if (wasChanged.current) {
          refreshRecords()
        }
      }
    }
  }, [])

  useEffect(() => {
    setWait(true)
    startLoader()
    CMSService.getRecord(id, collectionId, recordId)
      .then(res => setForm(res))
      .catch(err => toast(err))
      .finally(() => {
        stopLoader()
        setWait(false)
      })
  }, [collectionId, recordId, lastUpdate])

  const setForm = (res: AxiosResponse<any, any>) => {
    const record: CMSCollectionRecord = {}
    if (currentFolder || resourceType) {
      assetRealProperties.forEach(property => {
        record[property.name] =
          res.data[property.name] !== undefined
            ? res.data[property.name]
            : getField(property.type)?.getDefaultValue?.(dataObjects, property.dataObject) || null
      })
      if (record.url && record.resourceType) {
        record.resource = {
          url: record.url,
          name: oldFileName,
          size: record.bytes,
          type: `${record.resourceType}/${record.format}`,
          thumbnailUrl: record.thumbnailUrl,
        }
      } else {
        record.resource = null
      }
      if (!record.folder) {
        record.folder = currentFolder?.id || rootFolderId
      }
    } else {
      properties.forEach(property => {
        record[property.name] =
          res.data[property.name] !== undefined
            ? res.data[property.name]
            : getField(property.type)?.getDefaultValue?.(dataObjects, property.dataObject) || null
      })
    }
    setStatus(res.data.recordStatus)
    setValuesBeforeEdit(JSON.stringify(record) + record.resource?.name)
    formik.setValues(record)
  }

  const publishRecord = (publish: boolean) => {
    setPublishLoading(true)
    startLoader()
    CMSService.publishRecord(id, collectionId, recordId, publish)
      .then(res => {
        setStatus(res.data.recordStatus)
        if (refreshRef) {
          refreshRef()
        } else if (addRef) {
          document.dispatchEvent(new Event(`ReferenceItem/${collectionId}/${recordId}`))
        } else {
          wasChanged.current = true
        }
      })
      .catch(err => toast(err))
      .finally(() => {
        stopLoader()
        setPublishLoading(false)
      })
  }

  const dublicateRecord = () => {
    setLocalLoading(true)
    startLoader()
    const record = formik.values
    const recordCopy = JSON.parse(JSON.stringify(record)) as CMSCollectionRecord
    if (currentFolder || resourceType) {
      delete recordCopy.resource
    }
    CMSService.createRecord(id, collectionId, recordCopy)
      .then(res => {
        wasChanged.current = true
        navigate(
          `/projects/${id}/${page}/${collectionId}/${res.data.id}${currentFolder ? `?folder=${currentFolder.id}` : ''}`
        )
      })
      .catch(err => toast(err))
      .finally(() => {
        stopLoader()
        setLocalLoading(false)
      })
  }

  const deleteRecord = (callback: () => void) => {
    if (toRemove) {
      setLocalLoading(true)
      startLoader()
      CMSService.deleteRecord(id, collectionId, recordId)
        .then(() => {
          wasChanged.current = true
          setLastUpdate(+new Date())
        })
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
          setLocalLoading(false)
        })
    }
  }

  const showConfirmWhen =
    !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values) + formik.values.resource?.name
  const hasProperties = !!properties.length
  const assetFolder = formik.values.folder
  const closePath = `/projects/${id}/${page}/${collectionId}${
    currentFolder ? `?folder=${assetFolder || currentFolder.id}` : ''
  }`

  useEffect(() => {
    if (showConfirmWhen) {
      const abortController = new AbortController()
      const record = formik.values
      setLocalLoading(true)
      startLoader()
      CMSService.saveRecord(id, collectionId, recordId, record, abortController)
        .then(res => {
          setForm(res)
          if (refreshRef) {
            refreshRef()
          } else if (addRef) {
            delete res.data.recordStatus
            addRef(resourceType ? res.data : `${collectionPrefix}${collectionId}/${recordId}`)
            document.dispatchEvent(new Event(`ReferenceItem/${collectionId}/${recordId}`))
          } else {
            wasChanged.current = true
          }
        })
        .catch(err => !abortController.signal.aborted && toast(err))
        .finally(() => {
          stopLoader()
          setLocalLoading(false)
        })
      return () => {
        abortController.abort()
      }
    }
  }, [formik.values])

  const publishOption = {
    label:
      status === RecordStatus.PUBLISHED ? 'Unpublish' : status === RecordStatus.CHANGED ? 'Publish changes' : 'Publish',
    iconName: Name.GLOBAL_FILE_TRANSPARENT,
    onClick: () => publishRecord(status !== RecordStatus.PUBLISHED),
  }
  const dublicateOption = {
    label: 'Dublicate',
    iconName: Name.RIGHT_SIDEBAR_COPY,
    onClick: () => dublicateRecord(),
  }
  const deleteOption = {
    label: status === RecordStatus.CHANGED ? 'Discard' : 'Delete',
    iconName: Name.PICKERS_DELETE,
    onClick: () => setToRemove(true),
    red: true,
  }

  return (
    <>
      <RightForm
        showConfirmWhen={showConfirmWhen}
        onClose={close ? close : () => navigate(closePath)}
        title={
          wait ? (
            <></>
          ) : (
            <>
              <Box display="flex" alignItems="center" gap="10px">
                {close ? (
                  <Crumbs
                    firstCrumbTitle="Edit record"
                    onFirstCrumbClick={close}
                    secondCrumbTitle={`Add ${resourceType || 'record'}`}
                    small
                  />
                ) : (
                  <Crumbs
                    firstCrumbTitle={getCollectionName(currentCollection.name)}
                    secondCrumbTitle={`${status ? 'Edit' : 'Add'} ${currentFolder ? 'asset' : 'record'}`}
                    icon={<Icon name={Name.RIGHT_SIDEBAR_CLOSE} />}
                    to={closePath}
                    small
                  />
                )}
                {status && <Status status={status} />}
              </Box>
              {status && (
                <Box display="flex" gap="10px">
                  <Button
                    disabled={status === RecordStatus.PUBLISHED || localLoading || publishLoading}
                    onClick={formik.submitForm}
                    loading={publishLoading}
                  >
                    {status === RecordStatus.CHANGED ? 'Publish changes' : 'Publish'}
                  </Button>
                  <styled.ActionsWrapper>
                    <DropDown
                      options={close ? [publishOption] : [publishOption, dublicateOption, deleteOption]}
                      top="25px"
                      labelElement={<Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />}
                    />
                  </styled.ActionsWrapper>
                </Box>
              )}
            </>
          )
        }
        form={
          wait ? (
            <></>
          ) : hasProperties ? (
            <>
              {currentCollection.helpText && <Text type={Type.SUB_TITLE}>{currentCollection.helpText}</Text>}
              {inputs(properties, formik, resourceType)}
              {inputs(subCollectionsProperties, subCollectionsFormik)}
            </>
          ) : (
            <Box mt="40px">
              <Text type={Type.BODY}>No fields for this collection yet</Text>
            </Box>
          )
        }
      />
      <RemoveContent
        toRemove={toRemove}
        title={`${status === RecordStatus.CHANGED ? 'Discard' : 'Delete'} record`}
        text={`Are you sure? This action <b>can not be undone</b>. Selected data will be ${
          status === RecordStatus.CHANGED ? 'discarded' : 'deleted'
        } immediately`}
        clean={() => setToRemove(false)}
        remove={deleteRecord}
      />
    </>
  )
}
