import React, { useEffect, useState } from 'react'

import { Notification, NotificationColor } from 'components'

export const VersionController: React.FC = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!window.location.href.includes('http://localhost')) {
      const getVersion = () =>
        fetch('/version.json')
          .then(res => res.json())
          .then(({ currentVersion }) => process.env.REACT_APP_RELEASE !== currentVersion && setOpen(true))
          .catch(() => {})
      getVersion()
      const interval = setInterval(getVersion, 60000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [])

  // @ts-ignore
  const reload = () => window.location.reload(true)

  return <Notification open={open} color={NotificationColor.DARK} close={() => setOpen(false)} onClick={reload} />
}
