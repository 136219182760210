import { Box } from '@mui/material'
import React, { ReactElement, useContext, useEffect, useState } from 'react'

import {
  acceptDataTypesValues,
  Button,
  ButtonColor,
  CustomPicker,
  dataTypes,
  SelectStringValueField,
  StringField,
  VariableField,
} from 'components'
import { ProjectContext } from 'context'
import { LocalVariable, Screen, ScreenParameter, ValueType, VariableSourceType } from 'utils'

const buttonPadding = { padding: '9.5px' }

interface Props {
  close: () => void
  value: ScreenParameter
  onChange: (val: ScreenParameter) => void
  onRemove?: () => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const InputParameterPicker: React.FC<Props> = ({
  close,
  value,
  onChange,
  onRemove,
  screenConfig,
  allLocalVariables,
}) => {
  const {
    config: {
      data: { dataObjects = [] },
    },
  } = useContext(ProjectContext)
  const [subPicker, setSubPicker] = useState<ReactElement | null>(null)
  const [inputParameter, setInputParameter] = useState(JSON.parse(JSON.stringify(value)) as ScreenParameter)
  const { parameter, required, inout, type, accept, collection, dataObject } = inputParameter

  useEffect(() => {
    setInputParameter(JSON.parse(JSON.stringify(value)) as ScreenParameter)
  }, [value])

  const onConfirm = () => {
    onChange(inputParameter)
    close()
  }

  const onDelete =
    value && onRemove
      ? () => {
          onRemove()
          close()
        }
      : undefined

  const confirmDisabled =
    !parameter ||
    !type ||
    (type === ValueType.array && !accept) ||
    ((type === ValueType.record || accept === ValueType.record) && !collection)

  return (
    <CustomPicker
      title="Input parameters"
      close={close}
      optionsContainer={
        <Box maxHeight="500px" overflow="auto">
          {subPicker || (
            <>
              <StringField
                label="Parameter"
                value={parameter}
                onChange={value => setInputParameter(inputParameter => ({ ...inputParameter, parameter: value }))}
                setSubPicker={setSubPicker}
              />
              <SelectStringValueField
                label="Type"
                value={type}
                onChange={value =>
                  setInputParameter(inputParameter => ({
                    parameter: inputParameter.parameter,
                    type: value as ValueType,
                    required: inputParameter.required,
                    inout: inputParameter.inout,
                  }))
                }
                setSubPicker={setSubPicker}
                options={dataTypes.map(el => el.value)}
              />
              {type === ValueType.array && (
                <SelectStringValueField
                  label="Accept"
                  value={accept}
                  onChange={value =>
                    setInputParameter(inputParameter => ({
                      parameter: inputParameter.parameter,
                      type: inputParameter.type,
                      accept: value as ValueType,
                      required: inputParameter.required,
                      inout: inputParameter.inout,
                    }))
                  }
                  setSubPicker={setSubPicker}
                  options={acceptDataTypesValues.map(el => el.value)}
                />
              )}
              {(type === ValueType.record || accept === ValueType.record) && (
                <VariableField
                  label="Collection"
                  source={{ type: VariableSourceType.collection, collection }}
                  onSourceChange={value =>
                    setInputParameter(inputParameter => ({ ...inputParameter, collection: value?.collection }))
                  }
                  setSubPicker={setSubPicker}
                  collectionMode
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {(type === ValueType.keyValueMap || accept === ValueType.keyValueMap) && (
                <SelectStringValueField
                  label="Data object"
                  value={dataObject}
                  onChange={value => setInputParameter(inputParameter => ({ ...inputParameter, dataObject: value }))}
                  setSubPicker={setSubPicker}
                  options={dataObjects.map(el => el.name)}
                />
              )}
              <SelectStringValueField
                label="Required"
                value={required !== undefined ? String(required) : undefined}
                onChange={value =>
                  setInputParameter(inputParameter => ({
                    ...inputParameter,
                    required: value === 'true',
                    inout: undefined,
                  }))
                }
                setSubPicker={setSubPicker}
                options={['true', 'false']}
              />
              <SelectStringValueField
                label="Inout"
                value={inout !== undefined ? String(inout) : undefined}
                onChange={value =>
                  setInputParameter(inputParameter => ({
                    ...inputParameter,
                    inout: value === 'true',
                    required: undefined,
                  }))
                }
                setSubPicker={setSubPicker}
                options={['true', 'false']}
              />
              <Box
                padding="15px 10px"
                display="flex"
                justifyContent="space-between"
                gap="8px"
                flexWrap="wrap-reverse"
                width="100%"
              >
                {onDelete ? (
                  <Button style={buttonPadding} color={ButtonColor.DELETE} onClick={onDelete}>
                    Remove
                  </Button>
                ) : (
                  <span />
                )}
                <Box display="flex" gap="8px">
                  <Button style={buttonPadding} color={ButtonColor.SECONDARY} onClick={close}>
                    Cancel
                  </Button>
                  <Button style={buttonPadding} onClick={onConfirm} disabled={confirmDisabled}>
                    Confirm
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      }
    />
  )
}
