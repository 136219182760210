import React from 'react'
import { ISvg, Svg } from '../Svg'

const NewConversation: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M9.24899 3.17007H4.51024C2.93996 3.17007 1.66699 4.44299 1.66699 6.01322V15.4905C1.66699 17.0607 2.93996 18.3337 4.51024 18.3337H13.9877C15.558 18.3337 16.831 17.0607 16.831 15.4905L16.831 10.7519M6.40574 13.595L9.85395 12.9002C10.037 12.8633 10.2051 12.7732 10.3371 12.6411L18.0563 4.91796C18.4264 4.54768 18.4261 3.94746 18.0557 3.57749L16.4205 1.94419C16.0502 1.57437 15.4503 1.57462 15.0804 1.94476L7.36041 9.66871C7.22866 9.80052 7.1387 9.96826 7.10178 10.1509L6.40574 13.595Z"
      stroke="#212133"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default NewConversation
