import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { HugeLinkBox, LinkBox } from '../ProjectMenu/ProjectMenu.styled'

import { Button, ButtonVariant, Icon, Modal, Name } from 'components'
import { Banner } from './components'

interface Props {
  wide?: boolean
}

export const Notifications: React.FC<Props> = ({ wide }) => {
  const [active, setActive] = useState(false)
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    const observer = new ResizeObserver(() => setShowBanner(document.body.clientHeight > 800))
    if (wide) {
      observer.observe(document.body)
    }
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <>
      {wide ? (
        <>
          {showBanner && <Banner />}
          <HugeLinkBox onClick={() => setActive(true)}>
            <Box width="36px" minWidth="36px" display="flex" alignItems="center" justifyContent="center">
              <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_NOTIFICATION} />
            </Box>
            <Box ml="10px" display="flex" alignItems="center" justifyContent="space-between" width="100%">
              Notifications
              <Button variant={ButtonVariant.TOP_PANEL_ACTION}>View</Button>
            </Box>
          </HugeLinkBox>
        </>
      ) : (
        <LinkBox hasHover darkBlue_200>
          <i onClick={() => setActive(true)}>
            <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_NOTIFICATION} />
          </i>
        </LinkBox>
      )}
      <Modal active={active} title="Notifications" onClose={() => setActive(false)} width="640px">
        <Box height="450px" display="flex" justifyContent="center" alignItems="center">
          <img src="/assets/noInvites.gif" alt="no invites" style={{ width: '56%', borderRadius: '16px' }} />
        </Box>
      </Modal>
    </>
  )
}
