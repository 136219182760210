import React, { useContext, useEffect, useState } from 'react'
import AceEditor from 'react-ace'

import * as styled from './FileContent.styled'

import { DropDown, Icon, Name } from 'components'
import { UserContext } from 'context'
import { File } from 'pages/Repository/pages'

// @ts-ignore
const modelist = ace.require('ace/ext/modelist')

const getImage = (data: File) => (data.type.includes('image') ? `data:${data.type};base64,${btoa(data.content)}` : '')

interface Props {
  ai?: boolean
  path: string
  onClick: (path: string) => void
  data: File
  flag: string
}

export const FileContent: React.FC<Props> = ({ ai, path, onClick, data, flag }) => {
  const { user } = useContext(UserContext)
  const [image, setImage] = useState(getImage(data))

  useEffect(() => {
    setImage(getImage(data))
  }, [data])

  const crumbs = path.replace(flag, '').split('/')
  const commit = crumbs[0]?.slice(0, 7)
  const name = path.split('/').slice(-1)[0]
  const mode = modelist.getModeForPath(name).name

  return (
    <styled.Container>
      <styled.Name>{name}</styled.Name>
      <styled.DropDowns>
        <DropDown options={[{ label: 'Source' }]} defaultLabel="Source" width="120px" />
        <DropDown
          options={[{ label: 'master', iconName: Name.REPOSITORY_BRANCH }]}
          defaultLabel="master"
          width="120px"
        />
        <DropDown options={[{ label: commit }]} defaultLabel={commit} width="120px" />
      </styled.DropDowns>
      <styled.File>
        <styled.FileHead>
          <Icon name={Name.REPOSITORY_FILE} />
          <styled.FileCrumbs>
            {crumbs.map((el, ind) => (
              <i
                key={el}
                onClick={() =>
                  onClick(
                    `${path
                      .split('/')
                      .slice(0, -(crumbs.length - 1 - ind))
                      .join('/')}${ind === 0 ? '/' : ''}`
                  )
                }
              >
                {ind === 0 ? 'Repository' : `/ ${el}`}
              </i>
            ))}
          </styled.FileCrumbs>
        </styled.FileHead>
        {image ? (
          <styled.ImageWrapper ai={ai} isLimitedAccess={user.isLimitedAccess}>
            <img src={image} alt="img" />
          </styled.ImageWrapper>
        ) : (
          <styled.CodeWrapper ai={ai} isLimitedAccess={user.isLimitedAccess}>
            <AceEditor value={data.content} mode={mode} width="100%" height="100%" readOnly />
          </styled.CodeWrapper>
        )}
      </styled.File>
    </styled.Container>
  )
}
