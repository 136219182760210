import { Box } from '@mui/material'
import Chart from 'chart.js/auto'
import React, { useEffect, useRef } from 'react'

import { Card } from '../Card'

const items = [
  { month: 'Jun', money: 1 },
  { month: 'Jul', money: 2 },
  { month: 'Aug', money: 1 },
  { month: 'Sep', money: 2 },
  { month: 'Oct', money: 2 },
  { month: 'Now', money: 3 },
]

export const MonthlySpend: React.FC = () => {
  const ref = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (ref.current) {
      const chart = new Chart(ref.current, {
        type: 'bar',
        data: {
          labels: items.map(row => row.month),
          datasets: [
            { label: 'Project plans', backgroundColor: '#007488', data: items.map(row => row.money) },
            { label: 'Usage', backgroundColor: '#FF7957', data: items.map(row => row.money) },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: { y: { ticks: { stepSize: 1, callback: value => `${value} $` } } },
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
                boxWidth: 8,
                boxHeight: 8,
              },
            },
          },
        },
      })
      return () => {
        chart.destroy()
      }
    }
  }, [])

  return (
    <Card title="Monthly spend" buttonTitle="View usage" onClick={() => {}}>
      <Box p="15px 30px">
        <canvas height="200px" ref={ref} />
      </Box>
    </Card>
  )
}
