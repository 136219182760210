import styled from 'styled-components'

export const AutocompleteInput = styled.div`
  width: 100%;
  position: relative;
  & > div > div > div {
    & > input {
      height: auto !important;
      padding: 0 !important;
      &::placeholder {
        opacity: unset;
        color: ${props => props.theme.colors.grey1};
      }
      &:disabled {
        &::placeholder {
          color: ${props => props.theme.colors.disabledTextColor};
        }
      }
    }
    & > fieldset {
      display: none;
    }
  }
`

export const ListOption = styled.li`
  background-color: transparent !important;
  &:hover {
    color: ${props => props.theme.colors.darkText}!important;
    background-color: ${props => props.theme.colors.settingField}!important;
  }
`

export const IconWrapper = styled.div<{ absolute?: boolean }>`
  position: ${props => props.absolute && 'absolute'};
  top: ${props => props.absolute && '9px'};
  left: ${props => props.absolute && '18px'};
  display: flex;
  align-items: center;
  margin-right: 5px;
  & svg {
    & g {
      opacity: 1;
    }
    & path {
      fill: ${props => props.theme.colors.darkText};
    }
  }
`
