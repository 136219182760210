import React, { useEffect } from 'react'

export const useOnClickOutside = (ref: React.RefObject<HTMLDivElement>, handler: Function) => {
  useEffect(() => {
    const listener = (e: any) => {
      if (!ref.current || ref.current.contains(e.target)) {
        return
      }
      handler(e)
    }
    const timeout = setTimeout(() => document.addEventListener('click', listener), 0)
    return () => {
      document.removeEventListener('click', listener)
      clearTimeout(timeout)
    }
  }, [ref.current])
}
