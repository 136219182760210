import React from 'react'

import { BooleanInput, SourceInput } from 'components'
import { RightSidebarItem, SubWrapper } from 'partials/RightSidebar/components'
import { LocalVariable, Resource, Screen, ScreenComponent } from 'utils'
import { Common, Id } from '../common'

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const ProductSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const { componentType, name, selected, product } = component

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        <SourceInput
          subLabel="Product"
          source={product?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'product', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <BooleanInput
          subLabel="Selected by default"
          value={selected?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'selected', value: { constant: value } }])}
          source={selected?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'selected', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    </RightSidebarItem>
  )
}
