import { useFormik } from 'formik'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Button, ButtonColor, ButtonSize, InputProperty, inputs, ModalActions, ModalInputs, validate } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { useAddBillingAccount, useBillingAccounts, useParamsFromUrl, useRegions, useSuggestedGcpProjectId } from 'hooks'
import { ProjectsService } from 'services/projects'
import { ValueType } from 'utils'

interface Props {
  onContinue: () => void
  onBack: () => void
  connectGCP: boolean
}

export const Setup: React.FC<Props> = ({ onContinue, onBack, connectGCP }) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id, name: projectName, billingAccountId: projectBillingAccountId },
  } = useContext(ProjectContext)
  const { name, gcpProjectId, regionId, setName, setGcpProjectId, setRegionId } = useParamsFromUrl()
  const { onAddBillingAccount } = useAddBillingAccount()
  const { suggestedGcpProjectId } = useSuggestedGcpProjectId(id, name, connectGCP)
  const { billingAccounts } = useBillingAccounts()
  const { regions } = useRegions(id, connectGCP)
  const [billingAccountId, setBillingAccountId] = useState(projectBillingAccountId || billingAccounts[0]?.id || null)
  const [wait, setWait] = useState(false)

  const formik = useFormik({
    initialValues: connectGCP ? { name, gcpProjectId, billingAccountId, regionId } : { billingAccountId },
    validate: data => validate(data, properties),
    onSubmit: data => {
      const { billingAccountId } = data
      if (billingAccountId) {
        if (projectBillingAccountId) {
          onContinue()
        } else {
          setWait(true)
          ProjectsService.updateBillingAccountId(id, billingAccountId)
            .then(() => onContinue())
            .catch(err => toast(err))
            .finally(() => setWait(false))
        }
      }
    },
  })

  useEffect(
    () => {
      formik.setValues(connectGCP ? { name, gcpProjectId, billingAccountId, regionId } : { billingAccountId })
    },
    connectGCP ? [name, gcpProjectId, billingAccountId, regionId] : [billingAccountId]
  )

  useEffect(() => {
    if (connectGCP) {
      if (name !== formik.values.name) {
        setName(formik.values.name)
      }
    }
  }, [formik.values.name])

  useEffect(() => {
    if (connectGCP) {
      if (gcpProjectId !== formik.values.gcpProjectId) {
        setGcpProjectId(formik.values.gcpProjectId)
      }
    }
  }, [formik.values.gcpProjectId])

  useEffect(() => {
    if (billingAccountId !== formik.values.billingAccountId) {
      setBillingAccountId(formik.values.billingAccountId)
    }
  }, [formik.values.billingAccountId])

  useEffect(() => {
    if (connectGCP) {
      if (regionId !== formik.values.regionId) {
        setRegionId(formik.values.regionId)
      }
    }
  }, [formik.values.regionId])

  useEffect(() => {
    if (connectGCP) {
      if (!name) {
        setName(projectName)
      }
    }
  }, [projectName])

  useEffect(() => {
    if (connectGCP) {
      if (!gcpProjectId) {
        setGcpProjectId(suggestedGcpProjectId)
      }
    }
  }, [suggestedGcpProjectId])

  useEffect(() => {
    if (projectBillingAccountId) {
      setBillingAccountId(projectBillingAccountId)
    } else if (!billingAccountId && billingAccounts[0]?.id) {
      setBillingAccountId(billingAccounts[0].id)
    }
  }, [projectBillingAccountId, JSON.stringify(billingAccounts)])

  useEffect(() => {
    if (connectGCP) {
      if (!regionId && regions[0]?.regionId) {
        setRegionId(regions[0].regionId)
      }
    }
  }, [JSON.stringify(regions)])

  const properties = useMemo(
    (): InputProperty[] =>
      connectGCP
        ? [
            {
              name: 'name',
              type: ValueType.string,
              position: 0,
              isRequired: true,
              title: 'Name your GCP project',
            },
            {
              name: 'gcpProjectId',
              type: ValueType.string,
              position: 1,
              isRequired: true,
              title: 'GCP project id',
              helpText: 'The GCP project id. It cannot be changed later. Choose carefully.',
              editByModal: true,
            },
            {
              name: 'billingAccountId',
              type: ValueType.string,
              position: 2,
              isRequired: true,
              title: 'Select billing account',
              optionsToSelect: billingAccounts.map(el => ({ value: el.id, label: el.name })),
              disabled: !!projectBillingAccountId,
              onAddBillingAccount,
            },
            {
              name: 'regionId',
              type: ValueType.string,
              position: 3,
              isRequired: true,
              title: 'Select the location where the data will be stored',
              optionsToSelect: regions.map(el => ({ value: el.regionId, label: `${el.name} (${el.regionId})` })),
            },
          ]
        : [
            {
              name: 'billingAccountId',
              type: ValueType.string,
              position: 0,
              isRequired: true,
              title: 'Select billing account',
              optionsToSelect: billingAccounts.map(el => ({ value: el.id, label: el.name })),
              disabled: !!projectBillingAccountId,
              onAddBillingAccount,
            },
          ],
    connectGCP
      ? [connectGCP, billingAccounts, projectBillingAccountId, regions]
      : [connectGCP, billingAccounts, projectBillingAccountId]
  )

  return (
    <>
      <ModalInputs>{inputs(properties, formik)}</ModalInputs>
      <ModalActions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={onBack}>
          Back
        </Button>
        <Button size={ButtonSize.DEFAULT} onClick={formik.submitForm} loading={wait}>
          Continue
        </Button>
      </ModalActions>
    </>
  )
}
