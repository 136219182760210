import React, { useContext } from 'react'

import * as styled from './Common.styled'

import { Button, ButtonColor, ButtonSize, Icon, ModalActions, Name } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { GithubParamsName } from 'pages'
import { ProjectsService } from 'services/projects'
import { githubPopup } from './GithubConnectionPopup'

interface Props {
  close: () => void
  stateParam: number
  setGithubToken: React.Dispatch<React.SetStateAction<string>>
}

export const GitRepository: React.FC<Props> = ({ close, stateParam, setGithubToken }) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)

  const continueWithGithub = () => {
    githubPopup(
      `https://github.com/login/oauth/authorize?scope=user&client_id=${
        process.env.REACT_APP_GITHUB_CLIENT_ID
      }&redirect_uri=${window.location.origin + '/connected'}&state=${stateParam}`
    )
      .then(() => {
        setTimeout(() => {
          const code = localStorage.getItem(GithubParamsName.CODE)
          if (code) {
            localStorage.removeItem(GithubParamsName.CODE)
            ProjectsService.getGithubToken(id, code)
              .then(res => setGithubToken(res.data.token))
              .catch(err => toast(err))
          }
        }, 0)
      })
      .catch(err => toast(err))
  }

  return (
    <>
      <styled.Info>
        <p>No Repository Connected</p>
      </styled.Info>
      <ModalActions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={close}>
          Cancel
        </Button>
        <Button
          color={ButtonColor.BLACK}
          size={ButtonSize.DEFAULT}
          onClick={continueWithGithub}
          leftIcon={<Icon name={Name.SIZE_24_GITHUB} />}
        >
          Continue with GitHub
        </Button>
      </ModalActions>
    </>
  )
}
