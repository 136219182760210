import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { ActionsIconTbodyWrapper, Avatar, DropDown, Icon, Name, NoContent, TableWrapper } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { ProjectPermission, useUsers } from 'hooks'
import { firestore } from 'services/firebase'
import { firstLetterUpperCase } from 'utils'

export const TeammatesContent: React.FC = () => {
  const themeContext = useContext(ThemeContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [wait, setWait] = useState(true)
  const [permissions, setPermissions] = useState<ProjectPermission[]>([])
  const users = useUsers(permissions, true)

  useEffect(() => {
    startLoader()
    const unsubscribe = firestore.collection(`projects/${id}/permissions`).onSnapshot({
      next: res => {
        setPermissions(res.docs.map(el => ({ id: el.id, ...el.data() })) as ProjectPermission[])
        stopLoader()
        setWait(false)
      },
      error: err => {
        toast(err)
        stopLoader()
      },
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return wait ? (
    <></>
  ) : (
    <TableWrapper empty={!permissions.length} big>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {permissions.length} teammate{permissions.length !== 1 && 's'}
            </TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        {!!permissions.length ? (
          <TableBody>
            {permissions.map(el => {
              const currentPermissionUser = users.find(user => user.id === el.id)
              return (
                <TableRow key={el.id}>
                  <TableCell>
                    <Box display="flex" gap="10px" alignItems="center">
                      <Avatar value={currentPermissionUser?.photoUrl} width={30} />
                      {currentPermissionUser?.displayName}
                    </Box>
                  </TableCell>
                  <TableCell>{currentPermissionUser?.email}</TableCell>
                  <TableCell>
                    {el.role && (
                      <span
                        style={{
                          padding: '6px 10px',
                          borderRadius: '8px',
                          border: `1px solid ${themeContext.colors.darkBlue_100}`,
                          background: themeContext.colors.tableRoleBg,
                        }}
                      >
                        {firstLetterUpperCase(el.role.toLowerCase().replaceAll('_', ' '))}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell onClick={e => e.stopPropagation()}>
                    <ActionsIconTbodyWrapper>
                      <div>
                        <DropDown
                          options={[
                            { label: 'Delete member', iconName: Name.PICKERS_DELETE, onClick: () => {}, red: true },
                          ]}
                          top="25px"
                          labelElement={
                            <Box display="flex" alignItems="center">
                              <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />
                            </Box>
                          }
                        />
                      </div>
                    </ActionsIconTbodyWrapper>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        ) : (
          <NoContent isTable text="Empty" />
        )}
      </Table>
    </TableWrapper>
  )
}
