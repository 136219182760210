import { useFormik } from 'formik'
import React from 'react'

import { Crumbs, Icon, InputProperty, inputs, Name, RightForm, validate } from 'components'
import { ConfigLogic, firstLetterUpperCase, ValueType } from 'utils'

interface Props {
  name: keyof ConfigLogic
  close: () => void
}

export const LogicItem: React.FC<Props> = ({ name, close }) => {
  const properties: InputProperty[] = [
    {
      name,
      type: ValueType.logicItem,
      position: 0,
      hideLabel: true,
    },
  ]

  const formik = useFormik({
    initialValues: {},
    validate: data => validate(data, properties),
    onSubmit: data => {},
  })

  return (
    <RightForm
      showConfirmWhen={false}
      onClose={close}
      title={
        <Crumbs
          firstCrumbTitle={firstLetterUpperCase(name)}
          onFirstCrumbClick={close}
          icon={<Icon name={Name.RIGHT_SIDEBAR_CLOSE} />}
          small
        />
      }
      form={<>{inputs(properties, formik)}</>}
    />
  )
}
