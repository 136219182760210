import { createContext, memo, useContext, useEffect, useState } from 'react'
import { switchColorConfig } from './constants'
import { getColors, high, low } from './utils/formatters'
import {
  computePickerPosition,
  computeSquareXY,
  getDegrees,
  getGradientType,
  getHandleValue,
  getNewHsl,
  isUpperCase,
} from './utils/utils'

var tinycolor = require('tinycolor2')
const { crossSize } = switchColorConfig
const PickerContext = createContext()

export const PickerContextWrapper = memo(({ children, offsetLeft, value, onChange, squareSize, squareHeight }) => {
  const isGradient = value?.includes('gradient')
  const isImage = value?.includes('url(')
  const gradientType = getGradientType(value)
  const degrees = getDegrees(value)
  const degreeStr = gradientType === 'linear-gradient' ? `${degrees}deg` : 'circle'
  const colors = getColors(value)
  const indexedColors = colors?.map((c, i) => ({ ...c, index: i }))
  const currentColorObj = indexedColors?.filter(c => isUpperCase(c.value))[0] || indexedColors[0]
  const currentColor = currentColorObj?.value
  const selectedColor = currentColorObj?.index
  const currentLeft = currentColorObj?.left
  const [tinyColor, setTinyColor] = useState(tinycolor(currentColor))
  const [inputType, setInputType] = useState('rgb')

  const { r, g, b, a: opacity } = tinyColor.toRgb()
  const { h, s, l } = tinyColor.toHsl()
  const { s: hsvS, v: hsvV } = tinyColor.toHsv()
  const [internalHue, setInternalHue] = useState(Math.round(h))
  const hue = Math.round(h)
  const [x, y] = computeSquareXY([hue, s, l], squareSize, squareHeight)
  const [previousColors, setPreviousColors] = useState([])
  const [previousGraidents, setPreviousGradients] = useState([])
  const [previousImages, setPreviousImages] = useState([])

  useEffect(() => {
    setTinyColor(tinycolor(currentColor))
    setInternalHue(hue)
  }, [currentColor, hue])

  useEffect(() => {
    if (isImage) {
      setPreviousImages([value, ...previousImages?.slice(0, 4)])
    } else if (isGradient) {
      setPreviousGradients([value, ...previousGraidents?.slice(0, 4)])
    } else {
      if (tinycolor(value).isValid()) {
        setPreviousColors([value, ...previousColors?.slice(0, 4)])
      }
    }
  }, [value])

  const createGradientStr = newColors => {
    let sorted = newColors.sort((a, b) => a.left - b.left)
    let colorString = sorted?.map(cc => `${cc?.value} ${cc.left}%`)
    onChange(`${gradientType}(${degreeStr}, ${colorString.join(', ')})`)
  }

  const handleGradient = (newColor, left = currentLeft) => {
    let remaining = colors?.filter(c => !isUpperCase(c.value))
    let newColors = [{ value: newColor.toUpperCase(), left: left }, ...remaining]
    createGradientStr(newColors)
  }

  const handleChange = newColor => {
    if (isGradient) {
      handleGradient(newColor)
    } else {
      onChange(newColor)
    }
  }

  const handleOpacity = e => {
    let newO = getHandleValue(e) / 100
    let newColor = `rgba(${r}, ${g}, ${b}, ${newO})`
    handleChange(newColor)
  }

  const handleHue = e => {
    let newHue = getHandleValue(e) * 3.6
    let newHsl = getNewHsl(newHue, s, l, opacity, setInternalHue)
    handleChange(newHsl)
  }

  const handleColor = (e, ctx) => {
    const [x, y] = computePickerPosition(e, squareHeight)
    const x1 = Math.min(x + crossSize / 2, squareSize - 1)
    const y1 = Math.min(y + crossSize / 2, squareHeight - 1)
    const [r, g, b] = ctx.getImageData(x1, y1, 1, 1).data
    let newColor = `rgba(${r}, ${g}, ${b}, ${opacity})`
    handleChange(newColor)
  }

  const setSelectedColor = index => {
    let newGradStr = colors?.map((cc, i) => ({ ...cc, value: i === index ? high(cc) : low(cc) }))
    createGradientStr(newGradStr)
  }

  const addPoint = e => {
    let left = getHandleValue(e, offsetLeft)
    let newColors = [...colors.map(c => ({ ...c, value: low(c) })), { value: currentColor, left: left }]
    createGradientStr(newColors)
  }

  const deletePoint = () => {
    if (colors?.length > 2) {
      let remaining = colors?.filter((rc, i) => i !== selectedColor)
      createGradientStr(remaining)
    }
  }

  const pickerState = {
    x,
    y,
    s,
    l,
    r,
    g,
    b,
    hue,
    hsvS,
    hsvV,
    value,
    colors,
    degrees,
    opacity,
    onChange,
    addPoint,
    inputType,
    tinyColor,
    handleHue,
    isGradient,
    isImage,
    offsetLeft,
    squareSize,
    handleColor,
    currentLeft,
    deletePoint,
    internalHue,
    squareHeight,
    setInputType,
    gradientType,
    handleChange,
    currentColor,
    selectedColor,
    handleOpacity,
    setInternalHue,
    previousColors,
    handleGradient,
    setSelectedColor,
    previousGraidents,
    previousImages,
  }

  return <PickerContext.Provider value={pickerState}>{children}</PickerContext.Provider>
})

export function usePicker() {
  return useContext(PickerContext)
}
