import { Autocomplete, Paper, TextField } from '@mui/material'
import { memo, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from './SimpleArrayInput.styled'

import { Icon, Name } from 'components'
import { CMSContext } from 'context'
import { rootFolderId } from 'services/cms'
import { isUrl, ValueType } from 'utils'
import { FieldProps } from '../../types'

interface SimpleArrayInputProps extends FieldProps {
  value: string[] | number[] | boolean[] | null
  onChange: (val: string[] | number[] | boolean[] | null) => void
}

export const SimpleArrayInput = memo((props: SimpleArrayInputProps) => (
  <styled.AutocompleteInput>
    <Autocomplete
      size="small"
      fullWidth
      multiple
      freeSolo
      options={props.accept === ValueType.boolean ? [true, false] : []}
      isOptionEqualToValue={() => false}
      disabled={props.disabled}
      // @ts-ignore
      value={props.value || []}
      onChange={(e, v) => {
        const value = (
          props.accept === ValueType.number
            ? v.map(el => +el)
            : props.accept === ValueType.boolean
            ? v
                .filter(el => el === true || el === false || el === 'true' || el === 'false')
                .map(el => (el === 'true' ? true : el === 'false' ? false : el))
            : props.accept === ValueType.url
            ? v.filter(el => isUrl(el as string))
            : v
        ) as string[] | number[] | boolean[]
        props.onChange(value.length ? value : null)
      }}
      PaperComponent={CustomPaper}
      renderInput={params => (
        <TextField
          type={props.accept === ValueType.number ? 'number' : 'text'}
          {...params}
          placeholder={`Type the ${props.accept} value and hit enter`}
        />
      )}
      renderOption={(props, option) => <styled.ListOption {...props}>{String(option)}</styled.ListOption>}
      getOptionLabel={option => String(option)}
    />
  </styled.AutocompleteInput>
))

interface MultipleSelectProps extends FieldProps {
  value: string[] | null
  onChange: (val: string[] | null) => void
}

export const MultipleSelect = memo((props: MultipleSelectProps) => (
  <styled.AutocompleteInput>
    <Autocomplete
      size="small"
      fullWidth
      multiple
      options={props.optionsToSelect?.filter(el => !props.value?.includes(el.value)) || []}
      // @ts-ignore
      isOptionEqualToValue={(option, value) => option.value === value}
      disabled={props.disabled}
      // @ts-ignore
      value={props.value || []}
      onChange={(e, v) => {
        const value = v.map(el => (el.value ? el.value : el)) as string[]
        props.onChange(value.length ? value : null)
      }}
      PaperComponent={CustomPaper}
      renderInput={params => <TextField type="text" {...params} placeholder={props.placeholder} />}
      renderOption={(props, option) => <styled.ListOption {...props}>{option.label}</styled.ListOption>}
      // @ts-ignore
      getOptionLabel={option => props.optionsToSelect?.find(el => el.value === option)?.label || option.label || option}
    />
  </styled.AutocompleteInput>
))

interface SimpleSelectProps extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const SimpleSelect = memo((props: SimpleSelectProps) => (
  <styled.AutocompleteInput>
    <Autocomplete
      size="small"
      fullWidth
      options={props.optionsToSelect || []}
      // @ts-ignore
      isOptionEqualToValue={(option, value) => option.value === value}
      disabled={props.disabled}
      // @ts-ignore
      value={props.value}
      // @ts-ignore
      onChange={(e, v) => props.onChange(v?.value || v || null)}
      PaperComponent={CustomPaper}
      renderInput={params => <TextField type="text" {...params} placeholder={props.placeholder} />}
      renderOption={(props, option) => <styled.ListOption {...props}>{option.label}</styled.ListOption>}
      // @ts-ignore
      getOptionLabel={option => props.optionsToSelect?.find(el => el.value === option)?.label || option.label || option}
    />
  </styled.AutocompleteInput>
))

interface FolderInputProps extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const FolderInput = memo((props: FolderInputProps) => {
  const { folders } = useContext(CMSContext)

  return !!folders.length ? (
    <styled.AutocompleteInput>
      <Autocomplete
        size="small"
        fullWidth
        options={folders}
        disabled={props.disabled}
        value={folders.find(el => el.id === props.value)}
        onChange={(e, option) => props.onChange(option?.id || rootFolderId)}
        renderInput={params => <TextField {...params} />}
        getOptionLabel={option => option.name.split('/').join(' / ')}
        PaperComponent={CustomPaper}
        renderOption={(props, option) => (
          <styled.ListOption {...props}>
            <styled.IconWrapper>
              <Icon width={24} height={24} name={Name.ADDITIONAL_HOME_FOLDER} />
            </styled.IconWrapper>
            {option.name.split('/').join(' / ')}
          </styled.ListOption>
        )}
      />
      <styled.IconWrapper absolute>
        <Icon width={24} height={24} name={Name.ADDITIONAL_HOME_FOLDER} />
      </styled.IconWrapper>
    </styled.AutocompleteInput>
  ) : (
    <></>
  )
})

export const CustomPaper = (props: any) => {
  const themeContext = useContext(ThemeContext)

  return (
    <Paper {...props} sx={{ bgcolor: themeContext.colors.selectBackground, color: themeContext.colors.darkText1 }} />
  )
}
