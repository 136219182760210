import React, { useContext, useEffect, useMemo, useState } from 'react'

import * as styled from './RightSidebarItem.styled'

import { Icon, Name, Rotation, Switch } from 'components'
import { DeviceSettingsContext, ProjectContext, selectedDefaultState } from 'context'
import { ScreenComponent, findComponentParentDataById } from 'utils'

export const openAllChildrenSettings = 'openAllChildrenSettings'

interface Props {
  children: React.ReactNode
  hasCloseIcon?: boolean
  canHide?: boolean
  title: string
  onClose?: () => void
  small?: boolean
  switchValue?: boolean
  onSwitchChange?: (val: boolean) => void
  onBackClick?: () => void
}

export const RightSidebarItem: React.FC<Props> = ({
  children,
  hasCloseIcon,
  canHide,
  title,
  onClose,
  small,
  switchValue,
  onSwitchChange,
  onBackClick,
}) => {
  const {
    config: { screens = [] },
  } = useContext(ProjectContext)
  const { selected, setSelected } = useContext(DeviceSettingsContext)
  const [open, setOpen] = useState(true)

  useEffect(() => {
    setOpen(onSwitchChange ? !!switchValue : true)
  }, [switchValue])

  const close = () => {
    if (onClose) {
      onClose()
    } else {
      setSelected(selectedDefaultState)
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.detail % 2 === 0) {
      setTimeout(() => document.dispatchEvent(new Event(openAllChildrenSettings + title)), 0)
      if (canHide) {
        setOpen(true)
      }
    } else {
      if (canHide) {
        setOpen(open => !open)
      }
    }
  }

  const onCrumbClick = useMemo(() => {
    if (!small) {
      const componentId = selected.subComponentId || selected.componentId
      if (componentId) {
        const screen = screens.find(el => el.id === selected.screenId)
        if (screen) {
          const componentParentData = findComponentParentDataById(screen, componentId)
          if (componentParentData) {
            const { componentParent } = componentParentData
            if (selected.subComponentId) {
              if (selected.innerModeComponentId === componentParent.id) {
                return () =>
                  setSelected(selected => ({
                    ...selected,
                    componentId: componentParent.id,
                    subComponentId: '',
                    innerModeComponentId: '',
                  }))
              } else {
                return () => setSelected(selected => ({ ...selected, subComponentId: componentParent.id }))
              }
            } else {
              if ((componentParent as ScreenComponent).componentType) {
                return () =>
                  setSelected(selected => ({
                    ...selected,
                    componentId: componentParent.id,
                    innerModeComponentId: '',
                  }))
              } else {
                return () =>
                  setSelected(selected => ({
                    ...selected,
                    screenId: componentParent.id,
                    componentId: '',
                    innerModeComponentId: '',
                  }))
              }
            }
          }
        }
      } else if (selected.tabScreen) {
        return () => setSelected(selected => ({ ...selected, tabScreen: '', innerModeComponentId: '' }))
      } else if (selected.tabBarId) {
        return () => setSelected(selected => ({ ...selected, tabBarId: '' }))
      } else if (selected.topBarId) {
        return () => setSelected(selected => ({ ...selected, topBarId: '' }))
      }
    }
  }, [selected])

  return (
    <styled.Wrapper small={!!small}>
      <styled.TitleWrapper onClick={handleClick} pointer={!!canHide} small={!!small} isHidden={!title}>
        <styled.Title small={!!small} grey1={switchValue === false}>
          {(onCrumbClick || onBackClick) && (
            <span onClick={onCrumbClick || onBackClick}>
              ..
              <Icon name={Name.RIGHT_SIDEBAR_ARROW_THIN_DOWN} rotation={Rotation.RIGHT} width={12} height={12} />
            </span>
          )}
          {title}
        </styled.Title>
        {hasCloseIcon && <Icon name={Name.RIGHT_SIDEBAR_CLOSE} onClick={close} />}
        {canHide && <Icon name={Name.RIGHT_SIDEBAR_ARROW_THIN_DOWN} rotation={open ? undefined : Rotation.RIGHT} />}
        {onSwitchChange && <Switch checked={!!switchValue} onChange={onSwitchChange} />}
      </styled.TitleWrapper>
      {open && children}
    </styled.Wrapper>
  )
}
