import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'

import { CustomSection, CustomSectionTitle, InputProperty, PWAPreview, inputs, validate } from 'components'
import { useScreenshotFromVB } from 'hooks'
import { InnerLaunchScreenIcon, ValueType } from 'utils'

const properties: InputProperty[] = [
  {
    name: InnerLaunchScreenIcon.splashIcon,
    type: ValueType.launchScreenIcon,
    position: 0,
    title: 'Splash Icon',
    helpText: 'Icon displayed on the splash screen during app launch.',
    smallWidth: true,
  },
  {
    name: InnerLaunchScreenIcon.brandLogo,
    type: ValueType.launchScreenIcon,
    position: 1,
    title: 'Brand Logo',
    helpText: 'Optional logo for your corporate or umbrella brand.',
    smallWidth: true,
  },
  {
    name: 'backgroundColor',
    type: ValueType.launchScreenBackground,
    position: 2,
    title: 'Background Color Style',
    helpText: 'Background color or gradient for the splash screen.',
    optionsToSelect: [],
    smallWidth: true,
  },
]

export const LaunchScreen: React.FC = () => {
  const getScreenshotFromVB = useScreenshotFromVB()
  const [url, setUrl] = useState('')

  useEffect(() => {
    getScreenshotFromVB('Launch Screen').then(res => setUrl(res))
  }, [getScreenshotFromVB])

  const formik = useFormik({
    initialValues: {
      splashIcon: InnerLaunchScreenIcon.splashIcon,
      brandLogo: InnerLaunchScreenIcon.brandLogo,
    },
    validate: data => validate(data, properties),
    onSubmit: data => {},
  })

  return (
    <CustomSection>
      <CustomSectionTitle mb="-5px">Launch screen</CustomSectionTitle>
      <Box display="flex">
        <Box width="100%">{inputs(properties, formik)}</Box>
        {url && (
          <Box mt="-80px" sx={{ transform: 'scale(0.77)' }}>
            <PWAPreview noTransform>
              <img src={url} alt="launchScreen" />
            </PWAPreview>
          </Box>
        )}
      </Box>
    </CustomSection>
  )
}
