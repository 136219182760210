import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import * as styled from './ProjectMenu.styled'

import { Icon, Name } from 'components'
import { IRoute, routes } from 'routes'
import { Notifications } from '../Notifications'

interface Props {
  id: string
}

export const ProjectMenu: React.FC<Props> = ({ id }) => {
  const { pathname } = useLocation()

  const replacedPath = (path: string) => path.replace(':id', id)

  const checkActive = (route: IRoute) => {
    const path = replacedPath(route.path)
    return route.index ? pathname === path : pathname.startsWith(path)
  }

  return (
    <styled.ControllsWrapper>
      <styled.Navigation>
        {id &&
          routes.map(
            el =>
              el.icon && (
                <styled.LinkBox key={el.path} hasHover active={checkActive(el)}>
                  <styled.Hint>{el.name}</styled.Hint>
                  <Link to={replacedPath(el.customPath || el.path)}>{el.icon}</Link>
                </styled.LinkBox>
              )
          )}
      </styled.Navigation>
      <styled.Navigation>
        <Notifications />
        <styled.LinkBox hasHover darkBlue_200>
          <Link to="/">
            <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_HELP} />
          </Link>
        </styled.LinkBox>
      </styled.Navigation>
    </styled.ControllsWrapper>
  )
}
