import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { HugeLinkBox } from '../ProjectMenu/ProjectMenu.styled'
import * as styled from './Profile.styled'

import { Avatar, DropDown, Icon, Name } from 'components'
import { UserContext } from 'context'
import { auth } from 'services/firebase'

interface Props {
  isXL: boolean
}

export const Profile: React.FC<Props> = ({ isXL }) => {
  const { firebaseUser, user, signIn } = useContext(UserContext)
  const themeContext = useContext(ThemeContext)

  const lastOption = firebaseUser.isAnonymous
    ? {
        label: 'Sign in',
        iconName: Name.PICKERS_LOGOUT,
        onClick: signIn,
      }
    : {
        label: 'Log out',
        iconName: Name.PICKERS_LOGOUT,
        onClick: () => auth.signOut(),
        color: themeContext.colors.delete,
      }

  return (
    <DropDown
      options={
        isXL
          ? [lastOption]
          : [
              { label: 'Account settings', iconName: Name.PICKERS_ACCOUNT_SETTINGS, to: '/account' },
              { label: 'Terms & policies', iconName: Name.PICKERS_TERMS_AND_POLICES, to: '/account', divider: true },
              lastOption,
            ]
      }
      top={isXL ? '-120px' : '-180px'}
      left={!isXL ? '232px' : undefined}
      right={isXL ? '0px' : undefined}
      width="240px"
      topChildren
      style={{ paddingTop: '0' }}
      labelElement={
        <HugeLinkBox isProfile isXL={isXL}>
          <Avatar value={user.photoUrl} width={35} />
          <Box ml="10px" display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box>{user.displayName}</Box>
            <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />
          </Box>
        </HugeLinkBox>
      }
    >
      <styled.ProfileInfo>
        <Avatar value={user.photoUrl} width={35} />
        <Box ml="10px">
          <p>{user.displayName}</p>
          <p>{firebaseUser.email}</p>
        </Box>
      </styled.ProfileInfo>
    </DropDown>
  )
}
