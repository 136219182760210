import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 310px;
  width: 100%;
  white-space: normal;
  & p {
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.theme.colors.grey1};
    text-align: center;
    max-width: 245px;
  }
`

export const TalbeBody = styled.tbody`
  width: 100%;
  & tr {
    pointer-events: none;
    & td {
      padding: 0;
      &:hover {
        background: transparent !important;
      }
    }
    &:hover {
      background: transparent !important;
    }
  }
`
