import { Box } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

import * as styled from './Fields.styled'

import { getFieldIcon, Icon, Name } from 'components'
import { CMSCollectionProperty } from 'services/cms'
import { moveTopBottomInList } from 'utils'

interface Props {
  properties: CMSCollectionProperty[]
  setProperties: React.Dispatch<React.SetStateAction<CMSCollectionProperty[]>>
  propertiesToRemoveIds: string[]
  activePropertyId: string
  setActivePropertyId: React.Dispatch<React.SetStateAction<string>>
  restoreField: (propertyId: string) => void
  setToRemove: React.Dispatch<React.SetStateAction<CMSCollectionProperty | null>>
}

export const Movable: React.FC<Props> = ({
  properties,
  setProperties,
  propertiesToRemoveIds,
  activePropertyId,
  setActivePropertyId,
  restoreField,
  setToRemove,
}) => {
  const [copy, setCopy] = useState(properties)

  useEffect(() => {
    setCopy(properties)
  }, [JSON.stringify(properties)])

  const propertiesToShow = useMemo(() => copy.filter(el => !el.isHidden), [copy])

  const onMovableMouseDown = (e: any, property: CMSCollectionProperty) => {
    const node = e.target.parentNode.parentNode
    setActivePropertyId(property.id)
    moveTopBottomInList(
      node,
      10,
      property,
      copy,
      setCopy,
      setProperties,
      undefined,
      copy.length - propertiesToShow.length
    )
  }

  return (
    <styled.Properties>
      {propertiesToShow.map(el => (
        <styled.Property
          key={el.id}
          onClick={e => {
            e.stopPropagation()
            setActivePropertyId(el.id)
          }}
          active={el.id === activePropertyId}
        >
          <Box width="100%">
            <styled.PropertyMove onMouseDown={e => onMovableMouseDown(e, el)}>
              <Icon name={Name.ADDITIONAL_MOVABLE} width={32} height={32} />
            </styled.PropertyMove>
            <Icon name={getFieldIcon(el.type)} width={22} height={22} />
            <styled.PropertyName toRemove={propertiesToRemoveIds.includes(el.id)}>{el.name}</styled.PropertyName>
            {el.accept && `${el.accept}s `}
            {el.type}
          </Box>
          {propertiesToRemoveIds.includes(el.id) ? (
            <>
              <styled.DopInfo red>marked for deletion</styled.DopInfo>
              <styled.Action>
                <Icon
                  name={Name.ADDITIONAL_RESET}
                  onClick={e => {
                    e.stopPropagation()
                    restoreField(el.id)
                  }}
                />
              </styled.Action>
            </>
          ) : (
            <>
              {el.isRequired && <styled.DopInfo>Required</styled.DopInfo>}
              {el.isSystem && <styled.DopInfo>system field</styled.DopInfo>}
              {!el.isSystem && (
                <styled.Action>
                  <Icon
                    name={Name.RIGHT_SIDEBAR_DELETE}
                    onClick={e => {
                      e.stopPropagation()
                      setToRemove(el)
                    }}
                  />
                </styled.Action>
              )}
            </>
          )}
        </styled.Property>
      ))}
    </styled.Properties>
  )
}
