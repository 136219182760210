import { Portal } from '@mui/material'
import React, { useEffect } from 'react'

import * as styled from './Modal.styled'

import { Button, ButtonColor, ButtonSize, Icon, Name, Text, Type } from 'components'

interface Props {
  children?: React.ReactNode
  active: boolean
  title?: string
  subTitle?: string
  onClose: () => void
  onButtonClick?: React.MouseEventHandler<HTMLElement>
  buttonTitle?: string
  buttonDisabled?: boolean
  buttonLoading?: boolean
  deleteButton?: boolean
  width?: string
}

export const Modal: React.FC<Props> = ({
  children,
  active,
  title,
  subTitle,
  onClose,
  onButtonClick,
  buttonTitle,
  buttonDisabled,
  buttonLoading,
  deleteButton,
  width,
}) => {
  useEffect(() => {
    const onKeyDown = (e: Event) => (e as KeyboardEvent).code === 'Escape' && onClose()
    if (active) {
      document.addEventListener('keydown', onKeyDown)
    }
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [active])

  return (
    <Portal>
      <styled.ModalOverlay active={active} onMouseDown={onClose}>
        <styled.ModalWrapper active={active} onMouseDown={e => e.stopPropagation()} width={width}>
          {title && (
            <Text type={Type.H1} fontWeight="700" as="span">
              {title}
            </Text>
          )}
          {subTitle && <Text type={Type.SUB_TITLE}>{subTitle}</Text>}
          <styled.CloseIcon>
            <Icon name={Name.SIZE_24_CLOSE} onClick={onClose} />
          </styled.CloseIcon>
          {active && children}
          {!!buttonTitle && (
            <styled.ModalActions>
              <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={onClose} type="button">
                Cancel
              </Button>
              <Button
                size={ButtonSize.DEFAULT}
                color={deleteButton ? ButtonColor.DELETE : ButtonColor.PRIMARY}
                disabled={buttonDisabled}
                onClick={onButtonClick}
                type="button"
                loading={buttonLoading}
              >
                {buttonTitle}
              </Button>
            </styled.ModalActions>
          )}
        </styled.ModalWrapper>
      </styled.ModalOverlay>
    </Portal>
  )
}
