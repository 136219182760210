import React from 'react'

import { Connections, CustomSectionsWrapper, Text, Type } from 'components'
import { Links, SettingsLayout } from '../components'

export const Domains: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Domains</Text>
    <Text type={Type.SUB_TITLE}>Connect your app to a custom domain for a professional and branded experience.</Text>
    <Links />
    <CustomSectionsWrapper>
      <Connections domains />
    </CustomSectionsWrapper>
  </SettingsLayout>
)
