import styled from 'styled-components'

export const PopoverBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Text = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  min-width: 360px;
  max-width: 500px;
  padding: 8px;
  background-color: ${props => props.theme.colors.selectBackground};
  border: 1px solid ${props => props.theme.colors.darkBlue_100};
  border-radius: 12px;
  box-shadow: 0px 2px 8px 0px #1a18182d;
  z-index: 12;
  color: ${props => props.theme.colors.darkText};
  font-size: 14px;
  font-weight: 400;
  & > a {
    text-decoration: none;
    color: ${props => props.theme.colors.primaryText};
  }
`
