import React from 'react'

import * as styled from './Common.styled'

interface Props {
  mt: string
}

export const Terms: React.FC<Props> = ({ mt }) => (
  <styled.Paragraph mt={mt}>
    CodePlatform stores this information securely. Your account security is always our priority. By connecting an
    account you agree to these <b>terms of service</b> and <b>privacy policy</b>.
  </styled.Paragraph>
)
