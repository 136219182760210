import React from 'react'

import * as styled from './Project.styled'

import { FetchProjectWrapper } from 'components'
import { lg, useIsBreakpoint, xxl } from 'hooks'
import { Studio } from 'pages'
import { TopPanelProvider } from 'providers'

interface Props {
  id: string
  close: () => void
  isMD: boolean
  isXL: boolean
}

export const Project: React.FC<Props> = ({ id, close, isMD, isXL }) => {
  const isLG = useIsBreakpoint(lg)
  const isXXL = useIsBreakpoint(xxl)

  return (
    <styled.Project>
      <TopPanelProvider>
        {id && (
          <FetchProjectWrapper ai close={close} id={id} isMD={isMD} isLG={isLG} isXXL={isXXL && !isXL}>
            <Studio ai isXL={isXL} />
          </FetchProjectWrapper>
        )}
      </TopPanelProvider>
    </styled.Project>
  )
}
