import styled, { css } from 'styled-components'

export const FrameWrapper = styled.div<{
  mobileMode: boolean
  desktopMode: boolean
  $width?: string
  $height?: string
  noTransform?: boolean
  ai?: boolean
  isLimitedAccess: boolean
}>`
  position: relative;
  max-width: ${props => !props.desktopMode && !props.mobileMode && (props.$width || '1180px')};
  max-height: ${props => !props.desktopMode && !props.mobileMode && (props.$height || '820px')};
  width: ${props => (props.desktopMode ? '100%' : props.mobileMode ? '410px' : props.$width || '100%')};
  height: ${props => (props.desktopMode ? '100%' : props.mobileMode ? '864px' : props.$height || '100%')};
  border-radius: ${props => (props.mobileMode ? '60px' : !props.desktopMode && '12px')};
  border: ${props =>
    props.mobileMode ? `10px solid #2d2d2d` : !props.desktopMode && `0.5px solid ${props.theme.colors.borderColor}`};
  box-shadow: ${props => !props.mobileMode && !props.desktopMode && '0px 2px 50px 0px #1a181826'};
  overflow: ${props => props.mobileMode && 'hidden'};
  & > iframe,
  & > img {
    border-radius: ${props => !props.mobileMode && 'inherit'};
  }
  @media (min-width: 768px) {
    ${props =>
      !props.noTransform &&
      !props.desktopMode &&
      css`
        @media (max-height: ${!props.ai && props.isLimitedAccess ? 990 : 940}px) {
          transform: scale(0.9);
        }
        @media (max-height: ${!props.ai && props.isLimitedAccess ? 900 : 850}px) {
          transform: scale(0.8);
        }
        @media (max-height: ${!props.ai && props.isLimitedAccess ? 810 : 760}px) {
          transform: scale(0.7);
        }
        @media (max-height: ${!props.ai && props.isLimitedAccess ? 720 : 670}px) {
          transform: scale(0.6);
        }
        @media (max-height: ${!props.ai && props.isLimitedAccess ? 640 : 590}px) {
          transform: scale(0.5);
        }
        @media (max-height: ${!props.ai && props.isLimitedAccess ? 550 : 500}px) {
          transform: scale(0.4);
        }
        @media (max-height: ${!props.ai && props.isLimitedAccess ? 460 : 410}px) {
          transform: scale(0.3);
        }
      `}
  }
`

export const Home = styled.div`
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  height: 5px;
  width: 134px;
  border-radius: 10px;
  background: #2d2d2d;
  @media (max-width: 767px) {
    display: none;
  }
`

export const Resizer = styled.div<{ bottom?: boolean }>`
  position: absolute;
  top: ${props => (props.bottom ? 'calc(100% + 10px)' : '50%')};
  left: ${props => (props.bottom ? '50%' : 'calc(100% + 10px)')};
  transform: ${props => (props.bottom ? 'translateX(-50%)' : 'translateY(-50%)')};
  width: ${props => (props.bottom ? 40 : 4)}px;
  height: ${props => (props.bottom ? 4 : 40)}px;
  cursor: ${props => (props.bottom ? 'ns-resize	' : 'ew-resize')};
  border-radius: 2px;
  background: ${props => props.theme.colors.borderColor};
  transition: 300ms ease-out;
  &:hover {
    background: ${props => props.theme.colors.darkBlue_300};
  }
`
