import { Box, Table, TableBody } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from './PaymentHistory.styled'

import { Icon, Loader, Name, NoContent } from 'components'
import { ProgressContext } from 'context'
import { ProjectsService } from 'services/projects'
import { firstLetterUpperCase, getMoney } from 'utils'
import { Card } from '../Card'

export const PaymentHistory: React.FC = () => {
  const { toast } = useContext(ProgressContext)
  const { billingAccountId } = useParams()
  const [wait, setWait] = useState(true)
  const [invoices, setInvoices] = useState<any[]>([])

  useEffect(() => {
    setWait(true)
    const abortController = new AbortController()
    ProjectsService.getInvoices(billingAccountId as string, abortController)
      .then(res => setInvoices(res.data))
      .catch(err => !abortController.signal.aborted && toast(err))
      .finally(() => setWait(false))
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <Card title="Payment history" buttonTitle="View all" onClick={() => {}}>
      {wait ? (
        <Box height="310px" display="flex" justifyContent="center" alignItems="center">
          <Loader />
        </Box>
      ) : (
        <Box p="0 25px" width="100%" display="table">
          {!invoices.length ? (
            <NoContent text="No invoices yet." />
          ) : (
            <Table>
              <TableBody>
                {invoices.map(el => (
                  <styled.Row key={el.id}>
                    <td>{new Date(el.created * 1000).toLocaleDateString('en-US')}</td>
                    <td>{getMoney(el.currency, el.total)}</td>
                    <td>
                      <b>{firstLetterUpperCase(el.status)}</b>
                    </td>
                    <td>{el.number}</td>
                    <td>
                      {el.hosted_invoice_url && (
                        <a href={el.hosted_invoice_url} target="_blank" rel="noreferrer">
                          <Icon name={Name.REPOSITORY_LINK} />
                        </a>
                      )}
                    </td>
                  </styled.Row>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      )}
    </Card>
  )
}
