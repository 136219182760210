import React from 'react'
import { ISvg, Svg } from '../Svg'

const Preview: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M11.68 7.38699C12.1067 7.65944 12.1067 8.34056 11.68 8.61301L4.96 12.9041C4.53333 13.1766 4 12.836 4 12.2911L4 3.7089C4 3.164 4.53333 2.82344 4.96 3.09589L11.68 7.38699Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Preview
