import { Box } from '@mui/material'
import React from 'react'

import { Text, Type } from 'components'

export const Ready: React.FC = () => (
  <Box height="373px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <img src="/assets/ready.svg" alt="ready" />
    <Text type={Type.SUB_TITLE}>Connected!</Text>
  </Box>
)
