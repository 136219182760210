import { Box } from '@mui/material'
import React, { useContext } from 'react'

import { AnyVariableInput, CustomField, SubPicker } from 'components'
import { ProjectContext } from 'context'
import { AnyVariable, getVariableName, LocalVariable, Screen, ValueType } from 'utils'

interface Props {
  valueType: ValueType
  label: string
  value?: AnyVariable
  onChange: (val?: AnyVariable) => void
  setSubPicker: (
    value: React.SetStateAction<React.ReactElement<any, string | React.JSXElementConstructor<any>> | null>
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const AnyVariableField: React.FC<Props> = ({
  valueType,
  label,
  value,
  onChange,
  setSubPicker,
  screenConfig,
  allLocalVariables,
}) => {
  const { language } = useContext(ProjectContext)

  return (
    <CustomField
      label={label}
      value={
        value?.source
          ? getVariableName(value.source)
          : value?.arrayConstant
          ? `[${value.arrayConstant.length} items]`
          : value?.booleanConstant !== undefined
          ? String(value.booleanConstant)
          : value?.textConstant?.locales[language]
      }
      onClick={() =>
        setSubPicker(() => (
          <SubPicker onBack={() => setSubPicker(null)}>
            <Box m="0 10px 222px">
              <AnyVariableInput
                subLabel="Value"
                valueType={valueType}
                value={value}
                onChange={value => {
                  onChange(value)
                  setSubPicker(null)
                }}
                screenConfig={screenConfig}
                allLocalVariables={allLocalVariables}
              />
            </Box>
          </SubPicker>
        ))
      }
    />
  )
}
