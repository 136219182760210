import { Box } from '@mui/material'
import React, { useContext } from 'react'

import * as styled from './TopPanel.styled'

import { BillingConnectionModal } from 'components'
import { ProjectContext, TopPanelContext } from 'context'
import { useTasks } from 'hooks'
import { LogicItem, StoreManager } from 'pages'
import { AIBtns, AITabs, DeployBtn, Navigation, OpenVBBtn, ProfileBtn, RunBtn } from './components'

interface Props {
  ai?: boolean
  close?: () => void
  isMD?: boolean
  isLG?: boolean
  isXL?: boolean
  isXXL?: boolean
}

export const TopPanel: React.FC<Props> = ({ ai, close, isMD, isLG, isXL, isXXL }) => {
  const {
    backButton,
    leftButtons,
    pageLeftButtons,
    pageCenterButtons,
    rightButtons,
    page,
    openStoreManager,
    setOpenStoreManager,
    openLogicItem,
    setOpenLogicItem,
  } = useContext(TopPanelContext)
  const {
    project: { id, name, logoUrl },
  } = useContext(ProjectContext)
  const { tasks, runningTasks, failedTasks, setupSteps } = useTasks()

  return (
    <>
      <styled.TopPanel hasBackButton={!!backButton} isXL={isXL}>
        {isXL ? (
          <>
            <styled.Buttons ai={ai}>
              <styled.Logo>
                <img src={logoUrl} alt="logo" />
              </styled.Logo>
              <styled.Name>{name}</styled.Name>
            </styled.Buttons>
            <styled.Buttons ai={ai} right>
              <RunBtn />
            </styled.Buttons>
          </>
        ) : (
          <>
            {ai ? (
              <>
                <styled.Buttons ai>
                  <AIBtns name={name} close={close} />
                </styled.Buttons>
                {isMD && <styled.Buttons ai={ai} />}
                <styled.Buttons ai center={!isMD} right={isMD}>
                  <AITabs isMD={isMD} isLG={isLG} isXXL={isXXL} />
                </styled.Buttons>
              </>
            ) : (
              <>
                {backButton && <styled.BackButton>{backButton}</styled.BackButton>}
                <styled.Buttons>{pageLeftButtons || leftButtons}</styled.Buttons>
                <Box
                  display="flex"
                  justifyContent="center"
                  position="relative"
                  ml={!!pageCenterButtons ? '-109px' : !!backButton ? '-49px' : undefined}
                >
                  {pageCenterButtons || (
                    <Navigation tasks={tasks} runningTasks={runningTasks} failedTasks={failedTasks} />
                  )}
                </Box>
              </>
            )}
            {rightButtons && (
              <styled.Buttons ai={ai} right>
                {rightButtons}
              </styled.Buttons>
            )}
            {!isMD && (
              <styled.Buttons ai={ai} right hidden={!!rightButtons}>
                <ProfileBtn ai={ai} />
                {ai ? <OpenVBBtn id={id} isLG={isLG} isXXL={isXXL} /> : <RunBtn />}
                <DeployBtn ai={ai} setOpenStoreManager={setOpenStoreManager} tasks={tasks} setupSteps={setupSteps} />
              </styled.Buttons>
            )}
          </>
        )}
      </styled.TopPanel>
      {openStoreManager && <StoreManager close={() => setOpenStoreManager(false)} />}
      {openLogicItem && <LogicItem name={openLogicItem} close={() => setOpenLogicItem(null)} />}
      {page && <styled.Page>{page}</styled.Page>}
      <BillingConnectionModal />
    </>
  )
}

export const EmptyTopPanel: React.FC = () => <styled.TopPanel />
