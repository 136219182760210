import { Box } from '@mui/material'
import React from 'react'

import * as styled from './Common.styled'

export const Ready: React.FC = () => (
  <Box height="388px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <img src="/assets/ready.svg" alt="ready" />
    <styled.SubTitle>Your account is connected!</styled.SubTitle>
  </Box>
)
