import { Box } from '@mui/material'
import React, { useState } from 'react'

import { LocalVariable, Screen, ValueType, VariableSource } from 'utils'
import { CurrentVariable, SelectField, SelectNewSource } from './components'

interface Props {
  valueType?: ValueType
  value?: VariableSource
  onChange: (val: VariableSource) => void
  onRemove?: () => void
  close: () => void
  dataMode?: boolean
  recordMode?: boolean
  collectionMode?: boolean
  withoutTransforms?: boolean
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const Variable: React.FC<Props> = ({
  valueType,
  value,
  onChange,
  onRemove,
  close,
  dataMode,
  recordMode,
  collectionMode,
  withoutTransforms,
  screenConfig,
  allLocalVariables,
}) => {
  const [currentVariable, setCurrentVariable] = useState<VariableSource | null>(value || null)
  const [newSource, setNewSource] = useState<VariableSource | null>(null)

  return (
    <Box maxHeight="500px" overflow="auto">
      {!currentVariable && !newSource ? (
        <SelectNewSource
          value={value}
          setCurrentVariable={setCurrentVariable}
          setNewSource={setNewSource}
          dataMode={dataMode}
          recordMode={recordMode}
          collectionMode={collectionMode}
          allLocalVariables={allLocalVariables}
        />
      ) : newSource && !currentVariable ? (
        <SelectField
          value={value}
          setCurrentVariable={setCurrentVariable}
          newSource={newSource}
          setNewSource={setNewSource}
          dataMode={dataMode}
          recordMode={recordMode}
          collectionMode={collectionMode}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      ) : currentVariable ? (
        <CurrentVariable
          valueType={valueType}
          value={value}
          currentVariable={currentVariable}
          setCurrentVariable={setCurrentVariable}
          setNewSource={setNewSource}
          onChange={onChange}
          onRemove={onRemove}
          close={close}
          dataMode={dataMode}
          recordMode={recordMode}
          collectionMode={collectionMode}
          withoutTransforms={withoutTransforms}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      ) : null}
    </Box>
  )
}
