import { memo, useState } from 'react'

import * as styled from './CoordinateInput.styled'

import { Button, ButtonColor, ButtonSize, Icon, Name } from 'components'
import { CoordinateConstant } from 'utils'
import { MapPortal } from './MapPortal'

interface Props {
  value: CoordinateConstant | null
  onChange: (val: CoordinateConstant | null) => void
  onRemove?: (e: any) => void
}

export const CoordinateItem = memo(({ value, onChange, onRemove }: Props) => {
  const [open, setOpen] = useState(false)

  const onCoordinateChange = (name: 'latitude' | 'longitude', val: string) => {
    const copy = value ? (JSON.parse(JSON.stringify(value)) as CoordinateConstant) : {}
    if (!val) {
      delete copy[name]
    } else {
      copy[name] = val
    }
    onChange(Object.keys(copy).length ? copy : null)
  }

  return (
    <styled.CoordinateInput>
      <input
        onMouseMove={e => e.stopPropagation()}
        type="number"
        value={value?.latitude}
        onChange={e => onCoordinateChange('latitude', e.target.value)}
        placeholder="Latitude"
        data-1p-ignore
      />
      <input
        onMouseMove={e => e.stopPropagation()}
        type="number"
        value={value?.longitude}
        onChange={e => onCoordinateChange('longitude', e.target.value)}
        placeholder="Longitude"
        data-1p-ignore
      />
      <Button
        type="button"
        size={ButtonSize.DEFAULT}
        color={ButtonColor.SECONDARY}
        leftIcon={<Icon name={Name.LEFT_MENU_MAP} />}
        onClick={() => setOpen(true)}
      >
        Open Map
      </Button>
      {onRemove && (
        <Button
          type="button"
          size={ButtonSize.DEFAULT}
          color={ButtonColor.SECONDARY}
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DELETE} />}
          onClick={onRemove}
        >
          Remove
        </Button>
      )}
      {open && <MapPortal value={value} onChange={onChange} close={() => setOpen(false)} />}
    </styled.CoordinateInput>
  )
})
