import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ActionsIconTbodyWrapper, DropDown, Icon, Name, NoContent, RemoveContent, TableWrapper } from 'components'
import { ProgressContext } from 'context'
import { BillingAccount, useBillingAccounts } from 'hooks'
import { ProjectsService } from 'services/projects'

export const BillingAccountsContent: React.FC = () => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const navigate = useNavigate()
  const { billingAccounts, wait } = useBillingAccounts()
  const [toRemove, setToRemove] = useState<BillingAccount | null>(null)

  const deleteBillingAccount = (callback: () => void) => {
    if (toRemove) {
      startLoader()
      ProjectsService.deleteBillingAccount(toRemove.id)
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
        })
    }
  }

  return wait ? (
    <></>
  ) : (
    <>
      <TableWrapper empty={!billingAccounts.length} big>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {billingAccounts.length} billing account{billingAccounts.length !== 1 && 's'}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {!!billingAccounts.length ? (
            <TableBody>
              {billingAccounts.map(el => (
                <TableRow key={el.id} onClick={() => navigate(el.id)}>
                  <TableCell>{el.name}</TableCell>
                  <TableCell onClick={e => e.stopPropagation()}>
                    <ActionsIconTbodyWrapper>
                      <div>
                        <DropDown
                          options={[
                            {
                              label: 'View/edit',
                              iconName: Name.PICKERS_EDIT_RECORDS,
                              onClick: () => navigate(el.id),
                            },
                            {
                              label: 'Delete',
                              iconName: Name.PICKERS_DELETE,
                              onClick: () => setToRemove(el),
                              red: true,
                            },
                          ]}
                          top="25px"
                          labelElement={
                            <Box display="flex" alignItems="center">
                              <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />
                            </Box>
                          }
                        />
                      </div>
                    </ActionsIconTbodyWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <NoContent isTable text="Empty" />
          )}
        </Table>
      </TableWrapper>
      <RemoveContent
        toRemove={toRemove}
        title="Delete billing account"
        text="You are about to delete the billing account <b>{name}</b>. To make sure, enter the folder name below"
        clean={() => setToRemove(null)}
        remove={deleteBillingAccount}
        nameConfirmation
      />
    </>
  )
}
