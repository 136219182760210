import React, { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Modal } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { Integration, IntegrationAppstore, IntegrationStatus } from 'hooks'
import { ProjectsService } from 'services/projects'
import { Code, Login, Ready, Team } from './components'

export const connectAppStoreParamName = 'connect-appstore'

interface Props {
  appstoreIntegration: Integration
}

export const AppStoreConnectionModal: React.FC<Props> = ({ appstoreIntegration }) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const active = !!searchParams.get(connectAppStoreParamName)

  const close = () => {
    searchParams.delete(connectAppStoreParamName)
    setSearchParams(searchParams, { replace: true })
  }

  const unlink = () => {
    close()
    ProjectsService.deleteIntegration(id, appstoreIntegration.id).catch(err => toast(err))
  }

  return (
    <Modal
      active={active}
      title="App Store Connect Account"
      onClose={
        appstoreIntegration.status === IntegrationStatus.OTP || appstoreIntegration.status === IntegrationStatus.TEAM
          ? unlink
          : close
      }
      width="494px"
    >
      {appstoreIntegration.status === IntegrationStatus.READY ? (
        <Ready />
      ) : appstoreIntegration.status === IntegrationStatus.OTP ? (
        <Code token={appstoreIntegration.token} close={unlink} />
      ) : appstoreIntegration.status === IntegrationStatus.TEAM ? (
        <Team
          token={appstoreIntegration.token}
          teams={(appstoreIntegration.data as IntegrationAppstore | undefined)?.teams}
          close={unlink}
        />
      ) : (
        <Login close={close} />
      )}
    </Modal>
  )
}
