import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'

import { FetchProjectWrapper, ReadOnlyMode } from 'components'
import { UserContext } from 'context'
import { useIsBreakpoint, xl } from 'hooks'
import { LeftSidebar } from 'partials'
import { LayoutProvider, ProgressProvider, ProjectsProvider, TopPanelProvider, UserProvider } from 'providers'
import { routes } from 'routes'
import { GlobalStyle, ThemeProvider } from 'theme'

export const Layout: React.FC = () => (
  <LayoutProvider>
    <ThemeProvider>
      <GlobalStyle />
      <ProgressProvider>
        <UserProvider>
          <Children />
        </UserProvider>
      </ProgressProvider>
    </ThemeProvider>
  </LayoutProvider>
)

const Children: React.FC = () => {
  const { user } = useContext(UserContext)
  const isXL = useIsBreakpoint(xl)
  const { id } = useParams()
  const { pathname } = useLocation()
  const ai = routes
    .filter(el => !!el.ai)
    .map(el => el.path)
    .includes(pathname)

  const readOnlyMode = user.isLimitedAccess && id

  return ai ? (
    <Outlet />
  ) : (
    <ProjectsProvider id={id}>
      <TopPanelProvider>
        {readOnlyMode && <ReadOnlyMode />}
        <Box height={readOnlyMode ? 'calc(100% - 50px)' : '100%'} display="flex" position="relative" overflow="hidden">
          <LeftSidebar id={id} isXL={isXL} />
          {!(isXL && !id) && (
            <Box width={`calc(100% - ${id ? 60 : 266}px)`} height="100%" zIndex="1">
              {id ? (
                <FetchProjectWrapper id={id} isXL={isXL}>
                  <Outlet />
                </FetchProjectWrapper>
              ) : (
                <Outlet />
              )}
            </Box>
          )}
        </Box>
      </TopPanelProvider>
    </ProjectsProvider>
  )
}
