import React from 'react'
import { ISvg, Svg } from '../Svg'

export const Authentication: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M13.4953 3.35342C11.8211 2.93734 10.2476 2.18921 8.868 1.15341L8.4 0.800081C8.28461 0.713533 8.14425 0.666748 8 0.666748C7.85576 0.666748 7.7154 0.713533 7.6 0.800081L7.13334 1.15141C5.75377 2.18721 4.1802 2.93534 2.506 3.35141C2.36126 3.38736 2.23274 3.4708 2.14101 3.58839C2.04928 3.70598 1.99963 3.85094 2 4.00008V7.08008C2.00291 8.29676 2.28763 9.49622 2.83184 10.5844C3.37605 11.6726 4.16495 12.6199 5.13667 13.3521L7.6 15.2001C7.7154 15.2866 7.85576 15.3334 8 15.3334C8.14425 15.3334 8.28461 15.2866 8.4 15.2001L10.8667 13.3521C11.8378 12.6196 12.626 11.6721 13.1697 10.5839C13.7133 9.49576 13.9975 8.29648 14 7.08008V4.00008C14 3.85146 13.9503 3.70711 13.8589 3.58994C13.7675 3.47278 13.6395 3.38953 13.4953 3.35342ZM10.4713 7.13808L7.80467 9.80475C7.67965 9.92973 7.51011 9.99994 7.33334 9.99994C7.15656 9.99994 6.98702 9.92973 6.862 9.80475L5.52867 8.47141C5.40723 8.34568 5.34003 8.17728 5.34155 8.00248C5.34307 7.82768 5.41318 7.66047 5.53679 7.53687C5.6604 7.41326 5.8276 7.34315 6.0024 7.34163C6.1772 7.34011 6.3456 7.40731 6.47134 7.52875L7.33334 8.39075L9.52867 6.19542C9.6544 6.07398 9.8228 6.00678 9.9976 6.0083C10.1724 6.00982 10.3396 6.07993 10.4632 6.20354C10.5868 6.32714 10.6569 6.49435 10.6585 6.66915C10.66 6.84395 10.5928 7.01235 10.4713 7.13808Z"
      fill="#7F899E"
    />
  </Svg>
)
