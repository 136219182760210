import styled from 'styled-components'

export const Background = styled.div`
  background-image: url(/assets/transparent.png);
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ImageBoxHover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${props => `${props.theme.colors.black}80`};
  opacity: 0;
  pointer-events: none;
  transition: ${props => props.theme.transition.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  & > button {
    width: 100%;
    border: 1px solid #ffffff33;
    background: #0000003f;
    backdrop-filter: blur(10px);
  }
  & input[type='file'],
  input[type='file']::-webkit-file-upload-button {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`

export const ImageBox = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;
  display: flex;
  justify-content: center;
  position: relative;
  & > img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  &:hover {
    & ${ImageBoxHover} {
      opacity: 1;
      pointer-events: all;
    }
  }
`
