import { Box } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import * as styled from './Template.styled'

import { Button, ButtonColor, ButtonSize, Text, Type } from 'components'
import { ProgressContext } from 'context'
import { ITemplate } from 'context/types'
import { useIcon } from 'hooks'
import { ProjectsService } from 'services/projects'
import { landingUrl } from 'utils'

const removeSpaces = (str?: string) =>
  str
    ?.replaceAll(' ', '-')
    .replaceAll('&', 'and')
    .replaceAll(',', '')
    .replaceAll(':', '')
    .replaceAll('?', '')
    .toLowerCase()

interface Props {
  template?: ITemplate
}

export const Template: React.FC<Props> = ({ template }) => {
  const themeContext = useContext(ThemeContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const navigate = useNavigate()
  const [wait, setWait] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useIcon(ref, template)

  const createProject = () => {
    if (template) {
      setWait(true)
      startLoader()
      ProjectsService.createProject(template.id)
        .then(res => navigate(`/projects/${res.data.id}`))
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setWait(false)
        })
    }
  }

  return (
    <styled.TemplateContainer>
      <styled.TemplateBox load={!template}>
        <styled.TemplateImage src={template?.studioImage?.url} />
        <styled.TemplateHover>
          <Text type={Type.H3} as="p" color={themeContext.colors.darkText3}>
            {template?.description}
          </Text>
          <styled.ButtonsBox>
            <Button
              size={ButtonSize.DEFAULT}
              color={ButtonColor.SECONDARY1}
              to={`${landingUrl}/templates/${removeSpaces(template?.landingSlug)}`}
              disabled={!template?.landingSlug}
            >
              Learn more
            </Button>
            <Button
              size={ButtonSize.DEFAULT}
              color={ButtonColor.SECONDARY1}
              to={template?.previewUrl}
              disabled={!template?.previewUrl}
            >
              Preview
            </Button>
            <Button
              size={ButtonSize.DEFAULT}
              onClick={template?.aiGenerated ? () => navigate('/ai') : createProject}
              disabled={wait}
            >
              Start with {template?.aiGenerated ? 'AI' : 'template'}
            </Button>
          </styled.ButtonsBox>
        </styled.TemplateHover>
      </styled.TemplateBox>
      {template && (
        <styled.BottomBox>
          <styled.IconBox>
            <styled.MainIcon src={template.mainIcon.url} />
            {template.studioAdditionalIcon && <styled.AddIcon ref={ref} />}
          </styled.IconBox>
          <Box>
            <Text type={Type.H3} as="span">
              {template?.title}
            </Text>
            <Text
              type={Type.BODY}
              color={themeContext.colors.black_400}
              styles={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '210px',
              }}
            >
              {template?.subTitle}
            </Text>
          </Box>
        </styled.BottomBox>
      )}
    </styled.TemplateContainer>
  )
}
