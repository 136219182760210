import styled from 'styled-components'

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const SectionTitle = styled.div`
  z-index: 1;
  background-color: ${props => props.theme.colors.selectBackground};
  color: ${props => props.theme.colors.grey};
  font-size: 12px;
  font-weight: 500;
  padding: 5px 0;
  position: sticky;
  top: 0;
  margin-bottom: -10px;
`

export const User = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`

export const UserName = styled.span`
  color: ${props => props.theme.colors.darkText};
  & > i {
    color: ${props => props.theme.colors.grey};
  }
`

export const Role = styled.span`
  margin-left: auto;
  white-space: nowrap;
  color: ${props => props.theme.colors.darkText};
  text-transform: lowercase;
`
