import { useContext, useEffect, useState } from 'react'

import { ProgressContext } from 'context'
import { ProjectsService } from 'services/projects'

export interface Region {
  flag: string
  name: string
  regionId: string
}

export const useRegions = (id: string, subscribe: boolean) => {
  const { toast } = useContext(ProgressContext)
  const [regions, setRegions] = useState<Region[]>([])
  const [wait, setWait] = useState(true)

  useEffect(() => {
    if (subscribe) {
      const abortController = new AbortController()
      fetch('https://us-central1-codeplatform-dev.cloudfunctions.net/country')
        .then(res => res.json())
        .then(res =>
          ProjectsService.getRegions(id, res.cityLatLong, res.city, res.country, res.region, abortController)
        )
        .then(res => setRegions(res.data))
        .catch(err => !abortController.signal.aborted && toast(err))
        .finally(() => setWait(false))
      return () => {
        abortController.abort()
      }
    }
  }, [id, subscribe])

  return { regions, wait }
}
