import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

import { Container, MaxWidthContainer } from '../index.styled'

import { Crumbs, CustomSectionsWrapper, Text, Type } from 'components'
import { Details, MonthlySpend, PaymentHistory, PaymentMethods, Plans, Quotas } from './components'

export const BillingAccountDashboard: React.FC = () => (
  <>
    <Container>
      <MaxWidthContainer>
        <Crumbs firstCrumbTitle="Billing" secondCrumbTitle="Billing account" to="/billing" />
        <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
          Workspace members can create, edit, and delete projects. They can also access project data and invite other
          members.
        </Text>
        <Box display="flex" gap="20px">
          <CustomSectionsWrapper>
            <MonthlySpend />
            <PaymentHistory />
            <Plans />
          </CustomSectionsWrapper>
          <Box width="300px" minWidth="300px" display="flex" flexDirection="column" gap="20px">
            <Details />
            <PaymentMethods />
            <Quotas />
          </Box>
        </Box>
      </MaxWidthContainer>
    </Container>
    <Outlet />
  </>
)
