import styled from 'styled-components'

export const Refresh = styled.p`
  margin-top: 15px;
  color: #737bf8;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  cursor: pointer;
  transition: 0.3s color ease;
`

export const Box = styled.div<{
  open: boolean
  hasText?: boolean
  bgColor: string
  borderColor: string
  center: boolean
  inside: boolean
}>`
  opacity: ${props => (props.open ? '1' : '0')};
  pointer-events: ${props => (props.open ? 'all' : 'none')};
  position: ${props => !props.center && !props.inside && 'fixed'};
  top: 65px;
  right: 25px;
  margin: ${props => props.center && '30px auto -10px'};
  z-index: ${props => !props.center && !props.inside && 1000};
  width: ${props => (props.inside ? 'auto' : props.center ? '437px' : props.hasText ? '555px' : '315px')};
  background-color: ${props => props.bgColor};
  border: 2px solid ${props => props.borderColor};
  border-radius: 16px;
  padding: 15px 15px ${props => (props.hasText ? '20px' : '15px')} 15px;

  & > div {
    & > div {
      & > svg {
        min-width: 24px;
        min-height: 24px;
        max-width: 24px;
        max-height: 24px;
      }
    }
    & > svg {
      cursor: pointer;
      margin-top: 4px;
      min-width: 16px;
      min-height: 16px;
      max-width: 16px;
      max-height: 16px;
      path {
        stroke: ${props => (props.borderColor !== 'transparent' ? props.borderColor : '#FFFFFF')};
      }
    }
  }
  &:hover {
    ${Refresh} {
      color: #444ff5;
    }
  }
`

export const Content = styled.div<{ hasText: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    width: 100%;
  }
`

export const TextBox = styled.div<{ textColor: string }>`
  flex-grow: 1;
  color: ${props => props.textColor};
  margin-top: 3px;
  margin-left: 10px;
  max-width: calc(100% - 50px);
`

export const Title = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  word-break: break-word;
`

export const Description = styled.p<{ inside: boolean }>`
  margin-top: 4px;
  max-width: ${props => !props.inside && '426px'};
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  word-break: break-word;
`

export const Date = styled.p`
  margin-top: 15px;
  font-size: 12px;
  line-height: 135%;
  color: #9999ab;
`

export const BtnBox = styled.div`
  margin-left: 50px;
  height: 100%;
  display: flex;
  align-items: center;
`

export const Btn = styled.button<{ yellow?: boolean }>`
  border-radius: 8px;
  border: 1px solid ${props => (props.yellow ? '#E9B63F' : '#e43f3e')};
  background: #fff;
  height: 30px;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212133;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  white-space: nowrap;
  cursor: pointer;
`
