import { Box } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as styled from './ProfileBtn.styled'

import { Avatar, Button, ButtonVariant, DropDown, Icon, Name } from 'components'
import { ProgressContext, ProjectContext, UserContext } from 'context'
import { ProjectPermission, useOnClickOutside, useUsers } from 'hooks'
import { firestore } from 'services/firebase'

interface Props {
  ai?: boolean
}

export const ProfileBtn: React.FC<Props> = ({ ai }) => {
  const { firebaseUser, user, signIn } = useContext(UserContext)
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const navigate = useNavigate()
  const [permissions, setPermissions] = useState<ProjectPermission[]>([])
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))
  const users = useUsers(permissions, open)

  useEffect(() => {
    if (open) {
      const unsubscribe = firestore.collection(`projects/${id}/permissions`).onSnapshot({
        next: res => setPermissions(res.docs.map(el => ({ id: el.id, ...el.data() })) as ProjectPermission[]),
        error: err => toast(err),
      })
      return () => {
        unsubscribe()
      }
    }
  }, [open])

  const manageTeammates = () => {
    setOpen(false)
    navigate(`/projects/${id}/settings/teammates`)
  }

  return (
    <div ref={ref}>
      <DropDown
        top="36px"
        right="-130px"
        width="300px"
        outerOpenState={open}
        onClick={() => setOpen(open => !open)}
        labelElement={
          <Button
            active
            variant={ButtonVariant.TOP_PANEL_ACTION}
            leftIcon={<Avatar value={user.photoUrl} width={22} bR={4} />}
            rightIcon={<Icon name={Name.GLOBAL_ARROW_BUTTON} />}
            onClick={() => setOpen(open => !open)}
          />
        }
      >
        <Box
          onClick={e => e.stopPropagation()}
          display="flex"
          flexDirection="column"
          gap="15px"
          p="0 15px"
          maxHeight={`calc(100vh - ${ai ? 90 : user.isLimitedAccess ? 130 : 90}px)`}
          overflow="auto"
        >
          <styled.Section>
            <styled.SectionTitle>PROJECT TEAMMATES</styled.SectionTitle>
            {permissions.map(el => {
              const currentPermissionUser = users.find(user => user.id === el.id)
              return (
                <styled.User key={el.id}>
                  <Avatar value={currentPermissionUser?.photoUrl} width={24} bR={6} />
                  <styled.UserName>
                    {currentPermissionUser?.displayName}
                    {el.id === firebaseUser.uid && <i> (you)</i>}
                  </styled.UserName>
                  <styled.Role>{el.role}</styled.Role>
                </styled.User>
              )
            })}
          </styled.Section>
          {firebaseUser.isAnonymous ? (
            <Button style={{ width: '100%', position: 'sticky', bottom: 0 }} onClick={signIn}>
              Sign in
            </Button>
          ) : (
            <Button style={{ width: '100%', position: 'sticky', bottom: 0 }} onClick={manageTeammates}>
              Manage teammates
            </Button>
          )}
        </Box>
      </DropDown>
    </div>
  )
}
