import styled from 'styled-components'

export const Container = styled.div`
  margin: 20px auto 0;
  max-width: 1280px;
  width: 100%;
`

export const Crumbs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  white-space: pre;
  color: ${props => props.theme.colors.grey1};
  font-size: 14px;
  line-height: 21px;
  & a {
    transition: 300ms ease-out;
    text-decoration: none;
    color: ${props => props.theme.colors.grey1};
    &:last-child {
      pointer-events: none;
    }
    &:hover {
      color: ${props => props.theme.colors.darkText};
    }
  }
`

export const File = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
  border-radius: 8px;
  overflow: hidden;
`

export const FileHead = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 14px;
  height: 50px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  background-color: ${props => props.theme.colors.primaryBackground};
  & > div {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${props => props.theme.colors.darkText};
    font-weight: ${props => props.theme.weights.bold};
    & svg {
      margin-right: 10px;
    }
  }
`

export const FileContent = styled.pre<{ isLimitedAccess: boolean }>`
  height: calc(100vh - ${props => (props.isLimitedAccess ? 427 : 377)}px);
  box-sizing: border-box;
  overflow: scroll;
  padding: 20px;
  font-size: 13px;
  font-family: monospace;
  line-height: 18px;
  color: ${props => props.theme.colors.darkText};
`

export const Pipeline = styled(File)<{ width: string }>`
  min-width: ${props => props.width};
  max-width: ${props => props.width};
`

export const PipelineHead = styled(FileHead)``

export const PipelineContent = styled.div<{ isLimitedAccess: boolean }>`
  max-height: calc(100vh - ${props => (props.isLimitedAccess ? 427 : 377)}px);
  padding: 5px;
`
