import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Modal } from 'components'
import { Integration, IntegrationStatus } from 'hooks'
import { GithubParamsName } from 'pages'
import { ConnectGitRepository, GitRepository, Ready } from './components'

export const connectGithubParamName = 'connect-github'

interface Props {
  githubIntegration: Integration
}

export const GithubConnectionModal: React.FC<Props> = ({ githubIntegration }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const active = !!searchParams.get(connectGithubParamName)
  const [stateParam, setStateParam] = useState(0)
  const [githubToken, setGithubToken] = useState('')

  useEffect(() => {
    if (active) {
      const stateParam = Math.random()
      setStateParam(stateParam)
      localStorage.setItem(GithubParamsName.STATE_PARAM, String(stateParam))
    }
  }, [active])

  const close = () => {
    searchParams.delete(connectGithubParamName)
    setSearchParams(searchParams, { replace: true })
  }

  return (
    <Modal
      active={active}
      title={
        githubIntegration.status === IntegrationStatus.READY
          ? 'Git Repository'
          : githubToken
          ? 'Connect Git Repository'
          : 'Git Repository'
      }
      subTitle={
        githubIntegration.status === IntegrationStatus.READY
          ? undefined
          : githubToken
          ? 'Connect your repository to manage the source code. Every push will trigger assigned CI/CD pipeline.'
          : 'Connect repository to ge the source code. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor.'
      }
      onClose={close}
      width="640px"
    >
      {githubIntegration.status === IntegrationStatus.READY ? (
        <Ready />
      ) : githubToken ? (
        <ConnectGitRepository close={close} stateParam={stateParam} githubToken={githubToken} />
      ) : (
        <GitRepository close={close} stateParam={stateParam} setGithubToken={setGithubToken} />
      )}
    </Modal>
  )
}
