import styled from 'styled-components'

import { StepStatus, TaskStatus } from 'hooks'

export enum BuildStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
}

export const Item = styled.div<{
  deploy?: boolean
  task?: boolean
  pipeline?: boolean
  step?: boolean
}>`
  overflow: hidden;
  border: ${props =>
    !props.deploy &&
    !props.task &&
    !props.pipeline &&
    !props.step &&
    `1px solid ${props.theme.colors.borderColorToLightBorder}`};
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  & i {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: ${props => props.theme.colors.grey1};
  }
  & span {
    color: ${props => props.theme.colors.darkText};
  }
  & b {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    color: ${props => props.theme.colors.darkText};
    word-break: break-all;
  }
  & a {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: ${props => props.theme.colors.primaryText};
    &:hover {
      color: ${props => props.theme.colors.primaryTextHover};
      & > svg path {
        stroke: ${props => props.theme.colors.primaryTextHover};
      }
    }
  }
`

export const ItemTop = styled.div<{
  noData?: boolean
  deploy?: boolean
  build?: boolean
  task?: boolean
  active?: boolean
  onClick?: () => void
  hasHover?: boolean
}>`
  background: ${props =>
    (props.deploy || props.build || props.task || props.active) && props.theme.colors.settingField};
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  & > svg {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
  }
  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &:hover {
    cursor: ${props => !!props.onClick && 'pointer'};
    background: ${props => !!props.hasHover && props.theme.colors.settingFieldHover};
  }
`

export const ItemBottom = styled.div`
  border-top: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
  padding: 15px 20px;
`

export const Separator = styled.div`
  background: ${props => props.theme.colors.borderColor};
  width: 100%;
  height: 1px;
  margin-bottom: 25px;
`

export const ItemColumns = styled.div`
  display: flex;
  column-gap: 40px;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`

export const Status = styled.i<{ status?: BuildStatus }>`
  padding-left: 5px;
  color: ${props =>
    props.status === BuildStatus.SUCCESSFUL
      ? '#389E0D'
      : props.status === BuildStatus.FAILED
      ? props.theme.colors.red
      : '#FFD701'} !important;
`

export const DotStatus = styled.div<{ status?: TaskStatus | StepStatus }>`
  width: 6px;
  min-width: 6px;
  height: 6px;
  min-height: 6px;
  border-radius: 50%;
  background: ${props =>
    props.status === TaskStatus.COMPLETED || props.status === StepStatus.COMPLETED
      ? '#389E0D'
      : props.status === TaskStatus.FAILED || props.status === StepStatus.FAILED
      ? props.theme.colors.red
      : props.status === TaskStatus.IN_PROGRESS || props.status === StepStatus.IN_PROGRESS
      ? '#FFD701'
      : props.theme.colors.grey} !important;
`

export const SmallItem = styled(Item)`
  border-radius: 8px;
  line-height: 18px;
`

export const SmallItemTop = styled(ItemTop)`
  position: relative;
  padding: ${props => (props.noData ? '16px 10px' : props.deploy ? '10px' : '7px 10px 7px')};
  justify-content: ${props => props.noData && 'center'};
  gap: 10px;
  & > img,
  & > svg {
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
  }
  & > div {
    gap: 0;
    & > div {
      color: ${props => props.theme.colors.darkText};
    }
  }
`

export const Dot = styled.div`
  display: inline-block;
  margin-bottom: 2px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${props => props.theme.colors.green};
`

export const Line = styled.i`
  position: absolute;
  top: -12px;
  left: 17px;
  height: 25px;
  width: 1.5px;
  background: #6ab767;
  border-radius: 100px;
`

export const Setup = styled.i`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.iconHover} !important;
  }
`
