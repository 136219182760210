import React, { useContext } from 'react'

import { Icon, ImagePicker, Name } from 'components'
import { ProjectContext } from 'context'
import { SettingField } from 'partials/RightSidebar/components'
import { getVariableName, LocalVariable, Resource, Screen, VariableSource } from 'utils'

interface Props {
  big?: boolean
  small?: boolean
  label?: string
  subLabel?: string
  value?: string
  onChange: (val?: string, resources?: Resource[]) => void
  source?: VariableSource
  onSourceChange?: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  sfByDefault?: boolean
  withoutDelete?: boolean
  disabled?: boolean
  insidePicker?: boolean
}

export const ImageInput: React.FC<Props> = ({
  big,
  small,
  label,
  subLabel,
  value,
  onChange,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
  sfByDefault,
  withoutDelete,
  disabled,
  insidePicker,
}) => {
  const {
    config: { resources = [] },
  } = useContext(ProjectContext)
  const url = value ? resources.find(el => el.id === value)?.url : undefined

  return (
    <SettingField
      big={big}
      small={small}
      label={label}
      subLabel={subLabel}
      insidePicker={insidePicker}
      value={source ? getVariableName(source) : url}
      leftIcon={
        insidePicker ? undefined : source ? (
          <Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />
        ) : url ? (
          <img src={url} alt="preview" />
        ) : (
          <Icon name={Name.RIGHT_SIDEBAR_ADD_ASSET} />
        )
      }
      disabled={disabled}
      optionsContainer={
        <ImagePicker
          close={() => {}}
          url={url}
          onChange={onChange}
          source={source}
          onSourceChange={onSourceChange}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
          sfByDefault={sfByDefault}
          withoutDelete={withoutDelete}
        />
      }
      actions={
        !withoutDelete && (value || source) ? (
          <Icon
            name={Name.RIGHT_SIDEBAR_DELETE}
            onClick={() => {
              onChange()
              onSourceChange?.()
            }}
          />
        ) : undefined
      }
    />
  )
}
