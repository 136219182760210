import { Box } from '@mui/material'
import React, { useContext } from 'react'

import * as styled from './ThemeToggle.styled'

import { DropDown, Icon, Name, Text, Type } from 'components'
import { LayoutContext } from 'context'
import { ThemeType } from 'theme'

interface Props {
  ai?: boolean
  wide?: boolean
}

export const ThemeToggle: React.FC<Props> = ({ ai, wide }) => {
  const { theme, setTheme } = useContext(LayoutContext)
  const isDarkDeviceTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

  return (
    <DropDown
      options={wide ? [] : [{ label: 'Go to projects', to: '/', iconName: Name.PICKERS_BACK_TO_PROJECTS }]}
      top="54px"
      left="54px"
      width="262px"
      labelElement={
        <styled.Logo ai={ai} wide={wide}>
          <Icon
            width={wide ? 163 : 34}
            height={34}
            name={wide ? Name.LOGO_CODE_PLATFORM_FULL : Name.LOGO_CODE_PLATFORM}
          />
          {!wide && (
            <styled.ArrowControl>
              <Icon width={8} height={8} name={Name.LOGO_CORNER_ARROW} />
            </styled.ArrowControl>
          )}
        </styled.Logo>
      }
    >
      <styled.ThemeTogglerBox wide={wide}>
        <styled.TogglerBox
          width={100}
          text
          onClick={() => setTheme(isDarkDeviceTheme ? ThemeType.DARK : ThemeType.LIGHT)}
        >
          <Box width="100%">
            <Text type={Type.BODY} inherit as="span">
              System
            </Text>
            <span>The same as on the device</span>
          </Box>
        </styled.TogglerBox>
        <styled.TogglerBox width={60} dark onClick={() => setTheme(ThemeType.DARK)} active={theme === ThemeType.DARK}>
          <Icon name={Name.ADDITIONAL_DARK} />
          <Text type={Type.BODY} as="span" inherit>
            Dark
          </Text>
        </styled.TogglerBox>
        <styled.TogglerBox width={60} onClick={() => setTheme(ThemeType.LIGHT)} active={theme === ThemeType.LIGHT}>
          <Icon name={Name.ADDITIONAL_LIGHT} />
          <Text type={Type.BODY} as="span" inherit>
            Light
          </Text>
        </styled.TogglerBox>
      </styled.ThemeTogglerBox>
    </DropDown>
  )
}
