import React, { useEffect } from 'react'

import * as styled from './Monetization.styled'

import { Connections, Text, Type } from 'components'
import { Cards, Paywalls, Products, Transactions } from './sections'

export const Monetization: React.FC = () => {
  useEffect(() => {
    document.title = 'Monetization | CodePlatform'
  }, [])

  return (
    <styled.Container>
      <styled.Content>
        <Text type={Type.TITLE}>Monetization</Text>
        <Text type={Type.SUB_TITLE}>Your in-app & subscriptions analytics</Text>
        <Connections appStore googlePlay stripe />
        <Cards />
        <Transactions />
        <Products />
        <Paywalls />
      </styled.Content>
    </styled.Container>
  )
}
