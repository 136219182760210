import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import * as styled from 'components/Menu/Menu.styled'

import { Menu } from 'components'
import { ProjectContext } from 'context'
import { IRoute, routes } from 'routes'

export const Navigation: React.FC = () => {
  const {
    project: { id },
  } = useContext(ProjectContext)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const navRoutes = routes.find(el => el.path === '/projects/:id/settings')?.children || []

  const groupBy = (arr: IRoute[]) =>
    arr
      .map((el: IRoute) => el.parent)
      .reduce((acc: any, val: any, i: number) => {
        acc[val] = (acc[val] || []).concat(arr[i])
        return acc
      }, {})

  const navigationChaptersObject = groupBy(navRoutes)
  let navigationChapters = []
  for (let key in navigationChaptersObject) {
    navigationChapters.push({
      chapter: key,
      childs: navigationChaptersObject[key],
    })
  }

  const replacedPath = (path: string) => path.replace(':id', id)

  const checkActive = (route: IRoute) => {
    const path = replacedPath(route.path)
    return route.index ? pathname === path : pathname.startsWith(path)
  }

  return (
    <Menu
      firstChild={
        <>
          {navigationChapters.map((parent: any) => (
            <styled.NavMarginElement key={parent.chapter}>
              <styled.NavHead>
                <styled.Title>{parent.chapter}</styled.Title>
              </styled.NavHead>
              <styled.NavBody>
                {parent.childs.map((el: IRoute) => (
                  <styled.NavItem
                    key={el.path}
                    lvl={0}
                    active={checkActive(el)}
                    onClick={() => navigate(replacedPath(el.customPath || el.path))}
                    big
                    noArrowMode
                  >
                    {el.icon}
                    <span>{el.name}</span>
                  </styled.NavItem>
                ))}
              </styled.NavBody>
            </styled.NavMarginElement>
          ))}
        </>
      }
    />
  )
}
