import { useFormik } from 'formik'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Block, Paragraph, Title } from '../../Settings.styled'
import * as styled from './Variables.styled'

import {
  AnyFieldWrapper,
  Button,
  ButtonColor,
  ButtonSize,
  Icon,
  InputProperty,
  inputs,
  Modal,
  ModalActions,
  Name,
  RemoveContent,
  validate,
} from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { Variable } from 'context/types'
import { ProjectsService } from 'services/projects'
import { ValueType } from 'utils'

export const Variables: React.FC = () => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [variables, setVariables] = useState<Variable[]>([])
  const [wait, setWait] = useState(true)
  const [toRemove, setToRemove] = useState<Variable | null>(null)
  const [newVariable, setNewVariable] = useState({ key: '', value: '' })
  const [toEdit, setToEdit] = useState<Variable | null>(null)

  const getVariables = () =>
    ProjectsService.getVariables(id).then(res => {
      setVariables(res.data.values)
      setWait(false)
    })

  useEffect(() => {
    getVariables().catch(err => toast(err))
  }, [])

  const createVariable = () => {
    const { key, value } = newVariable
    if (key && value) {
      setWait(true)
      startLoader()
      ProjectsService.createVariable(id, { key, value, secured: false })
        .then(() => {
          setNewVariable({ key: '', value: '' })
          return getVariables()
        })
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setWait(false)
        })
    }
  }

  const deleteVariable = (callback: () => void) => {
    if (toRemove) {
      startLoader()
      ProjectsService.deleteVariable(id, toRemove.uuid)
        .then(() => getVariables())
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
        })
    }
  }

  return (
    <>
      <Block>
        <Title>Build Variables</Title>
        <Paragraph mb="20px">Review app config vars</Paragraph>
        <styled.BuildVariables>
          {variables.map(el => (
            <div key={el.uuid}>
              <styled.Inputs>
                <AnyFieldWrapper control={<input disabled value={el.key} />} />
                <AnyFieldWrapper control={<input disabled value={el.secured ? '*********' : el.value} />} />
                <styled.Actions>
                  {!el.secured && !el.system && (
                    <>
                      <Icon name={Name.REPOSITORY_EDIT} onClick={() => setToEdit(el)} />
                      <Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => setToRemove(el)} />{' '}
                    </>
                  )}
                </styled.Actions>
              </styled.Inputs>
            </div>
          ))}
          {!wait && (
            <div>
              <styled.Inputs>
                <AnyFieldWrapper
                  control={
                    <input
                      value={newVariable.key}
                      onChange={e => setNewVariable(newVariable => ({ ...newVariable, key: e.target.value }))}
                    />
                  }
                />
                <AnyFieldWrapper
                  control={
                    <input
                      value={newVariable.value}
                      onChange={e => setNewVariable(newVariable => ({ ...newVariable, value: e.target.value }))}
                    />
                  }
                />
                <styled.Actions>
                  <Button disabled={!newVariable.key || !newVariable.value} onClick={createVariable}>
                    Add
                  </Button>
                </styled.Actions>
              </styled.Inputs>
            </div>
          )}
        </styled.BuildVariables>
      </Block>
      <Modal active={!!toEdit} title="Edit variable" onClose={() => setToEdit(null)}>
        {toEdit && <Children close={() => setToEdit(null)} toEdit={toEdit} getVariables={getVariables} />}
      </Modal>
      <RemoveContent
        toRemove={toRemove}
        title="Delete variable"
        text="Are you sure? This action <b>can not be undone</b>. Selected variable will be deleted immediately"
        clean={() => setToRemove(null)}
        remove={deleteVariable}
      />
    </>
  )
}

interface ChildrenProps {
  close: () => void
  toEdit: Variable
  getVariables: () => Promise<void>
}

const Children: React.FC<ChildrenProps> = ({ close, toEdit, getVariables }) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [wait, setWait] = useState(false)

  const { uuid, key, value, secured } = toEdit

  const formik = useFormik({
    initialValues: { key, value },
    validate: data => validate(data, properties),
    onSubmit: data => {
      const { key, value } = data
      setWait(true)
      ProjectsService.updateVariable(id, uuid, { key, value, secured })
        .then(() => {
          close()
          return getVariables()
        })
        .catch(err => toast(err))
        .finally(() => setWait(false))
    },
  })

  useEffect(() => {
    formik.setValues({ key, value })
  }, [key, value])

  const properties = useMemo(
    (): InputProperty[] => [
      {
        name: 'key',
        type: ValueType.string,
        position: 0,
        isRequired: true,
      },
      {
        name: 'value',
        type: ValueType.string,
        position: 1,
        isRequired: true,
      },
    ],
    []
  )

  return (
    <>
      {inputs(properties, formik)}
      <ModalActions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={close}>
          Cancel
        </Button>
        <Button size={ButtonSize.DEFAULT} onClick={formik.submitForm} loading={wait}>
          Save
        </Button>
      </ModalActions>
    </>
  )
}
