import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as styled from './Chat.styled'

import { Button, ButtonSize, Icon, Loader, LoaderVariant, Name } from 'components'
import { ProgressContext, UserContext } from 'context'
import { useParamsFromUrl } from 'hooks'
import { Conversation, ConversationsService, Message, MessageBeforeSend } from 'services/conversations'
import firebase, { firestore } from 'services/firebase'
import { ProjectsService } from 'services/projects'
import { generateFirestoreId, onContentEditablePaste } from 'utils'
import {
  Auth,
  Buttons,
  ChatMessage,
  Colors,
  GenerateCollections,
  GenerateComponents,
  GenerateScreens,
  Icons,
  ImportData,
  RegionsSuggestions,
  Suggestions,
  Templates,
  UserMessage,
} from './components'

interface ChatProps {
  id: string | null
  setId: React.Dispatch<React.SetStateAction<string | null>>
  isMD: boolean
  isXL: boolean
}

export const Chat: React.FC<ChatProps> = ({ id, setId, isMD, isXL }) => {
  const { firebaseUser } = useContext(UserContext)
  const { toast } = useContext(ProgressContext)
  const { setConversationIdToUrl, isAdmin, conversationId, ...params } = useParamsFromUrl()
  const navigate = useNavigate()
  const messagesBox = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLDivElement>(null)
  const [sending, setSending] = useState(false)
  const [stopping, setStopping] = useState(false)
  const [messageText, setMessageText] = useState<string | null>(null)
  const [onClickActionButton, setOnClickActionButton] = useState<(() => void) | null>(null)
  const [conversation, setConversation] = useState<Conversation | null>(null)
  const [messages, setMessages] = useState<Message[]>([])
  const [messageToEdit, setMessageToEdit] = useState<Message | null>(null)
  const prevMessage = messages[messages.findIndex(el => el.id === messageToEdit?.id) + 1] as Message | undefined
  const nextMessage = messages[messages.findIndex(el => el.id === messageToEdit?.id) - 1] as Message | undefined
  const projectDraftAttachment = messages[0]?.projectDraftAttachment
  const generating = prevMessage?.status === 'generating'
  const pending = prevMessage?.status === 'pending'
  const suggestions = prevMessage?.keyboard?.suggestions
  const showActionButton = prevMessage?.keyboard?.showActionButton
  const blockTyping = prevMessage?.keyboard?.blockTyping
  const placeholder = prevMessage?.keyboard?.placeholder
  const payload = prevMessage?.keyboard?.payload
  const widget = prevMessage?.keyboard?.widget
  const messageButtons = prevMessage?.buttons
  const context = conversation?.context
  const sendMessageDisabled = isAdmin || sending || generating || pending
  const submitDisabled = sendMessageDisabled || blockTyping || !messageText

  useEffect(() => {
    if (conversationId) {
      const conversationUnsubscribe = firestore
        .collection('conversations')
        .doc(conversationId)
        .onSnapshot({
          next: res => {
            const resData = res.data()
            if (resData) {
              const conversation = { id: res.id, ...resData } as Conversation
              setConversation(conversation)
              if (!isAdmin && !conversation.geocoding) {
                fetch('https://us-central1-codeplatform-dev.cloudfunctions.net/country')
                  .then(res => res.json())
                  .then(res => firestore.collection('conversations').doc(conversationId).update({ geocoding: res }))
                  .catch(err => toast(err))
              }
            }
          },
          error: err => toast(err),
        })
      const messagesUnsubscribe = firestore
        .collection(`conversations/${conversationId}/messages`)
        .orderBy('createdAt', 'desc')
        .onSnapshot({
          next: res => setMessages(res.docs.map(el => ({ id: el.id, ...el.data() })) as Message[]),
          error: err => toast(err),
        })
      return () => {
        conversationUnsubscribe()
        messagesUnsubscribe()
      }
    } else if (!isAdmin) {
      createConversation()
    }
  }, [conversationId])

  useEffect(() => {
    if (!isAdmin && conversation && context && context.templateId && !conversation.projectId) {
      ProjectsService.createProject(context.templateId)
        .then(res => firestore.collection('conversations').doc(conversation.id).update({ projectId: res.data.id }))
        .catch(err => toast(err))
    }
  }, [context?.templateId, conversation?.projectId])

  useEffect(() => {
    if (messageToEdit && messageToEdit.body && messageToEdit.format === 'text') {
      setText(messageToEdit.body)
      return () => clear()
    }
  }, [JSON.stringify(messageToEdit)])

  const createConversation = () => {
    const {
      intent,
      templateName,
      templateId,
      planPriceId,
      page,
      codeplatform_ga_session_id,
      userAgent,
      initialMessage,
    } = params
    ConversationsService.createConversation({
      intent,
      templateName,
      templateId,
      planPriceId,
      page,
      codeplatform_ga_session_id,
      userAgent,
      initialMessage,
    })
      .then(res => setConversationIdToUrl(res.data.id))
      .catch(err => toast(err))
  }

  const sendMessage = (messageBeforeSend: MessageBeforeSend) => {
    if (conversation && !sendMessageDisabled) {
      const message: Message = {
        id: generateFirestoreId(),
        authorId: firebaseUser.uid,
        status: 'pending',
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        ...messageToEdit,
        ...messageBeforeSend,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }
      if (!messageToEdit && messagesBox.current) {
        messagesBox.current.scrollTo(messagesBox.current.scrollWidth, messagesBox.current.scrollHeight)
      }
      setSending(true)
      firestore
        .collection(`conversations/${conversation.id}/messages`)
        .doc(message.id)
        .set(message)
        .then(() => {
          clear()
          if (nextMessage) {
            firestore
              .collection(`conversations/${conversation.id}/messages`)
              .doc(nextMessage.id)
              .update({ cmd: 'regenerate' })
              .catch(err => toast(err))
          }
        })
        .catch(err => toast(err))
        .finally(() => setSending(false))
    }
  }

  const stopGenerating = () => {
    if (conversation && prevMessage) {
      setStopping(true)
      firestore
        .collection(`conversations/${conversation.id}/messages`)
        .doc(prevMessage.id)
        .update({ cmd: 'stop' })
        .catch(err => toast(err))
        .finally(() => setStopping(false))
    }
  }

  const changePayload = (payload: any) => {
    if (conversation && prevMessage) {
      firestore
        .collection(`conversations/${conversation.id}/messages`)
        .doc(prevMessage.id)
        .update({ keyboard: { ...prevMessage.keyboard, payload } })
        .catch(err => toast(err))
    }
  }

  const use = onClickActionButton
    ? () => {
        onClickActionButton()
        if (conversation && prevMessage) {
          firestore
            .collection(`conversations/${conversation.id}/messages`)
            .doc(prevMessage.id)
            .update({ buttons: prevMessage.buttons?.filter(el => el.action !== 'use') })
            .catch(err => toast(err))
        }
      }
    : undefined

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => e.code === 'Enter' && !e.shiftKey && onSubmit(e)

  const onSubmit = (e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!submitDisabled) {
      sendMessage({ body: messageText, format: 'text' })
    }
  }

  const setText = (text: string) => {
    if (inputRef.current) {
      inputRef.current.innerText = text
      setMessageText(text)
    }
  }

  const clear = () => {
    setText('')
    setOnClickActionButton(null)
    setMessageToEdit(null)
  }

  const widgetElement =
    widget === 'templates' ? (
      <Templates
        payload={payload}
        sendMessage={sendMessage}
        setOnClickActionButton={setOnClickActionButton}
        templateId={context?.templateId}
      />
    ) : widget === 'icons' ? (
      <Icons
        payload={payload}
        changePayload={changePayload}
        sendMessage={sendMessage}
        setOnClickActionButton={setOnClickActionButton}
        icon={context?.icon}
      />
    ) : widget === 'colors' ? (
      <Colors
        payload={payload}
        changePayload={changePayload}
        sendMessage={sendMessage}
        setOnClickActionButton={setOnClickActionButton}
        color={context?.color}
      />
    ) : widget === 'regions' ? (
      <RegionsSuggestions payload={payload} sendMessage={sendMessage} />
    ) : widget === 'auth' ? (
      <Auth sendMessage={sendMessage} isAdmin={isAdmin} />
    ) : isAdmin ? (
      <></>
    ) : widget === 'generate-screens' ? (
      <GenerateScreens payload={payload} />
    ) : widget === 'generate-components' ? (
      <GenerateComponents payload={payload} />
    ) : widget === 'refactor' ? (
      <></>
    ) : widget === 'generate-collection' ? (
      <GenerateCollections setOnClickActionButton={setOnClickActionButton} />
    ) : widget === 'import-data' ? (
      <ImportData />
    ) : widget === 'generate-content' ? (
      <></>
    ) : undefined

  const buttons = messageButtons?.length ? (
    <Buttons messageButtons={messageButtons} useDisabled={!use || sendMessageDisabled} use={use} />
  ) : undefined

  return conversation ? (
    <styled.Chat isMD={isMD} hidden={!!(isXL && id)}>
      <styled.DashedLogo src="/assets/dashed-logo.png" alt="logo" />
      <styled.DashedLogo src="/assets/dashed-logo.png" alt="logo" right />
      <styled.MessagesBox ref={messagesBox}>
        {messages.map((el, i) =>
          el.authorId === firebaseUser.uid ? (
            <UserMessage
              key={el.id}
              message={el}
              editMode={messageToEdit?.id === el.id}
              setEditMode={() => setMessageToEdit(el)}
            />
          ) : (
            <ChatMessage
              key={el.id + i}
              message={el}
              widgetElement={prevMessage?.id === el.id ? widgetElement : undefined}
              buttons={prevMessage?.id === el.id ? buttons : undefined}
              id={id}
              setId={setId}
              isXL={isXL}
            />
          )
        )}
      </styled.MessagesBox>
      <styled.InputBox>
        {messageToEdit && (
          <styled.EditMode>
            Edit message
            <Icon name={Name.PICKERS_CLOSE} onClick={() => setMessageToEdit(null)} />
          </styled.EditMode>
        )}
        {suggestions && <Suggestions suggestions={suggestions} sendMessage={sendMessage} />}
        {projectDraftAttachment ? (
          <Button
            type="button"
            size={ButtonSize.DEFAULT}
            onClick={() => navigate(`/projects/${projectDraftAttachment.projectId}`)}
          >
            Edit visually
          </Button>
        ) : showActionButton ? (
          <Button
            type="button"
            size={ButtonSize.DEFAULT}
            onClick={onClickActionButton || undefined}
            disabled={!onClickActionButton || sendMessageDisabled}
          >
            {placeholder || 'Select an option above to proceed'}
          </Button>
        ) : (
          <styled.Form blockTyping={blockTyping} onSubmit={onSubmit}>
            <styled.Input>
              <div
                contentEditable={!blockTyping}
                onKeyDown={onKeyDown}
                onPaste={onContentEditablePaste}
                onInput={e => setMessageText((e.target as HTMLDivElement).innerText.trim())}
                placeholder={placeholder || 'Ask a follow up…'}
                ref={inputRef}
              />
              <styled.SendMessageButton
                type={generating ? 'button' : 'submit'}
                disabled={generating ? stopping : submitDisabled}
                generating={generating}
                onClick={generating ? () => stopGenerating() : undefined}
              >
                <Icon name={Name.ADDITIONAL_SEND_MESSAGE} width={24} height={24} />
              </styled.SendMessageButton>
            </styled.Input>
          </styled.Form>
        )}
      </styled.InputBox>
      <styled.BottomText>CodePlatform may make mistakes. Please use with care.</styled.BottomText>
    </styled.Chat>
  ) : (
    <Loader variant={LoaderVariant.DOTS} />
  )
}

interface InitialMessageProps {
  isMD: boolean
}

export const InitialMessage: React.FC<InitialMessageProps> = ({ isMD }) => {
  const { setInitialMessageToUrl } = useParamsFromUrl()
  const inputRef = useRef<HTMLDivElement>(null)
  const [messageText, setMessageText] = useState<string | null>(null)
  const submitDisabled = !messageText

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => e.code === 'Enter' && !e.shiftKey && onSubmit(e)

  const onSubmit = (e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!submitDisabled) {
      setInitialMessageToUrl(messageText)
    }
  }

  return (
    <styled.Chat isMD={isMD} initialMessage>
      <styled.DashedLogo src="/assets/dashed-logo.png" alt="logo" initialMessage />
      <styled.DashedLogo src="/assets/dashed-logo.png" alt="logo" initialMessage right />
      <styled.InitialMessage>
        <styled.InitialMessageTitle isMD={isMD}>Ready to start building?</styled.InitialMessageTitle>
        <styled.Form onSubmit={onSubmit}>
          <styled.Input initialMessage>
            <div
              contentEditable
              onKeyDown={onKeyDown}
              onPaste={onContentEditablePaste}
              onInput={e => setMessageText((e.target as HTMLDivElement).innerText.trim())}
              placeholder="Describe the app you want to build..."
              ref={inputRef}
            />
            <styled.SendMessageButton type="submit" disabled={submitDisabled}>
              <Icon name={Name.ADDITIONAL_SEND_MESSAGE} width={24} height={24} />
            </styled.SendMessageButton>
          </styled.Input>
        </styled.Form>
      </styled.InitialMessage>
      <styled.BottomText>CodePlatform may make mistakes. Please use with care.</styled.BottomText>
    </styled.Chat>
  )
}
