import firebase from 'services/firebase'
import { Resource, Screen, ScreenComponent, httpClient } from 'utils'

type CallState =
  | 'ringing'
  | 'connected'
  | 'ended'
  | 'missed'
  | 'rejected'
  | 'failed'
  | 'on-hold'
  | 'busy'
  | 'unavailable'

type Context = {
  [key: string]: any
}

type Url = string

export interface Conversation {
  id: string
  type: 'chat' | 'assistant' | 'channel'
  lastMessageAuthor?: string
  lastMessageDate?: firebase.firestore.FieldValue
  lastMessageId?: string
  lastMessageText?: string
  lastMessageStatus?: 'sent' | 'pending' | 'failed' | 'generating' | 'error'
  name: string
  cover?: Url
  participantIds: string[]
  context?: Context
  createdAt: any
  updatedAt: any
  deletedAt?: any
  status: 'active' | 'archived'
  visibility: 'public' | 'private'
  projectId?: string
  geocoding?: Geocoding
}

interface Geocoding {
  country: string
  city: string
  region: string
  cityLatLong: string
}

interface LocationAttachment {
  lat: number
  lng: number
}

interface ContactAttachment {
  name: string
  phone: string
}

interface PollAttachment {
  question: string
  options: string[]
}

interface UnfurlAttachment {
  url: Url
  title: string
  description: string
  image: string
}

interface StickerAttachment {
  stickerId: string
  url: Url
  width: number
  height: number
}

interface CallAttachment {
  callId: string
  state: CallState
  duration: number | null
  participants: string[]
}

interface ColorAttachment {
  color: string
}

interface TemplateAttachment {
  templateId: string
}

export interface EmojiIconAttachment {
  id: string
  data: string
}

export interface IconAttachment extends Resource {}

interface AuthAttachment {
  displayName: string | null
  email: string | null
  providerId: string
  photoURL: string | null
}

interface RegionAttachment {
  regionId: string
}

export interface ProjectDraftAttachment {
  projectId: string
  name: string
  description: string
  iconUrl: string
  version: string
}

export type TemplatesKeyboardPayload = TemplateAttachment[]

export type IconsKeyboardPayload = (EmojiIconAttachment | IconAttachment)[]

export type ColorsKeyboardPayload = ColorAttachment[]

export type RegionsKeyboardPayload = {
  name: string
  regionId: string
  flag: string
}[]

export type GenerateScreensKeyboardPayload = Screen[]

export type GenerateComponentsKeyboardPayload = ScreenComponent[]

type ButtonPayload = any

export interface Button {
  text?: string
  action: 'like' | 'dislike' | 'use' | 'regenerate' | 'custom'
  payload?: ButtonPayload
}

interface Reaction {
  emoji: string
  count: number
  participantIds: string[]
}

type KeyboardPayload = any

interface Keyboard {
  title?: string
  placeholder?: string
  suggestions?: string[]
  type: 'inline' | 'reply'
  widget?:
    | 'templates'
    | 'icons'
    | 'colors'
    | 'regions'
    | 'auth'
    | 'generate-screens'
    | 'generate-components'
    | 'refactor'
    | 'generate-collection'
    | 'import-data'
    | 'generate-content'
  payload?: KeyboardPayload
  blockTyping?: boolean
  showActionButton?: boolean
}

export interface MessageBeforeSend {
  body: string | null
  format: 'markdown' | 'html' | 'text' | 'json'
  replyTo?: string
  imageAttachment?: Resource
  videoAttachment?: Resource
  audioAttachment?: Resource
  fileAttachment?: Resource
  locationAttachment?: LocationAttachment
  contactAttachment?: ContactAttachment
  pollAttachment?: PollAttachment
  unfurlAttachment?: UnfurlAttachment
  colorAttachment?: ColorAttachment
  stickerAttachment?: StickerAttachment
  callAttachment?: CallAttachment
  templateAttachment?: TemplateAttachment
  iconAttachment?: IconAttachment
  authAttachment?: AuthAttachment
  regionAttachment?: RegionAttachment
  projectDraftAttachment?: ProjectDraftAttachment
}

export interface Message extends MessageBeforeSend {
  id: string
  authorId: string
  status: 'sent' | 'pending' | 'failed' | 'generating' | 'error'
  thumbnail?: Url
  buttons?: Button[]
  reactions?: Reaction[]
  keyboard?: Keyboard
  isEdited?: boolean
  createdAt: any
  updatedAt: any
  deletedAt?: any
  cmd?: string
}

const createConversation = (context: { [key: string]: unknown }) => {
  const url = `/conversations`
  return httpClient.post(url, {
    type: 'assistant',
    name: 'Assistant',
    cover: null,
    visibility: 'private',
    participantIds: ['ai-agent'],
    context,
  })
}

export const ConversationsService = {
  createConversation,
}
