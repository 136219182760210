import { rgbToHex } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import { BooleanInput, ColorPicker, OneColorSvg, TwoColorsSvg } from 'components'
import { ProjectContext } from 'context'
import { RightSidebarItem, SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { getBrandingColorType } from 'utils'
import { IBackground } from '../../utils'

interface Props {
  activeElement: IBackground
  updateActiveElement: (activeElement: IBackground) => void
  close: () => void
  appliedToAllScreens: boolean
  applyToAllScreens: (background: string, colorName: string) => void
}

export const BackgroundSettings: React.FC<Props> = ({
  activeElement,
  updateActiveElement,
  close,
  appliedToAllScreens,
  applyToAllScreens,
}) => {
  const [apply, setApply] = useState(appliedToAllScreens)
  const { background, colorName } = activeElement
  const {
    config: {
      branding: { colorStyles },
    },
    theme,
  } = useContext(ProjectContext)
  const currentColor = colorName ? colorStyles[getBrandingColorType(colorName)][theme] : background

  useEffect(() => {
    if (apply) {
      if (colorName) {
        applyToAllScreens('', colorName)
      } else {
        applyToAllScreens(background, '')
      }
    } else {
      applyToAllScreens('', '')
    }
  }, [apply, currentColor])

  const onBackgroundChange = (background: string) =>
    updateActiveElement({ ...activeElement, background, colorName: '' })

  const onColorNameChange = (colorName: string) => updateActiveElement({ ...activeElement, colorName, background: '' })

  const isImage = background.includes('url(')
  const isGradient = background.includes('gradient')
  const kindOfBackground = isImage ? 'Image' : isGradient ? 'Gradient' : background ? rgbToHex(background) : ''

  const sectionName = 'Screenshot'

  return (
    <RightSidebarItem title={sectionName} hasCloseIcon onClose={close}>
      <SubWrapper title="Background" childrenFor={sectionName}>
        <SettingField
          subLabel="Fill"
          value={colorName || kindOfBackground}
          leftIcon={
            colorName ? (
              <TwoColorsSvg left={currentColor} right={currentColor} />
            ) : isImage || isGradient ? (
              <OneColorSvg background={background} />
            ) : (
              <OneColorSvg color={currentColor} />
            )
          }
          optionsContainer={
            <ColorPicker
              close={() => {}}
              value={colorName}
              onChange={onColorNameChange}
              colorName={colorName}
              color={currentColor}
              onColorChange={onBackgroundChange}
            />
          }
        />
        <BooleanInput value={apply} onChange={value => setApply(!!value)} subLabel="Apply style to all screens" />
      </SubWrapper>
    </RightSidebarItem>
  )
}
