import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ActionsIconTbodyWrapper,
  CustomSection,
  CustomSectionSubTitle,
  CustomSectionTitle,
  CustomSectionTitleAction,
  CustomSectionTitleWithActionWrapper,
  DropDown,
  Icon,
  Name,
  NoContent,
  RemoveContent,
  TableWrapper,
} from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { LogicService } from 'services/logic'
import { ConfigLogic, Embedder, firstLetterUpperCase, Flow, Indexer, Prompt, Retriever } from 'utils'

interface LogicItemTableProps {
  name: keyof ConfigLogic
  singularName: string
}

const LogicItemTable: React.FC<LogicItemTableProps> = ({ name, singularName }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id },
    config: { logic },
  } = useContext(ProjectContext)
  const navigate = useNavigate()
  const items = logic[name]?.map(el => ({ id: el.name, ...el })) || []
  const [toRemove, setToRemove] = useState<Flow | Prompt | Indexer | Retriever | Embedder | null>(null)

  const remove = (callback: () => void) => {
    if (toRemove) {
      startLoader()
      LogicService.deleteLogicItemRecord(id, name, toRemove.name)
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
        })
    }
  }

  return (
    <>
      <TableWrapper empty={!items.length} big mt="30px">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{firstLetterUpperCase(singularName)} name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {!!items.length ? (
            <TableBody>
              {items.map(el => (
                <TableRow key={el.id} onClick={() => navigate(`${name}/${el.id}`)}>
                  <TableCell>{el.name}</TableCell>
                  <TableCell onClick={e => e.stopPropagation()}>
                    <ActionsIconTbodyWrapper>
                      <div>
                        <DropDown
                          options={[
                            {
                              label: `Delete ${singularName}`,
                              iconName: Name.PICKERS_DELETE,
                              onClick: () => setToRemove(el),
                              red: true,
                            },
                          ]}
                          top="25px"
                          labelElement={
                            <Box display="flex" alignItems="center">
                              <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />
                            </Box>
                          }
                        />
                      </div>
                    </ActionsIconTbodyWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <NoContent isTable text="Empty" />
          )}
        </Table>
      </TableWrapper>
      <RemoveContent
        toRemove={toRemove}
        title={`Delete ${singularName}`}
        text={`You are about to delete the ${singularName} <b>{name}</b>. To make sure, enter the ${singularName} name below`}
        clean={() => setToRemove(null)}
        remove={remove}
        nameConfirmation
      />
    </>
  )
}

interface Props {
  name: keyof ConfigLogic
}

export const LogicItem: React.FC<Props> = ({ name }) => {
  const title = firstLetterUpperCase(name)
  const singularName = name.slice(0, -1)
  const subTitle =
    name === 'flows'
      ? 'Create and manage flows to control the sequence of operations in your application.'
      : name === 'prompts'
      ? 'Add and customize prompts to enhance user interaction within your application.'
      : 'Automate tasks triggered by collection record changes, like building vector indexes or forwarding data to third-party systems.'

  return (
    <CustomSection>
      <CustomSectionTitleWithActionWrapper mb="10px">
        <CustomSectionTitle>{title}</CustomSectionTitle>
        <CustomSectionTitleAction to={`${name}/add`}>
          <Icon name={Name.RIGHT_SIDEBAR_ADD} />
          Add new {singularName}
        </CustomSectionTitleAction>
      </CustomSectionTitleWithActionWrapper>
      <CustomSectionSubTitle>{subTitle}</CustomSectionSubTitle>
      <LogicItemTable name={name} singularName={singularName} />
    </CustomSection>
  )
}
