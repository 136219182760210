import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from './PaymentMethods.styled'

import { Loader, NoContent } from 'components'
import { ProgressContext } from 'context'
import { ProjectsService } from 'services/projects'
import { Card } from '../Card'

interface PaymentMethod {
  id: string
  card: { brand: string; last4: string }
}

export const PaymentMethods: React.FC = () => {
  const { toast } = useContext(ProgressContext)
  const { billingAccountId } = useParams()
  const [wait, setWait] = useState(true)
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])

  useEffect(() => {
    setWait(true)
    const abortController = new AbortController()
    ProjectsService.getPaymentMethods(billingAccountId as string, abortController)
      .then(res => setPaymentMethods(res.data))
      .catch(err => !abortController.signal.aborted && toast(err))
      .finally(() => setWait(false))
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <Card title="Payment methods">
      {wait ? (
        <Box height="310px" display="flex" justifyContent="center" alignItems="center">
          <Loader />
        </Box>
      ) : !paymentMethods.length ? (
        <NoContent text="No payment methods yet." />
      ) : (
        <Box p="15px 20px 25px" display="flex" flexDirection="column" gap="15px">
          {paymentMethods.map(el => (
            <styled.Item key={el.id}>
              {el.card.brand} **** {el.card.last4}
            </styled.Item>
          ))}
        </Box>
      )}
    </Card>
  )
}
