import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 50px;
  width: 100%;
`

export const Name = styled.h2`
  margin-bottom: 10px;
  font-weight: ${props => props.theme.weights.bold};
  color: ${props => props.theme.colors.darkText};
  font-size: 14px;
  line-height: 21px;
`

export const File = styled.div`
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 8px;
  padding: 15px 20px;
  color: ${props => props.theme.colors.darkText};
  font-size: 14px;
  line-height: 21px;
  overflow-x: auto;
  * {
    font: revert;
    margin: revert;
  }
`
