import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'

import { CustomSection, CustomSectionActions, CustomSectionTitle, InputProperty, inputs, validate } from 'components'
import { ProgressContext, UserContext } from 'context'
import { refreshUser } from 'hooks'
import { firestore } from 'services/firebase'
import { UploadService } from 'services/upload'
import { ValueType } from 'utils'

const properties: InputProperty[] = [
  {
    name: 'displayName',
    type: ValueType.string,
    position: 0,
    isRequired: true,
    title: 'Your name',
    helpText: 'Lorem Ipsum is simply dummy text',
    smallWidth: true,
  },
  {
    name: 'photoUrl',
    type: ValueType.avatar,
    position: 1,
    title: 'Avatar',
    helpText: 'Lorem Ipsum is simply dummy text',
    smallWidth: true,
  },
]

export const Profile: React.FC = () => {
  const { firebaseUser, user } = useContext(UserContext)
  const { displayName, photoUrl } = user
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')

  const formik = useFormik({
    initialValues: { displayName, photoUrl },
    validate: data => validate(data, properties),
    onSubmit: data => {
      const { displayName, photoUrl } = data
      if (displayName && photoUrl) {
        startLoader()
        if (photoUrl.startsWith('blob:')) {
          fetch(photoUrl)
            .then(response => response.blob())
            .then(res => {
              const formData = new FormData()
              formData.append('resource', new File([res], `avatar.${res.type.split('/')[1]}`, { type: res.type }))
              return UploadService.uploadAvatar(formData).then(res => {
                const photoUrl = res.data.url
                return Promise.all([
                  firebaseUser.updateProfile({ displayName, photoURL: photoUrl }),
                  firestore.collection('users').doc(firebaseUser.uid).update({ displayName, photoUrl }),
                ]).then(() => refreshUser())
              })
            })
            .catch(err => toast(err))
            .finally(() => stopLoader())
        } else {
          Promise.all([
            firebaseUser.updateProfile({ displayName, photoURL: photoUrl }),
            firestore.collection('users').doc(firebaseUser.uid).update({ displayName, photoUrl }),
          ])
            .then(() => refreshUser())
            .catch(err => toast(err))
            .finally(() => stopLoader())
        }
      }
    },
  })

  useEffect(() => {
    const record = { displayName, photoUrl }
    formik.setValues(record)
    setValuesBeforeEdit(JSON.stringify(record))
  }, [displayName, photoUrl])

  const cancel = () => {
    const record = JSON.parse(valuesBeforeEdit)
    formik.setValues(record)
  }

  const showActions = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)

  return (
    <CustomSection showActions={showActions}>
      <CustomSectionTitle mb="-5px">Profile</CustomSectionTitle>
      {inputs(properties, formik)}
      {showActions && <CustomSectionActions cancel={cancel} onClick={formik.submitForm} />}
    </CustomSection>
  )
}
