import React from 'react'

import { Modal } from 'components'

interface Props {
  active: boolean
  onConfirm: (val: unknown) => void
  onCancel: (val: unknown) => void
}

export const Confirm: React.FC<Props> = ({ active, onConfirm, onCancel }) => (
  <Modal
    active={active}
    title="Leave and lose data"
    subTitle="You are about to leave the page with unsaved data. Are you sure you want to proceed?"
    onClose={() => onCancel(true)}
    onButtonClick={() => onConfirm(true)}
    buttonTitle="Leave and lose data"
    deleteButton
  />
)
