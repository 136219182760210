import { Box } from '@mui/material'
import React, { useState } from 'react'

import { AnyVariableInput, Button, ButtonColor, CustomPicker, Icon, Name } from 'components'
import { RightSidebarItem, SubWrapper } from 'partials/RightSidebar/components'
import { AnyVariable, LocalVariable, Screen, ValueType } from 'utils'

const buttonPadding = { padding: '9.5px' }

interface Props {
  close: () => void
  value?: AnyVariable[]
  onChange: (val?: AnyVariable[]) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
}

export const ArrayConstantPicker: React.FC<Props> = ({ close, value, onChange, screenConfig, allLocalVariables }) => {
  const [currentArrayConstant, setCurrentArrayConstant] = useState(value)

  const onItemAdd = () => setCurrentArrayConstant(currentArrayConstant => [...(currentArrayConstant || []), {}])

  const onItemRemove = (index: number) =>
    setCurrentArrayConstant(currentArrayConstant => {
      const copy = JSON.parse(JSON.stringify(currentArrayConstant)) as AnyVariable[] | undefined
      copy?.splice(index, 1)
      if (!copy?.length) {
        return undefined
      }
      return copy
    })

  const onItemChange = (index: number, anyVariable: AnyVariable) => {
    setCurrentArrayConstant(currentArrayConstant => {
      const copy = JSON.parse(JSON.stringify(currentArrayConstant)) as AnyVariable[] | undefined
      copy?.splice(index, 1, anyVariable)
      return copy
    })
  }

  const onConfirm = () => {
    onChange(currentArrayConstant)
    close()
  }

  const onDelete = value
    ? () => {
        onChange()
        close()
      }
    : undefined

  const confirmDisabled = !!currentArrayConstant?.find(el => !el.source)

  return (
    <CustomPicker
      title="Array"
      close={close}
      optionsContainer={
        <Box maxHeight="500px" overflow="auto">
          <SubWrapper
            small
            noBorderBottom
            title="Items"
            onAdd={onItemAdd}
            canHide={!!currentArrayConstant?.length}
            childrenFor=""
          >
            {currentArrayConstant && (
              <Box display="flex" flexDirection="column" gap="12px">
                {currentArrayConstant.map((el, i) => (
                  <RightSidebarItem key={`${JSON.stringify(el)}${i}`} small title="">
                    <SubWrapper
                      small
                      title="ITEM"
                      childrenFor=""
                      customIcon={<Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onItemRemove(i)} />}
                    >
                      <AnyVariableInput
                        subLabel="Value"
                        valueType={ValueType.any}
                        value={el}
                        onChange={value => onItemChange(i, value || {})}
                        screenConfig={screenConfig}
                        allLocalVariables={allLocalVariables}
                      />
                    </SubWrapper>
                  </RightSidebarItem>
                ))}
              </Box>
            )}
          </SubWrapper>
          <Box
            padding="15px 10px"
            display="flex"
            justifyContent="space-between"
            gap="8px"
            flexWrap="wrap-reverse"
            width="100%"
          >
            {onDelete ? (
              <Button style={buttonPadding} color={ButtonColor.DELETE} onClick={onDelete}>
                Remove
              </Button>
            ) : (
              <span />
            )}
            <Box display="flex" gap="8px">
              <Button style={buttonPadding} color={ButtonColor.SECONDARY} onClick={close}>
                Cancel
              </Button>
              <Button style={buttonPadding} onClick={onConfirm} disabled={confirmDisabled}>
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      }
    />
  )
}
