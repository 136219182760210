import React from 'react'
import { ISvg, Svg } from '../Svg'

export const GooglePlay: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M3.49183 0.255884C2.86587 -0.0900969 2.13091 -0.0820974 1.50894 0.260883L8.79853 6.9855L11.2474 4.53664L3.49183 0.255884ZM0.779984 0.948844C0.601994 1.23983 0.5 1.57281 0.5 1.92579V14.0711C0.5 14.4131 0.592995 14.7391 0.760985 15.024L8.09157 7.69346L0.779984 0.948844ZM14.4942 6.32854L12.1583 5.03961L9.53349 7.66346L12.7503 10.6303L14.4952 9.66735C15.1232 9.31937 15.4992 8.69541 15.4992 7.99745C15.4982 7.29948 15.1232 6.67552 14.4942 6.32854ZM8.82653 8.37142L1.47594 15.722C1.79493 15.904 2.14491 16 2.49689 16C2.83687 16 3.17885 15.914 3.49183 15.741L11.8284 11.1403L8.82653 8.37142Z"
      fill="#7F899E"
    />
  </Svg>
)
