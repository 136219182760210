import styled from 'styled-components'

export const Container = styled.div<{ ai?: boolean; isXL?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: ${props => (props.ai ? (props.isXL ? '18px 4px' : '18px 25px') : '25px')};
  background-color: ${props => props.theme.colors.secondaryBackground};
`
