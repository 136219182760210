import { marked } from 'marked'
import React, { useContext, useEffect, useState } from 'react'

import * as styled from './Readme.styled'

import { ProgressContext, ProjectContext } from 'context'
import { ProjectsService } from 'services/projects'

interface IReadme {
  hash: string
}

export const Readme: React.FC<IReadme> = ({ hash }) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [data, setData] = useState('')

  useEffect(() => {
    const abortController = new AbortController()
    ProjectsService.getRepositoryFiles(id, `/${hash}/README.md`, abortController)
      .then(res => setData(res.data))
      .catch(err => !abortController.signal.aborted && toast(err))
    return () => {
      abortController.abort()
    }
  }, [])

  return data ? (
    <styled.Container id="readme">
      <styled.Name>README.md</styled.Name>
      <styled.File dangerouslySetInnerHTML={{ __html: marked(data, { async: false }) as string }} />
    </styled.Container>
  ) : null
}
