import React, { useContext, useState } from 'react'

import { Button, ButtonColor, Modal } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { Integration, IntegrationId } from 'hooks'
import { ProjectsService } from 'services/projects'

interface Props {
  integration: Integration
}

export const Unlink: React.FC<Props> = ({ integration }) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [open, setOpen] = useState(false)
  const [wait, setWait] = useState(false)

  const unlink = () => {
    setWait(true)
    ProjectsService.deleteIntegration(id, integration.id)
      .then(() => setOpen(false))
      .catch(err => toast(err))
      .finally(() => setWait(false))
  }

  return (
    <>
      <Button color={ButtonColor.CUSTOM_DELETE} onClick={() => setOpen(true)}>
        Unlink
      </Button>
      <Modal
        active={open}
        title={`Unlink from ${
          integration.id === IntegrationId.github
            ? 'GitHub'
            : integration.id === IntegrationId.appStore
            ? 'App Store'
            : ''
        }`}
        subTitle={`Unlink this app from the connected ${
          integration.id === IntegrationId.github
            ? 'GitHub repository'
            : integration.id === IntegrationId.appStore
            ? 'App Store account'
            : ''
        }?`}
        onClose={() => setOpen(false)}
        onButtonClick={unlink}
        buttonTitle="Unlink"
        buttonLoading={wait}
        deleteButton
      />
    </>
  )
}
