import { useFormik } from 'formik'
import React, { memo, useMemo, useState } from 'react'

import * as styled from './RemoveContent.styled'

import { Button, ButtonColor, ButtonSize, InputProperty, inputs, Modal, ModalActions, validate } from 'components'
import { ValueType } from 'utils'

interface Props {
  title: string
  toRemove: any
  clean: () => void
  remove: (callback: () => void) => void
  text: string
  buttonTitle?: string
  nameConfirmation?: boolean
  blue?: boolean
}

export const RemoveContent: React.FC<Props> = memo(
  ({ title, toRemove, clean, remove, text, buttonTitle, nameConfirmation, blue }) => {
    const name = nameConfirmation ? toRemove?.name : ''

    return (
      <Modal active={!!toRemove} title={title} onClose={clean}>
        <styled.SubTitle dangerouslySetInnerHTML={{ __html: nameConfirmation ? text.replace('{name}', name) : text }} />
        <Children
          name={name}
          title={title}
          clean={clean}
          remove={remove}
          buttonTitle={buttonTitle}
          nameConfirmation={nameConfirmation}
          blue={blue}
        />
      </Modal>
    )
  }
)

interface ChildrenProps {
  name: string
  title: string
  clean: () => void
  remove: (callback: () => void) => void
  buttonTitle?: string
  nameConfirmation?: boolean
  blue?: boolean
}

const Children: React.FC<ChildrenProps> = ({ name, title, clean, remove, buttonTitle, nameConfirmation, blue }) => {
  const [wait, setWait] = useState(false)

  const formik = useFormik({
    initialValues: nameConfirmation ? { name: null } : {},
    validate: data => validate(data, properties),
    onSubmit: () => {
      setWait(true)
      remove(() => {
        clean()
        setWait(false)
      })
    },
  })

  const properties = useMemo(
    (): InputProperty[] =>
      nameConfirmation
        ? [
            {
              name: 'name',
              type: ValueType.string,
              position: 0,
              isRequired: true,
              hideLabel: true,
            },
          ]
        : [],
    [nameConfirmation]
  )

  return (
    <>
      {inputs(properties, formik)}
      <ModalActions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={clean}>
          Cancel
        </Button>
        <Button
          size={ButtonSize.DEFAULT}
          color={blue ? ButtonColor.PRIMARY : ButtonColor.DELETE}
          onClick={formik.submitForm}
          // @ts-ignore
          disabled={nameConfirmation ? name !== formik.values.name?.trim() : false}
          loading={wait}
        >
          {buttonTitle || title}
        </Button>
      </ModalActions>
    </>
  )
}
