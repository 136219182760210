import { useContext, useEffect, useState } from 'react'

import { ProgressContext, ProjectContext } from 'context'
import { firestore } from 'services/firebase'

export interface Integration {
  id: IntegrationId
  status: IntegrationStatus
  data?: IntegrationGithub | IntegrationAppstore | IntegrationGooglePlay | IntegrationStripe
  error?: string
  token?: string
  isHidden?: boolean
}

export enum IntegrationId {
  airtable = 'airtable',
  appetize = 'appetize',
  appStore = 'app-store',
  firebase = 'firebase',
  firebaseAuth = 'firebase-auth',
  firebaseFunctions = 'firebase-functions',
  firebaseHosting = 'firebase-hosting',
  firebaseStorage = 'firebase-storage',
  firestore = 'firestore',
  genkit = 'genkit',
  github = 'github',
  googlePlay = 'google-play',
  stripe = 'stripe',
  twilio = 'twilio',
  cms = 'cms',
  analytics = 'analytics',
  monetization = 'monetization',
}

export interface IntegrationGithub {
  installationId: string
  slug: string
}

export interface IntegrationAppstore {
  username: string
  teamId?: string
  teamName?: string
  secretId?: string
  signature?: string
  teams?: { id: string; name: string }[]
}

export interface IntegrationGooglePlay {
  username: string
  teamId: string
  teamName: string
}

// TODO
export interface IntegrationStripe {
  username: string
  teamId: string
  teamName: string
}

export enum IntegrationStatus {
  PENDING = 'PENDING',
  READY = 'READY',
  FAILED = 'FAILED',
  OTP = 'OTP',
  TEAM = 'TEAM',
}

export const useProjectIntegrations = () => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [integrations, setIntegrations] = useState<Integration[]>([])
  const githubIntegration = integrations.find(el => el.id === IntegrationId.github) || null
  const appstoreIntegration = integrations.find(el => el.id === IntegrationId.appStore) || null
  const googleplayIntegration = integrations.find(el => el.id === IntegrationId.googlePlay) || null
  const stripeIntegration = integrations.find(el => el.id === IntegrationId.stripe) || null
  const firebaseIntegration = integrations.find(el => el.id === IntegrationId.firebase) || null
  const firestoreIntegration = integrations.find(el => el.id === IntegrationId.firestore) || null
  const firebaseAuthIntegration = integrations.find(el => el.id === IntegrationId.firebaseAuth) || null
  const firebaseStorageIntegration = integrations.find(el => el.id === IntegrationId.firebaseStorage) || null
  const firebaseHostingIntegration = integrations.find(el => el.id === IntegrationId.firebaseHosting) || null
  const firebaseFunctionsIntegration = integrations.find(el => el.id === IntegrationId.firebaseFunctions) || null
  const cmsIntegration = integrations.find(el => el.id === IntegrationId.cms) || null

  useEffect(() => {
    const unsubscribe = firestore.collection(`projects/${id}/integrations`).onSnapshot({
      next: res => setIntegrations(res.docs.map(el => ({ id: el.id, ...el.data() })) as Integration[]),
      error: err => toast(err),
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return {
    integrations,
    githubIntegration,
    appstoreIntegration,
    googleplayIntegration,
    stripeIntegration,
    firebaseIntegration,
    firestoreIntegration,
    firebaseAuthIntegration,
    firebaseStorageIntegration,
    firebaseHostingIntegration,
    firebaseFunctionsIntegration,
    cmsIntegration,
  }
}

export const useProjectIntegration = (integrationId: IntegrationId) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [integration, setIntegration] = useState<Integration | null>(null)

  useEffect(() => {
    const unsubscribe = firestore
      .collection(`projects/${id}/integrations`)
      .doc(integrationId)
      .onSnapshot({
        next: res => {
          const resData = res.data()
          if (resData) {
            setIntegration({ id: res.id, ...resData } as Integration)
          }
        },
        error: err => toast(err),
      })
    return () => {
      unsubscribe()
    }
  }, [])

  return integration
}
