import { Box } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  ButtonColor,
  ButtonVariant,
  Crumbs,
  DeviceSettings,
  DropDown,
  Icon,
  Name,
  PWAPreview,
  PWAPreviewIframe,
} from 'components'
import { TopPanelContext } from 'context'
import { useOnClickOutside } from 'hooks'
import { Code } from 'pages/Repository/pages'

interface AIBtnsProps {
  name: string
  close?: () => void
}

export const AIBtns: React.FC<AIBtnsProps> = ({ name, close }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  return (
    <>
      <Crumbs firstCrumbTitle={name} onFirstCrumbClick={close} icon={<Icon name={Name.RIGHT_SIDEBAR_CLOSE} />} small />
      <div ref={ref}>
        <DropDown
          top="36px"
          width="50px"
          options={[{ label: 'v1' }]}
          outerOpenState={open}
          onClick={() => setOpen(open => !open)}
          labelElement={
            <Button
              active={open}
              variant={ButtonVariant.TOP_PANEL_ACTION}
              rightIcon={<Icon name={Name.GLOBAL_ARROW_BUTTON} />}
              onClick={() => setOpen(open => !open)}
            >
              v1
            </Button>
          }
        />
      </div>
    </>
  )
}

interface AITabsProps {
  isMD?: boolean
  isLG?: boolean
  isXXL?: boolean
}

export const AITabs: React.FC<AITabsProps> = ({ isMD, isLG, isXXL }) => {
  const { setPage } = useContext(TopPanelContext)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [sourceCodeOpen, setSourceCodeOpen] = useState(false)

  useEffect(() => {
    if (previewOpen) {
      setPage(
        <Box width="100%" display="flex" alignItems="center" justifyContent="center">
          <PWAPreview adaptive ai>
            <PWAPreviewIframe adaptive />
          </PWAPreview>
          {!isMD && <DeviceSettings ai changeDeviceMode />}
        </Box>
      )
      return () => {
        setPage(null)
      }
    }
  }, [previewOpen, isMD])

  useEffect(() => {
    if (sourceCodeOpen) {
      setPage(
        <Box width="100%" height="100%" overflow="auto">
          <Box maxWidth="1280px" p="0 20px 20px" m="0 auto">
            <Code ai isMD={isMD} isLG={isLG} isXXL={isXXL} />
          </Box>
        </Box>
      )
      return () => {
        setPage(null)
      }
    }
  }, [sourceCodeOpen, isMD, isLG, isXXL])

  return (
    <>
      <Button
        custom
        variant={ButtonVariant.TOP_PANEL_ACTION}
        leftIcon={<Icon name={Name.GLOBAL_SCREENS} />}
        active={!previewOpen && !sourceCodeOpen}
        onClick={() => {
          setPreviewOpen(false)
          setSourceCodeOpen(false)
        }}
      >
        {!isLG && !isXXL && 'Screens'}
      </Button>
      <Button
        custom
        variant={ButtonVariant.TOP_PANEL_ACTION}
        leftIcon={<Icon name={Name.GLOBAL_PREVIEW} />}
        active={previewOpen}
        onClick={() => {
          setPreviewOpen(true)
          setSourceCodeOpen(false)
        }}
      >
        {!isLG && !isXXL && 'Preview'}
      </Button>
      <Button
        custom
        variant={ButtonVariant.TOP_PANEL_ACTION}
        leftIcon={<Icon name={Name.GLOBAL_SOURCE_CODE} />}
        active={sourceCodeOpen}
        onClick={() => {
          setPreviewOpen(false)
          setSourceCodeOpen(true)
        }}
      >
        {!isLG && !isXXL && 'Source code'}
      </Button>
    </>
  )
}

interface OpenVBBtnProps {
  id: string
  isLG?: boolean
  isXXL?: boolean
}

export const OpenVBBtn: React.FC<OpenVBBtnProps> = ({ id, isLG, isXXL }) => {
  const navigate = useNavigate()

  return (
    <Button
      variant={ButtonVariant.LINK}
      color={ButtonColor.SECONDARY}
      leftIcon={<Icon name={Name.LEFT_SIDEBAR_STUDIO} />}
      onClick={() => navigate(`/projects/${id}`)}
    >
      {!isLG && !isXXL && 'Edit visually'}
    </Button>
  )
}
