import { httpClient } from 'utils'

const uploadResource = async (projectId: string, resource: any) => {
  const { name, type } = resource
  const res = await httpClient.post(`/projects/${projectId}/storage`, {
    fileName: name,
    contentType: type,
  })
  const uploadUrl = res.data.uploadUrl as string
  await fetch(uploadUrl, {
    method: 'PUT',
    body: resource,
  })
  const url = uploadUrl.split('?')[0]
  const filePath = decodeURI(`uploads/${url.split('/uploads/')[1]}`)
  await httpClient.patch(`/projects/${projectId}/storage`, { filePath, isPublic: true })
  return url
}

const uploadAvatar = (formData: FormData) => {
  const url = `/users/avatars`
  return httpClient.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const UploadService = {
  uploadResource,
  uploadAvatar,
}
