import styled from 'styled-components'

export const ContentBox = styled.div<{
  maxHeight: number
  maxHeightMobileLg: number
  maxHeightMobileMd: number
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${props => props.theme.headerSize.heightDesktop}px 0;

  @media (min-width: ${props => props.theme.breakpoints.lg + 1}px) {
    @media (max-height: ${props => props.maxHeight}px) {
      margin: calc(${props => props.theme.headerSize.heightDesktop}px + 10px) 0;
      align-items: flex-start;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    @media (max-height: ${props => props.maxHeightMobileLg}px) {
      margin: calc(${props => props.theme.headerSize.heightMobile}px + 10px) 0;
      align-items: flex-start;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    padding-bottom: ${props => props.theme.spacing.s1}px;
    margin: 40px 0;
    align-items: center;
    @media (max-height: ${props => props.maxHeightMobileMd}px) {
      margin: calc(${props => props.theme.headerSize.heightMobile}px + 10px) 0;
      align-items: flex-start;
    }
  }
`

export const Title = styled.h1`
  font-size: ${props => props.theme.fontSize.m3}px;
  line-height: ${props => props.theme.lineHeight.l1}px;
  text-align: center;
  margin: 67px 0 0;
  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: ${props => props.theme.fontSize.m2}px;
    line-height: ${props => props.theme.lineHeight.m4}px;
  }
`

export const UnderTitle = styled.p`
  padding: 0 calc(205 / 1440 * 100%);
  text-align: center;
  font-weight: ${props => props.theme.weights.regular};
  font-size: ${props => props.theme.fontSize.m1}px;
  line-height: ${props => props.theme.lineHeight.m3}px;
  color: ${props => props.theme.colors.black};
  margin: 15px 0 40px;
  & span {
    color: ${props => props.theme.colors.blue};
    cursor: pointer;
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    padding: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: ${props => props.theme.fontSize.s4}px;
    line-height: ${props => props.theme.lineHeight.m2}px;
  }
`
