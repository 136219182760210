import pluralize from 'pluralize'
import { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as styled from 'components/Menu/Menu.styled'

import { Icon, Name, RemoveContent } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import {
  CMSService,
  assetsId,
  collectionNameToFind,
  getParentCollectionName,
  reservedCollectionNames,
} from 'services/cms'
import { Collection } from 'utils'
import { AddNew } from '../AddNew'
import { Branch, CollectionsItem } from './CollectionsItem'

interface Props {
  collections: Collection[]
  currentCollection?: Collection
  setIsSchemaPage: React.Dispatch<React.SetStateAction<boolean>>
}

export const CollectionsItems: React.FC<Props> = ({ collections, currentCollection, setIsSchemaPage }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const navigate = useNavigate()
  const [addCollection, setAddCollection] = useState(false)
  const [subCollectionParentName, setSubCollectionParentName] = useState<string>()
  const [toRemove, setToRemove] = useState<Collection | null>(null)

  const getCollectionsTree = (lvl: number, root?: string): Branch[] =>
    collections
      .filter(el => el.name !== assetsId)
      .filter(
        el =>
          collectionNameToFind(el.name).split('/').length === lvl + 1 && (root ? el.name.startsWith(`${root}/`) : true)
      )
      .map(el => ({ ...el, children: getCollectionsTree(lvl + 1, el.name) } as Branch))

  const collectionsTree = useMemo(() => getCollectionsTree(0), [collections])

  const openRecords = (cId: string) => {
    setIsSchemaPage(false)
    navigate(`/projects/${id}/collections/${cId}`)
  }

  const openSchema = (cId: string) => {
    navigate(`/projects/${id}/collections/${cId}`)
    setIsSchemaPage(true)
  }

  const createCollection = (name: string, callback: () => void) => {
    if (name) {
      const plural = pluralize.plural(name).toLowerCase()
      const singular = pluralize.singular(name).toLowerCase()
      if (
        [...reservedCollectionNames, ...collections.map(el => el.name)].find(el =>
          (subCollectionParentName
            ? [`${subCollectionParentName}/${plural}`, `${subCollectionParentName}/${singular}`]
            : [plural, singular]
          ).includes(el.toLowerCase())
        )
      ) {
        toast(`You cannot create collection named ${name}`)
        callback()
      } else {
        startLoader()
        CMSService.createCollection(id, plural, subCollectionParentName)
          .then(res => openSchema(res.data.name))
          .catch(err => toast(err))
          .finally(() => {
            callback()
            stopLoader()
          })
      }
    }
  }

  const remove = (callback: () => void) => {
    if (toRemove) {
      startLoader()
      CMSService.deleteCollection(id, toRemove.name)
        .then(() => {
          if (currentCollection?.name === toRemove.name) {
            const parentCollectionName = getParentCollectionName(currentCollection.name)
            openRecords(parentCollectionName || '')
          }
        })
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
        })
    }
  }

  const closeAddCollection = () => {
    setAddCollection(false)
    setSubCollectionParentName(undefined)
  }

  return (
    <>
      <styled.NavHead>
        <styled.Title>Collections</styled.Title>
        <Icon name={Name.RIGHT_SIDEBAR_ADD} onClick={() => setAddCollection(true)} />
      </styled.NavHead>
      <styled.NavBody>
        {collectionsTree.map(el => (
          <CollectionsItem
            key={el.name}
            collection={el}
            currentCollection={currentCollection}
            openRecords={openRecords}
            openSchema={openSchema}
            setToRemove={setToRemove}
            setSubCollectionParentName={setSubCollectionParentName}
            lvl={0}
          />
        ))}
      </styled.NavBody>
      <AddNew
        active={addCollection || !!subCollectionParentName}
        close={closeAddCollection}
        onAdd={createCollection}
        subCollectionParentName={subCollectionParentName}
      />
      <RemoveContent
        toRemove={toRemove}
        title="Delete collection"
        text="You are about to delete the collection <b>{name}</b>. To make sure, enter the collection name below"
        clean={() => setToRemove(null)}
        remove={remove}
        nameConfirmation
      />
    </>
  )
}
