import { useFormik } from 'formik'
import React, { useContext, useMemo, useState } from 'react'

import { Button, ButtonColor, ButtonSize, InputProperty, inputs, Modal, ModalActions, validate } from 'components'
import { ProgressContext } from 'context'
import { MultiFactorUser, PhoneAuthProvider, PhoneMultiFactorGenerator } from 'firebase/auth'
import { refreshUser } from 'hooks'
import { ValueType } from 'utils'

interface Props {
  multiFactorUser: MultiFactorUser
  verificationId: string
  setVerificationId: React.Dispatch<React.SetStateAction<string>>
}

export const CheckCode: React.FC<Props> = ({ multiFactorUser, verificationId, setVerificationId }) => {
  const close = () => setVerificationId('')

  return (
    <Modal active={!!verificationId} title="Enter code" onClose={close}>
      <Children close={close} multiFactorUser={multiFactorUser} verificationId={verificationId} />
    </Modal>
  )
}

interface ChildrenProps {
  close: () => void
  multiFactorUser: MultiFactorUser
  verificationId: string
}

const Children: React.FC<ChildrenProps> = ({ close, multiFactorUser, verificationId }) => {
  const { toast } = useContext(ProgressContext)
  const [wait, setWait] = useState(false)

  const formik = useFormik({
    initialValues: { code: null },
    validate: data => validate(data, properties),
    onSubmit: data => {
      const { code } = data
      if (code) {
        setWait(true)
        const phoneAuthCredential = PhoneAuthProvider.credential(verificationId, code)
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential)
        multiFactorUser
          .enroll(multiFactorAssertion)
          .then(() => {
            close()
            refreshUser()
          })
          .catch(err => toast(err))
          .finally(() => setWait(false))
      }
    },
  })

  const properties = useMemo(
    (): InputProperty[] => [
      {
        name: 'code',
        type: ValueType.string,
        position: 0,
        isRequired: true,
      },
    ],
    []
  )

  return (
    <>
      {inputs(properties, formik)}
      <ModalActions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={close}>
          Cancel
        </Button>
        <Button size={ButtonSize.DEFAULT} onClick={formik.submitForm} loading={wait}>
          Check code
        </Button>
      </ModalActions>
    </>
  )
}
