import { Box } from '@mui/material'
import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactTimeago from 'react-timeago'

import { ProgressContext, UserContext } from 'context'
import { Conversation } from 'services/conversations'
import { firestore } from 'services/firebase'
import { firstLetterUpperCase } from 'utils'

countries.registerLocale(enLocale)

interface Props {
  adminMode?: boolean
}

export const ConversationsHistory: React.FC<Props> = ({ adminMode }) => {
  const { firebaseUser } = useContext(UserContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const navigate = useNavigate()
  const [conversations, setConversations] = useState<Conversation[]>([])

  useEffect(() => {
    startLoader()
    const unsubscribe = adminMode
      ? firestore
          .collection('conversations')
          .orderBy('createdAt', 'desc')
          .onSnapshot({
            next: res => {
              setConversations(res.docs.map(el => ({ id: el.id, ...el.data() })) as Conversation[])
              stopLoader()
            },
            error: err => {
              toast(err)
              stopLoader()
            },
          })
      : firestore
          .collection('conversations')
          .orderBy('createdAt', 'desc')
          .where('participantIds', 'array-contains', firebaseUser.uid)
          .onSnapshot({
            next: res => {
              setConversations(res.docs.map(el => ({ id: el.id, ...el.data() })) as Conversation[])
              stopLoader()
            },
            error: err => {
              toast(err)
              stopLoader()
            },
          })
    return () => {
      unsubscribe()
    }
  }, [firebaseUser.uid])

  return (
    <Box display="flex" flexDirection="column" gap="10px" width="100%" height="100%" padding="10px" overflow="auto">
      {conversations.map(el => (
        <Box
          key={el.id}
          onClick={() => navigate(`/ai?conversationId=${el.id}${adminMode ? '&adminMode=true' : ''}`)}
          sx={{ wordBreak: 'break-all', cursor: 'pointer', borderRadius: '8px', padding: '10px' }}
        >
          <Box>
            {el.geocoding?.city ? firstLetterUpperCase(el.geocoding.city) : '-'}{' '}
            {el.geocoding?.country ? countries.getName(el.geocoding.country, 'en') : '-'}
          </Box>
          <Box>{el.lastMessageText}</Box>
          <Box>Page: {el.context?.page || '-'}</Box>
          <Box>Intent: {el.context?.intent || '-'}</Box>
          <Box>User Agent: {el.context?.userAgent || '-'}</Box>
          <Box>
            Created: <ReactTimeago date={new Date(el.createdAt.seconds * 1000)} />
          </Box>
        </Box>
      ))}
    </Box>
  )
}
