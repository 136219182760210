import { Box } from '@mui/material'
import React from 'react'

import { AnyVariableInput } from 'components'
import { RightSidebarItem, SubWrapper } from 'partials/RightSidebar/components'
import { CollectionVariable, LocalVariable, Screen, ValueType, VariableSource } from 'utils'

interface Props {
  currentVariable: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const CollectionParams: React.FC<Props> = ({
  currentVariable,
  setCurrentVariable,
  screenConfig,
  allLocalVariables,
}) => {
  const paramsKeys = currentVariable.collection?.name.match(/{([^}]*)}/g)?.map(el => el.slice(1, -1))

  return (
    <>
      {paramsKeys?.map(el => (
        <SubWrapper key={el} small noBorderBottom childrenFor="">
          <Box display="flex" flexDirection="column" gap="12px">
            <RightSidebarItem small title="">
              <SubWrapper small title={el} childrenFor="">
                <AnyVariableInput
                  valueType={ValueType.string}
                  value={currentVariable.collection?.params?.[el]}
                  onChange={value =>
                    value
                      ? setCurrentVariable(currentVariable =>
                          currentVariable
                            ? {
                                ...currentVariable,
                                collection: {
                                  ...(currentVariable.collection as CollectionVariable),
                                  params: { ...currentVariable.collection?.params, [el]: value },
                                },
                              }
                            : null
                        )
                      : setCurrentVariable(currentVariable => {
                          if (currentVariable) {
                            const currentVariableCopy = JSON.parse(JSON.stringify(currentVariable)) as VariableSource
                            if (currentVariableCopy.collection?.params?.[el]) {
                              delete currentVariableCopy.collection.params[el]
                            }
                            return currentVariableCopy
                          } else {
                            return null
                          }
                        })
                  }
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              </SubWrapper>
            </RightSidebarItem>
          </Box>
        </SubWrapper>
      ))}
    </>
  )
}
