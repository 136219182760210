import React from 'react'

import { Connections, CustomSectionsWrapper, Text, Type } from 'components'
import { Links, SettingsLayout } from '../components'

export const Stripe: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Stripe</Text>
    <Text type={Type.SUB_TITLE}>Accept payments in your web app with Stripe.</Text>
    <Links />
    <CustomSectionsWrapper>
      {/* <Notification
        color={NotificationColor.RED}
        open
        inside
        title="2-Factor verification required"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip"
        fix={() => {}}
      /> */}
      <Connections stripe />
    </CustomSectionsWrapper>
  </SettingsLayout>
)
