import styled from 'styled-components'

export const Container = styled.div<{ settings?: boolean }>`
  padding: ${props => (props.settings ? '30px 90px' : '30px 40px')};
  width: 100%;
  height: ${props => (props.settings ? '100%' : 'calc(100% - 60px)')};
  overflow: hidden auto;
  background-color: ${props => props.theme.colors.secondaryBackground};
  position: relative;
`

export const Content = styled.div<{ settings?: boolean }>`
  max-width: ${props => (props.settings ? 980 : 1280)}px;
  width: 100%;
  margin: 0 auto;
`
