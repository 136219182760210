import React from 'react'
import { ISvg, Svg } from '../Svg'

export const Design: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M0.30865 5.89586L7.64184 10.5624C7.74889 10.6303 7.87305 10.6664 7.99983 10.6664C8.12448 10.6664 8.24848 10.6318 8.35782 10.5624L15.691 5.89586C15.7856 5.83554 15.8634 5.75237 15.9173 5.65404C15.9713 5.55571 15.9996 5.44538 15.9997 5.33322C15.9997 5.22114 15.9714 5.11087 15.9174 5.01263C15.8635 4.9144 15.7856 4.83136 15.691 4.77123L8.35779 0.103989C8.25074 0.0360669 8.12658 0 7.9998 0C7.87302 0 7.74885 0.0360669 7.6418 0.103989L0.30865 4.77123C0.214066 4.83136 0.13619 4.9144 0.0822362 5.01263C0.0282825 5.11087 -3.16484e-06 5.22114 2.65597e-10 5.33322C6.23063e-05 5.44538 0.0283707 5.55571 0.0823133 5.65405C0.136256 5.75238 0.214095 5.83554 0.30865 5.89586Z"
      fill="#7F899E"
    />
    <path
      d="M15.691 10.104L13.6264 8.79004L9.07381 11.6873C8.75047 11.892 8.37982 12 7.99983 12C7.61984 12 7.24919 11.892 6.92785 11.688L2.37326 8.79004L0.30865 10.104C0.214101 10.1643 0.136267 10.2475 0.0823248 10.3458C0.028383 10.4442 7.09293e-05 10.5545 2.65597e-10 10.6667C-3.16484e-06 10.7787 0.0282825 10.889 0.0822362 10.9872C0.13619 11.0855 0.214066 11.1685 0.30865 11.2286L7.64184 15.8959C7.74886 15.9639 7.87304 15.9999 7.99983 15.9999C8.12383 15.9999 8.24848 15.9652 8.35782 15.8959L15.691 11.2286C15.7856 11.1685 15.8634 11.0855 15.9174 10.9872C15.9713 10.889 15.9996 10.7787 15.9996 10.6667C15.9996 10.5545 15.9713 10.4442 15.9173 10.3458C15.8634 10.2475 15.7855 10.1643 15.691 10.104Z"
      fill="#7F899E"
    />
  </Svg>
)
