import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'
import { Integration, IntegrationGooglePlay, IntegrationStatus } from 'hooks'

export const connectGooglePlayParamName = 'connect-googleplay'

interface Props {
  googleplayIntegration: Integration | null
}

export const GooglePlayButton: React.FC<Props> = ({ googleplayIntegration }) => {
  const navigate = useNavigate()

  return googleplayIntegration?.status === IntegrationStatus.READY ? (
    <a href="https://play.google.com" target="_blank" rel="noreferrer">
      <Button
        variant={ButtonVariant.LINK}
        color={ButtonColor.SECONDARY}
        leftIcon={<Icon name={Name.SIZE_24_PLAY_STORE} />}
      >
        {(googleplayIntegration?.data as IntegrationGooglePlay | undefined)?.teamName}
      </Button>
    </a>
  ) : (
    <Button
      variant={ButtonVariant.LINK}
      color={ButtonColor.SECONDARY}
      leftIcon={<Icon name={Name.SIZE_24_PLAY_STORE} />}
      onClick={() => navigate(`?${connectGooglePlayParamName}=true`)}
      disabled={!googleplayIntegration}
    >
      Link to Google Play
    </Button>
  )
}
