import { FormControlLabel } from '@mui/material'
import styled from 'styled-components'

import { ThemeType } from 'theme'
import { CoordinateInput } from './components/CoordinateInput/CoordinateInput.styled'
import { PhoneNumberInput } from './components/PhoneNumberInput/PhoneNumberInput.styled'
import { AutocompleteInput } from './components/SimpleArrayInput/SimpleArrayInput.styled'
import { VariableInput } from './components/VariableInput/VariableInput.styled'

export const Field = styled(FormControlLabel)<{
  $isPlaceholder?: boolean
  $smallWidth?: boolean
  $ai?: boolean
  $editByModal?: boolean
  $password?: boolean
  $folder?: boolean
  $select?: boolean
  $noMargin?: boolean
}>`
  margin: ${props => (props.$noMargin ? '0' : '30px 0 0')} !important;
  width: 100%;
  max-width: ${props => props.$smallWidth && '576px'};
  box-sizing: border-box;
  align-items: flex-start !important;
  position: relative;
  & > span {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.theme.colors.darkText};
    margin-bottom: 10px;
  }
  & ${PhoneNumberInput} > div > input,
  & ${AutocompleteInput} > div > div > div,
  & ${CoordinateInput} > input,
  & ${VariableInput} > input,
  & > input,
  & > select,
  & > textarea {
    resize: none;
    outline: none;
    width: 100%;
    padding: ${props =>
      props.$folder
        ? '9px 60px 9px 45px'
        : props.$select
        ? '9px 60px 9px 9px'
        : props.$ai || props.$editByModal || props.$password
        ? '9px 32px 9px 9px'
        : '9px'} !important;
    box-sizing: border-box;
    font-family: ${props => props.theme.fonts.primary};
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid
      ${props => (props.theme.themeType === ThemeType.LIGHT ? props.theme.colors.borderColor : 'transparent')};
    transition: border-color 300ms ease-out;
    background-color: ${props => props.theme.colors.secondaryButtonBg1};
    color: ${props => props.theme.colors.darkText};
    &::placeholder {
      opacity: unset;
      color: ${props => props.theme.colors.grey1};
    }
    &:hover,
    &:focus-within {
      border-color: ${props => props.theme.colors.blue};
    }
    &:disabled {
      pointer-events: none;
      border-color: ${props => props.theme.colors.borderColor};
      background: ${props => props.theme.colors.disabledBgColor};
      color: ${props => props.theme.colors.disabledTextColor};
      &::placeholder {
        color: ${props => props.theme.colors.disabledTextColor};
      }
    }
  }
  & > select {
    height: 39px;
    color: ${props => props.$isPlaceholder && props.theme.colors.grey1};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.6rem center;
    background-size: 1em;
    background-color: transparent;
    & > option {
      color: ${props => props.theme.colors.darkText};
    }
  }
  & > textarea {
    &:last-of-type {
      padding: 0 9px !important;
    }
  }
`

export const HelpText = styled.div`
  font-weight: 400;
  color: ${props => props.theme.colors.grey1};
  margin: 5px 0;
`

export const RunButton = styled.div`
  margin-top: 12px;
  & > button {
    width: 100%;
  }
`

export const ErrorMessage = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.red};
  & svg {
    min-width: 16px;
    min-height: 16px;
    margin-right: 6px;
    & circle {
      fill: ${props => props.theme.colors.red};
    }
  }
`

export const SimpleTsInputWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.borderColor};
  transition: border-color 300ms ease-out;
  & > div:first-child {
    padding: 15px;
    background: ${props => props.theme.colors.primaryBackground};
    border-radius: 7px 7px 0 0;
    color: ${props => props.theme.colors.darkText};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  & > div:last-child {
    border-radius: 0 0 7px 7px;
  }
  &:hover,
  &:focus-within {
    border-color: ${props => props.theme.colors.blue};
  }
`

export const SimpleJsonInputWrapper = SimpleTsInputWrapper
export const SimpleObjectInputWrapper = SimpleTsInputWrapper

export const ThreeRows = styled.div`
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  max-width: 200px;
`

export const Nowrap = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
`
