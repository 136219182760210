import React, { useContext } from 'react'

import * as styled from '../../../CustomPicker.styled'

import { getFieldIcon, Icon, Name, Rotation, SubPicker } from 'components'
import { ProjectContext } from 'context'
import { includesSearch, LocalVariable, ValueType, VariableSource, VariableSourceType } from 'utils'

const arrow = (
  <styled.Arrow>
    <Icon name={Name.RIGHT_SIDEBAR_ARROW_THIN_DOWN} rotation={Rotation.RIGHT} width={16} height={16} />
  </styled.Arrow>
)

interface Props {
  value?: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  setNewSource: (val: React.SetStateAction<VariableSource | null>) => void
  allLocalVariables: LocalVariable[]
  dataMode?: boolean
  recordMode?: boolean
  collectionMode?: boolean
}

export const SelectNewSource: React.FC<Props> = ({
  value,
  setCurrentVariable,
  setNewSource,
  allLocalVariables,
  dataMode,
  recordMode,
  collectionMode,
}) => {
  const {
    config: {
      data: { collections = [] },
    },
  } = useContext(ProjectContext)

  return (
    <SubPicker
      onBack={() => setCurrentVariable(value || null)}
      hideLabel={!value}
      ItemsContent={({ search }) => (
        <>
          {!collectionMode &&
            allLocalVariables
              .filter(el => includesSearch(el.name, search))
              .map(el => (
                <styled.Item
                  key={el.name}
                  onClick={() =>
                    setNewSource({
                      type: VariableSourceType.localVariable,
                      variableName: el.name,
                    })
                  }
                  active={value?.type === VariableSourceType.localVariable && value.variableName === el.name}
                  color="darkText1"
                  iconHover
                >
                  <Icon
                    name={
                      el.type
                        ? getFieldIcon(el.type)
                        : el.variable?.source?.type === VariableSourceType.collection
                        ? Name.ADDITIONAL_COLLECTION
                        : el?.variable?.arrayConstant
                        ? getFieldIcon(ValueType.array)
                        : el?.variable?.booleanConstant !== undefined
                        ? getFieldIcon(ValueType.boolean)
                        : Name.RIGHT_SIDEBAR_DEFAULT_ICON
                    }
                  />
                  <styled.ItemLabel>{el.name}</styled.ItemLabel>
                  {arrow}
                </styled.Item>
              ))}
          {!dataMode && !collectionMode && !recordMode && includesSearch('Global', search) && (
            <styled.Item
              onClick={() => setNewSource({ type: VariableSourceType.globalVariable })}
              active={value?.type === VariableSourceType.globalVariable}
              color="darkText1"
              iconHover
            >
              <Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />
              <styled.ItemLabel>Global</styled.ItemLabel>
              {arrow}
            </styled.Item>
          )}
          {!dataMode && !collectionMode && !recordMode && includesSearch('Components', search) && (
            <styled.Item
              onClick={() => setNewSource({ type: VariableSourceType.component })}
              active={value?.type === VariableSourceType.component}
              color="darkText1"
              iconHover
            >
              <Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />
              <styled.ItemLabel>Components</styled.ItemLabel>
              {arrow}
            </styled.Item>
          )}
          {collections
            .filter(el => includesSearch(el.name, search))
            .map(el => (
              <styled.Item
                key={el.name}
                onClick={() =>
                  setNewSource({
                    type: VariableSourceType.collection,
                    collection: { name: el.name },
                  })
                }
                active={value?.type === VariableSourceType.collection && value.collection?.name === el.name}
                color="darkText1"
                iconHover
              >
                <Icon name={Name.ADDITIONAL_COLLECTION} />
                <styled.ItemLabel>{el.name}</styled.ItemLabel>
                {arrow}
              </styled.Item>
            ))}
        </>
      )}
    />
  )
}
