import React from 'react'

import { BooleanInput, Icon, Name, Rotation } from 'components'
import { RightSidebarItem, SettingField, SettingNumberField, SubWrapper } from 'partials/RightSidebar/components'
import { LocalVariable, Resource, Screen, ScreenComponent } from 'utils'
import { Common, Id } from '../common'

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const CarouselSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const { componentType, name, subComponents = [], value, duration, loop } = component

  const moveSlide = (start: boolean, index: number) => {
    const slidesCopy = JSON.parse(JSON.stringify(subComponents)) as ScreenComponent[]
    let slideCopy: ScreenComponent | null = null
    if (start && index !== 0) {
      slideCopy = slidesCopy[index - 1]
      slidesCopy[index - 1] = slidesCopy[index]
      slidesCopy[index] = slideCopy
    } else if (!start && index !== slidesCopy.length - 1) {
      slideCopy = slidesCopy[index + 1]
      slidesCopy[index + 1] = slidesCopy[index]
      slidesCopy[index] = slideCopy
    }
    if (slideCopy) {
      onComponentPropertiesChange([{ propName: 'subComponents', value: slidesCopy }])
    }
  }

  const onSlideClick = (el: ScreenComponent) => {}

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
      </SubWrapper>
      <SubWrapper title="Slides" canHide onAdd={() => {}} childrenFor={componentType}>
        {subComponents.map((el, i) => (
          <SettingField
            key={el.id}
            value={el.name}
            onClick={() => onSlideClick(el)}
            actions={
              <>
                <Icon name={Name.RIGHT_SIDEBAR_MOVE_UP} rotation={Rotation.LEFT} onClick={() => moveSlide(false, i)} />
                <Icon name={Name.RIGHT_SIDEBAR_MOVE_UP} onClick={() => moveSlide(true, i)} />
                <Icon
                  name={Name.RIGHT_SIDEBAR_DELETE}
                  onClick={() =>
                    onComponentPropertiesChange([
                      { propName: 'subComponents', value: subComponents.filter(c => c.id !== el.id) },
                    ])
                  }
                />
              </>
            }
          />
        ))}
      </SubWrapper>
      <SubWrapper title="Options" canHide childrenFor={componentType}>
        <SettingNumberField
          subLabel="Default slide"
          value={value?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'value', value: { constant: value } }])}
          source={value?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'value', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingNumberField
          subLabel="Duration (sec)"
          value={duration !== undefined ? String(duration) : undefined}
          onChange={value => onComponentPropertiesChange([{ propName: 'duration', value: +value }])}
          onlyNumber
        />
        <BooleanInput
          subLabel="Loop"
          value={loop?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'loop', value: { constant: value } }])}
          source={loop?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'loop', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    </RightSidebarItem>
  )
}
