import { Box } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import * as styled from './ProjectsMenu.styled'

import { ProjectsContext } from 'context'
import { updatedAtSort } from 'pages/Projects/pages'
import { IRoute, routes } from 'routes'
import { Notifications } from '../Notifications'
import { Profile } from '../Profile'

interface Props {
  isXL: boolean
}

export const ProjectsMenu: React.FC<Props> = ({ isXL }) => {
  const { pathname } = useLocation()
  const { projects } = useContext(ProjectsContext)

  const checkActive = (route: IRoute) =>
    pathname === route.childPath || (route.index ? pathname === route.path : pathname.startsWith(route.path))

  const sortedProjects = useMemo(() => projects.slice().sort((a, b) => -updatedAtSort(a, b)), [projects])

  return (
    <styled.ControllsWrapper>
      {isXL ? (
        <Box overflow="auto" margin="0 -13px 13px" padding="0 13px" height="100%">
          <Box display="flex" flexWrap="wrap" rowGap="40px">
            {sortedProjects.map(el => (
              <styled.Project key={el.id} to={`/projects/${el.id}`}>
                <img src={el.logoUrl} alt={el.name} />
                <div>{el.name}</div>
              </styled.Project>
            ))}
          </Box>
        </Box>
      ) : (
        <Box margin="0 -13px 20px" padding="0 10px" display="flex" flexDirection="column" gap="6px" overflow="auto">
          {routes.map(
            el =>
              el.projectsPageIcon && (
                <styled.LinkBox key={el.path} active={checkActive(el)}>
                  <Link to={el.customPath || el.path}>
                    {el.projectsPageIcon} {el.name}
                  </Link>
                </styled.LinkBox>
              )
          )}
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap="12px">
        {!isXL && <Notifications wide />}
        <Profile isXL={isXL} />
      </Box>
    </styled.ControllsWrapper>
  )
}
