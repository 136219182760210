import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as styled from './About.styled'

import { Icon, Name } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { Activity, ActivityItemSmall, ActivityItemType, Build } from 'pages/Repository/components'
import { firestore } from 'services/firebase'

interface Props {
  scrollToReadme: () => void
  refreshRepo: () => void
  settings?: boolean
  isLG?: boolean
  isXXL?: boolean
}

export const About: React.FC<Props> = ({ scrollToReadme, refreshRepo, settings, isLG, isXXL }) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id, description },
  } = useContext(ProjectContext)
  const navigate = useNavigate()
  const [activitiesWait, setActivitiesWait] = useState(true)
  const [activities, setActivities] = useState<Activity[]>([])
  const [buildsWait, setBuildsWait] = useState(true)
  const [builds, setBuilds] = useState<Build[]>([])

  useEffect(() => {
    let activitiesLoaded = false
    const activitiesUnsubscribe = firestore
      .collection(`projects/${id}/activities`)
      .orderBy('time', 'desc')
      .limit(3)
      .onSnapshot({
        next: res => {
          setActivitiesWait(false)
          setActivities(res.docs.map(el => ({ id: el.id, ...el.data() })) as Activity[])
          if (activitiesLoaded) {
            refreshRepo()
          }
          activitiesLoaded = true
        },
        error: err => toast(err),
      })
    const buildsUnsubscribe = firestore
      .collection(`projects/${id}/builds`)
      .orderBy('build_number', 'desc')
      .limit(3)
      .onSnapshot({
        next: res => {
          setBuildsWait(false)
          setBuilds(res.docs.map(el => ({ id: el.id, ...el.data() })) as Build[])
        },
        error: err => toast(err),
      })
    return () => {
      activitiesUnsubscribe()
      buildsUnsubscribe()
    }
  }, [])

  return isLG || isXXL ? (
    <></>
  ) : (
    <styled.About>
      <h2>About</h2>
      <p>{description}</p>
      <span onClick={scrollToReadme}>
        <Icon name={Name.REPOSITORY_README} />
        Readme
      </span>
      {!activitiesWait && (
        <Box mt="15px" display="flex" flexDirection="column" gap="5px">
          <h2>Activities</h2>
          {activities.length ? (
            activities.map(el => <ActivityItemSmall key={el.id} item={el} type={ActivityItemType.ACTIVITY} />)
          ) : (
            <ActivityItemSmall type={ActivityItemType.NO_DATA} item={{ title: 'No activities' }} />
          )}
        </Box>
      )}
      {!buildsWait && (
        <Box mt="15px" display="flex" flexDirection="column" gap="5px">
          <h2>Builds</h2>
          {builds.length ? (
            builds.map(el => (
              <ActivityItemSmall
                key={el.id}
                item={el}
                type={ActivityItemType.BUILD}
                onClick={() =>
                  navigate(
                    settings
                      ? `/projects/${id}/settings/repository/builds/${el.build_number}/logs`
                      : `/projects/${id}/repository/builds/${el.build_number}/logs`
                  )
                }
              />
            ))
          ) : (
            <ActivityItemSmall type={ActivityItemType.NO_DATA} item={{ title: 'No builds' }} />
          )}
        </Box>
      )}
    </styled.About>
  )
}
