import { Box } from '@mui/material'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

import * as styled from './ScreenContainer.styled'

import { Icon, Name } from 'components'
import {
  DeviceSettingsContext,
  MovableComponentsContext,
  ProgressContext,
  ProjectContext,
  selectedDefaultState,
} from 'context'
import { refreshNavigationTree, setInputParameters, setLocalVariables, useVariable } from 'hooks'
import { openStylePickerEvent } from 'partials/RightSidebar/components'
import { movableElementId } from 'providers'
import {
  BarComponentType,
  ComponentType,
  ComponentsDirection,
  Config,
  ISize,
  Screen,
  ScreenComponent,
  ScreenTopBarComponentsKey,
  createAlertScreen,
  createScreen,
  createTabBar,
  createTopBar,
  devices,
  findComponent,
  findComponentParentDataById,
  generateFirestoreId,
  getBackScreenConfig,
  getComponentId,
  getComponentNameById,
  getComponentSmallIconByType,
  getComponentTypeById,
  getElementIdFromConfig,
  getImageByConfigs,
  getNewComponentPosition,
  getPx,
  isParentComponent,
  moveSlide,
  profieTabs,
  removeComponentById,
  runActions,
  setComponents,
  setEmptyMode,
  setInnerModeEvent,
  setTabs,
  tabBarHeight,
  topBarHeight,
} from 'utils'
import { hintLabelPositionHandler, hoverHintLabel, pointerLine, selectedHintLabel } from './utils'

const prevSlideEvent = 'prevSlideEvent'
const nextSlideEvent = 'nextSlideEvent'
const listEmptyModeEvent = 'listEmptyModeEvent'
const listItemModeEvent = 'listItemModeEvent'

const scroll: { [key: string]: () => void } = {}

interface Props {
  screenConfig: Screen
}

export const ScreenContainer: React.FC<Props> = ({ screenConfig }) => {
  const ref = useRef<HTMLDivElement>(null)
  const {
    screenName,
    id,
    components = [],
    topBar,
    showTopBar,
    showBackButton,
    screenTitle,
    inputParameters,
    localVariables,
    onScreenVisitActions,
  } = screenConfig
  const {
    device,
    compression,
    selected: {
      screenId: selectedScreenId,
      componentId: selectedComponentId,
      subComponentId: selectedSubComponentId,
      topBarId: selectedTopBarId,
      tabBarId: selectedTabBarId,
      tabScreen: selectedTabScreen,
      innerModeComponentId,
    },
    setSelected,
  } = useContext(DeviceSettingsContext)
  const selectedId = selectedTopBarId || selectedTabBarId || selectedComponentId
  const subSelectedId = selectedTabScreen || selectedSubComponentId
  const [hoveredId, setHoveredId] = useState('')
  const [lastUpdate, setLastUpdate] = useState(+new Date())
  const { projectReady, config, setConfig, language, theme } = useContext(ProjectContext)
  const {
    data: { collections, globalVariables },
    branding: {
      colorStyles,
      typography: { iosDefaultFontFamily: fontFamily, fontStyles = [] },
      appName,
      icons: { iosIcon },
    },
    tabBars = [],
    resources = [],
    screens = [],
  } = config
  const {
    movableComponent,
    setMovableComponent,
    movableTabBarComponent,
    setMovableTabBarComponent,
    movableTopBarComponent,
    setMovableTopBarComponent,
    // movableTabComponent,
    // setMovableTabComponent,
  } = useContext(MovableComponentsContext)
  const getVariable = useVariable()
  const { toast } = useContext(ProgressContext)
  // @ts-ignore
  const { width, height, safeAreaTop, safeAreaBottom, systemBar } = devices[device]
  const screenId = id
  const localScreenId = `screen.${screenName}.${screenId}`
  const topBarId = `${screenId}-${BarComponentType.topBar}`
  const localTopBarId = `${BarComponentType.topBar}.top bar.${topBarId}`
  const tabBar = tabBars.find(el => el.tabs?.find(el => el.screen === screenName))
  const tabBarId = `${tabBar?.id}`
  const localTabBarId = `${BarComponentType.tabBar}.${tabBar?.name}.${tabBarId}`

  const backScreenConfig = useMemo(
    () => getBackScreenConfig(screenName, screens),
    [screenName, JSON.stringify(screens)]
  )

  const isScreenActive = selectedScreenId === screenId
  const isComponentOnScreenSelected = isScreenActive && !!(selectedId || subSelectedId)

  useEffect(() => {
    refreshScreen()
      .then(() => {
        refreshNavigationTree()
        setLastUpdate(+new Date())
      })
      .catch(() => {})
  }, [
    projectReady,
    JSON.stringify(screenConfig),
    JSON.stringify(backScreenConfig),
    fontFamily,
    JSON.stringify(fontStyles),
    JSON.stringify(colorStyles),
    JSON.stringify(resources),
    JSON.stringify(tabBar),
    JSON.stringify(collections),
    JSON.stringify(globalVariables),
    JSON.stringify(appName),
    iosIcon,
    theme,
    device,
    language,
  ])

  const refreshScreen = () =>
    new Promise(async (resolve, reject) => {
      try {
        const screenWrapper = ref.current
        if (screenWrapper) {
          const getVariableValue = getVariable(screenConfig)
          if (inputParameters) {
            await setInputParameters(getVariableValue, inputParameters)
          }
          if (localVariables) {
            await setLocalVariables(language, getVariableValue, localVariables)
          }
          if (onScreenVisitActions) {
            await runActions(language, getVariableValue, onScreenVisitActions)
          }
          screenWrapper.innerHTML = ''
          const screen = await createScreen(
            localScreenId,
            screenConfig,
            screenWrapper,
            colorStyles,
            theme,
            resources,
            getVariableValue,
            () => onClickComponent({})
          )
          const showTopBarValue = showTopBar?.source
            ? (await getVariableValue(showTopBar.source)) === 'true'
            : !!showTopBar?.constant
          const getPxValue = getPx(!!tabBar, showTopBarValue, safeAreaTop, safeAreaBottom)
          if (showTopBarValue) {
            await createTopBar(
              localTopBarId,
              topBarId,
              topBarHeight + safeAreaTop,
              screen,
              fontFamily,
              fontStyles,
              colorStyles,
              theme,
              resources,
              compression,
              screenConfig,
              language,
              getPxValue,
              getVariableValue,
              getVariable,
              topBar,
              showBackButton,
              screenTitle,
              (componentId, subComponentId) => onClickComponent({ topBarId, componentId, subComponentId }),
              setHoveredId,
              setMovableComponent
            )
          }
          if (components.length) {
            await setComponents(
              components,
              screen,
              fontFamily,
              fontStyles,
              colorStyles,
              theme,
              resources,
              compression,
              true,
              screenConfig,
              language,
              getPxValue,
              getVariableValue,
              getVariable,
              func => (scroll[screenName] = func),
              (componentId, subComponentId) => onClickComponent({ componentId, subComponentId }),
              setHoveredId,
              config => setMovableComponent(JSON.parse(JSON.stringify(config)) as ScreenComponent)
            )
            if (scroll[screenName]) {
              scroll[screenName]()
            }
          }
          if (tabBar) {
            const screenTabBar = await createTabBar(
              localTabBarId,
              tabBarId,
              tabBar,
              tabBarHeight + safeAreaBottom,
              screen,
              colorStyles,
              theme,
              resources,
              getVariableValue,
              () => onClickComponent({ tabBarId }),
              setHoveredId
            )
            await setTabs(
              screenName,
              tabBar,
              screenTabBar,
              fontFamily,
              colorStyles,
              theme,
              resources,
              language,
              getVariableValue,
              tabScreen => onClickComponent({ tabBarId, tabScreen }),
              setHoveredId
              // config => setMovableTabComponent(JSON.parse(JSON.stringify(config)))
            )
          }
          if (backScreenConfig) {
            createAlertScreen(
              screen,
              screenWrapper,
              await getImageByConfigs(backScreenConfig, config, getVariable, language, theme)
            )
          }
        }
        resolve(true)
      } catch (err) {
        reject(err)
      }
    })

  const onClickComponent = ({
    componentId,
    subComponentId,
    topBarId,
    tabBarId,
    tabScreen,
  }: {
    componentId?: string
    subComponentId?: string
    topBarId?: string
    tabBarId?: string
    tabScreen?: string
  }) => {
    setSelected(selected => {
      const newSelected = {
        ...selectedDefaultState,
        screenId,
        innerModeComponentId:
          componentId === selected.innerModeComponentId ||
          topBarId === selected.innerModeComponentId ||
          tabBarId === selected.innerModeComponentId
            ? selected.innerModeComponentId
            : '',
        componentId: componentId || '',
        subComponentId: subComponentId || '',
        topBarId: topBarId || '',
        tabBarId: tabBarId || '',
        tabScreen: tabScreen || '',
      }
      return JSON.stringify(newSelected) === JSON.stringify(selected) ? selected : newSelected
    })
  }

  useEffect(() => {
    if (
      hoveredId &&
      hoveredId !== selectedId &&
      hoveredId !== subSelectedId &&
      !movableTabBarComponent &&
      !movableTopBarComponent
    ) {
      let hoveredComponent = ref.current?.querySelector(`[id$='${hoveredId}']`) as HTMLDivElement
      if (hoveredComponent) {
        if (movableComponent) {
          const component = findComponent(screenConfig, 'id', hoveredId)
          if (component) {
            if (!isParentComponent(component.componentType)) {
              hoveredComponent = hoveredComponent.parentNode as HTMLDivElement
            }
          } else {
            return
          }
        }
        //  else if (movableTabComponent) {
        //   if (tabBar) {
        //     const tabBarElement = document.getElementById(localTabBarId) as HTMLDivElement
        //     if (hoveredComponent.parentNode === tabBarElement) {
        //       hoveredComponent = tabBarElement
        //     } else if (hoveredComponent !== tabBarElement) {
        //       return
        //     }
        //   }
        // }
        hoveredComponent.classList.add('hoveredComponent')
        const screen = document.getElementById(localScreenId) as HTMLDivElement
        if (screen) {
          hoverHintLabel.innerHTML =
            '<div>' +
            ReactDOMServer.renderToStaticMarkup(
              getComponentSmallIconByType(getComponentTypeById(hoveredComponent.id))
            ) +
            getComponentNameById(hoveredComponent.id) +
            '</div>'
          hoveredComponent.appendChild(hoverHintLabel)
          hintLabelPositionHandler(hoverHintLabel, screen)
          return () => {
            hoveredComponent.classList.remove('hoveredComponent')
            hoverHintLabel.parentNode?.removeChild(hoverHintLabel)
          }
        }
      }
    }
  }, [hoveredId, selectedId])

  useEffect(() => {
    return outlineSelected(selectedId)
  }, [selectedId, subSelectedId, isScreenActive, lastUpdate])

  useEffect(() => {
    return outlineSelected(subSelectedId)
  }, [subSelectedId, isScreenActive, lastUpdate])

  useEffect(() => {
    if (selectedId && isScreenActive) {
      const selectedComponent = ref.current?.querySelector(`[id$='${selectedId}']`) as HTMLDivElement
      if (selectedComponent) {
        if (innerModeComponentId) {
          selectedComponent.classList.add('innerModeComponent')
          return () => {
            selectedComponent.classList.remove('innerModeComponent')
          }
        }
      }
    }
  }, [innerModeComponentId, selectedId, isScreenActive, lastUpdate])

  const outlineSelected = (componentId: string) => {
    if (componentId && isScreenActive) {
      const selectedComponent = ref.current?.querySelector(`[id$='${componentId}']`) as HTMLDivElement
      if (selectedComponent) {
        selectedComponent.classList.add('selectedComponent')
        const screen = document.getElementById(localScreenId) as HTMLDivElement
        if (screen) {
          const isCarousel = getComponentTypeById(selectedComponent.id) === ComponentType.carousel
          const isList = getComponentTypeById(selectedComponent.id) === ComponentType.list
          const isTabBar = getComponentTypeById(selectedComponent.id) === BarComponentType.tabBar
          const isTobBar = getComponentTypeById(selectedComponent.id) === BarComponentType.topBar
          const isText = getComponentTypeById(selectedComponent.id) === ComponentType.text
          const isButton = getComponentTypeById(selectedComponent.id) === ComponentType.button
          const innerModeComponent = isCarousel || isList || isTabBar || isTobBar
          const hasStyle = isText || isButton
          const hasActions = innerModeComponent || hasStyle || isCarousel
          selectedHintLabel.innerHTML =
            '<div>' +
            ReactDOMServer.renderToStaticMarkup(
              getComponentSmallIconByType(getComponentTypeById(selectedComponent.id))
            ) +
            getComponentNameById(selectedComponent.id) +
            '</div>' +
            (hasActions ? '<div>' : '') +
            (innerModeComponent
              ? `<svg viewBox="0 0 20 20" fill="none" style="pointer-events: auto;" onclick="document.dispatchEvent(new Event('${setInnerModeEvent}'))"><path d="M8.875 2.51554C9.57115 2.11362 10.4288 2.11362 11.125 2.51554L15.9192 5.28349C16.6154 5.68542 17.0442 6.4282 17.0442 7.23205V12.7679C17.0442 13.5718 16.6154 14.3146 15.9192 14.7165L11.125 17.4845C10.4288 17.8864 9.57115 17.8864 8.875 17.4845L4.08077 14.7165C3.38462 14.3146 2.95577 13.5718 2.95577 12.7679V7.23205C2.95577 6.4282 3.38462 5.68542 4.08077 5.28349L8.875 2.51554Z" stroke="white" stroke-width="1.5"/><circle cx="10" cy="10" r="2.25" stroke="white" stroke-width="1.5" /></svg>`
              : '') +
            (isCarousel ? '<i></i>' : '') +
            (isCarousel
              ? `<svg viewBox="0 0 16 16" fill="none" style="pointer-events: auto; transform: rotate(90deg);" onclick="document.dispatchEvent(new Event('${prevSlideEvent}'))"><path d="M3 6L8 11" stroke="white" stroke-width="1.5" stroke-linecap="round"></path><path d="M13 6L8 11" stroke="white" stroke-width="1.5" stroke-linecap="round"></path></svg><svg viewBox="0 0 16 16" fill="none" style="pointer-events: auto; transform: rotate(-90deg);" onclick="document.dispatchEvent(new Event('${nextSlideEvent}'))"><path d="M3 6L8 11" stroke="white" stroke-width="1.5" stroke-linecap="round"></path><path d="M13 6L8 11" stroke="white" stroke-width="1.5" stroke-linecap="round"></path></svg>`
              : '') +
            (isList ? '<i></i>' : '') +
            (isList
              ? `<svg viewBox="0 0 16 16" fill="none" style="pointer-events: auto; transform: rotate(90deg);" onclick="document.dispatchEvent(new Event('${listItemModeEvent}'))"><path d="M3 6L8 11" stroke="white" stroke-width="1.5" stroke-linecap="round"></path><path d="M13 6L8 11" stroke="white" stroke-width="1.5" stroke-linecap="round"></path></svg><svg viewBox="0 0 16 16" fill="none" style="pointer-events: auto; transform: rotate(-90deg);" onclick="document.dispatchEvent(new Event('${listEmptyModeEvent}'))"><path d="M3 6L8 11" stroke="white" stroke-width="1.5" stroke-linecap="round"></path><path d="M13 6L8 11" stroke="white" stroke-width="1.5" stroke-linecap="round"></path></svg>`
              : '') +
            ((innerModeComponent || isCarousel || isList) && hasStyle ? '<i></i>' : '') +
            (hasStyle
              ? `<svg viewBox="0 0 20 20" fill="none" style="pointer-events: auto;" onclick="document.dispatchEvent(new Event('${openStylePickerEvent}'))"><path d="M2 7H17.5" stroke="white" stroke-width="1.5" stroke-linecap="round"/><path d="M2 13H17.5" stroke="white" stroke-width="1.5" stroke-linecap="round"/><circle cx="7" cy="7" r="1.75" stroke="white" stroke-width="1.5"/><circle cx="13" cy="13" r="1.75" stroke="white" stroke-width="1.5"/></svg>`
              : '') +
            (hasActions ? '</div>' : '')
          const setInnerMode = () =>
            setSelected(selected => ({
              ...selected,
              innerModeComponentId: selectedId,
            }))
          if (innerModeComponent) {
            document.addEventListener(setInnerModeEvent, setInnerMode)
          }
          const prevSlideWrapper = () => moveSlide(selectedComponent)
          const nextSlideWrapper = () => moveSlide(selectedComponent, true)
          if (isCarousel) {
            document.addEventListener(prevSlideEvent, prevSlideWrapper)
            document.addEventListener(nextSlideEvent, nextSlideWrapper)
          }
          const listItemModeWrapper = () => setEmptyMode(selectedComponent, undefined)
          const listEmptyModeWrapper = () => setEmptyMode(selectedComponent, undefined, true)
          if (isList) {
            document.addEventListener(listItemModeEvent, listItemModeWrapper)
            document.addEventListener(listEmptyModeEvent, listEmptyModeWrapper)
          }
          hoverHintLabel.parentNode?.removeChild(hoverHintLabel)
          selectedComponent.appendChild(selectedHintLabel)
          hintLabelPositionHandler(selectedHintLabel, screen)
          return () => {
            if (innerModeComponent) {
              document.removeEventListener(setInnerModeEvent, setInnerMode)
            }
            if (isCarousel) {
              document.removeEventListener(prevSlideEvent, prevSlideWrapper)
              document.removeEventListener(nextSlideEvent, nextSlideWrapper)
            }
            if (isList) {
              document.removeEventListener(listItemModeEvent, listItemModeWrapper)
              document.removeEventListener(listEmptyModeEvent, listEmptyModeWrapper)
            }
            selectedComponent.classList.remove('selectedComponent')
            selectedHintLabel.parentNode?.removeChild(selectedHintLabel)
          }
        }
      }
    }
  }

  useEffect(() => {
    const screen = document.getElementById(localScreenId) as HTMLDivElement
    if (screen && movableComponent) {
      const configCopy = JSON.parse(JSON.stringify(config)) as Config
      const screenCopy = configCopy.screens?.find(el => el.id === screenId)
      if (screenCopy) {
        pointerLine.id = getElementIdFromConfig(movableComponent)
        screen.onmousemove = e => {
          const componentOnScreen = screen.querySelector(`[id$='${movableComponent.id}']`)
          // @ts-ignore
          const moveOnScreen = e.detail !== true && e.detail !== false
          let componentOnScreenReplacedWithPointerLine = false
          if (componentOnScreen && componentOnScreen !== pointerLine) {
            componentOnScreen.parentNode?.replaceChild(pointerLine, componentOnScreen)
            refreshNavigationTree()
            componentOnScreenReplacedWithPointerLine = true
          }
          removeComponentById(screenCopy, movableComponent.id)
          const target = (
            componentOnScreenReplacedWithPointerLine && moveOnScreen ? pointerLine.parentNode : e.target
          ) as HTMLDivElement
          const targetComponentId = getComponentId(target.id)
          const topBarComponentsKey = getComponentNameById(target.id) as ScreenTopBarComponentsKey
          const component = findComponent(screenCopy, 'id', targetComponentId)
          if (component || Object.values(ScreenTopBarComponentsKey).includes(topBarComponentsKey)) {
            if ((component ? isParentComponent(component.componentType) : true) && moveOnScreen) {
              const rowDirection = component
                ? component.componentsDirection && component.componentsDirection !== ComponentsDirection.vertical
                : true
              const targetChildren = Array.from(target.children).filter(
                el => el !== pointerLine && el !== hoverHintLabel && el !== selectedHintLabel
              )
              let insertBeforeElement = targetChildren[0]
              let indexOfComponent = 0
              targetChildren.forEach((el, i) => {
                const { x, y, width, height } = el.getBoundingClientRect()
                if ((!rowDirection && e.y >= y + height / 2) || (rowDirection && e.x >= x + width / 2)) {
                  insertBeforeElement = el.nextSibling as HTMLDivElement
                  indexOfComponent = i + 1
                }
              })
              if (component) {
                if (component.subComponents) {
                  component.subComponents.splice(indexOfComponent, 0, movableComponent)
                } else {
                  component.subComponents = [movableComponent]
                }
              } else {
                if (screenCopy.topBar) {
                  if (screenCopy.topBar[topBarComponentsKey]) {
                    screenCopy.topBar[topBarComponentsKey]?.splice(indexOfComponent, 0, movableComponent)
                  } else {
                    screenCopy.topBar[topBarComponentsKey] = [movableComponent]
                  }
                } else {
                  screenCopy.topBar = {
                    [topBarComponentsKey]: [movableComponent],
                  }
                }
              }
              if (!insertBeforeElement || pointerLine.nextSibling !== insertBeforeElement) {
                if (rowDirection) {
                  pointerLine.style.width = '2px'
                  pointerLine.style.height = '20px'
                } else {
                  pointerLine.style.width = '100%'
                  pointerLine.style.height = '2px'
                }
                try {
                  target.insertBefore(pointerLine, insertBeforeElement)
                  refreshNavigationTree()
                } catch {}
              }
            } else if (component) {
              const componentParentData = findComponentParentDataById(screenCopy, targetComponentId)
              if (componentParentData) {
                const { componentParent, components } = componentParentData
                const rowDirection =
                  (componentParent as ScreenComponent).componentsDirection &&
                  (componentParent as ScreenComponent).componentsDirection !== ComponentsDirection.vertical
                let insertBeforeElement = target
                let indexOfComponent = components.indexOf(component)
                // e.detail detects if paste after
                if (e.detail) {
                  insertBeforeElement = target.nextSibling as HTMLDivElement
                  indexOfComponent += 1
                } else if (moveOnScreen) {
                  const { x, y, width, height } = target.getBoundingClientRect()
                  if ((!rowDirection && e.y >= y + height / 2) || (rowDirection && e.x >= x + width / 2)) {
                    insertBeforeElement = target.nextSibling as HTMLDivElement
                    indexOfComponent += 1
                  }
                }
                components.splice(indexOfComponent, 0, movableComponent)
                if (!insertBeforeElement || pointerLine.nextSibling !== insertBeforeElement) {
                  if (rowDirection) {
                    pointerLine.style.width = '2px'
                    pointerLine.style.height = '20px'
                  } else {
                    pointerLine.style.width = '100%'
                    pointerLine.style.height = '2px'
                  }
                  try {
                    target.parentNode?.insertBefore(pointerLine, insertBeforeElement)
                    refreshNavigationTree()
                  } catch {}
                }
              }
            }
          } else if (target === screen) {
            const targetChildren = Array.from(target.children).filter(
              el => el !== pointerLine && el !== hoverHintLabel && el !== selectedHintLabel
            )
            let insertBeforeElement = targetChildren[0]
            let indexOfComponent = 0
            targetChildren.forEach((el, i) => {
              const { y, height } = el.getBoundingClientRect()
              if (e.y >= y + height / 2) {
                insertBeforeElement = el.nextSibling as HTMLDivElement
                indexOfComponent = i + 1
              }
            })
            if (screenCopy.components) {
              screenCopy.components.splice(indexOfComponent, 0, movableComponent)
            } else {
              screenCopy.components = [movableComponent]
            }
            if (!insertBeforeElement || pointerLine.nextSibling !== insertBeforeElement) {
              pointerLine.style.width = '100%'
              pointerLine.style.height = '2px'
              try {
                target.insertBefore(pointerLine, insertBeforeElement)
                refreshNavigationTree()
              } catch {}
            }
          }
          document.onmouseup = () => {
            const movable = document.getElementById(movableElementId)
            if (movable) {
              movable.parentNode?.removeChild(movable)
              document.onmousemove = () => {}
            }
            movableComponent.id = generateFirestoreId()
            if (
              target === screen &&
              (isParentComponent(movableComponent.componentType) ||
                movableComponent.componentType === ComponentType.map)
            ) {
              movableComponent.margins = { top: ISize.topScreenMargins, bottom: ISize.bottomScreenMargins }
            }
            if (!movableComponent.name) {
              movableComponent.name = `${movableComponent.componentType} ${getNewComponentPosition(
                screenConfig,
                movableComponent.componentType
              )}`
            }
            setConfig(configCopy)
            setMovableComponent(null)
            const elementWithInnerMode = screen.querySelector('.innerModeComponent')
            const inElementWithInnerMode = !!elementWithInnerMode?.querySelector(`[id='${pointerLine.id}']`)
            if (elementWithInnerMode && inElementWithInnerMode) {
              const innerModeComponentId = getComponentId(elementWithInnerMode.id)
              if (getComponentTypeById(elementWithInnerMode.id) === BarComponentType.topBar) {
                onClickComponent({
                  topBarId: innerModeComponentId,
                  subComponentId: movableComponent.id,
                })
              } else {
                onClickComponent({
                  componentId: innerModeComponentId,
                  subComponentId: movableComponent.id,
                })
              }
            } else {
              onClickComponent({ componentId: movableComponent.id })
            }
            document.onmouseup = () => {}
          }
        }

        screen.onmouseleave = () => {
          removeComponentById(screenCopy, movableComponent.id)
          pointerLine.parentNode?.removeChild(pointerLine)
          refreshNavigationTree()
        }

        return () => {
          screen.onmousemove = () => {}
          screen.onmouseleave = () => {}
          document.onmouseup = () => {}
          pointerLine.parentNode?.removeChild(pointerLine)
        }
      }
    }
  }, [JSON.stringify(movableComponent)])

  useEffect(() => {
    const screen = document.getElementById(localScreenId) as HTMLDivElement
    if (screen && movableTopBarComponent) {
      const configCopy = JSON.parse(JSON.stringify(config)) as Config
      const screenCopy = configCopy.screens?.find(el => el.id === screenId)
      if (screenCopy) {
        pointerLine.id = localTopBarId
        screen.onmousemove = () => {
          pointerLine.style.position = 'absolute'
          pointerLine.style.top = `${topBarHeight + safeAreaTop}px`
          pointerLine.style.width = '100%'
          pointerLine.style.height = '2px'
          screen.appendChild(pointerLine)
          refreshNavigationTree()
          document.onmouseup = () => {
            const movable = document.getElementById(movableElementId)
            if (movable) {
              movable.parentNode?.removeChild(movable)
              document.onmousemove = () => {}
            }
            screenCopy.showTopBar = { constant: true }
            setConfig(configCopy)
            setMovableTopBarComponent(false)
            onClickComponent({ topBarId })
            document.onmouseup = () => {}
          }

          screen.onmouseleave = () => {
            pointerLine.parentNode?.removeChild(pointerLine)
            pointerLine.style.removeProperty('position')
            pointerLine.style.removeProperty('top')
            refreshNavigationTree()
          }
        }

        return () => {
          screen.onmousemove = () => {}
          screen.onmouseleave = () => {}
          document.onmouseup = () => {}
          pointerLine.parentNode?.removeChild(pointerLine)
          pointerLine.style.removeProperty('position')
          pointerLine.style.removeProperty('top')
        }
      }
    }
  }, [movableTopBarComponent])

  useEffect(() => {
    const screen = document.getElementById(localScreenId) as HTMLDivElement
    if (screen && !tabBar && movableTabBarComponent) {
      const configCopy = JSON.parse(JSON.stringify(config)) as Config
      const tabBarCopy = configCopy.tabBars?.[0]
      if (tabBarCopy) {
        pointerLine.id = `${BarComponentType.tabBar}.${tabBarCopy.name}.${tabBarCopy.id}`
        screen.onmousemove = () => {
          pointerLine.style.position = 'absolute'
          pointerLine.style.bottom = `${tabBarHeight + safeAreaBottom}px`
          pointerLine.style.width = '100%'
          pointerLine.style.height = '2px'
          screen.appendChild(pointerLine)
          refreshNavigationTree()
          document.onmouseup = () => {
            const movable = document.getElementById(movableElementId)
            if (movable) {
              movable.parentNode?.removeChild(movable)
              document.onmousemove = () => {}
            }
            const hasProfileTabs =
              tabBarCopy.tabs?.filter(el => profieTabs.includes(el.screen)).length === profieTabs.length
            if (hasProfileTabs ? tabBarCopy.tabs?.length || 0 < 7 : tabBarCopy.tabs?.length || 0 < 5) {
              const tab = {
                image: { constant: { resourceId: 'd00a57df-f20c-4261-ab78-5967ab3d3409' } },
                screen: screenName,
                title: { key: 'key', locales: { [language]: screenName } },
                visible: { constant: true },
              }
              if (tabBarCopy.tabs) {
                tabBarCopy.tabs.unshift(tab)
              } else {
                tabBarCopy.tabs = [tab]
              }
              setConfig(configCopy)
              onClickComponent({ tabBarId: tabBarCopy.id })
            } else {
              toast(
                "Oops! The number of tabs is limited to 5. Please remove a tab and try again. Alternatively, you could add the link into the 'More' screen."
              )
            }
            setMovableTabBarComponent(false)
            document.onmouseup = () => {}
          }
        }

        screen.onmouseleave = () => {
          pointerLine.parentNode?.removeChild(pointerLine)
          pointerLine.style.removeProperty('position')
          pointerLine.style.removeProperty('bottom')
          refreshNavigationTree()
        }
      }

      return () => {
        screen.onmousemove = () => {}
        screen.onmouseleave = () => {}
        document.onmouseup = () => {}
        pointerLine.parentNode?.removeChild(pointerLine)
        pointerLine.style.removeProperty('position')
        pointerLine.style.removeProperty('bottom')
      }
    }
  }, [movableTabBarComponent])

  // useEffect(() => {
  //   const tabBarElement = document.getElementById(localTabBarId) as HTMLDivElement
  //   if (tabBarElement && movableTabComponent) {
  //     const configCopy = JSON.parse(JSON.stringify(config)) as Config
  //     const tabBarCopy = configCopy.tabBars.find(el =>
  //       el.tabs.find(el => el.screen === screenName)
  //     ) as TabBar
  //     pointerLine.id = `${BarComponentType.tab}.${movableTabComponent.screen}.${movableTabComponent.screen}`

  //     tabBarElement.onmousemove = e => {
  //       pointerLine.style.width = '2px'
  //       pointerLine.style.height = '20px'
  //       const componentOnScreen = tabBarElement.querySelector(`[id$='${movableTabComponent.screen}']`)
  //       if (componentOnScreen && componentOnScreen !== pointerLine) {
  //         componentOnScreen.parentNode?.replaceChild(pointerLine, componentOnScreen)
  //       } else {
  //         tabBarElement.appendChild(pointerLine)
  //       }
  //       updateNavigationTree()
  //       tabBarCopy.tabs = [
  //         ...tabBarCopy.tabs.filter(el => el.screen !== movableTabComponent.screen),
  //         movableTabComponent,
  //       ]

  //       document.onmouseup = () => {
  //         const movable = document.getElementById(movableElementId)
  //         if (movable) {
  //           movable.parentNode?.removeChild(movable)
  //           document.onmousemove = () => {}
  //         }
  //         // movableTabComponent.screen = 'Home'
  //         onClickComponent('', tabBar?.id, movableTabComponent.screen)
  //         setConfig(configCopy)
  //         setMovableTabComponent(null)
  //         document.onmouseup = () => {}
  //       }
  //     }

  //     tabBarElement.onmouseleave = () => {
  //       tabBarCopy.tabs = tabBarCopy.tabs.filter(el => el.screen !== movableTabComponent.screen)
  //       pointerLine.parentNode?.removeChild(pointerLine)
  //       updateNavigationTree()
  //     }

  //     return () => {
  //       tabBarElement.onmousemove = () => {}
  //       tabBarElement.onmouseleave = () => {}
  //       document.onmouseup = () => {}
  //       pointerLine.parentNode?.removeChild(pointerLine)
  //     }
  //   }
  // }, [movableTabComponent])

  return (
    <styled.ScreenContainer
      compression={compression}
      width={width}
      height={height}
      onMouseDown={() => onClickComponent({})}
      onClick={e => e.stopPropagation()}
    >
      <styled.ScreenName isScreenActive={isScreenActive} isComponentOnScreenSelected={isComponentOnScreenSelected}>
        <Icon name={Name.ADDITIONAL_SCREEN} width={24} height={24} />
        {screenName}
        {/* <Box ml="auto">
          <AIIconBtn whiteIcon={isScreenActive && !isComponentOnScreenSelected} />
        </Box> */}
      </styled.ScreenName>
      <Box width="100%" position="relative">
        <styled.ScreenWrapper
          ref={ref}
          height={height}
          isScreenActive={isScreenActive}
          isComponentOnScreenSelected={isComponentOnScreenSelected}
          disabled={screenName === 'Launch Screen'}
        />
        {systemBar()}
      </Box>
    </styled.ScreenContainer>
  )
}
