import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as styled from 'pages/CMS/pages/CollectionSchema/components/Fields/Fields.styled'

import { getFieldIcon, Icon } from 'components'
import { ProjectContext } from 'context'
import { Collection, Config } from 'utils'
import { collections } from './constants'

interface Props {
  setOnClickActionButton: React.Dispatch<React.SetStateAction<(() => void) | null>>
}

export const GenerateCollections: React.FC<Props> = ({ setOnClickActionButton }) => {
  const {
    project: { id },
    setConfig,
  } = useContext(ProjectContext)
  const navigate = useNavigate()
  const [selected, setSelected] = useState<Collection | null>(null)

  useEffect(() => {
    setSelected(collections[0])
  }, [])

  const addCollection = (collection: Collection) => {
    setConfig(config => {
      const configCopy = JSON.parse(JSON.stringify(config)) as Config
      configCopy.data.collections = [
        ...(configCopy.data.collections?.filter(a => a.name !== collection.name) || []),
        collection,
      ]
      return configCopy
    })
    navigate(`/projects/${id}/collections/${collection.name}`)
  }

  useEffect(() => {
    setOnClickActionButton(() => (selected ? () => addCollection(selected) : null))
  }, [JSON.stringify(selected)])

  return selected ? (
    <Box sx={{ pointerEvents: 'none' }}>
      {selected.properties?.map(el => (
        <styled.Property key={el.name}>
          <Box width="100%">
            <Icon name={getFieldIcon(el.type)} width={22} height={22} />
            <styled.PropertyName>{el.name}</styled.PropertyName>
            {el.type}
            {el.accept && ` of ${el.accept}s`}
          </Box>
          {el.isRequired && <styled.DopInfo>Required</styled.DopInfo>}
          {el.isSystem && <styled.DopInfo>system field</styled.DopInfo>}
        </styled.Property>
      ))}
    </Box>
  ) : (
    <></>
  )
}
