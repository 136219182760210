import React from 'react'

import {
  Button,
  ButtonColor,
  ButtonVariant,
  CustomSection,
  CustomSectionButtons,
  CustomSectionSubTitle,
  CustomSectionTitle,
  Icon,
  Name,
} from 'components'
import { useProjectIntegrations } from 'hooks'
import {
  AppStoreButton,
  AppStoreConnectionModal,
  GithubButton,
  GithubConnectionModal,
  githubDescription,
  GooglePlayButton,
  StripeButton,
} from './components'

interface Props {
  appStore?: boolean
  googlePlay?: boolean
  stripe?: boolean
  github?: boolean
  domains?: boolean
}

export const Connections: React.FC<Props> = ({ appStore, googlePlay, stripe, github, domains }) => {
  const { appstoreIntegration, googleplayIntegration, stripeIntegration, githubIntegration } = useProjectIntegrations()

  return (
    <CustomSection style={{ paddingBottom: '37px' }}>
      <CustomSectionTitle>{domains ? 'App domains' : 'Connections'}</CustomSectionTitle>
      <CustomSectionSubTitle>
        {appStore && googlePlay && stripe
          ? 'Authorize CodePlatform to access your App Store Connect, Google Play, and Stripe accounts to manage in-app purchases and subscriptions.'
          : appStore
          ? 'Authorize CodePlatform to access your App Store Connect account to automate app distribution and manage in-app purchases and subscriptions.'
          : googlePlay
          ? 'Authorize CodePlatform to access your Google Play account to automate app distribution and manage in-app purchases and subscriptions.'
          : stripe
          ? 'Authorize CodePlatform to access your Stripe Account and manage in-app purchases and subscriptions.'
          : github
          ? githubDescription
          : domains
          ? 'Specify the domain name you want users to see when accessing your app. This can be a full domain (e.g., yourdomain.com) or a subdomain (e.g., app.yourdomain.com).'
          : null}
      </CustomSectionSubTitle>
      <CustomSectionButtons>
        {appStore && <AppStoreButton appstoreIntegration={appstoreIntegration} />}
        {googlePlay && <GooglePlayButton googleplayIntegration={googleplayIntegration} />}
        {stripe && <StripeButton stripeIntegration={stripeIntegration} />}
        {github && <GithubButton githubIntegration={githubIntegration} />}
        {domains && (
          <Button
            variant={ButtonVariant.LINK}
            color={ButtonColor.SECONDARY}
            leftIcon={<Icon name={Name.SETTINGS_DOMAINS} />}
          >
            Add Custom Domain
          </Button>
        )}
      </CustomSectionButtons>
      {appStore && appstoreIntegration ? <AppStoreConnectionModal appstoreIntegration={appstoreIntegration} /> : <></>}
      {github && githubIntegration ? <GithubConnectionModal githubIntegration={githubIntegration} /> : <></>}
    </CustomSection>
  )
}
