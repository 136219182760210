import React, { useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from './PWAPreview.styled'

import { DeviceMode, desktopModeEvent, mobileModeEvent, tabletModeEvent } from 'components'
import { UserContext } from 'context'

interface Props {
  children: React.ReactNode
  noTransform?: boolean
  adaptive?: boolean
  ai?: boolean
}

export const PWAPreview: React.FC<Props> = ({ children, noTransform, adaptive, ai }) => {
  const { user } = useContext(UserContext)
  const themeContext = useContext(ThemeContext)
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<string>()
  const [height, setHeight] = useState<string>()
  const [deviceMode, setDeviceMode] = useState(
    adaptive && window.innerWidth < 768 ? DeviceMode.DESKTOP : DeviceMode.MOBILE
  )

  useEffect(() => {
    if (adaptive) {
      const setMobileModeFromListener = () => setDeviceMode(DeviceMode.MOBILE)
      const setTabletModeFromListener = () => setDeviceMode(DeviceMode.TABLET)
      const setDesktopModeFromListener = () => setDeviceMode(DeviceMode.DESKTOP)
      const onResize = () => setDeviceMode(window.innerWidth < 768 ? DeviceMode.DESKTOP : DeviceMode.MOBILE)
      document.addEventListener(mobileModeEvent, setMobileModeFromListener)
      document.addEventListener(tabletModeEvent, setTabletModeFromListener)
      document.addEventListener(desktopModeEvent, setDesktopModeFromListener)
      window.addEventListener('resize', onResize)
      return () => {
        document.removeEventListener(mobileModeEvent, setMobileModeFromListener)
        document.removeEventListener(tabletModeEvent, setTabletModeFromListener)
        document.removeEventListener(desktopModeEvent, setDesktopModeFromListener)
        window.removeEventListener('resize', onResize)
      }
    }
  }, [adaptive])

  const onResizeMouseDown = ({ currentTarget }: React.MouseEvent<HTMLDivElement>, bottom?: boolean) => {
    document.onmousemove = e => {
      if (ref.current) {
        document.body.style.userSelect = 'none'
        document.body.style.cursor = bottom ? 'ns-resize' : 'ew-resize'
        currentTarget.style.background = themeContext.colors.darkBlue_300
        ref.current.style.pointerEvents = 'none'
        const { width, height } = ref.current.getBoundingClientRect()
        const { innerWidth, innerHeight } = window
        const compression =
          innerHeight <= 670 ? 0.6 : innerHeight <= 760 ? 0.7 : innerHeight <= 850 ? 0.8 : innerHeight <= 940 ? 0.9 : 1
        if (bottom) {
          const newHeight = (height - 82 + (e.pageY - (height + (innerHeight - height) / 2)) * 2) / compression
          if (newHeight >= 350 && e.pageY < innerHeight) {
            setHeight(`${newHeight}px`)
          }
        } else {
          const newWidth = (width - 22 + (e.pageX - (width + (innerWidth - width) / 2)) * 2) / compression
          if (newWidth >= 350 && e.pageX < innerWidth) {
            setWidth(`${newWidth}px`)
          }
        }
      }
    }
    document.onmouseup = () => {
      if (ref.current) {
        document.body.style.removeProperty('user-select')
        document.body.style.removeProperty('cursor')
        currentTarget.style.removeProperty('background')
        ref.current.style.removeProperty('pointer-events')
        document.onmousemove = () => {}
        document.onmouseup = () => {}
      }
    }
  }

  return (
    <styled.FrameWrapper
      ref={ref}
      mobileMode={deviceMode === DeviceMode.MOBILE}
      desktopMode={deviceMode === DeviceMode.DESKTOP}
      $width={width}
      $height={height}
      noTransform={noTransform}
      ai={ai}
      isLimitedAccess={user.isLimitedAccess}
    >
      {deviceMode === DeviceMode.MOBILE ? (
        <styled.Home />
      ) : deviceMode === DeviceMode.TABLET ? (
        <>
          <styled.Resizer onMouseDown={e => onResizeMouseDown(e)} />
          <styled.Resizer bottom onMouseDown={e => onResizeMouseDown(e, true)} />
        </>
      ) : (
        <></>
      )}
      {children}
    </styled.FrameWrapper>
  )
}
