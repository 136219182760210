import { DeploymentType, ProjectApplication, ProjectRole, TaskType } from 'hooks'
import { Data, Screen, httpClient } from 'utils'

const createProject = (templateId: string) => {
  const url = `/projects`
  return httpClient.post(url, { name: 'Untitled', templateId })
}

const deleteProject = (projectId: string) => {
  const url = `/projects/${projectId}`
  return httpClient.delete(url)
}

const updateProjectLogo = (projectId: string, logoUrl: string) => {
  const url = `/projects/${projectId}/update-logo`
  return httpClient.put(url, { logoUrl })
}

const promote = (projectId: string, screenshots: string[]) => {
  const url = `/projects/${projectId}/promote`
  return httpClient.post(url, { screenshots })
}

const addProjectMember = (projectId: string, email: string, role: ProjectRole) => {
  const url = `/projects/${projectId}/members`
  return httpClient.post(url, { email, role })
}

const getRepositoryCommits = (projectId: string) => {
  const url = `/projects/${projectId}/repository/commits`
  return httpClient.get(url)
}

const getRepositoryFiles = (projectId: string, path: string, abortController: AbortController) => {
  const url = `/projects/${projectId}/repository/src${path}`
  return httpClient.get(url, { signal: abortController.signal })
}

const getWebFirebaseConfig = (projectId: string) => {
  const url = `/projects/${projectId}/firebase/configurations/web`
  return httpClient.get(url)
}

const createIndex = (projectId: string, data: any) => {
  const url = `/projects/${projectId}/createIndex`
  return httpClient.post(url, data)
}

const getVariables = (projectId: string) => {
  const url = `/projects/${projectId}/repository/variables`
  return httpClient.get(url)
}

interface VariableDto {
  key: string
  value: string
  secured: boolean
}

const createVariable = (projectId: string, data: VariableDto) => {
  const url = `/projects/${projectId}/repository/variables`
  return httpClient.post(url, data)
}

const updateVariable = (projectId: string, variableId: string, data: VariableDto) => {
  const url = `/projects/${projectId}/repository/variables/${variableId}`
  return httpClient.put(url, data)
}

const deleteVariable = (projectId: string, variableId: string) => {
  const url = `/projects/${projectId}/repository/variables/${variableId}`
  return httpClient.delete(url)
}

const getPipelineSteps = (projectId: string, pipelineId: string) => {
  const url = `/projects/${projectId}/repository/pipelines/${pipelineId}/steps`
  return httpClient.get(url)
}

const getStepsLogs = (projectId: string, pipelineId: string, stepId: string) => {
  const url = `/projects/${projectId}/repository/pipelines/${pipelineId}/steps/${stepId}/logs`
  return httpClient.get(url)
}

const getGithubToken = (projectId: string, code: string) => {
  const url = `/projects/${projectId}/integrations/github/auth`
  return httpClient.post(url, { code })
}

const createGithubIntegration = (
  projectId: string,
  githubToken: string,
  data: { installationId: number; repositorySlug: string }
) => {
  const url = `/projects/${projectId}/integrations/github`
  return httpClient.put(url, data, { headers: { 'x-github-token': githubToken } })
}

const getGithubInstallations = (projectId: string, githubToken: string) => {
  const url = `/projects/${projectId}/integrations/github/installations`
  return httpClient.get(url, { headers: { 'x-github-token': githubToken } })
}

const getGithubRepositories = (projectId: string, installationId: number, githubToken: string) => {
  const url = `/projects/${projectId}/integrations/github/installations/${installationId}/repositories`
  return httpClient.get(url, { headers: { 'x-github-token': githubToken } })
}

const startAppstoreSession = (projectId: string, data: { username: string; password: string }) => {
  const url = `/projects/${projectId}/integrations/appstore/auth`
  return httpClient.post(url, data)
}

const updateAppstoreSession = (projectId: string, token: string, otp: String) => {
  const url = `/projects/${projectId}/integrations/appstore/auth`
  return httpClient.put(url, { otp }, { headers: { 'x-cp-appstore-token': token } })
}

const createAppstoreIntegration = (projectId: string, token: string, teamId: string) => {
  const url = `/projects/${projectId}/integrations/appstore`
  return httpClient.put(url, { teamId }, { headers: { 'x-cp-appstore-token': token } })
}

const deleteIntegration = (projectId: string, integrationId: string) => {
  const url = `/projects/${projectId}/integrations/${integrationId}`
  return httpClient.delete(url)
}

const uploadResource = (projectId: string, formData: FormData, abortController?: AbortController) => {
  const url = `/projects/${projectId}/resources`
  return httpClient.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    signal: abortController?.signal,
  })
}

const updateResource = (
  projectId: string,
  resourceId: string,
  formData: FormData,
  abortController?: AbortController
) => {
  const url = `/projects/${projectId}/resources/${resourceId}`
  return httpClient.patch(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    signal: abortController?.signal,
  })
}

export interface TaskDto {
  type: TaskType
  deploymentType?: DeploymentType
  applicationId?: ProjectApplication
}

const createTask = (projectId: string, data: TaskDto) => {
  const url = `/projects/${projectId}/tasks`
  return httpClient.post(url, data)
}

const cancellTask = (projectId: string, taskId: string) => {
  const url = `/projects/${projectId}/tasks/${taskId}/cancell`
  return httpClient.post(url)
}

const getReleaseNextVersion = (projectId: string) => {
  const url = `/projects/${projectId}/releases/next-version`
  return httpClient.get(url)
}

const getPWAToken = (projectId: string) => {
  const url = `/projects/${projectId}/pwa-token`
  return httpClient.get(url)
}

const getUsers = (projectId: string) => {
  const url = `/projects/${projectId}/users`
  return httpClient.get(url)
}

const suggestComponentStyles = (
  projectId: string,
  componentId: string,
  screen: Screen,
  data: Data,
  abortController: AbortController
) => {
  const url = `/projects/${projectId}/ai-tools/suggest-comonent-styles`
  return httpClient.post(url, { componentId, screen, data }, { signal: abortController.signal })
}

const refactorComponent = (
  projectId: string,
  componentId: string,
  screen: Screen,
  data: Data,
  abortController: AbortController
) => {
  const url = `/projects/${projectId}/ai-tools/refactor-component`
  return httpClient.post(url, { componentId, screen, data }, { signal: abortController.signal })
}

const setupBillingAccount = (redirectUrl: string) => {
  const url = `/billing-accounts/setup`
  return httpClient.post(url, { redirectUrl })
}

const createBillingAccount = (checkoutSessionId: string) => {
  const url = `/billing-accounts`
  return httpClient.post(url, { checkoutSessionId })
}

const getBillingAccount = (billingAccountId: string, abortController?: AbortController) => {
  const url = `/billing-accounts/${billingAccountId}`
  return httpClient.get(url, { signal: abortController?.signal })
}

const updateBillingAccount = (billingAccountId: string, values: any) => {
  const url = `/billing-accounts/${billingAccountId}`
  return httpClient.patch(url, { ...values })
}

const deleteBillingAccount = (billingAccountId: string) => {
  const url = `/billing-accounts/${billingAccountId}`
  return httpClient.delete(url)
}

const getPaymentMethods = (billingAccountId: string, abortController?: AbortController) => {
  const url = `/billing-accounts/${billingAccountId}/payment-methods`
  return httpClient.get(url, { signal: abortController?.signal })
}

const getSubscriptions = (billingAccountId: string, abortController?: AbortController) => {
  const url = `/billing-accounts/${billingAccountId}/subscriptions`
  return httpClient.get(url, { signal: abortController?.signal })
}

const getInvoices = (billingAccountId: string, abortController?: AbortController) => {
  const url = `/billing-accounts/${billingAccountId}/invoices`
  return httpClient.get(url, { signal: abortController?.signal })
}

const getProducts = (billingAccountId: string, ids?: string, abortController?: AbortController) => {
  const url = `/billing-accounts/${billingAccountId}/products${ids ? `?ids=${ids}` : ''}`
  return httpClient.get(url, { signal: abortController?.signal })
}

const getProductPrices = (billingAccountId: string, productId: string, abortController: AbortController) => {
  const url = `/billing-accounts/${billingAccountId}/products/${productId}/prices`
  return httpClient.get(url, { signal: abortController?.signal })
}

const getProjectSubscriptions = (projectId: string, abortController: AbortController) => {
  const url = `/projects/${projectId}/subscriptions/current`
  return httpClient.get(url, { signal: abortController.signal })
}

const getProjectPlans = (projectId: string, abortController: AbortController) => {
  const url = `/projects/${projectId}/plans`
  return httpClient.get(url, { signal: abortController.signal })
}

const createProjectSubscription = (projectId: string, priceId: string) => {
  const url = `/projects/${projectId}/subscriptions`
  return httpClient.post(url, { priceId })
}

const updateProjectSubscription = (projectId: string, subscriptionId: string, priceId: string) => {
  const url = `/projects/${projectId}/subscriptions/${subscriptionId}`
  return httpClient.put(url, { priceId })
}

const getRegions = (
  projectId: string,
  cityLatLong: string,
  city: string,
  country: string,
  region: string,
  abortController: AbortController
) => {
  const url = `/projects/${projectId}/integrations/firebase/locations?cityLatLong=${cityLatLong}&city=${city}&country=${country}&region=${region}`
  return httpClient.get(url, { signal: abortController.signal })
}

const getSuggestedGcpProjectId = (projectId: string, projectName: string, abortController: AbortController) => {
  const url = `/projects/${projectId}/integrations/firebase/suggest-id?projectName=${projectName}`
  return httpClient.get(url, { signal: abortController.signal })
}

const initGcpProject = (projectId: string, name: string, gcpProjectId: string, regionId: string) => {
  const url = `/projects/${projectId}/integrations/firebase/init`
  return httpClient.post(url, { name, gcpProjectId, regionId })
}

const updateBillingAccountId = (projectId: string, billingAccountId: string) => {
  const url = `/projects/${projectId}`
  return httpClient.patch(url, { billingAccountId })
}

export const ProjectsService = {
  createProject,
  deleteProject,
  updateProjectLogo,
  promote,
  addProjectMember,
  getRepositoryCommits,
  getRepositoryFiles,
  getWebFirebaseConfig,
  createIndex,
  getVariables,
  createVariable,
  updateVariable,
  deleteVariable,
  getPipelineSteps,
  getStepsLogs,
  getGithubToken,
  createGithubIntegration,
  getGithubInstallations,
  getGithubRepositories,
  startAppstoreSession,
  updateAppstoreSession,
  createAppstoreIntegration,
  deleteIntegration,
  uploadResource,
  updateResource,
  createTask,
  cancellTask,
  getReleaseNextVersion,
  getPWAToken,
  getUsers,
  suggestComponentStyles,
  refactorComponent,
  setupBillingAccount,
  createBillingAccount,
  getBillingAccount,
  updateBillingAccount,
  deleteBillingAccount,
  getPaymentMethods,
  getSubscriptions,
  getInvoices,
  getProducts,
  getProductPrices,
  getProjectSubscriptions,
  getProjectPlans,
  createProjectSubscription,
  updateProjectSubscription,
  getRegions,
  getSuggestedGcpProjectId,
  initGcpProject,
  updateBillingAccountId,
}
