import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as styled from './Details.styled'

import { Loader, NoContent } from 'components'
import { ProgressContext } from 'context'
import { ProjectsService } from 'services/projects'
import { getMoney } from 'utils'
import { Card } from '../Card'

interface BillingAccount {
  name: string
  customerName: string
  email: string
  phone: string
  taxId: string
  taxCountry: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  postalCode: string
  country: string
  currency: string
  balance: number
}

export const Details: React.FC = () => {
  const { toast } = useContext(ProgressContext)
  const { billingAccountId } = useParams()
  const navigate = useNavigate()
  const [wait, setWait] = useState(true)
  const [billingAccount, setBillingAccount] = useState<BillingAccount>()

  useEffect(() => {
    setWait(true)
    const abortController = new AbortController()
    ProjectsService.getBillingAccount(billingAccountId as string, abortController)
      .then(res => setBillingAccount(res.data))
      .catch(err => !abortController.signal.aborted && toast(err))
      .finally(() => setWait(false))
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <Card title="Details" buttonTitle="Manage" onClick={() => navigate('edit')}>
      {wait ? (
        <Box height="310px" display="flex" justifyContent="center" alignItems="center">
          <Loader />
        </Box>
      ) : !billingAccount ? (
        <NoContent text="No billing account yet." />
      ) : (
        <Box p="15px 20px 25px" display="flex" flexDirection="column" gap="15px">
          <styled.Balance>
            Balance
            <span>{getMoney(billingAccount.currency, billingAccount.balance)}</span>
          </styled.Balance>
          <styled.Item>
            <span>Account details</span>
            <span>{[billingAccount.name, billingAccount.customerName].join(', ')}</span>
          </styled.Item>
          <styled.Item>
            <span>Billing address</span>
            <span>
              {[
                billingAccount.addressLine1,
                billingAccount.addressLine2,
                billingAccount.postalCode,
                billingAccount.city,
                billingAccount.state,
                billingAccount.country,
                billingAccount.phone,
              ].join(', ')}
            </span>
          </styled.Item>
          <styled.Item>
            <span>Billing email</span>
            <span> {billingAccount.email}</span>
          </styled.Item>
          <styled.Item>
            <span>Tax ID</span>
            <span>{billingAccount.taxId}</span>
          </styled.Item>
        </Box>
      )}
    </Card>
  )
}
