import { useFormik } from 'formik'
import React, { useContext, useMemo, useState } from 'react'

import {
  Button,
  ButtonColor,
  ButtonSize,
  InputProperty,
  Modal,
  ModalActions,
  Recaptcha,
  inputs,
  recaptchaVerifierId,
  validate,
} from 'components'
import { ProgressContext } from 'context'
import { MultiFactorUser, PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth'
import { auth } from 'services/firebase'
import { ValueType } from 'utils'

interface Props {
  multiFactorUser: MultiFactorUser
  sendCodeModal: boolean
  setSendCodeModal: React.Dispatch<React.SetStateAction<boolean>>
  setVerificationId: React.Dispatch<React.SetStateAction<string>>
}

export const SendCode: React.FC<Props> = ({ multiFactorUser, sendCodeModal, setSendCodeModal, setVerificationId }) => {
  const close = () => setSendCodeModal(false)

  return (
    <Modal active={sendCodeModal} title="Add phone number" onClose={close}>
      <Children close={close} multiFactorUser={multiFactorUser} setVerificationId={setVerificationId} />
    </Modal>
  )
}

interface ChildrenProps {
  close: () => void
  multiFactorUser: MultiFactorUser
  setVerificationId: React.Dispatch<React.SetStateAction<string>>
}

const Children: React.FC<ChildrenProps> = ({ close, multiFactorUser, setVerificationId }) => {
  const { toast } = useContext(ProgressContext)
  const [wait, setWait] = useState(false)

  const formik = useFormik({
    initialValues: { phoneNumber: null },
    validate: data => validate(data, properties),
    onSubmit: data => {
      const { phoneNumber } = data
      if (phoneNumber) {
        setWait(true)
        multiFactorUser.getSession().then(session => {
          // @ts-ignore
          const phoneAuthProvider = new PhoneAuthProvider(auth)
          const phoneInfoOptions = { phoneNumber, session }
          phoneAuthProvider
            .verifyPhoneNumber(
              phoneInfoOptions,
              // @ts-ignore
              new RecaptchaVerifier(auth, recaptchaVerifierId, { size: 'invisible' })
            )
            .then(verificationId => {
              setVerificationId(verificationId)
              close()
            })
            .catch(err => toast(err))
            .finally(() => setWait(false))
        })
      }
    },
  })

  const properties = useMemo(
    (): InputProperty[] => [
      {
        name: 'phoneNumber',
        type: ValueType.phoneNumber,
        position: 0,
        isRequired: true,
        title: 'Phone number',
      },
    ],
    []
  )

  return (
    <>
      {inputs(properties, formik)}
      <ModalActions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={close}>
          Cancel
        </Button>
        <Button size={ButtonSize.DEFAULT} onClick={formik.submitForm} loading={wait}>
          Send code
        </Button>
      </ModalActions>
      {wait && <Recaptcha />}
    </>
  )
}
