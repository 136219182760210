import { useContext, useEffect, useState } from 'react'

import { ProgressContext } from 'context'
import { firestore } from 'services/firebase'
import { ProjectPermission } from './useProject'

export interface User {
  id: string
  email: string
  displayName: string
  photoUrl: string
  isLimitedAccess: boolean
  isAnonymous: boolean
}

export const useUsers = (permissions: ProjectPermission[], subscribe: boolean) => {
  const { toast } = useContext(ProgressContext)
  const ids = permissions.map(el => el.id)
  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    if (subscribe) {
      const unsubscribers = ids.map(id =>
        firestore
          .collection('users')
          .doc(id)
          .onSnapshot({
            next: res => {
              const resData = res.data()
              if (resData) {
                const user = { id: res.id, ...resData } as User
                setUsers(users => [...users.filter(el => el.id !== user.id), user])
              }
            },
            error: err => toast(err),
          })
      )
      return () => {
        unsubscribers.map(el => el())
      }
    }
  }, [JSON.stringify(ids), subscribe])

  return users
}
