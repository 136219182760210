import { Box } from '@mui/material'
import React from 'react'

import * as styled from '../CustomPicker.styled'

import { CustomPicker } from 'components'
import { Task, TaskType } from 'hooks'
import { ActivityItemSmall, ActivityItemType } from 'pages/Repository/components'

export const openLogs = (logsUrl: string) => window.open(logsUrl)

export const openProjectSetupModalEvent = 'openProjectSetupModal'

interface Props {
  close: () => void
  tasks: Task[]
}

export const TasksPicker: React.FC<Props> = ({ close, tasks }) => (
  <CustomPicker
    title={
      <>
        Tasks <span>({tasks.length} total)</span>
      </>
    }
    close={close}
    optionsContainer={
      <styled.Items noSearchMode height="411px" padding="8px">
        <Box display="flex" flexDirection="column" gap="5px">
          {tasks.map(el => (
            <ActivityItemSmall
              key={el.id}
              item={el}
              type={ActivityItemType.TASK}
              onClick={
                el.type === TaskType.PROJECT_SETUP
                  ? () => document.dispatchEvent(new Event(openProjectSetupModalEvent))
                  : el.logsUrl
                  ? () => el.logsUrl && openLogs(el.logsUrl)
                  : undefined
              }
              steps={el.steps}
            />
          ))}
        </Box>
      </styled.Items>
    }
  />
)
