import { Box } from '@mui/material'
import React from 'react'

import { Button, ButtonColor, CustomField } from 'components'
import {
  getVariableCollectionName,
  getVariableName,
  LocalVariable,
  Screen,
  ValueType,
  VariableSource,
  VariableSourceType,
} from 'utils'
import { CollectionParams } from './CollectionParams'
import { isFiltersNotValid } from './Filters'
import { Query } from './Query'
import { isSearchNotValid } from './Search'
import { Selector } from './Selector'
import { isTransformsNotValid, Transforms } from './Transforms'

const buttonPadding = { padding: '9.5px' }

interface Props {
  valueType?: ValueType
  value?: VariableSource
  currentVariable: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  setNewSource: (val: React.SetStateAction<VariableSource | null>) => void
  onChange: (val: VariableSource) => void
  onRemove?: () => void
  close: () => void
  dataMode?: boolean
  recordMode?: boolean
  collectionMode?: boolean
  withoutTransforms?: boolean
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const CurrentVariable: React.FC<Props> = ({
  valueType,
  value,
  currentVariable,
  setCurrentVariable,
  setNewSource,
  onChange,
  onRemove,
  close,
  dataMode,
  recordMode,
  collectionMode,
  withoutTransforms,
  screenConfig,
  allLocalVariables,
}) => {
  const collectionName = getVariableCollectionName(currentVariable, allLocalVariables)

  const onConfirm = () => {
    onChange(currentVariable)
    close()
  }

  const onDelete =
    value && onRemove
      ? () => {
          onRemove()
          close()
        }
      : undefined

  const confirmDisabled =
    !!(
      currentVariable.type === VariableSourceType.collection &&
      (recordMode ? true : currentVariable.fieldName) &&
      !currentVariable.selector
    ) ||
    isTransformsNotValid(currentVariable.transforms) ||
    isFiltersNotValid(currentVariable.query?.filters) ||
    isSearchNotValid(currentVariable.query?.search)

  return (
    <>
      <CustomField
        label="Source"
        value={getVariableName(currentVariable)}
        onClick={() => {
          setCurrentVariable(null)
          setNewSource(null)
        }}
      />
      {currentVariable.type === VariableSourceType.collection && (
        <CollectionParams
          currentVariable={currentVariable}
          setCurrentVariable={setCurrentVariable}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      )}
      {currentVariable.type === VariableSourceType.collection && (recordMode ? true : currentVariable.fieldName) && (
        <Selector
          selector={currentVariable.selector}
          setCurrentVariable={setCurrentVariable}
          collectionName={collectionName}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      )}
      {dataMode && (
        <Query
          currentVariable={currentVariable}
          setCurrentVariable={setCurrentVariable}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      )}
      {!collectionMode && !withoutTransforms && (
        <Transforms
          valueType={valueType}
          transforms={currentVariable.transforms}
          collectionName={collectionName}
          fieldName={currentVariable.fieldName}
          setCurrentVariable={setCurrentVariable}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      )}
      <Box
        padding="15px 10px"
        display="flex"
        justifyContent="space-between"
        gap="8px"
        flexWrap="wrap-reverse"
        width="100%"
      >
        {onDelete ? (
          <Button style={buttonPadding} color={ButtonColor.DELETE} onClick={onDelete}>
            Remove
          </Button>
        ) : (
          <span />
        )}
        <Box display="flex" gap="8px">
          <Button style={buttonPadding} color={ButtonColor.SECONDARY} onClick={close}>
            Cancel
          </Button>
          <Button style={buttonPadding} onClick={onConfirm} disabled={confirmDisabled}>
            Confirm
          </Button>
        </Box>
      </Box>
    </>
  )
}
