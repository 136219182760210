import React, { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import * as styled from './Notification.styled'

import { Icon, Name } from 'components'

export enum NotificationColor {
  BLUE = 'blue',
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  DARK = 'dark',
  LIGHT = 'light',
}

const NotificationVariants = {
  blue: {
    icon: <Icon name={Name.NOTIFICATION_INFO} />,
    title: 'Entry saved',
    borderColor: '#8A91F9',
    bgColor: '#F6F4FF',
    textColor: '#737BF8',
  },
  green: {
    icon: <Icon name={Name.NOTIFICATION_SUCCESS} />,
    title: 'Changes applied successfully',
    borderColor: '#32C48D',
    bgColor: '#F1FAF7',
    textColor: '#05543F',
  },
  yellow: {
    icon: <Icon name={Name.NOTIFICATION_WARN} />,
    title: 'Something went wrong',
    borderColor: '#E9B63F',
    bgColor: '#FEFDEB',
    textColor: '#8D4B11',
  },
  red: {
    icon: <Icon name={Name.NOTIFICATION_FAILED} />,
    title: 'Could not apply changes',
    borderColor: '#E43F3E',
    bgColor: '#FDF1F1',
    textColor: '#9A1C1D',
  },
  dark: {
    icon: <Icon name={Name.NOTIFICATION_REFRESH} />,
    title: 'New version available',
    borderColor: 'transparent',
    bgColor: '#212133',
    textColor: '#FFFFFF',
  },
  light: {
    icon: <Icon name={Name.NOTIFICATION_NEWS} />,
    title: 'News title',
    borderColor: '#212133',
    bgColor: '#FFFFFF',
    textColor: '#212133',
  },
}

interface Props {
  open: boolean
  color: NotificationColor
  title?: string
  description?: string
  close?: () => void
  onClick?: () => void
  fix?: () => void
  signIn?: () => void
  autoClose?: boolean
  center?: boolean
  inside?: boolean
}

export const Notification: React.FC<Props> = memo(
  ({ open, color, title, description, close, onClick, fix, signIn, autoClose, center, inside }) => {
    const location = useLocation()

    useEffect(() => {
      if (open && close && autoClose) {
        close()
      }
    }, [location])

    useEffect(() => {
      if (open && close && autoClose) {
        setTimeout(close, 5000)
      }
    }, [open])

    return (
      <styled.Box
        open={open}
        hasText={!!description}
        bgColor={NotificationVariants[color].bgColor}
        borderColor={NotificationVariants[color].borderColor}
        center={!!center}
        inside={!!inside}
      >
        <styled.Content hasText={!!description}>
          <div>
            {NotificationVariants[color].icon}
            <styled.TextBox textColor={NotificationVariants[color].textColor}>
              <styled.Title>{title ? title : NotificationVariants[color].title}</styled.Title>
              {description && <styled.Description inside={!!inside}>{description}</styled.Description>}
              {color === NotificationColor.DARK && <styled.Refresh onClick={onClick}>Refresh to update</styled.Refresh>}
              {color === NotificationColor.LIGHT && <styled.Date>Today 12:30 PM</styled.Date>}
            </styled.TextBox>
            {fix && (
              <styled.BtnBox>
                <styled.Btn onClick={fix}>Fix connection</styled.Btn>
              </styled.BtnBox>
            )}
            {signIn && (
              <styled.BtnBox>
                <styled.Btn onClick={signIn} yellow>
                  Sign in
                </styled.Btn>
              </styled.BtnBox>
            )}
          </div>
          {close && <Icon name={Name.RIGHT_SIDEBAR_CLOSE} onClick={close} />}
        </styled.Content>
      </styled.Box>
    )
  }
)
