import React, { useContext, useState } from 'react'

import { Button, ButtonColor, ButtonSize, ModalActions } from 'components'
import { AppPlans } from 'components/AIModal/components/Chat/components'
import { ProgressContext, ProjectContext } from 'context'
import { useParamsFromUrl } from 'hooks'
import { ProjectsService } from 'services/projects'

interface Props {
  onContinue: () => void
  onBack: () => void
  connectGCP: boolean
}

export const ChoosePlan: React.FC<Props> = ({ onContinue, onBack, connectGCP }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    project: { id, billingAccountId },
  } = useContext(ProjectContext)
  const { planPriceId, name, gcpProjectId, regionId, setPlanPriceId } = useParamsFromUrl()
  const [onClickActionButton, setOnClickActionButton] = useState<(() => void) | null>(null)
  const [wait, setWait] = useState(false)

  const initGcpProject = () => {
    if (name && gcpProjectId && regionId) {
      setWait(true)
      startLoader()
      ProjectsService.initGcpProject(id, name, gcpProjectId, regionId)
        .then(() => onContinue())
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setWait(false)
        })
    }
  }

  return !billingAccountId ? (
    <></>
  ) : (
    <>
      <AppPlans
        setWait={setWait}
        setOnClickActionButton={setOnClickActionButton}
        planPriceId={planPriceId}
        setPlanPriceId={setPlanPriceId}
        id={id}
        billingAccountId={billingAccountId}
        initGcpProject={connectGCP ? initGcpProject : undefined}
      />
      <ModalActions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={onBack}>
          Back
        </Button>
        <Button
          size={ButtonSize.DEFAULT}
          onClick={onClickActionButton || undefined}
          disabled={!onClickActionButton || (connectGCP ? !name || !gcpProjectId || !regionId : false)}
          loading={wait}
        >
          Continue
        </Button>
      </ModalActions>
    </>
  )
}
