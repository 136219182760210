import Wrapper from './components/Wrapper/Wrapper'
import { GlobalStyle } from './theme/GlobalStyles'
import { ThemeProvider } from './theme/theme'

interface Props {
  children: React.ReactNode
}

export const LandingLayout = ({ children }: Props) => (
  <ThemeProvider>
    <GlobalStyle />
    <Wrapper>{children}</Wrapper>
  </ThemeProvider>
)
