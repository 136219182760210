import { Box } from '@mui/material'
import React from 'react'

import { Container, MaxWidthContainer } from '../index.styled'

import { Crumbs, Text, Type } from 'components'
import { AddNew, BillingAccountsContent } from './components'

export const Billing: React.FC = () => (
  <Container>
    <MaxWidthContainer>
      <Crumbs firstCrumbTitle="Billing" icon={null} />
      <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
        Manage billing for your projects.
      </Text>
      <Box pr="320px">
        <AddNew />
        <BillingAccountsContent />
      </Box>
    </MaxWidthContainer>
  </Container>
)
