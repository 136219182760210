import React, { useContext, useMemo } from 'react'

import { BrandingColorInput, CommonPicker, ElementWithLocalizedContent, Icon, ImageInput, Name } from 'components'
import { DeviceSettingsContext, ProjectContext } from 'context'
import { RightSidebarItem, SettingField, SettingTextField, SubWrapper } from 'partials/RightSidebar/components'
import { BrandingColorType, Config, LocalVariable, Resource, Screen, TabBar, TabBarItem, getTextareaValue } from 'utils'

interface Props {
  tabBars: TabBar[]
  tabBar: TabBar
  tab: TabBarItem
  isActive: boolean
  onTabPropertiesChange: (props: { propName: keyof TabBarItem; value: any; resources?: Resource[] }[]) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const TabSetting: React.FC<Props> = ({
  tabBars,
  tabBar,
  tab,
  isActive,
  onTabPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const { title, image, screen } = tab
  const { config, setConfig, language } = useContext(ProjectContext)
  const { setSelected } = useContext(DeviceSettingsContext)

  const tabBarValues = useMemo(() => tabBars.map(el => ({ value: el.id, label: el.name })), [tabBars])

  const onTabTabBarChange = (value: string) => {
    if (tabBar.id !== value) {
      const configCopy = JSON.parse(JSON.stringify(config)) as Config
      const tabBars = configCopy.tabBars
      const oldTabBar = tabBars?.find(el => el.id === tabBar.id) as TabBar
      oldTabBar.tabs = oldTabBar.tabs?.filter(el => el.screen !== screen)
      const newTabBar = tabBars?.find(el => el.id === value) as TabBar
      newTabBar.tabs?.push(tab)
      setSelected(selected => ({ ...selected, tabBarId: value }))
      setConfig(configCopy)
    }
  }

  const sectionName = 'Tab'

  return (
    <RightSidebarItem title={sectionName} hasCloseIcon>
      <SubWrapper childrenFor={sectionName}>
        <SettingTextField
          key={language}
          label="Name"
          value={getTextareaValue(language, title).value}
          onChange={value =>
            onTabPropertiesChange([
              {
                propName: 'title',
                value: {
                  key: getTextareaValue(language, title).key,
                  locales: { ...title?.locales, [language]: value },
                },
              },
            ])
          }
          translation={
            title ? (
              <ElementWithLocalizedContent
                title={`Tab for ${screen} screen`}
                value={title}
                onChange={value => onTabPropertiesChange([{ propName: 'title', value }])}
              />
            ) : undefined
          }
        />
        <SettingField
          subLabel="Tab bar"
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
          value={tabBar.name}
          optionsContainer={
            <CommonPicker
              title="Tab bars"
              value={tabBar.id}
              onChange={onTabTabBarChange}
              values={tabBarValues}
              close={() => {}}
            />
          }
        />
      </SubWrapper>
      <SubWrapper title="Icon" canHide childrenFor={sectionName}>
        <ImageInput
          subLabel="Asset"
          value={image.constant?.resourceId}
          onChange={(value, resources) =>
            onTabPropertiesChange([{ propName: 'image', value: { constant: { resourceId: value } }, resources }])
          }
          source={image?.source}
          onSourceChange={value => onTabPropertiesChange([{ propName: 'image', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
          sfByDefault
        />
        <BrandingColorInput
          subLabel="Tint color"
          value={isActive ? `@${BrandingColorType.primary}` : `@${BrandingColorType.neutral}`}
          onChange={() => {}}
          onSourceChange={() => {}}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
          withoutDelete
        />
      </SubWrapper>
    </RightSidebarItem>
  )
}
