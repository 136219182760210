import { useContext, useEffect, useState } from 'react'

import { ProgressContext, UserContext } from 'context'
import { firestore } from 'services/firebase'
import { ProjectsService } from 'services/projects'
import { useParamsFromUrl } from './useParamsFromUrl'

export interface BillingAccount {
  id: string
  stripeCustomerId?: string
  googleBillingAccountId?: string
  vendor?: string
  provider?: string
  name: string
  memberIds: string[]
}

export const useBillingAccounts = () => {
  const { firebaseUser } = useContext(UserContext)
  const { toast } = useContext(ProgressContext)
  const [billingAccounts, setBillingAccounts] = useState<BillingAccount[]>([])
  const [wait, setWait] = useState(true)

  useEffect(() => {
    const unsubscribe = firestore
      .collection('billing-accounts')
      .where('memberIds', 'array-contains', firebaseUser.uid)
      .onSnapshot({
        next: res => {
          setBillingAccounts(res.docs.map(el => ({ id: el.id, ...el.data() })) as BillingAccount[])
          setWait(false)
        },
        error: err => toast(err),
      })
    return () => {
      unsubscribe()
    }
  }, [firebaseUser.uid])

  return { billingAccounts, wait }
}

export const useAddBillingAccount = () => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { success, checkoutSessionId, clearCheckoutParams } = useParamsFromUrl()
  const [wait, setWait] = useState(false)

  const onAddBillingAccount = () => {
    setWait(true)
    startLoader()
    ProjectsService.setupBillingAccount(window.location.href)
      .then(res => window.location.replace(res.data.url))
      .catch(err => toast(err))
      .finally(() => {
        stopLoader()
        setWait(false)
      })
  }

  useEffect(() => {
    if (success && checkoutSessionId) {
      setWait(true)
      startLoader()
      ProjectsService.createBillingAccount(checkoutSessionId)
        .then(() => clearCheckoutParams())
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setWait(false)
        })
    }
  }, [success, checkoutSessionId])

  return { onAddBillingAccount, wait }
}
