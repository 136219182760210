import { memo } from 'react'
import { switchColorConfig } from '../constants'
import { usePicker } from '../context'
import { ac, controlBtn, df, jc } from '../style'

const { defaultColor, defaultGradient, defaultImage } = switchColorConfig

export const Controls = memo(() => {
  const { isGradient, onChange, previousColors, previousGraidents, isImage, previousImages } = usePicker()
  const solidColor = previousColors?.[0] || defaultColor
  const gradientColor = previousGraidents?.[0] || defaultGradient
  const image = previousImages?.[0] || defaultImage

  const setSolid = () => {
    onChange(solidColor)
  }

  const setGradient = () => {
    onChange(gradientColor)
  }

  const setImage = () => {
    onChange(image)
  }

  return (
    <div style={{ paddingBottom: 10 }}>
      <div>
        <div
          style={{
            height: 30,
            background: '#9A9A9A26',
            borderRadius: 8,
            padding: 3,
            ...df,
            ...jc,
            ...ac,
          }}
        >
          <div style={{ ...controlBtn, ...controlBtnStyles(!isGradient && !isImage), ...df, ...ac }} onClick={setSolid}>
            Solid
          </div>
          <div style={{ ...controlBtn, ...controlBtnStyles(isGradient), ...df, ...ac }} onClick={setGradient}>
            Gradient
          </div>
          <div style={{ ...controlBtn, ...controlBtnStyles(isImage), ...df, ...ac }} onClick={setImage}>
            Image
          </div>
        </div>
      </div>
    </div>
  )
})

export const controlBtnStyles = selected => ({
  background: selected ? '#9A9A9A26' : '',
})
