import { Box } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { Menu } from 'components'
import { CMSContext, ProgressContext, ProjectContext } from 'context'
import { Folder } from 'context/types'
import { clearVariables } from 'hooks'
import { TopPanelCMS } from 'partials'
import { assetsId, collectionNameToFind, extractPath, getCollectionName, rootFolderId } from 'services/cms'
import { firestore } from 'services/firebase'
import { CollectionsItems, FoldersItems } from './components'
import { CollectionRecords, CollectionSchema } from './pages'

export const CMS: React.FC = () => {
  const themeContext = useContext(ThemeContext)
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
    config: {
      data: { collections = [] },
    },
  } = useContext(ProjectContext)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const folderId = searchParams.get('folder')
  const [isSchemaPage, setIsSchemaPage] = useState(false)
  const [folders, setFolders] = useState<Folder[]>([])
  const { collectionId, recordId } = extractPath(pathname.split('/').slice(4).join('/'))

  const { rootCollections, assets } = useMemo(() => {
    return {
      rootCollections: collections.filter(el => el.name !== assetsId && el.name.split('/').length === 1),
      assets: collections.find(el => el.name === assetsId) || null,
    }
  }, [collections])

  const { currentCollection, currentFolder } = useMemo(() => {
    const currentFolder = folders.find(el => el.id === folderId)
    return currentFolder && assets
      ? {
          currentCollection: { ...assets, name: getCollectionName(currentFolder.name) },
          currentFolder,
        }
      : !folderId
      ? {
          currentCollection: collections.find(
            el => collectionNameToFind(el.name) === collectionNameToFind(collectionId)
          ),
        }
      : {}
  }, [folderId, folders, assets, collections, collectionId])

  useEffect(() => {
    document.title = 'Collections | CodePlatform'
    const unsubscribe = firestore.collection(`projects/${id}/folders`).onSnapshot({
      next: res => {
        const foldersRes = res.docs.map(el => el.data())
        setFolders([{ id: rootFolderId, name: rootFolderId, isSystem: true }, ...foldersRes] as Folder[])
      },
      error: err => toast(err),
    })
    return () => {
      unsubscribe()
      clearVariables()
    }
  }, [])

  useEffect(() => {
    if (!collectionId && rootCollections.length) {
      navigate(`/projects/${id}/collections/${rootCollections[0].name}`, { replace: true })
    }
  }, [collectionId, JSON.stringify(rootCollections)])

  return (
    <>
      <TopPanelCMS
        isSchemaPage={isSchemaPage}
        setIsSchemaPage={setIsSchemaPage}
        collectionId={collectionId}
        recordId={recordId}
        currentCollection={currentCollection}
        currentFolder={currentFolder}
      />
      <Box
        height="calc(100% - 60px)"
        display="flex"
        justifyContent="space-between"
        bgcolor={themeContext.colors.secondaryBackground}
      >
        <Menu
          firstChild={
            <CollectionsItems
              collections={collections}
              currentCollection={currentCollection}
              setIsSchemaPage={setIsSchemaPage}
            />
          }
          secondChild={
            <FoldersItems folders={folders} currentFolder={currentFolder} setIsSchemaPage={setIsSchemaPage} />
          }
        />
        {currentCollection && (
          <CMSContext.Provider
            key={currentCollection.name + currentFolder?.id}
            value={{ rootCollections, assets, folders }}
          >
            {isSchemaPage ? (
              <CollectionSchema
                collectionId={collectionId}
                currentCollection={currentCollection}
                setIsSchemaPage={setIsSchemaPage}
              />
            ) : (
              <CollectionRecords
                page="collections"
                collectionId={collectionId}
                recordId={recordId}
                currentCollection={currentCollection}
                currentFolder={currentFolder}
              />
            )}
          </CMSContext.Provider>
        )}
      </Box>
    </>
  )
}
