import React from 'react'

import { CustomSectionsWrapper, Text, Type } from 'components'
import { Links, SettingsLayout } from '../components'
import { CustomCredentials } from './sections'

export const Appetize: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Appetize</Text>
    <Text type={Type.SUB_TITLE}>Preview and test your iOS and Android apps directly in your browser.</Text>
    <Links />
    <CustomSectionsWrapper>
      {/* <Notification
        color={NotificationColor.BLUE}
        open
        inside
        title="Live app previews"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip"
      /> */}
      <CustomCredentials />
    </CustomSectionsWrapper>
  </SettingsLayout>
)
